import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { FieldSet } from "../_graphql/schema";
import { BaseService } from "./base.service";
import { Subscribable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FieldsetsService extends BaseService<FieldSet> {
  FieldBase = gql`
  fragment FieldBase on Field {
    id
    name
    type
    label
    description
    orderNumber
    defaultValue
    displayType
    cssClass
    disabled
    visible
    dependencyPattern
    validation { 
      required 
      ...on ArrayValidation 
        { min max }
      ...on NumberValidation 
        { min max step } 
      ...on StringValidation 
        { minLength maxLength pattern } 
      ...on DateValidation 
        { minDate maxDate dateLimitStep dateFutureLimit datePastLimit } 
    }
  }
  `
  FieldFull = gql`
  fragment FieldFull on Field {
    ...FieldBase
    items { 
      ...FieldBase
      items {
        ...FieldBase
        items {
          ...FieldBase
          items {
            ...FieldBase
          }
        }
      }
    }
  }
  ${this.FieldBase}

  `
  selectOneFields = gql`
    fragment SelectOneFields on FieldSet {
      id
      name
      description
      companyId
      rootField {
        ...FieldFull
      }
      isSystemLocked
      isDefault
    }
    ${this.FieldFull}
  `;

  selectAllFields = gql`
    fragment SelectAllFields on FieldSet {
      id
      name
      description
      companyId
      created
      rootField {
        ...FieldFull
      }
      isSystemLocked
      isDefault
    }
    ${this.FieldFull}
  `;

  getFieldSetWithoutFieldsQuery = gql`
    query fieldSets {
      fieldSets {
        data {
          id name isDefault
        }
      }
    }
  `;

public getFieldSetWithoutFields(): Subscribable<FieldSet[]> {
  return super.query(this.getFieldSetWithoutFieldsQuery);
  }

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql("fieldSet", "fieldSets");
  }
}
