import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-type',
  templateUrl: './check-type.component.html',
  styleUrls: ['./check-type.component.scss']
})
export class CheckTypeComponent implements OnInit {
  @Input('checkType') checkType: string;

  constructor(
  ) { }
  ngOnInit(): void {

  }
}
