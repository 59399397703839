import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { ApplicationsComponent } from './applications.component';
import { ApplicationItemComponent } from './application-item/application-item.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { CheckTypeComponent } from 'src/app/components/check-type/check-type.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ApplicationsComponent,
    ApplicationItemComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    WrappersModule,
  ]
})
export class ApplicationsModule { }
