<mat-icon class="warning" matTooltip="{{'purchaseStatus_CREATED'|translate}}" *ngIf="status == 'CREATED'">
    outbound
</mat-icon>
<mat-icon class="warning" matTooltip="{{'purchaseStatus_COMPLETED'|translate}}" *ngIf="status == 'COMPLETED'">
    outbound
</mat-icon>
<mat-icon class="warning" matTooltip="{{'purchaseStatus_AUTHORIZED'|translate}}" *ngIf="status == 'AUTHORIZED'">
    outbound
</mat-icon>
<mat-icon class="ok" matTooltip="{{'purchaseStatus_CANCELED'|translate}}" *ngIf="status == 'CANCELED'">check_circle
</mat-icon>
<mat-icon class="warning" matTooltip="{{'purchaseStatus_CHARGED'|translate}}" *ngIf="status == 'CHARGED'">check_circle
</mat-icon>
<mat-icon class="error" matTooltip="{{'purchaseStatus_FAILED'|translate}}" *ngIf="status == 'FAILED'">error
</mat-icon>
<mat-icon class="info" matTooltip="{{'transactionStatus_INITIATED'|translate}}" *ngIf="status == 'INITIATED'">cancel
</mat-icon>
<mat-icon class="error" matTooltip="{{'purchaseStatus_PENDING'|translate}}" *ngIf="status == 'PENDING'">dangerous
</mat-icon>
<mat-icon class="error" matTooltip="{{'purchaseStatus_REFUNDED'|translate}}" *ngIf="status == 'REFUNDED'">dangerous
</mat-icon>
<mat-icon class="error" matTooltip="{{'purchaseStatus_VOIDED'|translate}}" *ngIf="status == 'VOIDED'">dangerous
</mat-icon>