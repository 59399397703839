import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscribable, Subscription } from 'rxjs';
import { Session } from 'src/app/_graphql/schema';
import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';

@Component({
  selector: 'app-certificate-public',
  templateUrl: './certificate-public.component.html',
  styleUrls: ['./certificate-public.component.scss']
})
export class CertificatePublicComponent implements OnInit, OnDestroy {
  onRoute$: Subscription;
  item$: Subscribable<Session>;
  constructor(
    private route: ActivatedRoute,
    private ss: SessionsCompanyService
  ) {
    this.onRoute$ = this.route.queryParams.subscribe(params => {
      this.item$ = this.ss.publicSession(this.route.snapshot.paramMap.get('id'));
    });
  }

  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.onRoute$?.unsubscribe();
  }
}
