<h1 mat-dialog-title>Translation</h1>
<mat-dialog-content>
  <div fxLayout='column' class="item-container" *ngIf="languages">
    <form [formGroup]="form" fxFlex fxLayout="column" fxLayoutGap="12px" style="padding: 0 12px;">
      <div fxLayout="row" class="form-row" fxLayoutGap="12px">
        <mat-form-field class="full-width">
          <mat-label>{{ 'Key' }}</mat-label>
          <input matInput type="text" formControlName="key" />
        </mat-form-field>
      </div>

      <div formArrayName="langs">
        <div *ngFor="let frm of langsFormArray.controls; let i = index;" [formGroupName]="i">
          <div fxLayout="row" class="form-row" fxLayoutGap="12px">
            <mat-form-field class="full-width">
              <mat-label>{{ languageName(frm.value.languageId) }}</mat-label>
              <textarea matInput type="text" formControlName="value" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
            <!-- <mat-icon *ngIf="true" (click)="convert(frm, frm.value.languageId)">arrow_left</mat-icon> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="convertAndSave()" [disabled]="loading">Auto Save</button>
  <!-- <button mat-button color="warn" (click)="restore()" *ngIf="">Auto Save</button> -->
  <div fxFlex></div>
  <button mat-button mat-dialog-close [disabled]="loading">Close</button>
  <button mat-button color="primary" (click)="save()" [disabled]="loading">Save</button>
</mat-dialog-actions>