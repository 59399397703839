import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.hasPermission(route.data?.permission)) {
      console.log(route.data)
      if (route.data.redirectPath) {
        this.router.navigate([route.data.redirectPath], {
          queryParams: { returnUrl: state.url }
        });
      }

      return false;
    }
    return true;
  }

}
