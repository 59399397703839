import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base.service';
import { Import } from '../_graphql/schema';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SanctionsService extends BaseService<Import> {
  selectOneFields = gql`
  fragment SelectOneFields on Import {
    id
    created 
    fileUrl
    checksEmpty
    checksWithResults
    individuals
    organizations
    moreInfo
    source
    user { id firstName lastName middleName }
  }`;

  selectAllFields = gql`
  fragment SelectAllFields on Import {
    id
    created deleted
    fileUrl
    checksEmpty
    checksWithResults
    individuals
    organizations
    moreInfo
    source
    user { id firstName lastName middleName }
  }`;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    delete this.queryParams.dateRange;
    delete this.queryParams.statsRange;
    this.initGql('import');
    this.queryParams.sortOrder = "DESC";
    this.queryParams.sortBy = "created";
  }
}
