import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { offsetLimitPagination } from '@apollo/client/utilities';

const uri = environment.apiUrl;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const middleware = new ApolloLink((operation, forward) => {
    let ls = localStorage.getItem('currentUser');
    let user;
    try {
      user = JSON.parse(ls);
    } catch (error) {
      localStorage.removeItem('currentUser')
      user = {};
    }

    if (user?.token) {
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', `Bearer ${user?.token}`)
      });
    }
    return forward(operation);
  });

  return {
    link: middleware.concat(httpLink.create({ uri })),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            inventories: {
              ...offsetLimitPagination(),
              read(existing, { args }) {

              }
            },
            setCompanyStatus: {
              ...offsetLimitPagination(),
              read(existing, { args }) {

              }
            },
            companies: {
              ...offsetLimitPagination(),
              read(existing, { args }) {

              }
            },
            providerChecks: {
              ...offsetLimitPagination(),
              read(existing, { args }) {

              }
            },
            providers: {
              ...offsetLimitPagination(),
              read(existing, { args }) {

              }
            },
            countries: {
              ...offsetLimitPagination(),
              read(existing, { args }) {

              }
            },
            imports: {
              ...offsetLimitPagination(),
              read(existing, { args }) {

              }
            },
            translations: {
              ...offsetLimitPagination(),
              read(existing, { args }) {
              }
            },
            sessions: {
              ...offsetLimitPagination(),
              read(existing, { args }) {
              }
            },
            users: {
              ...offsetLimitPagination(),
              read(existing, { args }) {
              }
            },
            applications: {
              ...offsetLimitPagination(),
              read(existing, { args }) {
              }
            },
            configurations: {
              ...offsetLimitPagination(),
              read(existing, { args }) {
              }
            },
            companyChecks: {
              ...offsetLimitPagination(),
              read(existing, { args }) {
              }
            },
            companyTokens: {
              ...offsetLimitPagination(),
              read(existing, { args }) {
              }
            }
          },
        },
      },
    }),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
