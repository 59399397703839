<div>
    <div>
        <span *ngFor="let item of items; index as k">
            <span *ngIf="k==0">
                <a [routerLink]="linkPart+ item?.id" routerLinkActive="router-link-active">{{
                    item?.name}}</a>
            </span>
        </span>
        <span *ngIf="items.length>1">
            <button mat-button [matMenuTriggerFor]="menu">+ {{items.length -1}} more</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let item of items | slice:1" [routerLink]="linkPart+ item?.id"
                    routerLinkActive="router-link-active">{{
                    item?.name}}</button>

            </mat-menu>
        </span>
    </div>
</div>