import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Permission, Product, ProductCheck } from 'src/app/_graphql/schema';

import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

import { UiService } from 'src/app/_services/ui.service';
import { ProductsService } from 'src/app/_services/products.service';
import { ProductChecksService } from 'src/app/_services/product-checks.service';

@Component({
  selector: 'app-product-checks',
  templateUrl: './product-checks.component.html',
  styleUrls: ['./product-checks.component.scss']
})
export class ProductChecksComponent implements OnInit {
  $data: Subscribable<ProductCheck[]>;
  constructor(
    public service: ProductChecksService,
    public auth: AuthService,
    private router: Router,
    public ui: UiService,
  ) {
  }

  ngOnInit(): void {
    this.$data = this.service.all();

  }


}
