import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { PersonOrBusiness } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { InventoriesService } from 'src/app/_services/inventories.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

  data$: Subscribable<PersonOrBusiness[]>;

  constructor(
    private router: Router,
    public auth: AuthService,
    public service: InventoriesService
  ) {
    this.data$ = this.service.all();
  }

  navigateTo(referenceId) {
    this.router.navigate(['/', 'company', 'sessions', 'q', referenceId]);
  }
  ngOnInit(): void {


  }
}
