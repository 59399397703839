import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Person } from 'src/app/_graphql/schema';
import { Check } from 'src/app/_graphql/schema-session';

@Component({
  selector: 'app-view-person',
  templateUrl: './view-person.component.html',
  styleUrls: ['./view-person.component.scss']
})
export class ViewPersonComponent implements OnInit, OnDestroy {
  @Input('person') person: Person = null;

  constructor(
  ) { }
  ngOnInit(): void {

  }
  ngOnDestroy(): void {

  }
}
