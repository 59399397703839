<list-wrapper #wrapper [mainService]="service" navigateItem="invoices" title="Invoices"
  [readPerm]="auth.hasPermission(auth.perm.CompanyRead)" [managePerm]="false" [showDateFilter]="true">
  <div toolbox>
    <mat-form-field appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="statusFilter" multiple>
        <mat-option *ngFor="let item of transactionStatuses | keyvalue" [value]="item.value">
          {{('transactionStatus_'+item.value)|translate}}</mat-option>
        <mat-select-trigger>{{statusFilter?.value?.length }} selected</mat-select-trigger>

      </mat-select>
    </mat-form-field>
  </div>
  <mat-action-list fxLayout="column" class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>

      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Invoice Number' | translate}}</div>
      </div>
      <div fxFlex="110px">
        <div mat-line>{{'Created'|translate}}</div>
      </div>
      <div fxFlex="110px" class="text-right">
        <div mat-line>{{'Amount'|translate}}</div>
      </div>
    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item?.id)">
      <app-transaction-status matListIcon [status]="item?.status"></app-transaction-status>
      <strong mat-line>{{item?.name}}</strong>

      <div fxFlex="110px">
        <small mat-line>{{item?.created | date }}</small>
      </div>
      <div fxFlex="110px" class="text-right">
        <div mat-line>{{item?.totalPrice | currency }}</div>
      </div>
      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>