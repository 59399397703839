import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable } from 'rxjs';
import { Country, FieldSet } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CountriesService } from 'src/app/_services/countries.service';
import { FieldsetsService } from 'src/app/_services/fieldsets.service';

@Component({
  selector: 'app-fieldset-item',
  templateUrl: './fieldset-item.component.html',
  styleUrls: ['./fieldset-item.component.scss']
})
export class FieldsetItemComponent implements OnInit {
  data$: Subscribable<FieldSet>;

  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    description: [null, [Validators.required]],
    isSystemLocked: [false, []],
    rootField: [null, []],
  });

  constructor(
    public auth: AuthService,
    public service: FieldsetsService,
    private fb: FormBuilder
  ) {

  }

  ngOnInit(): void {
  }

}
