import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { BehaviorSubject, map, Subscribable } from 'rxjs';
import { Permission } from '../_graphql/schema';
import { Session } from '../_graphql/schema-session';
import { BaseService } from './base.service';
import { AuthService } from './auth.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SessionsCompanyService extends BaseService<Session> {
  DocumentFields = gql`
    fragment DocumentFields on Document {
      id
      cityOfIssue
      countyOfIssue
      serialNumber
      country
      districtOfIssue
      provinceOfIssue
      state
      type
      expiry
      idType
      individualReferenceNumber
      serialNumber
      cardType
      number
      images {
        id
        documentId
        description
        #imageBase64
        fileId
        name
      }
    }
  `;

  BusinessDocumentFields = gql`
  fragment BusinessDocumentFields on BusinessDocument {
    id
    number
    type
    expiry
  }
  `;
  AddressFields = gql`
  fragment AddressFields on Address {
      addressIdentifier
      id
      city
      countryCode
      postalCode
      province
      street
      streetLine2
      streetNumber
    }
  `;
  CommunicationFields = gql`
  fragment CommunicationFields on Communication {
      communicationIdentifier
      email
      ipAddress
      linkedIn
      phone
      website
      websiteIp
    }
  `;

  PersonShort = gql`
  fragment PersonShort on Person {
    id
    firstName
    middleName
    lastName
    title
    gender
    socialNumber
    referenceId
    nationality
  }`;

  PersonFields = gql`
  fragment PersonFields on Person {
    id
    lastName
    firstName
    middleName
    dob
    gender
    isoLatin1Name
    title
    role
    socialNumber
    referenceId
    nationality
    documents { ...DocumentFields }
    addresses { ...AddressFields }
    communications { ...CommunicationFields }
  }
  ${this.DocumentFields}
  ${this.AddressFields}
  ${this.CommunicationFields}`;

  BusinessShort = gql`
  fragment BusinessShort on Business {
    id companyName companyIdType companyId referenceId
  }`;





  selectOneFields = gql`
  fragment SelectOneFields on Session  {
    id
    overallCheckStatus
    isTesting
    code
    apiToken { name }
    status
    description
    configurationId
    isTesting
    configuration {
      id
      name
      description
      checks { id providerCheck { id name fieldSetId modified fieldSet { modified } }}
    }
    responseTransport
    created
    modified
    errorCode
    errorDescription
    userId
    user { id firstName lastName middleName }
    company { id name }
    persons { ...PersonFields }
    businesses {
      id
      companyId
      companyIdType
      referenceId
      foundingDate
      companyName
      documents { ...BusinessDocumentFields }
      addresses { ...AddressFields }
      communications { ...CommunicationFields }
      persons { ...PersonFields }
    }
    checks {
      id
      checkType
      checkStatus
      created
      started
      modified
      finished
      deleted
      errorCode
      errorDescription
      matches
      results (skip: 0, take: 10000) {
        id
        status 
        note
        category
        falsePositive
        truePositive
        falsePositiveUser { id firstName middleName lastName }
        truePositiveUser { id firstName middleName lastName }
        person {...PersonShort } 
        business { ...BusinessShort }
        groups {
          id
          group
          sequenceNumber
          fields {
            id
            field
            value
          }
        }
      }
    }
  }
  ${this.PersonShort}
  ${this.BusinessShort}
  ${this.DocumentFields}
  ${this.AddressFields}
  ${this.CommunicationFields}
  ${this.PersonFields}
  ${this.BusinessDocumentFields}
  `;

  //    Do not work:
  //    # price @include(if: ${this.auth.hasPermGraph(Permission.ConfigurationPricingRead)})
  //    # cost @include(if: ${this.auth.hasPermGraph(Permission.ProviderPricingRead)})

  selectAllFields = gql`
  fragment SelectAllFields on Session  {
    id
    status
    ${this.auth.allowedField(Permission.ConfigurationPricingRead, 'price')}
    ${this.auth.allowedField(Permission.ProviderPricingRead, 'cost')}
    code
    overallCheckStatus
    responseTransport
    description
    created
    apiToken{ name }
    user { id firstName lastName middleName }
    modified
    companyId
    persons { id firstName lastName middleName }
    businesses { id companyName }
    configurationId
    isTesting
    configuration {
      id
      name
      description
    }
    checks {
      id
      checkType
      checkStatus
      errorCode
      errorDescription
      matches      
    }
  }
  `;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar, protected auth: AuthService) {
    super(apollo, sb);

    this.initGql('session', 'sessions');
    this.queryParams.take = 25;
    this.queryParams.sortOrder = "DESC";
    this.queryParams.sortBy = "created";
  }

  gqlUpdateCheckResult: DocumentNode = gql`
  mutation updateCheckResult($id:ID!, $falsePositive: Boolean!, $note: String!) {
    updateCheckResult(
      id: $id,
      falsePositive: $falsePositive,
      note: $note
    ) {
      id status note
      falsePositive
      truePositive
      falsePositiveUserId
      falsePositiveUser { id firstName middleName lastName }
      truePositiveUserId
      truePositiveUser { id firstName middleName lastName }
      sessionCheck { 
        id checkStatus modified  
        session {
          id status overallCheckStatus
        }
      }
    }
  }`;
  public updateCheckResult(sessionId: string, resultId: string, falsePositive: boolean, note: string): Subscribable<any> {
    // var data: any = this.apollo.client.readQuery({
    //   query: this.selectOneQuery, variables: {
    //     id: sessionId,
    //   },
    // });
    // var _data = Object.assign({}, data.session)
    // _data.status = "UNDONE"
    // console.log(_data)
    // this.apollo.client.writeQuery({
    //   query: this.selectOneQuery,
    //   variables: {
    //     id: sessionId,
    //   },
    //   data: {
    //     session: _data
    //   },
    // });
    // this.addRefetchQuery(this.selectOneQuery, { id: sessionId })
    var m = super.mutation(this.gqlUpdateCheckResult, { id: resultId, falsePositive, note }, (store, { data: { submitComment } }) => {
      // Read the data from our cache for this query.
      const _data = store.readQuery(
        {
          query: this.selectOneQuery,
          variables: {
            id: sessionId,
          },
        }
      );
      var data = Object.assign({}, _data)
      // Add our comment from the mutation to the end.
      // console.log('////////////////////////')
      // console.log(data)
      // console.log('////////////////////////')
      // data.session.status = "kme"
      data.session.checks.forEach(check => {

        // console.log(check)
      });
      // Write our data back to the cache.
      // store.writeQuery({
      //   query: this.selectOneQuery, variables: {
      //     id: sessionId,
      //   }, data
      // });
    });

    return m
  }

  gqlPublicSession: DocumentNode = gql`
  query sessionCertificate($id:ID!) {
    sessionCertificate (id: $id){
      id
      code
      subjectName
      status
      overallCheckStatus
    }
  }`;
  public publicSession(sessionId: string): Subscribable<any> {
    return super.query(this.gqlPublicSession, { id: sessionId });
  }

  gqlPreSessionCreateCheck: DocumentNode = gql`
    mutation preSessionCreateCheck($configurationId: ID!){
      preSessionCreateCheck(configurationId: $configurationId)
    }
  `;
  public preSessionCreateCheck(configurationId): Subscribable<any> {
    return super.mutation(this.gqlPreSessionCreateCheck, { configurationId },);
  }

  gqlToggleMonitoring: DocumentNode = gql`
    mutation toggleMonitoring($sessionId: ID!, $enable: Boolean!){
      toggleMonitoring(sessionId: $sessionId, enable: $enable){
        ...SelectAllFields
      }
    }
    ${this.selectAllFields}
  `;

  public toggleMonitoring(sessionId): Subscribable<any> {
    var enable = false;
    this.addRefetchQuery(this.selectOneQuery, { id: sessionId })
    return super.mutation(this.gqlToggleMonitoring, { sessionId, enable },);
  }

  gqlImportSessions: DocumentNode = gql`
    mutation importBusinessData($fileId: ID, $configurationId: ID!, $description: String){
      importBusinessData(fileId: $fileId, configurationId: $configurationId, description: $description)
    }
  `;

  public importSessions(data: any): Subscribable<any> {
    console.log(data)
    this.addRefetchQuery(this.selectQuery, {})
    return super.mutation(this.gqlImportSessions, {
      fileId: data.fileId,
      configurationId: data.configurationId,
      description: data.description
    });
  }


  gqlGetSessionData = gql`
  query testEntities {
    testEntities {
      checkType
      country
      description
      ... on TestPersonGType {
        person {
          ...PersonFields
        }
      }
      ... on TestBusinessGType {
        business {
          id
          companyId
          companyIdType
          referenceId
          foundingDate
          companyName
          documents { ...BusinessDocumentFields }
          addresses { ...AddressFields }
          communications { ...CommunicationFields }
          persons { ...PersonFields }
        }
      }
    }
  }
  ${this.PersonFields}
  ${this.BusinessDocumentFields}
  ${this.AddressFields}
  ${this.CommunicationFields}
  `;

  public sessionTestDataForm = new BehaviorSubject<FormGroup>(null);
  public sessionTestDataForm$ = this.sessionTestDataForm.asObservable();

  public getSessionTestData(): Subscribable<any> {
    return this.apollo.watchQuery({
      query: this.gqlGetSessionData,
    }).valueChanges.pipe(
      map<any, any>((result: any) => {
        if (!result || !result.data) return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          var r = result.data[keys[0]];
          return r;
        }
        return null;
      })
    );
  }


  public getDashboard(data: any): Subscribable<any> {
    return this.apollo.watchQuery({
      variables: { pageRequestInput: data },
      query: gql`query dashboard ($pageRequestInput: PageRequestInput) {
        dashboard (pageRequestInput: $pageRequestInput) {
         sessionsCount
         personSessionsCount
         businessSessionsCount
         checksCount
         monitoringsCount
         personSessionsCount
        }
      }`,
    }).valueChanges.pipe(
      map<any, any>((result: any) => {
        if (!result || !result.data) return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          var r = result.data[keys[0]];
          return r;
        }
        return null;
      })
    );
  }

  getOneSessionQuery = gql`
  query sessions{
    sessions(pageRequestInput:{showDeleted:null, skip:0,take:1}) {
      data {
        id
      }
      totalCount
      __typename
    }
  }`

  getOneSession() {
    return this.query(this.getOneSessionQuery);
  }
}
