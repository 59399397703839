import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, first } from 'rxjs';
import { FieldSet, FieldType } from 'src/app/_graphql/schema';
import { Field } from 'src/app/_graphql/schema';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';
import { CustomValidator } from 'src/app/_validators/custom.validator';

@Component({
  selector: 'app-form-builder-definition',
  templateUrl: './form-builder-definition.component.html',
  styleUrls: ['./form-builder-definition.component.scss'],

})
export class FormBuilderDefinitionComponent implements OnInit {
  // rootField: any;
  form: FormGroup | FormArray | FormControl = this.fb.group({});
  _conf: Field;
  @Output() formValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() set conf(conf: any) {
    this._conf = conf;
    var ui = {};
    if (conf) {
      this.form = this.setupForm(this._conf);
    }
  }
  get conf() {
    return this._conf;
  }

  getFG(q: FormArray, idx: number): FormGroup {
    return q.at(idx) as FormGroup;
  }
  getFGbyName(q: FormGroup, key: string): FormGroup {
    return q.get(key) as FormGroup;
  }
  getFA(q: FormGroup, name: string): FormArray {
    return q.get(name) as FormArray
  }

  isArray(item: any) {
    return Array.isArray(item);
  }

  constructor(
    private providerCheckService: ProviderChecksService,
    private fb: FormBuilder,
  ) {
  }

  setupForm(conf: Field, parentIsFA = false): FormGroup | FormArray | FormControl {
    // if (conf?.type === 'ARRAY') {
    //   var def = {};
    //   var fa = this.fb.array([], [CustomValidator.minLengthArray(conf?.validation['min']),
    //   CustomValidator.maxLengthArray(conf?.validation['max'])]);
    //   conf.items.forEach(_conf => {
    //     fa.push(this.setupForm(_conf, true))
    //   });
    //   def[conf.name] = fa;

    //   return parentIsFA ? fa : this.fb.group(def)
    // }
    // else if (conf?.type === 'FG') {
    //   var def = {};
    //   var fg = this.fb.group({});

    //   conf.items.forEach(_conf => {
    //     if (_conf.type === "FG") {
    //       fg.addControl(_conf.name, this.setupForm(_conf, true))
    //       fg.addControl(_conf.name, this.setupForm(_conf, true))
    //     } else {
    //       fg.addControl(_conf.name, this.setupForm(_conf, _conf.type === "ARRAY"))
    //     }
    //   });
    //   def[conf.name] = fg;
    //   return parentIsFA ? fg : this.fb.group(def)
    // } else {

    // }

    var groupValidation = this.fb.group({});
    let _vldtn = conf?.validation;

    if (_vldtn.hasOwnProperty('required'))
      groupValidation.addControl('required', new FormControl(_vldtn?.required))
    if (_vldtn.hasOwnProperty('min'))
      groupValidation.addControl('min', new FormControl(_vldtn['min']))
    if (_vldtn.hasOwnProperty('max'))
      groupValidation.addControl('max', new FormControl(_vldtn['max']))
    if (_vldtn.hasOwnProperty('minLength'))
      groupValidation.addControl('minLength', new FormControl(_vldtn['minLength']))
    if (_vldtn.hasOwnProperty('maxLength'))
      groupValidation.addControl('maxLength', new FormControl(_vldtn['maxLength']))
    if (_vldtn.hasOwnProperty('pattern'))
      groupValidation.addControl('pattern', new FormControl(_vldtn['pattern']))

    var items = this.fb.array([]);
    if (conf?.items)
      conf?.items.forEach(_conf => {
        // console.log(_conf)
        items.push(this.setupForm(_conf, true))
      });
    var group = this.fb.group({
      id: [conf.id, []],
      name: [conf.name, [Validators.required]],
      type: [conf.type, [Validators.required]],
      displayType: [conf.displayType, []],
      validation: groupValidation,
      orderNumber: [null, []]
    })
    if (items.length) {
      group.addControl('items', items)
    }
    return group;

  }

  getValue() {
    console.log(this.form)
    console.log(this.conf)
  }




  ngOnInit(): void {
    let nesto = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((vals) => {
      this.formValue.emit(this.form.value)
    });

  }

  // get persons() {
  //   return (this.form.get('persons') as FormArray)?.controls;
  // }


  // generateForms(obj: Field): FormGroup | FormArray | FormControl {
  //   if (obj?.type === 'FG') {
  //     let formGroup = this.fb.group({});
  //     formGroup?.addControl(obj.name, this.fb.group({}));
  //     obj.items.forEach((item: any) => {
  //       // var k = this.generateForms(item);

  //       //   (formGroup.get(obj.name) as FormGroup)?.addControl();

  //       formGroup.setValidators(this.setValidators(item))
  //       formGroup.updateValueAndValidity();
  //     });

  //     return formGroup as FormGroup;
  //   }
  //   else if (obj?.type === 'ARRAY') {
  //     const formArray = this.fb.array([]);
  //     obj.items.forEach((item: any) => {
  //       formArray.push(this.generateForms(item));
  //       formArray.setValidators(this.setValidators(item))
  //       formArray.updateValueAndValidity();
  //     });
  //     var fa = {}
  //     fa[obj.name] = formArray;
  //     return this.fb.group(fa) as FormGroup;
  //   } else {
  //     let control = this.fb.control(null, this.setValidators(obj));

  //     return control as FormControl;
  //   }
  // }
  setValidators(field: any) {
    const validators = [];
    if (field?.validation && field?.validation?.required) {
      validators.push(Validators.required);
    }
    if (field?.validation && field?.validation?.minLength) {
      validators.push(Validators.minLength(field.validation.minLength));
    }
    if (field?.validation && field?.validation?.maxLength) {
      validators.push(Validators.maxLength(field.validation.maxLength));
    }
    if (field?.validation && field?.validation?.pattern) {
      validators.push(Validators.pattern(field.validation.pattern));
    }
    return validators;
  }

  clog(a) {
    console.log(a)
  }

}
