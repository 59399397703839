import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { take, Unsubscribable } from 'rxjs';

import { AuthService } from 'src/app/_services/auth.service';
import { EventBusService } from 'src/app/_services/event-bus.service';


import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-RESULT-item',
  templateUrl: './result-item.component.html',
  styleUrls: ['./result-item.component.scss']
})
export class ResultItemComponent implements OnInit, OnDestroy {
  sessionId: string;
  resultId: string;
  item$: Unsubscribable;
  item: any;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild("panelTop") panelTop: ElementRef;


  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  form: FormGroup;
  route$: Unsubscribable;

  constructor(
    public service: SessionsCompanyService,
    private route: ActivatedRoute,
    private eventBusService: EventBusService,
    private fb: FormBuilder,
    private ui: UiService,
    private _ngZone: NgZone,
    private titleService: Title,
    public auth: AuthService,
  ) {
    this.form = this.fb.group({
      note: [null, Validators.required],
    })
    this.titleService.setTitle("Session")
    this.route$ = this.route.params.subscribe(params => {
      this.loadData(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('resultId'));
    });
  }


  loadData(sessionId: string, resultId: string) {
    if (resultId == null || this.resultId == resultId) {
      return;
    }
    this.sessionId = sessionId;
    this.resultId = resultId;
    this.item$ = this.service.one(this.sessionId).subscribe(
      {
        next: val => {
          val?.checks.forEach(check => {
            check?.results.forEach(result => {
              if (result?.id == this.resultId) {
                this.item = result;
                this.panelTop?.nativeElement.scrollIntoView({ block: "start" });
              }
              // console.log(result);
            });
          });
        },
        error: err => this.ui.snack(err),
      }
    );
  }

  ngOnInit(): void { }
  onSubmit(falsePositive: boolean) {
    var note = this.form.get('note');
    this.service.updateCheckResult(this.sessionId, this.resultId, falsePositive, note.value).subscribe(
      {
        next: result => {
          this.form.patchValue({ note: null })
          this.navigateNext();
        },
        error: err => { this.ui.openSnackBar(err, "OK"); this.navigateNext(); }
      }
    )
  }
  navigateNext() {
    this.eventBusService.data$.next({ name: 'onSessionNavigateToNextResult', data: this.resultId })
  }
  ngOnDestroy(): void {
    this.item$?.unsubscribe();
    this.route$?.unsubscribe();
  }
}
