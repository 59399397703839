import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Provider } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { ProvidersService } from 'src/app/_services/providers.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {

  $data: Subscribable<Provider[]>;

  constructor(
    public auth: AuthService,
    public providers: ProvidersService,) {
  }

  ngOnInit(): void {
    this.$data = this.providers.all();
  }

}
