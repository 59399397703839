import { query } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, Subject, Unsubscribable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Permission } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { FiltersManagementService } from 'src/app/_services/filters-management.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'list-wrapper',
  templateUrl: './list-wrapper.component.html',
  styleUrls: ['./list-wrapper.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' }, },
  ],
})

export class ListWrapperComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  selectedRange = ''
  possibleRanges = ['today', 'yesterday', 'lastWeek', 'lastMonth', 'last7Days', 'last30Days']
  dateRange = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
  });
  queryString: FormControl;
  _showDeleted: boolean = false;
  @Input('other') other: any;
  @Input('showQueryField') showQueryField = true;
  @Input('showDeletedEnabled') showDeletedEnabled = false;
  @Input('mainService') mainService: any;
  @Input('navigateItem') navigateItem: string;
  @Input('title') title: string;
  @Input('showPagination') showPagination: boolean = true;
  @Input('showDateFilter') showDateFilter: boolean = false;

  @Input('readPerm') readPerm: Permission;
  @Input('managePerm') managePerm: Permission;
  @Output() onDateChanged: EventEmitter<any> = new EventEmitter();
  data$: Unsubscribable;
  dateFilter$: Unsubscribable;
  previousUrl: string | undefined;
  currentUrl: string | undefined;
  private destroy$ = new Subject<void>();
  constructor(
    public ui: UiService,
    public router: Router,
    public auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private filtersManagement: FiltersManagementService,
  ) {
   }
   clearFilter(property: string): void {
    if (this.filtersManagement.getFilters().hasOwnProperty(property)) {
      delete this.filtersManagement.getFilters()[property];
    }
  }
  ngOnDestroy() {
    this.dateFilter$?.unsubscribe();
    this.onDestroy$.next();

    if (!this.other)
      return;
    Object.keys(this.other)?.forEach(e => {
      if (typeof this.other[e]?.unsubscribe === 'function')
        this.other[e]?.unsubscribe();
    });
  }

  ngOnInit(): void {

    this.queryString = new FormControl(this.mainService.queryParams.query);
    if (this.showDateFilter) {
      this.dateFilter$ = this.dateRange.valueChanges.subscribe(vals => {
        this.mainService.queryParams.startDate = vals.startDate;
        this.mainService.queryParams.endDate = vals.endDate;
        this.onDateChanged.emit(null);
        this.mainService.fetchMoreData();
      })
    }
    this.ui.setTitle(this.title);
    this.mainService.queryParams.showDeleted = false;

    this.queryString.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(query => {
      this.mainService.pageIndex = 0;
      this.mainService.queryParams.skip = 0;
      this.mainService.queryParams.query = query;
      this.mainService.fetchMoreData();
    });

    this.mainService?.all().pipe(
      takeUntil(this.onDestroy$),
      catchError(err => {
        this.ui.snack(err.message);
        return EMPTY;
      })
    ).subscribe((data) => {
     this.router.events
    .pipe(takeUntil(this.destroy$), filter(event => event instanceof NavigationEnd 
      || event instanceof NavigationStart))
    .subscribe((event: NavigationEnd | NavigationStart) => {
      if (event instanceof NavigationStart) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.previousUrl?.includes(this.navigateItem) !== this.currentUrl?.includes(this.navigateItem) 
        && !this.previousUrl?.includes(this.navigateItem)) {
          this.clearFilter(this.navigateItem);
        }
      }
    });
    });
  }

  public newItem() {
    var path = ['..', this.navigateItem, 'new'];
    this.router.navigate(path, { relativeTo: this.activatedRoute });
  }

  public edit(id: number | string | null | undefined) {
    var path = [id];
    this.router.navigate(path, { relativeTo: this.activatedRoute });
  }

  showDeleted() {
    this.mainService.pageIndex = 0;
    this.mainService.skip = 0;
    this._showDeleted = !this._showDeleted;
    this.mainService.showDeleted(this._showDeleted);
  }
}
