import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-status',
  templateUrl: './session-status.component.html',
  styleUrls: ['./session-status.component.scss']
})
export class SessionStatusComponent implements OnInit {
  @Input('status') status: String;

  constructor(
  ) { }
  ngOnInit(): void {

  }
}
