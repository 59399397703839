<mat-card fxFlex fxLayout="column" class="subscription-card-item">
  <mat-card-title fxLayout="column" fxLayoutAlign="start center">
    {{item?.name}}
    <img [src]="'/assets/images/'+item?.icon" />

    <div class="prices"><span class="old" *ngIf="item?.oldPrice">
        {{
        item?.oldPrice | currency }}
      </span>
      <span class="new" *ngIf="item?.price">{{
        item?.price | currency }}/month</span>
      <span class="new">{{item?.description}}</span>
    </div>
    <h3>

    </h3>
    <p>{{ item?.alternateDescription }}</p>
  </mat-card-title>
  <mat-card-content fxFlex>

    <div *ngFor="let itm of item?.productChecks" class="itm">

      {{ itm?.name }}
      <small>{{ itm?.description }}</small>


      {{ itm?.includedChecks }} checks included<span *ngIf="itm?.nextPrice>0">, then {{
        itm?.nextPrice | currency }} per check</span>
    </div>
    <div *ngFor="let itm of item?.checks" class="itm">
      <strong>{{ itm?.name }}</strong>
      <div class="desc">{{ itm?.description }}</div>

      <small><span *ngIf="itm?.includedChecks ">
          {{ itm?.includedChecks }} {{itm?.includedChecks > 1 ? 'checks' : 'check'}} included</span><span
          *ngIf="itm?.includedChecks > 0 && itm?.nextPrice">,
          then
        </span><span *ngIf="itm?.nextPrice"> {{
          itm?.nextPrice | currency }}
          {{('paymentInterval_'+itm?.paymentInterval) | translate | lowercase
          }}

        </span>
      </small>
    </div>

    <div *ngFor="let itm of item?.features"
      [ngClass]="{itm: true, no: itm?.icon == 'no', yes: itm?.icon == 'yes'}">
      <strong>{{ itm?.name }}</strong>
      <small>{{ itm?.description }}</small>
    </div>
  </mat-card-content>
  <mat-card-actions align="center" style="margin: 0px 24px 24px 24px" *ngIf="(company$ | async)?.allowedPaymentMethod === 'CARD'">
    <ng-content> </ng-content>
  </mat-card-actions>
</mat-card>

<!-- <pre>{{ item | json }}</pre> -->