<item-wrapper #item [mainService]="service" [form]="form" navigateList="company" title="Company" [backOnSave]="true"
  [readPerm]="auth.hasPermission(auth.perm.CompanyRead)" [managePerm]="auth.hasPermission(auth.perm.CompanyManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
    <div fxLayout="row" fxLayoutGap="16px">
      <app-avatar-upload fxFlex="120px" formControlName="avatarId"></app-avatar-upload>
      <div fxFlex fxLayout="column">
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
          <mat-form-field fxFlex.gt-md>
            <mat-label>{{ 'Name' |translate }}</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
          <mat-form-field fxFlex.gt-md="25">
            <mat-label>{{ 'ID Number' | translate }}</mat-label>
            <input matInput formControlName="idNumber" />
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'Description' }}</mat-label>
            <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
          </mat-form-field>
          <mat-form-field fxFlex.gt-md="25">
            <mat-label>{{ 'VAT Number' | translate }}</mat-label>
            <input matInput formControlName="vatNumber" />
          </mat-form-field>
        </div>
      </div>
    </div>





    <h3>Communication</h3>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'E-mail' |translate }}</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Website' |translate }}</mat-label>
        <input matInput formControlName="website" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Phone Number' |translate }}</mat-label>
        <input matInput formControlName="phoneNumber" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'IP Address' | translate }}</mat-label>
        <input matInput formControlName="ipAddresses" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Facebook' |translate }}</mat-label>
        <input matInput formControlName="facebook" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'LinkedIn' |translate }}</mat-label>
        <input matInput formControlName="linkedin" />
      </mat-form-field>
    </div>
    <h3>Address</h3>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 1' | translate }}</mat-label>
        <input matInput formControlName="address" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 2' | translate }}</mat-label>
        <input matInput formControlName="address2" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex="25">
        <mat-label>{{ 'Postal Code' | translate }}</mat-label>
        <input matInput formControlName="zip" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'City' | translate }}</mat-label>
        <input matInput formControlName="city" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Country' | translate }}</mat-label>
        <mat-select formControlName="countryId">
          <mat-option *ngFor="let item of $countries | async" [value]="item.id">
            {{(item.name) | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'State' | translate }}</mat-label>
        <input matInput formControlName="state" />
      </mat-form-field>
    </div>

    <h3>{{'Contact Person'|translate}}</h3>
    <div fxLayout="row" class="form-row" fxLayoutGap="12px">
      <mat-form-field fxFlex="25">
        <mat-label>{{ 'Position' |translate }}</mat-label>
        <input matInput formControlName="contactPersonPosition" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Full Name' |translate }}</mat-label>
        <input matInput formControlName="contactPersonName" />
      </mat-form-field>
    </div>
  </form>
</item-wrapper>