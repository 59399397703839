import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'getKeysOf' })
export class getKeysOf implements PipeTransform {
    transform(list: any[] | null, k: string[]) {
        var getVal = function (obj, keys) {
            var o = obj,
                len = keys.length;
            for (var i = 0; i < len - 1; i++) {
                var key = keys[i];
                if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key]) {
                    o = o[key];
                } else {
                    throw new Error('Key ' + key + ' is not an object or is missing.');
                }
            }
            return o[keys[len - 1]]
        }
        var r = [];
        if (list) {
            list.forEach(item => {
                r.push(getVal(item, k));
            });
        }
        return r;
    }
}
