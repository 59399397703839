import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UiService } from 'src/app/_services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountriesService } from 'src/app/_services/countries.service';
import { Country } from 'src/app/_graphql/schema';
import { Subscribable } from 'rxjs';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  hide = true;
  form: FormGroup;
  $countries: Subscribable<Country[]>;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private ui: UiService,
    public countriesService: CountriesService,
    private snackBar: MatSnackBar,
  ) {
    this.$countries = this.countriesService.all();
    this.form = this.fb.group({
      companyId: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      vatNumber: [null, Validators.required],
      address: [null, [Validators.required]],
      address2: [null],
      zip: [null, [Validators.required]],
      city: [null, [Validators.required]],
      countryId: [null, [Validators.required]],
      website: [null, [Validators.required, Validators.pattern(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
      )]],

      userEmail: [null, [Validators.email, Validators.required]],
      userFirstName: [null, [Validators.required]],
      userLastName: [null, [Validators.required]],
      userPassword: [null, [Validators.required]],
      userPhone: [null, [Validators.pattern('[- +()0-9]{6,}'), Validators.required]],
      iAgree: [null, [Validators.requiredTrue]],
    });

  }

  ngOnInit() {
    // this.form.patchValue({
    //   userFirstName: "Dusko",
    //   userLastName: "Angirevic",
    //   userPhone: "+38765919289",
    //   userEmail: "dusko+1@invenit.io",
    //   userPassword: "Hopa123Cupa",
    //   companyName: "Kmekekekekek"
    // });
  }

  submit(): void {
    var item = Object.assign({}, this.form.value);
    this.form.disable()
    this.auth.register(item).subscribe((res: any) => {
      this.router.navigateByUrl('/info/user_registered');
    }, (error => {
      this.snackBar.open(error, 'Got it', {
        duration: 3000,
      });
      this.ui.snack(error.message);
      this.form.enable();
    }));
  }
}
