<h3 class="separator-top">{{'Addresses'|translate}}
    <button mat-icon-button color="primary" (click)="addLine()">
        <mat-icon>add_circle_outline</mat-icon>
    </button>
</h3>
<div *ngFor="let item of fg.controls; index as i">
    <ng-container [formGroup]="item">
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex fxLayoutGap="8px" fxFlex>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
                <mat-form-field fxFlex>
                    <mat-label>{{'Street Line 1'|translate}}</mat-label>
                    <input matInput formControlName="street" placeholder="">
                </mat-form-field>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex>
                        <mat-label>{{'Street Line 2'|translate}}</mat-label>
                        <input matInput formControlName="streetLine2" placeholder="">
                    </mat-form-field>
                    <mat-form-field fxFlex.gt-sm="30">
                        <mat-label>{{'Number'|translate}}</mat-label>
                        <input matInput formControlName="streetNumber" placeholder="">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex.gt-sm="25">
                        <mat-label>{{'Postal Code'|translate}}</mat-label>
                        <input matInput formControlName="postalCode" placeholder="">
                    </mat-form-field>
                    <mat-form-field fxFlex.gt-sm="25">
                        <mat-label>{{'City'|translate}}</mat-label>
                        <input matInput formControlName="city" placeholder="">
                    </mat-form-field>
                    <mat-form-field fxFlex.gt-sm="25">
                        <mat-label>{{'State/Province'|translate}}</mat-label>
                        <input matInput formControlName="province" placeholder="">
                    </mat-form-field>
                    <mat-form-field fxFlex.gt-sm="25">
                        <mat-label>{{'Country Code' |translate}}</mat-label>
                        <input matInput formControlName="countryCode" placeholder="">
                        <mat-hint>{{'Enter in ISO Code2 format' |translate}}</mat-hint>
                    </mat-form-field>
                </div>
                <hr />
            </div>
            <div fxFlex="50px">
                <button mat-icon-button color="warn" style="margin-top:6px" (click)="removeLine( i)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
        </div>

    </ng-container>
</div>