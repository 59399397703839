import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { Subscribable, Unsubscribable } from 'rxjs/internal/types';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { Invoice, Transaction, TransactionStatus } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { DocumentsService } from 'src/app/_services/documents.service';
import { InvoicesService } from 'src/app/_services/invoices.service';

import { UiService } from 'src/app/_services/ui.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-item',
  templateUrl: './invoice-item.component.html',
  styleUrls: ['./invoice-item.component.scss']
})
export class InvoiceItemComponent implements OnDestroy {
  data$: Unsubscribable;
  item: any;
  creatingPdf = false;
  onRoute$: Subscription;
  summaryColumns: string[] = ['name', 'quantity', 'price', 'subtotal'];
  summary = [];
  transactionColumns: string[] = ["status", "created", "totalPrice", "payedAmount",];
  transactions = [];
  $item:any;
  refetchInterval:any;

  constructor(
    public service: InvoicesService,
    public ds: DocumentsService,
    public auth: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    public ui: UiService
  ) {
    this.service.getInvoiceStatus(this.route.snapshot.paramMap.get('id'));
    this.ui.setTitle("Invoice");
      this.data$ = this.service.one(this.route.snapshot.paramMap.get('id')).subscribe({
        next: x => {
          this.item = x;
          this.summary = [];
          this.transactions = [];
          x.invoiceItems?.forEach(itm => {
            this.summary.push({
              'name': itm.name,
              'quantity': itm?.spentChecks ? itm?.spentChecks : itm?.purchaseItem?.includedChecks,
              'price': itm?.price,
              'subtotal': (itm?.spentChecks ? itm?.spentChecks : itm?.purchaseItem?.includedChecks) * itm?.price
            });
          })
          x.transactions.forEach(itm => {
            this.transactions?.push(itm);
          })
        }
      })
  }

  getTotalCost() {
    return this.summary.map(t => t.subtotal).reduce((acc, value) => acc + value, 0);
  }

  downloadInvoice() {
    if (this.item.documentId) {
      this.ds.downloadFile(this.item.documentId, this.item.name + '.pdf');
      return;
    }
    this.creatingPdf = true;
    this.service.createInvoice(this.item?.id).subscribe({
      next: x => {
        this.ds.downloadFile(x, this.item?.name + '.pdf')
        this.creatingPdf = false;
      }, error: e => {
        this.creatingPdf = false;
      }
    })
  }

  setInvoiceStatus(status: any) {
    this.service.setInvoiceStatus(this.item.id, status).subscribe({});
  }

  pay(invoiceId) {
    
    var mdl = <ConfirmationDialogData>{
      title: 'modal_title_payment_retry',
      content: 'modal_content_payment_retry',
      btnConfirmText: 'modal_btn_payment_retry',
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
       let unsub = this.service.payAgain(invoiceId).subscribe({
          next: x => {
            this.refetchInterval = interval(3000).subscribe(() => {
              this.service.customQueryRef.refetch();
              if (this.item?.status === 'COMPLETED')
                this.refetchInterval?.unsubscribe();
            })
            unsub?.unsubscribe();
          },
          error: err => {
            unsub?.unsubscribe();
          }
        });
      }
    });
  }
  ngOnDestroy(): void {
    // this.onRoute$?.unsubscribe()
    this.refetchInterval?.unsubscribe();
    this.data$?.unsubscribe();
  }
}
