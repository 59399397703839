<item-wrapper #item [mainService]="states" [form]="form" navigateList="states" title="States"
  [readPerm]="auth.hasPermission(auth.perm.ProviderRead)" [managePerm]="auth.hasPermission(auth.perm.ProviderManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column" fxLayoutGap="12px"
    style="padding: 0 12px;">
    <div fxLayout="row" class="form-row" fxLayoutGap="12px">
      <mat-form-field class="full-width">
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Country</mat-label>
        <mat-select formControlName="countryId">
          <mat-option *ngFor="let option of countries$|async" [value]="option?.id">
            {{ option?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>{{ 'ISO Code' }}</mat-label>
        <input matInput formControlName="isoCode" />
      </mat-form-field>
    </div>
  </form>
</item-wrapper>