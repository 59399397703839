import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscribable } from 'rxjs';
import { TranslateModuleComponent } from 'src/app/components/translations/translate-module/translate-module.component';
import { TranslationLanguage } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { DocumentsService } from 'src/app/_services/documents.service';
import { TranslationsService } from 'src/app/_services/translations.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit {
  exporting = false;
  data$: Subscribable<TranslationLanguage[] | null>;
  queryString = new FormControl('');
  constructor(
    public auth: AuthService,
    public ui: UiService,
    public dialog: MatDialog,
    private ds: DocumentsService,
    public trans: TranslationsService
  ) { }

  ngOnInit(): void {
    this.data$ = this.trans.all();
  }
  onImport(event) {


    if (event.target.files) {
      this.exporting = true;
      this.ds.postFiles(event.target.files, null)
        .subscribe({
          next: (data: any) => {
            if (data && data[0]) {
              console.log(data[0])
              this.trans.importTranslations(data[0].id).subscribe({
                next: x => {
                  this.exporting = false;
                  this.ui.openSnackBar('Translations imported!', 'OK')
                }
              })
            }

          }, error: (error: any) => {
            this.exporting = false;
          }
        });
    }

  }
  onExport() {
    this.exporting = true;
    this.trans.exportTranslations().subscribe({
      next: x => {
        this.trans.getExportTranslationsFileId().subscribe({
          next: id => {
            this.ds.downloadFile(id, 'Exported translations.csv')
            this.ui.openSnackBar('Translations exported!', 'OK')
            this.exporting = false;
          }
        })
      }
    });
  }

  newItem() {
    const dialogRef = this.dialog.open(TranslateModuleComponent, {
      height: 'auto',
      width: '800px',
      data: { term: '', keyDisabled: false }
    });
  }
  openModal(term, keyDisabled) {
    if (!term)
      return;
    const dialogRef = this.dialog.open(TranslateModuleComponent, {
      height: 'auto',
      width: '800px',
      data: { term, keyDisabled }
    });
  }
}
