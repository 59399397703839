<list-wrapper #wrapper [mainService]="trans" navigateItem="list_url" title="Translations"
  [readPerm]="auth.hasPermission(auth.perm.SystemRead)" [managePerm]="false">
  <div toolbox2>
    <button mat-icon-button (click)="onExport()" [disabled]="exporting">
      <mat-icon>file_download</mat-icon>
    </button>
    <button mat-icon-button (click)="fileUpload.click()" [disabled]="exporting">
      <mat-icon>file_upload</mat-icon>
    </button>
    <input type="file" (change)="onImport($event)" #fileUpload style="display: none;" />
  </div>
  <mat-action-list class="inv-list">
    <mat-list-item *ngFor="let item of (data$ | async); index as i" (click)="openModal(item.key, true)">
      <mat-icon matListIcon [color]="item?.translations?.length! >= 1 ? 'primary' : 'warn'">translate</mat-icon>
      <!-- <translate [color]="item?.translations?.length! >= 2 ? 'primary' : 'warn'" [term]="item.key" [keyDisabled]="true">
      </translate> -->
      <h3 mat-line>
        <code>{{item.key}}</code>
      </h3>


      <div *ngFor="let ltran of item.translations;" fxFlex="400px">
        <div mat-line>{{ ltran?.value! | slice:0:160 }} </div>
        <small mat-line>{{ltran?.language?.name}}</small>
      </div>
      <mat-divider [inset]="true" *ngIf="i+1<(data$ | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>

<button class="fab-fixed" mat-fab color="primary" (click)="newItem()"
  *ngIf="auth.hasPermission(auth.perm.SystemManage)">
  <mat-icon>add</mat-icon>
</button>