import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTabGroup, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';
import { Observable, Subscribable, Subscriber, Subscription, Unsubscribable } from 'rxjs';
import { Check, Session } from 'src/app/_graphql/schema-session';
import { EventBusService } from 'src/app/_services/event-bus.service';


import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
  providers: [
    // { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } }
  ]
})
export class SessionCertificateComponent implements OnInit, OnDestroy {
  title = "";
  qrCode = "";
  itemId: string;
  item$: Unsubscribable;
  item: Session;

  onRoute$: Subscription;
  animationDuration = "300ms"
  @ViewChild("tabs", { static: false }) tabs: MatTabGroup;




  constructor(
    public service: SessionsCompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private eventBusService: EventBusService,
    public ui: UiService,
    private titleService: Title
  ) {
    this.ui.setTitle("Session");
    this.onRoute$ = this.route.queryParams.subscribe(params => {
      this.loadData(this.route.snapshot.paramMap.get('id'));
    });
  }

  loadData(itemId: string) {
    if (itemId == null || this.itemId == itemId) {
      return;
    }
    this.qrCode = 'https://image-charts.com/chart?chs=350x350&cht=qr&choe=UTF-8&icqrb=393c46&icqrf=FFFFFF&chl=' + encodeURIComponent(window.location.protocol + '//' + window.location.host + '/certificate-public/' + itemId)
    this.itemId = itemId;

    this.item$ = this.service.one(this.itemId).subscribe({
      next: session => {
        this.item = session;
        this.title = "";
        if (this.item?.businesses.length) {
          var business = this.item?.businesses[0];
          this.title += business.companyName;
        } else if (this.item?.persons.length) {
          var person = this.item?.persons[0];
          this.title += person.firstName + ' ' + (person.middleName ? '(' + person.middleName + ')' : "") + ' ' + person.lastName
        }

        this.ui.setTitle("Session: " + this.title);
        setTimeout(() => {
          window.print();
          window.close();
        }, 900);
      }
    });
  }

  ngOnInit(): void { }

  scrollIntoView(element: any) {
    element.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      this.animationDuration = "300ms";
    }, 500);
  }


  ngOnDestroy(): void {
    this.item$?.unsubscribe();
    this.onRoute$?.unsubscribe();
  }

}

