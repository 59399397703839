<h3 class="separator-top">Documents
    <button mat-icon-button color="primary" (click)="addLine()">
        <mat-icon>add_circle_outline</mat-icon>
    </button>
</h3>
<div *ngFor="let item of fg.controls; index as i">
    <ng-container [formGroup]="item">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px" fxFlex>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex>
                        <mat-label>Country of Issue</mat-label>
                        <mat-select formControlName="country" (selectionChange)="onCountryChange(i)">
                            <mat-option *ngFor="let item of supportedIdentityCheckTypes"
                                [value]="item?.country?.isoCode2">
                                {{ item?.country?.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex *ngIf="(states && states.length)">
                        <mat-label>State of Issue</mat-label>
                        <mat-select formControlName="state">
                            <mat-option *ngFor="let state of states" [value]="state.isoCode">
                                {{ state?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex *ngIf="(supportedDocuments && supportedDocuments.length)">
                        <mat-label>Document Type</mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let supportedDocument of supportedDocuments"
                                [value]="supportedDocument">
                                {{('documentType_'+supportedDocument) | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex>
                        <mat-label>Document Number</mat-label>
                        <input matInput formControlName="number">
                    </mat-form-field>
                    <mat-form-field fxFlex *ngIf="['DRIVER_LICENCE'].indexOf(fg.at(i).get('type').value) != -1">
                        <mat-label>Version</mat-label>
                        <input matInput formControlName="version">
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>Expiry Date</mat-label>
                        <input matInput [matDatepicker]="expiry" formControlName="expiry">
                        <mat-datepicker-toggle matSuffix [for]="expiry"></mat-datepicker-toggle>
                        <mat-datepicker #expiry></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>Card Number</mat-label>
                        <input matInput formControlName="serialNumber">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0"
                    *ngIf="['MEDI_CARD'].indexOf(fg.at(i).get('type').value) != -1">
                    <mat-form-field fxFlex>
                        <mat-label>Individual Reference Number</mat-label>
                        <input matInput formControlName="individualReferenceNumber">
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>Card Type</mat-label>
                        <input matInput formControlName="cardType">
                    </mat-form-field>
                </div>
                <!-- <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex="50">
                        <mat-label>City of Issue</mat-label>
                        <input matInput formControlName="cityOfIssue" placeholder="">
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <mat-label>County of Issue</mat-label>
                        <input matInput formControlName="countyOfIssue" placeholder="">
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <mat-label>Province of Issue</mat-label>
                        <input matInput formControlName="provinceOfIssue" placeholder="">
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <mat-label>District of Issue</mat-label>
                        <input matInput formControlName="districtOfIssue" placeholder="">
                    </mat-form-field>
                </div> -->
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start">
                    <document-item *ngFor="let image of item.get('images').controls as FormArray; index as j"
                        [fg]="image" (onDelete)="removeImage(i, j)" style="max-width: 25%"></document-item>
                    <button mat-button (click)="addImage(i)" *ngIf="item.get('images').controls.length < 4"
                     [disabled]="item?.get('images')?.controls?.length > 0 && !(item.get('images')?.value[item?.get('images')?.controls?.length-1]?.fileId)">
                        <mat-icon>add</mat-icon> {{'Add Image' | translate}}
                    </button>
                </div>
                <hr />
            </div>

            <div fxFlex="50px">
                <button mat-icon-button color="warn" style="margin-top:6px" (click)="removeLine(i)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>

        </div>

    </ng-container>

</div>