import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable } from 'rxjs';
import { Tag } from 'src/app/_graphql/schema';
import { TagsService } from 'src/app/_services/tags.service';

@Component({
  selector: 'app-tag-item',
  templateUrl: './tag-item.component.html',
  styleUrls: ['./tag-item.component.scss']
})
export class TagItemComponent implements OnInit {
  data$: Subscribable<Tag>;
  color: string;

  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    shortCode: [null, [Validators.required, Validators.maxLength(6)]],
    description: [null, Validators.required],
    color: ['#f88a80', [Validators.required, Validators.minLength(7), Validators.maxLength(7)]],
    type: ['admin']
  });
  constructor(public tags: TagsService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form.controls.type.disable();
  }
}
