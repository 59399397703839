import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperComponent } from './wrapper/wrapper.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { TranslationsModule } from './translations/translations.module';
import { BaseModule } from './base/base.module';
import { LoginModule } from './login/login.module';
import { TagsModule } from './tags/tags.module';
import { CompaniesModule } from './companies/companies.module';
import { ProvidersModule } from './providers/providers.module';
import { ComponentsModule } from '../components/components.module';
import { SessionsModule } from '../company/sessions/sessions.module';
import { RegisterModule } from './register/register.module';
import { SanctionsModule } from './sanctions/sanctions.module';
import { ProductsModule } from './products/products.module';


@NgModule({
  declarations: [
    WrapperComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AdminRoutingModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LoginModule,
    RegisterModule,
    TranslationsModule,
    BaseModule,
    TagsModule,
    CompaniesModule,
    ProductsModule,
    ProvidersModule,
    SessionsModule,
    SanctionsModule
  ]
})
export class AdminModule { }
