import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { Permission, RegisterUserInput, User, UserInput } from '../_graphql/schema';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User;
  companyId: string;
  authToken: string | null;

  checkUserNameQuery = gql`
    query checkUsername($username: String) {
      checkUsername(username: $username) {
        authMethod
        username
      }
    }
  `;

  constructor(
    private router: Router,
    private apollo: Apollo,
  ) {

    const storage = JSON.parse(localStorage.getItem('currentUser') || '{}');

    if (storage && storage.user) {
      this.user = storage.user;
      this.authToken = storage.token;
    }
  }

  public register(item: RegisterUserInput) {
    return this.apollo.mutate({
      mutation: gql`
        mutation register($item: RegisterUserInput!) {
          register(registration: $item ) {
            id email authToken roles permissions
          }
        }
      `,
      variables: { item }
    });
  };

  public login(username: string, password: string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation loginUser($username: String!, $password: String!) {
          loginUser(username: $username, password: $password) {
            id email authToken roles emailConfirmed
            permissions
            language { id isoCode2 name }
          }
        }
      `,
      variables: {
        username: username,
        password: password
      }
    });

    // console.log(username, password);
    // return new Promise((resolve, reject) => {
    //   this.apollo.mutate({
    //     mutation: gql`
    //     mutation loginUser($username: String!, $password: String!) {
    //       loginUser(username: $username, password: $password) {
    //         id email authToken roles city
    //         language { id isoCode2 name }
    //       }
    //     }
    //   `,
    //     variables: {
    //       username: username,
    //       password: password
    //     }
    //   }).subscribe((res: any) => {
    //     if (res && res.data?.login) {
    //       console.log(res.data?.login);
    //       this.storeUser(res.data?.login);
    //       resolve(res.data?.login);
    //     }
    //     ;
    //   }, error => {
    //     console.log(error);
    //     reject(error)
    //   })
    // });
  }

  public isAllowedTo(rule: string): boolean {
    // console.log('Check permission for: '+ rule)
    return true;
  }

  storeUser(userData: any) {
    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        token: userData.authToken,
        user: userData
      })
    );

    this.authToken = userData.authToken;
    this.user = userData;
  }

  hasRoles(neededRoles: string[]) {
    if (!neededRoles || neededRoles.length === 0) {
      return false;
    }

    if (!this.user || !this.user.roles || this.user.roles.length === 0) {
      return false;
    }

    let retVal = false;
    neededRoles.forEach(el => {
      if (this.user.roles.includes(el)) {
        retVal = true;
      }
    });
    return retVal;
  }

  public get perm() {
    return Permission;
  }

  hasPermission(permission: Permission): boolean {
    // if (permission == this.perm.CompanyCheckPricingManage) {
    //   return false;
    // }
    // if (permission == this.perm.CompanyCheckPricingRead) {
    //   return false;
    // }
    if (!permission) {
      return false;
    }
    if (!this.user || !this.user.permissions || this.user.permissions.length === 0) {
      return false;
    }
    var hasPermision = this.user.permissions.indexOf(permission) > -1;
    if (!hasPermision) {
      // console.log('No permission >', permission)
    }

    return hasPermision;
  }

  allowedField(permission: Permission, fieldName: string): string {
    return this.hasPermission(permission) ? fieldName : '';
  }

  public logout(redirectPath: string = ''): void {
    this.apollo
      .client
      .cache.reset()
      .then(x => {
        console.log('cache reseted', x);
      });

    localStorage.removeItem('currentUser');
    this.user = null;
    this.authToken = null;
    window['_doreload'] = true;
    this.router.navigate(['/login']);
    // setTimeout(() => {
    //   this.router.navigate([redirectPath]);
    // }, 500);

  }

  public isLoggedIn() {
    return !!this.authToken;
  }

}
