<div title="" class="wrapper">
  <div #refHeader>
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="toolbox" style="font-size: 10pt !important">
    <mat-form-field style="max-width: 200px; min-width:200px; margin-bottom: 0;" appearance="outline"
      *ngIf="showQueryField">
      <mat-label>Type to search...</mat-label>
      <input matInput [formControl]="queryString">
      <button mat-icon-button matSuffix disabled="disabled">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <ng-content select="[toolbox]"></ng-content>
    <div fxFlex></div>
    <ng-content select="[toolbox2]"></ng-content>
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="showDeletedEnabled">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="showDeleted()">
        <mat-icon *ngIf="!this._showDeleted">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="this._showDeleted">check_box</mat-icon>
        Show deleted items
      </button>
    </mat-menu>

  </div>

  <div *ngIf="readPerm==null" class="infoblock warning"><strong>readPerm</strong> is not defined on List Wrapper</div>
  <div *ngIf="managePerm==null" class="infoblock warning"><strong>managePerm</strong> is not defined on List Wrapper
  </div>
  <app-placeholder [translationKey]="mainService.nodeNamePlural"
    *ngIf="mainService.loading == false && mainService.totalCount == 0"></app-placeholder>
  <div *ngIf="!mainService.loading else loading">

    <div *ngIf=" mainService.totalCount > 0">
      <ng-content></ng-content>
    </div>
  </div>


  <ng-content select="[footer]"></ng-content>
  <mat-paginator *ngIf="showPagination && (mainService.loading == false && mainService.totalCount > 0)"
    [length]="mainService.totalCount" [pageSize]="mainService.queryParams.take" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="mainService.applyPager($event.pageIndex, $event.pageSize)" [pageIndex]="mainService.pageIndex">
  </mat-paginator>
</div>
<button *ngIf="managePerm" color="primary" class="fab-fixed" mat-fab aria-label="Add new" (click)="newItem()">
  <mat-icon>add</mat-icon>
</button>

<ng-template #loading>
  <div fxLayout="column" fxLayoutAlign="center center" style="min-height: 75vh;">
    <div>
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </div>
</ng-template>