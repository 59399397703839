<!--<pre>{{form.value|json}}</pre> -->
<!-- 
  
<pre>{{formType}}</pre>
{{checksSupportedByConfiguration|json}} -->
<div style="height: calc(100% - 64px)">
  <mat-stepper [linear]="isLinear" #stepper >
    <!-- <mat-step label="Configuration Group" [stepControl]="form" [editable]="isEditable">
      <div class="holder">
        <mat-action-list class="inv-list">
          <mat-list-item *ngFor="let _application of $applications | async" (click)="setApplicationId(_application.id)">
            <mat-icon matListIcon *ngIf="_application.id == form.get('applicationId').value">radio_button_checked
            </mat-icon>
            <mat-icon matListIcon *ngIf="_application.id != form.get('applicationId').value">radio_button_unchecked
            </mat-icon>
            <h3 matLine>{{ _application.name }}</h3>
            <p matLine>
              <span>{{ _application.description }}</span>
            </p>
          </mat-list-item>
        </mat-action-list>
      </div>
      <div class="actions">
        <div fxFlex></div>
        <button mat-button matStepperNext [disabled]="!form.get('applicationId').valid">
          Next
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-step> -->

    <mat-step [stepControl]="form" [editable]="true">
      <ng-template matStepLabel>Configuration</ng-template>
      <div class="holder">
        <mat-action-list class="inv-list">
          <mat-list-item *ngFor="
              let _configuration of $configurations | async " (click)="setConfigurationId(_configuration.id)">
            <mat-icon matListIcon *ngIf="_configuration.id == form.get('configurationId').value">
              radio_button_checked
            </mat-icon>
            <mat-icon matListIcon *ngIf="_configuration.id != form.get('configurationId').value">
              radio_button_unchecked
            </mat-icon>
            <h3 matLine>{{ _configuration?.name }}</h3>
            <p matLine>
              <span>{{ _configuration?.description }}</span>
            </p>
            <!-- <div fxFlex="85px" class="text-right">
              <small>{{ getCheckSum(_configuration.checks) | currency }}</small>
            </div> -->
          </mat-list-item>
        </mat-action-list>
      </div>
      <div class="actions">
        <!-- <button mat-button matStepperPrevious>
          <mat-icon>chevron_left</mat-icon>
          Previous
        </button> -->
        <div fxFlex></div>
        <button mat-button matStepperNext [disabled]="!form.get('configurationId').valid">
          Next
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-step>

    <mat-step [editable]="form.get('configurationId').value != null">
      <ng-template matStepLabel>Session</ng-template>
      <div class="holder">
        <form [formGroup]="form" class="inv-list" fxLayout="row">
          <div fxFlex fxLayout="column" style="padding: 24px">
            <h3>{{ "Session Details" | translate }}</h3>
            <!-- <mat-radio-group aria-label="Select an option" fxLayout="column">
          <mat-radio-button value="person" (click)="onTypeChanged('person')" checked="true">Person</mat-radio-button>
          <mat-radio-button value="business" (click)="onTypeChanged('business')">Business</mat-radio-button>
        </mat-radio-group> -->
            <!-- <div>&nbsp;</div> -->

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
              <!-- <mat-form-field fxFlex.gt-sm="140px">
                <mat-label>{{ "Code" | translate }}</mat-label>
                <input matInput formControlName="code" />
              </mat-form-field> -->
              <mat-form-field fxFlex>
                <mat-label>{{ "Description" | translate }}</mat-label>
                <input matInput formControlName="description" />
              </mat-form-field>

            </div>
            <mat-checkbox color="primary" formControlName="isTesting">{{'session_check_isTesting_label'|translate}}
              <span>{{'session_check_isTesting_desc' | translate}}</span>
            </mat-checkbox>
            <mat-error style="margin-left:24px; margin-bottom:8px; font-size: small;"
              *ngIf="form.get('isTesting').invalid">
              {{'This field is required, since your company status is applied!' | translate}}
            </mat-error>
            <mat-checkbox color="primary" [formControl]="chkWaitForResult">{{'session_check_waitForResult_label' |
              translate}}
              <span>{{'session_check_waitForResult_desc' | translate}}</span>
            </mat-checkbox>
          </div>
        </form>
      </div>
      <div class="actions">
        <button mat-button matStepperPrevious>
          <mat-icon>chevron_left</mat-icon>
          {{ "Previous" | translate }}
        </button>
        <div fxFlex></div>
        <button mat-button matStepperNext
          [disabled]="!form.get('description').valid || form.get('code').invalid || form.get('isTesting').invalid">
          {{ "Next" | translate }} <mat-icon> chevron_right </mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="form" [editable]="isEditable" *ngIf="conf">
      <div class="holder">
        <ng-template matStepLabel>{{ conf?.label }}</ng-template>
        <div class="inv-list" *ngIf="form.get('isTesting').value">
          <div class="io-row io-align-items-center">
            <div class="io-p-3 io-flex"><strong>You wanted to create test session?</strong><br /> There are available
              test data to use.</div>
            <div class="io-no-flex io-pr-3">
              <button mat-button (click)="showTestDataPanel()">Show Data</button>
            </div>
          </div>
        </div>
          <app-form-builder [conf]="conf" [cloneData]="cloneData" [testData] ="testData" (formValid)="isValid($event)" (formValue)="formValue($event)"></app-form-builder>
      </div>
      <div class="actions">
       

        <button mat-button matStepperPrevious>
          <mat-icon>chevron_left</mat-icon>
          Previous
        </button>
        <div fxFlex></div>
        <button mat-button color="primary" matStepperNext [disabled]="formIsInvalid" (click)="onSubmit()">
          Finish 
          <mat-icon>verified</mat-icon>
        </button>
      </div>
    </mat-step>

    <mat-step fxFill>
      <ng-template matStepLabel>Create session</ng-template>
      <div fxLayout="column" fxLayoutAlign="center center" fxFill>
        <div>
          <mat-spinner diameter="40"></mat-spinner>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>