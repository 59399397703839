<form [formGroup]="form" *ngIf="conf">
  <ng-container *ngIf="conf" [ngTemplateOutlet]="formMainTemplate"
    [ngTemplateOutletContext]="{ conf:form.value, fg:form, parentType: 'ROOT' }"></ng-container>
</form>


<ng-template #formMainTemplate let-conf="conf" let-fg="fg" let-parentType="parentType">



  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>edit</mat-icon>
        {{conf.name}} /
        {{conf.type}} /
        {{parentType}}
      </mat-panel-title>

    </mat-expansion-panel-header>

    <form [formGroup]="fg">
      <div class="io-row">
        <mat-form-field>
          <mat-label>Key</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option value="ARRAY">ARRAY</mat-option>
            <mat-option value="FG" *ngIf="['ROOT', 'FG', 'ARRAY'].indexOf(parentType) !== -1">FG</mat-option>
            <mat-option value="TEXT" *ngIf="parentType=='FG'">TEXT</mat-option>
            <mat-option value="DATE" *ngIf="parentType=='FG'">DATE</mat-option>
            <mat-option value="NUMBER" *ngIf="parentType=='FG'">NUMBER</mat-option>
            <mat-option value="EMAIL" *ngIf="parentType=='FG'">EMAIL</mat-option>
            <mat-option value="SELECT" *ngIf="parentType=='FG'">SELECT</mat-option>
            <mat-option value="OPTION" *ngIf="parentType=='SELECT'">OPTION</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="['ARRAY', 'FG'].indexOf(conf.type) !== -1">
          <mat-label>Display Type</mat-label>
          <mat-select formControlName="displayType">
            <mat-option>None</mat-option>
            <mat-option value="h1">Heading 1</mat-option>
            <mat-option value="h2">Heading 2</mat-option>
            <mat-option value="h3">Heading 3</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Order Number</mat-label>
          <input matInput formControlName="orderNumber" />
        </mat-form-field>
      </div>
      <form formGroupName="validation">
        <h4>Validation</h4>

        <div class="io-row">
          <mat-form-field *ngIf="fg.get('validation')?.get('minLength')">
            <mat-label>Min Length</mat-label>
            <input matInput formControlName="minLength" />
          </mat-form-field>
          <mat-form-field *ngIf="fg.get('validation')?.get('maxLength')">
            <mat-label>Max Length</mat-label>
            <input matInput formControlName="maxLength" />
          </mat-form-field>
          <mat-form-field *ngIf="fg.get('validation')?.get('min')">
            <mat-label>Min</mat-label>
            <input matInput formControlName="min" />
          </mat-form-field>
          <mat-form-field *ngIf="fg.get('validation')?.get('max')">
            <mat-label>Max</mat-label>
            <input matInput formControlName="max" />
          </mat-form-field>
          <mat-form-field *ngIf="fg.get('validation')?.get('pattern')">
            <mat-label>Pattern</mat-label>
            <input matInput formControlName="pattern" />
          </mat-form-field>
        </div>
        <mat-checkbox formControlName="required">Field is required</mat-checkbox>
      </form>

      <ng-container [ngTemplateOutlet]="formArrayTemplate"
        [ngTemplateOutletContext]="{ conf: conf, fg:fg }"></ng-container>
    </form>
    <mat-action-row>
      <button mat-button color="primary">Next</button>
    </mat-action-row>
  </mat-expansion-panel><br />


</ng-template>
<ng-template #formArrayTemplate let-conf="conf" let-fg="fg">
  <div [formArray]="fa" *ngIf="getFA(fg, 'items') as fa">
    <div *ngFor="let item of fa.controls; index as idx">
      <div *ngIf="getFG(fa, idx) as _fg">

        <ng-container [ngTemplateOutlet]="formMainTemplate"
          [ngTemplateOutletContext]="{ conf:_fg.value, fg: getFG(fa, idx), parentType: conf.type }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #formGroupTemplate let-conf="conf" let-fg="fg">
  <mat-accordion *ngIf="getFGbyName(fg, conf.name) as _fg">

    <!-- <div>FG: {{conf.name}}</div> -->
    <mat-expansion-panel *ngFor="let item of conf?.items">
      <mat-expansion-panel-header>AAAA{{conf.name}}</mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="formMainTemplate"
        [ngTemplateOutletContext]="{ conf:item, fg: _fg }"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
<pre style="background-color: red;">{{ form.value | json}}</pre>
<pre>{{conf|json}}</pre>