<list-wrapper #wrapper [mainService]="countries" navigateItem="countries" title="Countries"
  [readPerm]="auth.hasPermission(auth.perm.ProviderRead)" [managePerm]="auth.hasPermission(auth.perm.ProviderManage)">
  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>

      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>
      <div fxFlex="120px" class="text-center">
        <div mat-line>{{'Order' }}</div>
      </div>
      <div fxFlex="120px" class="text-center">
        <div mat-line>{{'ISO Code 2' }}</div>
      </div>
      <div fxFlex="120px" class="text-center">
        <div mat-line>{{'ISO Code 3' }}</div>
      </div>
    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{ deleted: item?.deleted}">
      <mat-icon matListIcon>flag</mat-icon>
      <strong mat-line>{{ item?.name }}</strong>
      <small mat-line>

      </small>
      <div fxFlex="120px" class="text-center">
        <small>{{ item?.order }}</small>
      </div>
      <div fxFlex="120px" class="text-center">
        <small>{{ item?.isoCode2 }}</small>
      </div>
      <div fxFlex="120px" class="text-center">
        <small>{{ item?.isoCode3 }}</small>
      </div>
      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>

</list-wrapper>