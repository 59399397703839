<public-wrapper>
  <div fxLayout="column" fxLayoutAlign="center stretch">

    <h3 innerHTML="{{'Public Certificate' | translate}}"></h3>
    <table class="inv-table" *ngIf="item$|async as item ">
      <tr>
        <th>
          {{'session_status'|translate}}
        </th>
        <td>
          {{('sessionStatus_'+item?.status)|translate}}
        </td>
        <td style="width: 24px;">
          <app-session-status [status]="item?.status" style="display: inline;"></app-session-status>
        </td>
      </tr>
      <tr>
        <th>
          {{'session_overallCheckStatus'|translate}}
        </th>
        <td>
          {{('checkStatus_'+item?.overallCheckStatus)|translate}}
        </td>
        <td style="width: 24px;">
          <app-check-status [status]="item?.overallCheckStatus" style="display: inline;">
          </app-check-status>
        </td>
      </tr>
      <tr>
        <th>
          {{'session_code'|translate}}
        </th>
        <td>
          {{item?.code}}
        </td>
      </tr>
      <tr>
        <th>
          {{'session_name'|translate}}
        </th>
        <td>
          {{item?.subjectName}}
        </td>
      </tr>

    </table>
    <button mat-raised-button routerLink="/" fxFlex color="primary">{{'Back to login'| translate}}</button>
  </div>
</public-wrapper>