import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable, take } from 'rxjs';
import { Company, Country } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companies.service';
import { CountriesService } from 'src/app/_services/countries.service';

@Component({
  selector: 'app-company-item',
  templateUrl: './company-item.component.html',
  styleUrls: ['./company-item.component.scss']
})
export class CompanyItemComponent implements OnInit {
  service$: Subscribable<Company>;
  $countries: Subscribable<Country[]>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;


  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  form = this.fb.group({
    id: [null],
    avatarId: [null],
    name: [null, Validators.required],
    vatNumber: [null, Validators.required],
    description: [null, []],
    contactPersonPosition: [null],
    contactPersonName: [null],
    idNumber: [null, [Validators.required]],
    address: [null, [Validators.required]],
    address2: [null],
    zip: [null, [Validators.required]],
    city: [null, [Validators.required]],
    state: [null],
    email: [null],
    facebook: [null],
    linkedin: [null],
    phoneNumber: [null],
    countryId: [null, [Validators.required]],
    ipAddresses: [null],
    website: [null, [Validators.required]],
  });
  constructor(
    private _ngZone: NgZone,
    public service: CompaniesService,
    public auth: AuthService,
    public countriesService: CountriesService,
    private fb: FormBuilder
  ) {
    this.$countries = this.countriesService.dropdown();
  }


  ngOnInit(): void {
  }
}
