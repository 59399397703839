import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { ConfigurationsComponent } from './configurations.component';
import { ConfigurationItemComponent } from './configuration-item/configuration-item.component';
import { TranslationsModule } from 'src/app/admin/translations/translations.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';


@NgModule({
  declarations: [
    ConfigurationsComponent,
    ConfigurationItemComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ]
})
export class ConfigurationsModule { }
