<public-wrapper mini="true">
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch" (submit)="onSubmit()">
    <h3>{{'reset_password_title' | translate}}</h3>
    <p>{{'reset_password_description' | translate}}</p>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Email' | translate }}</mat-label>
      <input matInput type="email" [value]="this.form.get('username').value" disabled />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Verification Code' | translate }}</mat-label>
      <input matInput type="text" [value]="this.form.get('verificationCode')?.value" disabled />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'New Password' | translate}}</mat-label>
      <input matInput type="password" formControlName="newPassword" minlength="4" [type]="hide ? 'password' : 'text'"
        (keyup.enter)="onSubmit()" />
      <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <button mat-raised-button fxFlex color="primary" [disabled]="!this.form.valid">{{'Reset Password'|
      translate}}</button>
  </form>
</public-wrapper>