<public-wrapper mini="true">
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch">
    <mat-form-field fxFlex>
      <mat-label>{{ 'Email' | translate }}</mat-label>
      <input matInput type="email" formControlName="email" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Token' | translate }}</mat-label>
      <input matInput type="text" formControlName="token" />
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label>{{'Password' | translate}}</mat-label>
      <input matInput type="password" formControlName="password" minlength="4" [type]="hide ? 'password' : 'text'"
        (keyup.enter)="login()" />
      <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field> -->

  </form>
</public-wrapper>