import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-status',
  templateUrl: './company-status.component.html',
  styleUrls: ['./company-status.component.scss']
})
export class CompanyStatusComponent {
  @Input('status') status: string;

  constructor(
  ) { }

}
