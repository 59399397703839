import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscribable, Unsubscribable, debounceTime, distinctUntilChanged } from 'rxjs';
import { Country, Field, State } from 'src/app/_graphql/schema';
import { CountriesService } from 'src/app/_services/countries.service';
import { StatesService } from 'src/app/_services/states.service';
import { CustomValidator } from 'src/app/_validators/custom.validator';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],

})
export class FormBuilderComponent implements OnInit, OnDestroy {
  form: FormGroup | FormArray | FormControl = this.fb.group({});
  _conf: any;
  documentType: FormControl = new FormControl(null);
  dropdownItems:any[];
  states$: Subscribable<State[]>;
  countries$: Subscribable<Country[]>;
  valueChangeUsnub: Unsubscribable;
  valueChangeElementUsnub: any;

  @Output() formValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  
  @Input() set conf(conf: any) {
    this._conf = conf;
    if (conf) {
      this.form = this.setupForm(this._conf);
      this.documentType.patchValue(null); //solved config change return all documents
      this.subscribeToFormChanges();
      this.formValid.emit(this.form.invalid);  //solved enabled finish when changing conf
      if (this.cloneData) {
        this.compareFormWithCloneData(this.cloneData);
        if (this.dropdownItems) {
          let fg = this.getFormElementlByName('documents', this.form);
          if (this.cloneData?.persons[0]?.documents[0]?.type) {
            this.documentType.patchValue(this.cloneData.persons[0].documents[0]?.type) //patch document after getting cloning data
            this.filterConfig(fg, 'documents');
          }
        }
        this.form?.get(this.conf.name).patchValue(this.cloneData[this.conf.name]);    
      }
    
       if (this.testData) {
        this.compareFormWithCloneData(this.testData);
        this.form?.get(this.conf.name).patchValue(this.testData[this.conf.name]);
       }
    }
  }
  get conf() {
    return this._conf;
  }
  @Input() cloneData: any;
  @Input() testData: any;

  getFG(q: FormArray, idx: number): FormGroup {
    return q.at(idx) as FormGroup;
  }
  getFGbyName(q: FormGroup, key: string): FormGroup {
    return q.get(key) as FormGroup;
  }
  getFA(q: FormGroup, name: string): FormArray {
    return q.get(name) as FormArray
  }
  isArray(item: any) {
    return Array.isArray(item);
  }

  constructor(
    private fb: FormBuilder,
    private states: StatesService,
    private countries: CountriesService,
  ) {
  }

  setupForm(conf: Field, parentIsFA = false): FormGroup | FormArray | FormControl {
    if (conf?.type === 'ARRAY') {
      var def = {};
      var fa = this.fb.array([], [CustomValidator.minLengthArray(conf?.validation['min']),
      CustomValidator.maxLengthArray(conf?.validation['max'])]);
      conf.items.forEach(_conf => {
        if (conf.cssClass === 'showDropdown' && !this.dropdownItems) {
         this.dropdownItems = [...conf.items];
        }
        fa.push(this.setupForm(_conf, true))
      });
      def[conf.name] = fa;

      return parentIsFA ? fa : this.fb.group(def)
    }
    else if (conf?.type === 'FG') {
      var def = {};
      var fg = this.fb.group({});

      conf.items?.forEach(_conf => {
        if (_conf?.type === "FG")
          fg.addControl(_conf.name, this.setupForm(_conf, true))
        else
          fg.addControl(_conf.name, this.setupForm(_conf, _conf.type === "ARRAY"))
      });
      def[conf.name] = fg;
      return parentIsFA ? fg : this.fb.group(def)
    } else {
      let control = this.fb.control(conf?.defaultValue, this.setValidators(conf));
      return control as FormControl;
    }

  }
  subscribeToFormChanges() {
    this.valueChangeUsnub = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
        this.formValue.emit(this.form);
        this.formValid.emit(this.form.invalid);
        if (this.dropdownItems)
          this.changeDropdownItemsVisibility(this.dropdownItems);
    });
  }

  getElementFromPath(controlList: (string | number)[]): any {
    let currentControl: any = this.form;
    for (const control of controlList) {
      if (currentControl instanceof FormGroup) {
        currentControl = currentControl.get(control as any);
      } else if (currentControl instanceof FormArray) {
        currentControl = currentControl.at(control as number);
      }
    }

    return currentControl;
  }

  //checks if there are alfa numerical signs separated with '/'
  matchInput(inputText) {
    const regex = new RegExp('(?:[a-zA-Z0-9]+\\/)+[a-zA-Z0-9]+', 'g');
    let match;
    let matches = []; // Clear the previous matches
    
    while ((match = regex.exec(inputText)) !== null) {
      matches.push(match[0]);
    }
    return matches;
  }

//checks if there are other fields that already hid that field
  checkOthers(matchList, pattern) {
  let tmp = [];
   matchList.forEach(el => {
      let nesto = el.split('/').map(str => {
        const numericValue = parseFloat(str);
        return isNaN(numericValue) ? str : numericValue;
      });    
      if (new RegExp(pattern).test(el + '.'+ this.getElementFromPath(nesto)?.value) === true) {
        tmp.push(el);
      }
    }) 
   if (tmp?.length > 0) {
    return true
   } else return false;
  }

  hideShowField(conf) {
    if (!conf || !conf.items || !Array.isArray(conf.items)) {
      return null; 
    }
    for (const item of conf.items) {
      if (item?.dependencyPattern) {
        let matchList = this.matchInput(item?.dependencyPattern);
        matchList.forEach(listElement => { 
          let pathList = listElement.split('/').map(str => {
              const numericValue = parseFloat(str);
              return isNaN(numericValue) ? str : numericValue;
            });
            let elementTmp = this.getFormElementlByName(item.name, this.form); 


          this.valueChangeElementUsnub = this.getElementFromPath(pathList).valueChanges.subscribe(el=> {
             if (new RegExp(item?.dependencyPattern).test(listElement + '.'+ el ) === true  
             || this.checkOthers(matchList, new RegExp(item?.dependencyPattern))=== true) {
              if (elementTmp instanceof FormControl ) {
                elementTmp?.setValue(null, { emitEvent:false});
                elementTmp.disable({ emitEvent:false}); 
              }
              }
            else {
              if (item.validation.required && elementTmp instanceof FormControl  
                && this.checkOthers(matchList, new RegExp(item?.dependencyPattern)) === false) {
                elementTmp.enable({ emitEvent:false});
              }
            }
          })
        })
      } else
        this.hideShowField(item);       
      }
    }
    changeDropdownItemsVisibility(dropdownItems) {
      if (!dropdownItems) {
        return null; 
      }
      for (const item of dropdownItems) {
        if (item?.dependencyPattern) {
        let matchList = this.matchInput(item?.dependencyPattern);
        matchList?.forEach(listElement => { 
          let pathList = listElement.split('/').map(str => {
              const numericValue = parseFloat(str);
              return isNaN(numericValue) ? str : numericValue;
            });
    
             if (new RegExp(item?.dependencyPattern)?.test(listElement + '.'+ this.getElementFromPath(pathList)?.value ) === true  
             || this.checkOthers(matchList, new RegExp(item?.dependencyPattern))=== true) {
                this.dropdownItems = JSON.parse(JSON.stringify(this.dropdownItems));
                this.dropdownItems.map(el => {
                  if (el.name === item.name) {
                    el['visible'] = false;
                  }
                })
            }
            else {
              if (item?.dependencyPattern) {
                this.dropdownItems = JSON.parse(JSON.stringify(this.dropdownItems));
                this.dropdownItems.map(el => {
                  if (el.name === item.name) {
                    el['visible'] = true;
                  }
                })
              }
            }
      })
        }
        }
        
      }
    // formValues() {
    //   console.log(this.form)
    //   console.log(this.conf)
    //   console.log(this.dropdownItems)
    // }

//filters elements that are in dropdownItems
  filterConfig(documentsArray, confName) {
    documentsArray?.controls?.forEach(el => {
      el.reset();
    });
    let formaTmp = this.form.value;
    this._conf = JSON.parse(JSON.stringify(this.conf));
    this.objectToInitialValues(confName, this.conf);
    this.findFilterSpecificObject(this.documentType.value, this.conf);
    this.form = this.setupForm(this.conf);
    this.form.patchValue(formaTmp);
    this.subscribeToFormChanges();
    let control = this.getFormElementlByName('type', this.form);
    control?.setValue(this.documentType.value)
    this.hideShowField(this._conf);
    if (!this.valueChangeElementUsnub?.isStopped) {
      this.valueChangeElementUsnub?.next();
    }
  }

  getFormElementlByName(controlName: string, formGroup) {
    for (const controlKey in formGroup.controls) {
      if (controlKey === controlName) {
        return formGroup.get(controlKey);
      } else {
        const control = formGroup.get(controlKey);
        if (control instanceof FormGroup) {
          const nestedControl = this.getFormElementlByName(controlName, control);
          if (nestedControl) {
            return nestedControl;
          }
        } else if (control instanceof FormArray) {
          for (const nestedControl of control.controls) {
            if (nestedControl instanceof FormGroup) {
              const nestedFormControl = this.getFormElementlByName(controlName, nestedControl);
              if (nestedFormControl) {
                return nestedFormControl;
              }
            }
          }
        }
      }
    }
    return null;
  }
//dropdownItems always keep the inital values that are being hidden/showen depending on documentType value
  objectToInitialValues(choosenItem:any, conf) {
    if (conf.name === choosenItem) {
      conf.items = [...conf.items, conf.items[0]];
    }
     conf?.items?.forEach(item => {
      if (item.name !== choosenItem) 
        this.objectToInitialValues(choosenItem, item);
      else 
        item.items = this.dropdownItems;
     })
    }


  //finds object in nested object and filters only choosenItem or everything except choosen
  findFilterSpecificObject(choosenItem:any, conf, out = false) {
    if (!conf || !conf.items || !Array.isArray(conf.items)) {
      return null; 
    }
    for (const item of conf.items) {
      if (item.name === choosenItem) {
        return conf; // Return the parent item when the chosen item is found.
      } else {
        const foundItem = this.findFilterSpecificObject(choosenItem, item, out);
        if (foundItem)
          if (out) {
            foundItem.items = foundItem.items.filter(el => el?.name !== choosenItem);
          }
          else 
            foundItem.items = foundItem.items.filter(el => el?.name === choosenItem);

        }
      }
    }
//comparing cloneData with regular configuration for that check 
// (e.g. when cloneData has more elements in addresses and in configuration there is only one)
  compareFormWithCloneData(data:any) {
   data[this.conf.name]?.forEach((el, i) => {
      for (let item in el) {
        if (Array.isArray((el[item])))
        if (el[item]?.length !== this.form.get(this.conf.name).value[i][item]?.length) {
          for (let index = 0; index < (el[item]?.length - this.form.get(this.conf.name).value[i][item]?.length); index++) {
            this.addLine(item);
          }
        }     
      }
    })
  }
  ngOnInit(): void {
   this.subscribeToFormChanges();
   if (this.cloneData) {
    this.compareFormWithCloneData(this.cloneData);
    if (this.dropdownItems) {
      let fg = this.getFormElementlByName('documents', this.form);
      this.documentType.patchValue(this.cloneData.persons[0].documents[0].type);   //patch document after getting cloning data
      this.filterConfig(fg, 'documents');
    }
    this.form?.get(this.conf.name).patchValue(this.cloneData[this.conf.name]);    
    this.form.markAllAsTouched();
   }
   if (this.testData) {
    this.compareFormWithCloneData(this.testData);
     this.form?.get(this.conf.name).patchValue(this.testData[this.conf.name]);
   }

    this.states$ = this.states.all();
    this.states.queryParams.take = null;
    this.countries$ = this.countries.all();
    this.hideShowField(this._conf);

  }
  setValidators(field: any) {
    const validators = [];
    if (field?.validation && field?.validation?.required) {
      validators.push(Validators.required);
    }
    if (field?.validation && field?.validation?.pattern) {
      validators.push(Validators.pattern(field.validation.pattern)); 
    }
    if (field?.validation && field?.validation?.minLength) {
      validators.push(Validators.minLength(field.validation.minLength)); 
    }
    if (field?.validation && field?.validation?.maxLength) {
      validators.push(Validators.minLength(field.validation.maxLength)); 
    }
    return validators;
  }
//when having more blocks of fields (plus and delete)
  addLine(choosenItem) { 
    let formaTmp = this.form.value;
    this._conf = JSON.parse(JSON.stringify(this.conf));
    this.findAddObject(choosenItem, this.conf);
    this.form = this.setupForm(this.conf);
    this.form.patchValue(formaTmp);
    this.subscribeToFormChanges();
  }

  findAddObject(choosenItem:any, conf) {
  if (conf.name === choosenItem) {
    conf.items = [...conf.items, conf.items[0]];
  }
   conf?.items?.forEach(item => {
    if (item.name !== choosenItem) 
      this.findAddObject(choosenItem, item);
    else 
      item.items = [...item.items, item['items'][0]];
   })
  }

  findRemoveObject(choosenItem:any, conf, idx) {
    if (conf.name === choosenItem) {
      conf.items = conf.items.splice(idx,1);
      return;
    }
    conf?.items?.forEach(item => {
     if (item.name !== choosenItem)
       this.findRemoveObject(choosenItem, item, idx);
     else
      item.items.splice(idx,1);
    })
   }

  removeLine(choosenItem, idx, formElement) {
    formElement.removeAt(idx);
    let formaTmp = this.form.value;
    this.conf = JSON.parse(JSON.stringify(this.conf));
    this.findRemoveObject(choosenItem.name, this.conf, idx)
    this.form = this.setupForm(this.conf);
    this.form.patchValue(formaTmp);
    this.subscribeToFormChanges();
  }
//validations for dates
  getMin(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear, currMonth, currDate + 30);
  }

  getMax(data: any) {
    var currentYear = new Date().getFullYear();
    var currDate = new Date().getDate() + 1;
    var currMonth = new Date().getMonth();
    if (data === null) { return; }
    return new Date(currentYear - data, currMonth, currDate);
  }
  patchFileId(event, conf, fg) {
    console.log(event, conf, fg)
    fg.get('name').setValue(event.name);
    fg.get('fileId').setValue(event.fileId);

  }
  delete() {
    this.onDelete.emit();
  }

  ngOnDestroy(): void {
    this.valueChangeElementUsnub?.unsubscribe();
    this.valueChangeUsnub?.unsubscribe();
  }
}
