<div *ngIf="item else loading">
  <!-- <h1 fxLayout="row" fxLayoutGap="8px">
    <app-session-status [status]="item.status"></app-session-status>
    <span *ngIf="item?.persons.length">{{item?.persons[0].title}} {{item?.persons[0].firstName}}
      {{item?.persons[0].middleName}}
      {{item?.persons[0].lastName}}
      <small *ngIf="item?.persons[0].dob">{{item.persons[0].dob | date:"longDate"}}</small>
    </span>
    <span *ngIf="item?.businesses.length">
      {{item?.businesses[0].companyName}}
    </span>
  </h1> -->
  <div fxLayout="column">
  <div fxLayout="column" fxLayout.gt-md="row">
    <div fxFlex>
      <div class="inv-list">
        <mat-card>
          <mat-card-title class="mat-card-title">
            <div style="display:flex; justify-content: space-between;">
              <div>
                {{'Session Input'|translate}}
              </div>
              <div *ngIf="auth.hasPermission(auth.perm.SessionCreate)"  
              [matTooltip]="(((configurations$ | async) | filterOn : 'id' : item?.configurationId).length === 0 || providerCheckChanged)
               ? ('Unable to rerun session. Create new one instead.' | translate) : null">
                <button mat-stroked-button (click)="rerunSession()" 
                [disabled]="(!(((configurations$ | async) | filterOn : 'id' : item?.configurationId).length > 0)) || providerCheckChanged">
                  <mat-icon>loop</mat-icon>
                  {{'Rerun'|translate}}
                </button>
              </div>
            </div>
          </mat-card-title>
          <mat-card-content>
            <div *ngFor="let business of item?.businesses">
              <app-view-business [business]="business"></app-view-business>
            </div>
            <div *ngFor="let person of item?.persons">
              <app-view-person [person]="person"></app-view-person>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div fxFlex>
      <div class="inv-list">
        <mat-card>
          <mat-card-title class="mat-card-title">
            {{'Session Details' | translate}}
          </mat-card-title>
          <mat-card-content>
            <mat-accordion>
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <strong>{{ title }}</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                    <span></span>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <table>
                  <tr>
                    <th>{{'session_overallCheckStatus' | translate}}</th>
                    <td>
                      {{ ('checkStatus_'+item?.overallCheckStatus)|translate}}
                    </td>
                    <td style="width: 24px;">
                      <app-check-status [status]="item?.overallCheckStatus" style="display: inline;">
                      </app-check-status>
                    </td>
                  </tr>
                  <tr>
                    <th>{{'session_status' | translate}}</th>
                    <td>
                      {{ ('sessionStatus_'+item?.status)|translate}}
                    </td>
                    <td style="width: 24px;">
                      <app-session-status [status]="item?.status" style="display: inline;"></app-session-status>
                    </td>
                  </tr>
                  <tr *ngIf="item?.errorCode" class="label error">
                    <th>{{'session_error' | translate}}</th>
                    <td colspan="2">{{item?.errorDescription}}</td>
                  </tr>
                  <tr>
                    <th>{{'session_testing' | translate}}</th>
                    <td colspan="2">{{(item?.isTesting ? 'Yes' : 'No') | translate}}</td>
                  </tr>
                  <tr *ngIf="item?.code">
                    <th>{{'session_code' | translate}}</th>
                    <td colspan="2">{{item?.code}}</td>
                  </tr>
                  <tr *ngIf="item?.description">
                    <th>{{'session_description' | translate}}</th>
                    <td colspan="2">{{item?.description}}</td>
                  </tr>
                  <tr>
                    <th>{{'session_created' | translate}}</th>
                    <td colspan="2">{{item?.created | date:'short'}}</td>
                  </tr>
                  <tr *ngIf="item?.company">
                    <th>{{'session_created_by_company'|translate}}</th>
                    <td colspan="2">{{item?.company?.name }}</td>
                  </tr>
                  <tr *ngIf="item?.user">
                    <th>{{'session_created_by'|translate}}</th>
                    <td colspan="2">{{item?.user.firstName}} {{item?.user.middleName}} {{item?.user.lastName}}</td>
                  </tr>
                  <tr>
                    <th>{{'session_configuration' | translate}}</th>
                    <td colspan="2">{{item?.configuration?.name }}</td>
                  </tr>
                  <!-- <tr>
                    <th>{{'session_checks' | translate}}</th>
                    <td colspan="2">
                      <span *ngFor="let check of item?.checks; index as i"><br *ngIf="i>0">{{(
                        'checkType_'+check?.checkType)|translate}}</span>
                    </td>
                  </tr> -->
                  <!-- <tr>
                    <th>{{'session_providers' | translate}}</th>
                    <td colspan="2">
                      <span *ngFor="let check of item?.configuration?.checks; index as i"><span *ngIf="i>0">, </span>{{
                        check?.providerCheck?.provider?.name}}</span>
                    </td>
                  </tr> -->
                  <tr>
                    <th>{{'session_responseTransport' | translate}}</th>
                    <td colspan="2">{{('responseTransport_'+item?.responseTransport)|translate}}</td>
                  </tr>
                </table>
                <mat-action-row style="padding-left: 0px">
                  <button *ngIf="item?.status == 'ONGOING'" (click)="onCancelMonitoring()" mat-button
                    color="warn">{{'Cancel monitoring'
                    |translate}}</button>
                  <span fxFlex></span>
                  <a mat-button color="primary" [href]="'/certificate/'+ item.id" target="_blank">PRINT
                    CERTIFICATE</a>
                </mat-action-row>
              </mat-expansion-panel>
            </mat-accordion>
            <div style="margin-top: 24px;" *ngIf="checks">
              <h3>{{'session_checks'|translate}}</h3>
              <div class="infoblock info" *ngIf="hasResults()">Please, <strong>review all check results</strong> and
                mark them true or
                false positive to be
                able to continue with inqury.</div>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let check of checks; index as index">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="line-height: 46px;">
                      <strong>{{('checkType_'+check?.checkType)|translate}}</strong>
                    </mat-panel-title>
                    <mat-panel-description>
                      <span></span>
                      <app-check-status [status]="check?.checkStatus" style="display: inline;"></app-check-status>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <table>
                    <tr *ngIf="check?.errorCode" class="label error">
                      <th>{{'check_error' | translate}}</th>
                      <td>{{check?.errorDescription}}</td>
                    </tr>
                    <tr>
                      <th>{{'check_status' | translate}}</th>
                      <td>{{('checkStatus_'+check?.checkStatus) | translate}}</td>
                    </tr>
                    <tr *ngIf="check?.created">
                      <th>{{'check_created' | translate}}</th>
                      <td>{{check?.created | date:'short'}}</td>
                    </tr>
                    <tr *ngIf="check?.started">
                      <th>{{'check_started' | translate}}</th>
                      <td>{{check?.started | date:'short'}}</td>
                    </tr>
                    <tr *ngIf="check?.finished">
                      <th>{{'check_finished' | translate}}</th>
                      <td>{{check?.finished | date:'short'}}</td>
                    </tr>
                    <tr *ngIf="check?.modified">
                      <th>{{'check_modified' | translate}}</th>
                      <td>{{check?.modified | date:'short'}}</td>
                    </tr>
                    <tr *ngIf="check?.deleted">
                      <th>{{'check_deleted' | translate}}</th>
                      <td>{{check?.deleted | date:'short'}}</td>
                    </tr>

                    <tr *ngIf="check?.matches" class="label ok">
                      <th>{{'checkResults_statusFalsePositive'|translate}}</th>
                      <td>{{(check.results|filterOn:'status':['false-positive']).length}}</td>
                    </tr>
                    <tr *ngIf="check?.matches" class="label error">
                      <th>{{'checkResults_statusTruePositive'|translate}}</th>
                      <td>{{(check.results|filterOn:'status':['true-positive']).length}}</td>
                    </tr>
                    <tr *ngIf="check?.matches" class="label warn">
                      <th>{{'checkResults_statusPending'|translate}}</th>
                      <td>{{(check.results|filterOn:'status':['pending']).length}}</td>
                    </tr>
                    <tr *ngIf="check?.matches">
                      <th>{{'checkResults_total'|translate}}</th>
                      <td>{{check?.matches}}</td>
                    </tr>
                  </table>

                  <mat-action-row *ngIf="(check.results|filterOn:'status':['pending']).length">
                    <button mat-button color="primary" (click)="showTab(index)">REVIEW</button>
                  </mat-action-row>
                </mat-expansion-panel>

              </mat-accordion>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
  <div *ngIf="checks else loading">
    <div class="inv-list" *ngIf="hasResults()">
      <mat-tab-group #tabs [animationDuration]="animationDuration">
        <mat-tab *ngFor="let check of checks">
          <ng-template mat-tab-label>
            <app-check-type [checkType]="check.checkType"></app-check-type>
            <span>&nbsp;{{('checkType_'+check?.checkType)|translate}}</span>
            &nbsp;({{check.matches}})
          </ng-template>
          <div *ngIf="check.errorDescription" class="infoblock warning">
            <strong>Error {{check.errorCode}}</strong>:<br />
            {{check.errorDescription}}
          </div>
          <mat-action-list *ngIf="check?.results?.length; else noMatches">
            <h3 matSubheader fxLayout="row" fxFill>
              <div fxFlex="46px"></div>

              <div fxFlex>
                <div mat-line>{{'Name'|translate }}</div>
              </div>
              <div fxFlex="300px">
                <div mat-line>{{'Category'|translate }}</div>
              </div>
              <!-- <div fxFlex="140px" *ngIf="item?.persons.length">
                <div mat-line>{{'Gender' }}</div>
              </div> -->
              <div fxFlex="140px" *ngIf="item?.businesses?.length">
                <div mat-line>{{'Type'|translate }}</div>
              </div>
              <div fxFlex="210px">
                <div mat-line>{{'Reviewed'|translate }}</div>
              </div>

            </h3>
            <mat-list-item *ngFor="let result of check.results" [routerLink]="['result', result.id]"
              routerLinkActive="router-link-active" [ngClass]="{ warning: ['TER/SIP'].indexOf(result?.category)>-1}">

              <app-check-result-status mat-list-icon status="{{result?.status}}"></app-check-result-status>

              <h3 mat-line>
                {{ result?.person?.title }} {{ result?.person?.firstName }} {{ result?.person?.middleName }} {{
                result?.person?.lastName }}
                {{ result?.business?.companyName }}
              </h3>
              <small mat-line *ngIf="result?.note" [innerText]="ui.getLastNote(result?.note)">
              </small>
              <div fxFlex="300px">
                <small mat-line>
                 {{ result?.category }}
                </small>
              </div>
              <!-- <div fxFlex="140px">
                <div mat-line>{{ result?.person?.gender }}{{ result?.business?.companyIdType }}</div>
              </div> -->
              <div fxFlex="210px">
                <div mat-line>
                  {{ result?.falsePositiveUser?.firstName}} {{
                  result?.falsePositiveUser?.middleName}} {{ result?.falsePositiveUser?.lastName}}

                  {{ result?.truePositiveUser?.firstName}} {{
                  result?.truePositiveUser?.middleName}} {{ result?.truePositiveUser?.lastName}}

                </div>
                <div mat-line>
                  <small *ngIf="result?.falsePositive">{{ result?.falsePositive | date:'short'}}</small>
                  <small *ngIf="result?.truePositive">{{ result?.truePositive | date:'short'}}</small>
                  </div>
              </div>
              <mat-divider [inset]="true"></mat-divider>
            </mat-list-item>
          </mat-action-list>
          <ng-template #noMatches>
            <div class="noMatches">{{'No matched results.' | translate}}</div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
<ng-template #loading>
  <div fxFill fxLayout="column" fxLayoutAlign="center center">
    <div>
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </div>
</ng-template>