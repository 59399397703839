<mat-icon class="warning" matTooltip="{{'companyStatus_APPLIED'|translate}}" *ngIf="status == 'APPLIED'">outbound
</mat-icon>
<mat-icon class="ok" matTooltip="{{'companyStatus_ACTIVE'|translate}}" *ngIf="status == 'ACTIVE'">check_circle
</mat-icon>
<mat-icon class="warning" matTooltip="{{'companyStatus_PAUSED'|translate}}" *ngIf="status == 'PAUSED'">pending
</mat-icon>
<mat-icon class="error" matTooltip="{{'companyStatus_DECLINED'|translate}}" *ngIf="status == 'DECLINED'">error
</mat-icon>
<mat-icon class="info" matTooltip="{{'companyStatus_SUSPENDED'|translate}}" *ngIf="status == 'SUSPENDED'">cancel
</mat-icon>
<mat-icon class="error" matTooltip="{{'companyStatus_CANCELED'|translate}}" *ngIf="status == 'CANCELED'">dangerous
</mat-icon>