<item-wrapper #item [mainService]="service" [form]="form" navigateList="features" title="Feature"
  [readPerm]="auth.hasPermission(auth.perm.ProductRead)" [managePerm]="auth.hasPermission(auth.perm.ProductManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">
    <div fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field fxFlex="15%">
        <mat-label>{{ 'Icon' }}</mat-label>
        <mat-select formControlName="icon">
          <mat-option value="">
            None
          </mat-option>
          <mat-option value="yes">
            <mat-icon color="primary">check</mat-icon>
          </mat-option>
          <mat-option value="no">
            <mat-icon color="warn">close</mat-icon>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="15%">
        <mat-label>{{ 'Order' }}</mat-label>
        <input matInput type="number" formControlName="order" />
      </mat-form-field>
    </div>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description' }}</mat-label>
      <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </form>
</item-wrapper>