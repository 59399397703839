import { Unsubscribable } from "rxjs";

export class Subsink {
   private subscriptions: Unsubscribable[] = [];

   public set subscription(unsub: Unsubscribable) {
      this.subscriptions.push(unsub);
   }

   terminateAll() {
      this.subscriptions.forEach(e => e?.unsubscribe());
      this.subscriptions = [];
   }
}
