import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { ConfigApplication } from 'src/app/_graphql/schema';
import { ApplicationsService } from 'src/app/_services/applications.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
  $data: Subscribable<ConfigApplication[]>;

  constructor(
    public auth: AuthService,
    public applications: ApplicationsService,
  ) { }
  ngOnInit(): void {
    this.$data = this.applications.all();
  }
}
