<list-wrapper #wrapper [mainService]="service" navigateItem="products" title="Products"
  [readPerm]="auth.hasPermission(auth.perm.CompanyRead)" [managePerm]="auth.hasPermission(auth.perm.CompanyManage)"
  [showDateFilter]="true">
  <mat-action-list fxLayout="column" class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>
      <div fxFlex="90px" class="text-right" fxHide.lt-lg>
        <div mat-line>Checks</div>
      </div>
      <div fxFlex="90px" class="text-right" fxHide.lt-lg>
        <div mat-line>Features</div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>Price</div>
      </div>
      <div fxFlex="120px" class="text-right" fxHide.lt-lg>
        <div mat-line>Payment</div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>Visible</div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>Default</div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>Disabled</div>
      </div>
    </h3>

    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)">
      <mat-icon matListIcon>shop</mat-icon>
      <strong mat-line>{{ item.name }} </strong>
      <small mat-line>{{ item.description }}</small>
      <div fxFlex="90px" class="text-right" fxHide.lt-lg>
        <div mat-line>
          <small>{{item?.checks.length}}</small>
        </div>
      </div>
      <div fxFlex="90px" class="text-right" fxHide.lt-lg>
        <div mat-line>
          <small>{{item?.features.length}}</small>
        </div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>
          <small>{{item?.price | currency }}</small>
        </div>
      </div>
      <div fxFlex="120px" class="text-right" fxHide.lt-lg>
        <div mat-line>
          <small matTooltip="{{item?.paymentOption}}">{{item?.paymentOption | translate  }}</small>
        </div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>
          <small><mat-icon *ngIf="item?.isVisible" style="font-size: 16px;">check</mat-icon></small>
        </div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>
          <small><mat-icon *ngIf="item?.isDefault" style="font-size: 16px;">check</mat-icon></small>
        </div>
      </div>
      <div fxFlex="90px" class="text-right">
        <div mat-line>
          <small><mat-icon *ngIf="item?.isDisabled" style="font-size: 16px;">check</mat-icon></small>
        </div>
      </div>

      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>