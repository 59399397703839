import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';


import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

import { UiService } from 'src/app/_services/ui.service';
import { ProductsService } from 'src/app/_services/products.service';
import { PurchasesService } from 'src/app/_services/purchases.service';
import { Product, Purchase } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  $data: Subscribable<Product[]>;
  constructor(
    public service: ProductsService,
    public auth: AuthService,
    private router: Router,
    public ui: UiService,
  ) {
  }

  ngOnInit(): void {
    // this.service.queryParams.isVisible = true;
    this.$data = this.service.all();
  }
}
