import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

import { UiService } from 'src/app/_services/ui.service';
import { ProductFeaturesService } from 'src/app/_services/product-features.service';

@Component({
  selector: 'app-products-features',
  templateUrl: './products-features.component.html',
  styleUrls: ['./products-features.component.scss']
})
export class ProductsFeaturesComponent implements OnInit {
  $data: Subscribable<any[]>;
  constructor(
    public service: ProductFeaturesService,
    public auth: AuthService,
    private router: Router,
    public ui: UiService,
  ) {
  }

  ngOnInit(): void {
    this.$data = this.service.all();

  }


}
