import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { Subscribable } from "rxjs";
import { Invoice, TransactionStatus } from "../_graphql/schema";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
})
export class InvoicesService extends BaseService<Invoice> {
    constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
        super(apollo, sb);
        this.selectOneFields = gql`
            fragment SelectOneFields on Invoice {
                id
                name
                created
                modified
                status
                documentId
                totalPrice
                invoiceItems { 
                    id
                    price
                    name
                    spentChecks
                    purchase { id productName }
                    purchaseItem {
                        id
                        includedChecks
                        productCheck { id name }
                    }
                }
                transactions { id payedAmount status totalPrice created }
            }`;

        this.selectAllFields = gql`
            fragment SelectAllFields on Invoice {
                id
                name
                created
                invoiceItems { id }
                modified
                status
                totalPrice
                transactions { id }
            }`;

        this.initGql("invoice");
        this.queryParams.sortOrder = "DESC";
        this.queryParams.sortBy = "created";
    }
    gqlPayAgain: DocumentNode = gql`mutation payAgain($invoiceId: ID!) { 
        payAgain( invoiceId: $invoiceId ) 
    }`;
    public payAgain(invoiceId: any): Subscribable<any> {
        return super.mutation(this.gqlPayAgain, { invoiceId });
    }

    gqlCreateInvoice: DocumentNode = gql`mutation invoiceDocument($invoiceId: ID!) { 
        invoiceDocument( invoiceId: $invoiceId ) 
    }`;

    gqlSetInvoiceStatus: DocumentNode = gql`
      mutation setInvoiceStatus($invoiceId: ID!, $status: TransactionStatus!) { 
        setInvoiceStatus( invoiceId: $invoiceId, status: $status ) {
          id status
        }
      }`;

    public createInvoice(invoiceId: any): Subscribable<any> {
        this.addRefetchQuery(this.selectOneQuery, { id: invoiceId })
        return super.mutation(this.gqlCreateInvoice, { invoiceId });
    }

    public setInvoiceStatus(invoiceId: string, status: TransactionStatus): Subscribable<Invoice> {
        this.addRefetchQuery(this.selectOneQuery, { id: invoiceId })
        return super.mutation(this.gqlSetInvoiceStatus, { invoiceId, status });
    }

    gqlGetInvoiceStatus: DocumentNode = gql`
    query invoice ($id: ID!){
        invoice(id: $id) {
         id
         status 
        }
    }
    `;

    public getInvoiceStatus (id:string) {
        return this.query(this.gqlGetInvoiceStatus, {id:id});
      }

}
