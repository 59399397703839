<div [matMenuTriggerFor]="menu" class="switcher">
    <strong>
        {{ (user$|async)?.firstName}} {{ (user$|async)?.lastName}}
        <small *ngIf="showCompanyName">{{ (company$|async)?.name}}</small>
    </strong>

    <div class="avatar"
        [style.backgroundImage]="(getAvatar(company$|async) ? 'url('+ getAvatar(company$|async) +')' : '')">
        <!-- matBadge="!" matBadgeColor="warn" [matBadgeHidden]="(user$|async)?.emailConfirmed" -->
        <span *ngIf="!(company$|async)?.status">{{ (user$|async)?.firstName[0]}}{{ (user$|async)?.lastName[0]}}</span>
        <app-company-status [status]="(company$|async)?.status" *ngIf="(company$|async)?.status"></app-company-status>
    </div>
    <mat-icon>expand_more</mat-icon>
</div>
<mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="!(user$|async)?.emailConfirmed" (click)="resendVerificationEmail()">
        <mat-icon color="warn">error</mat-icon>
        Resend verification email
    </button>
    <button mat-menu-item [matMenuTriggerFor]="companySwitch" *ngIf="(user$|async)?.companies?.length > 1">
        <mat-icon>business</mat-icon>Switch to
    </button>
    <button mat-menu-item [routerLink]="['/', segment, 'users', auth?.user.id]" routerLinkActive="router-link-active">
        <mat-icon>person_outline</mat-icon>
        {{'Edit profile' |translate}}
    </button>
    <mat-divider></mat-divider>

    <button mat-menu-item [routerLink]="['/logout']" routerLinkActive="router-link-active">
        <mat-icon>logout</mat-icon>
        {{'Logout'|translate}}
    </button>
</mat-menu>
<mat-menu #companySwitch="matMenu">
    <button mat-menu-item *ngFor="let company of (user$|async)?.companies" (click)="openCompany(company?.id)">
        <mat-icon>business</mat-icon>
        <strong *ngIf="company?.id == (user$|async)?.company?.id">{{company?.name}}</strong>
        <span *ngIf="company?.id != (user$|async)?.company?.id">{{company?.name}}</span>
    </button>
</mat-menu>