import { style } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ConnectableObservable, Subscribable } from 'rxjs';
import { Company, CompanyToken } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companies.service';
import { TokensService } from 'src/app/_services/tokens.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-token-item',
  templateUrl: './token-item.component.html',
  styleUrls: ['./token-item.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class TokenItemComponent implements OnInit {
  selectionModel: any;
  permissionItems: string[] = [];

  data$: Subscribable<CompanyToken[]>;
  companies$: Subscribable<Company[]>;
  isHidden: boolean = true;

  form: FormGroup;

  constructor(
    public tokens: TokensService,
    public auth: AuthService,
    private fb: FormBuilder,
    private ui: UiService,
  ) {
    this.permissionItems = [auth.perm.SessionRead, auth.perm.SessionCreate, auth.perm.SessionCancel, auth.perm.ConfigurationRead, auth.perm.SessionTestCreate];
    this.form = this.fb.group({
      id: [null],
      name: [null, Validators.required],
      token: [null, []],
      ipWhitelist: [null, [Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
      permissions: [this.permissionItems],
      expiration: [moment().add(1, 'year'), [Validators.required]],
      isAllowed: [false, Validators.required],
      webhookAuthKey: [null, []],
      webhookUrl: [null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
    });

    this.selectionModel = new SelectionModel(
      true,
    );
  }

  ngOnInit(): void {


  }
  
  onClipboardCopy() {
    this.ui.snack('Successfully copied token.');
  }
  toggleRow(row) {
    this.selectionModel.toggle(row);
    // if (this.selectionModel.selected.length >= 5) {
    //   this.selectionModel.deselect(row);
    // }
    if (this.selectionModel.selected.includes(this.auth.perm.SessionCreate)) {
      this.selectionModel.select(this.auth.perm.SessionTestCreate);
      this.selectionModel.select(this.auth.perm.SessionRead);
    } else if (this.selectionModel.selected.includes(this.auth.perm.SessionTestCreate)) {
      this.selectionModel.select(this.auth.perm.SessionRead);
    }
    
    if (!this.selectionModel.selected.includes(this.auth.perm.SessionCreate) && row === this.auth.perm.SessionCreate) {
      this.selectionModel.deselect(this.auth.perm.SessionTestCreate);
      this.selectionModel.deselect(this.auth.perm.SessionRead);
    }
    
    if (!this.selectionModel.selected.includes(this.auth.perm.SessionTestCreate) && row === this.auth.perm.SessionTestCreate) {
      this.selectionModel.deselect(this.auth.perm.SessionRead);
    }
    this.form.patchValue({ permissions: this.selectionModel.selected })
  }
}
