import { Component, Input, forwardRef, HostListener, EventEmitter, Output, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';
import { DocumentsService } from 'src/app/_services/documents.service';
import { UiService } from 'src/app/_services/ui.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AvatarUploadComponent),
      multi: true,
    }]
})

export class AvatarUploadComponent implements ControlValueAccessor, Validator, OnInit {
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Input() image: any;
  @Input() applicationId: string;
  @Input() type: string = "avatar";
  _isDisabled = false;
  private avatarSubscription: Subscription;
  profileProgressMode: ProgressSpinnerMode = 'indeterminate';
  loading = false;
  private jsonString: string;
  private parseError: boolean;
  data: any;

  setDisabledState(isDisabled: boolean) {
    this._isDisabled = isDisabled;
    // this.renderer.setElementProperty(this.textInput.nativeElement, 'disabled', isDisabled);
    // disable other components here
  }

  @HostListener('change', ['$event.target.files'])
  emitFiles(event: FileList) {
    this.loading = true;
    const file = event && event.item(0);
    let backgroundImage: string | false | ArrayBuffer | null;
    const reader: any = new FileReader();
    reader.onload = () => { backgroundImage = (this.data !== 'no-bg' ? reader.result : false); }
    reader.readAsDataURL(file);
    this.avatarSubscription = this.docsService.postFiles(event, "AVATAR")
      .subscribe({
        next: (data: any) => {
          if (data && data[0]) {
            this.data = data[0].id;
            this.fileUploaded.emit(data[0])
            this.propagateChange(this.data);
            this.image = backgroundImage;
            this.loading = false;
          }
        }, error: (error: any) => {
          this.loading = false;
          console.log(error)


        }
      });
  }
  constructor(
    private docsService: DocumentsService,
    private ui: UiService
  ) {

  }
  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.avatarSubscription?.unsubscribe();
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;

      if (!this.image && this.data)
        this.image = `${environment.imageThumbnails}/${this.data}`;
    }
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    return (!this.parseError) ? null : {
      jsonParseError: {
        valid: false,
      },
    };
  }

  // not used, used for touch input
  public registerOnTouched() { }

  // change events from the textarea
  onChange(event: any) {
    ;
    // get value from text area
    let newValue = event.target.value;

    try {
      // parse it to json
      this.data = newValue;
      this.parseError = false;
    } catch (ex) {
      // set parse error if it fails
      this.parseError = true;
    }

    // update the form
    this.propagateChange(this.data);
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange = (_: any) => { };




  remove() {
    this.fileRemoved.emit(this.data)
    this.data = null;
    this.image = null;
    this.propagateChange(this.data)
  }
}
