import { AbstractControl } from "@angular/forms";

export class CustomValidator {
    // Number only validation
    static numeric(control: AbstractControl) {
        let val = control.value;

        if (val === null || val === '') return null;

        if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { 'invalidNumber': true };

        return null;
    }
    static minLengthArray = (min: number) => {
        return (c: AbstractControl): {[key: string]: any} => {
          if (c.value.length >= min || !min)
            return null;
      
          return { MinLengthArray: true};
        }
      }
    static maxLengthArray = (max: number) => {
        return (c: AbstractControl): {[key: string]: any} => {
          if (c.value.length <= max || !max)
            return null;
      
          return { MaxLengthArray: true};
        }
      }
}
