<mat-list-item [ngClass]="{ short: mode == 'short' }">
  <app-check-status matListIcon [status]="item?.overallCheckStatus">
  </app-check-status>
  <h3 mat-line>
    {{ item?.persons?.length > 0 ? (item.persons[0].firstName + ' ' + item.persons[0].lastName) : '' }}
    {{ item?.businesses?.length > 0 ? item.businesses[0].companyName : '' }}
  </h3>
  <small mat-line>
    {{item?.description}}</small>
  <div fxFlex="110px" fxHide.lt-md class="text-center">
    <div mat-line><code *ngIf="item?.code">{{item?.code }}</code></div>
  </div>
  <div fxFlex="36px">
    <mat-icon *ngIf="item?.persons?.length">person</mat-icon>
    <mat-icon *ngIf="item?.businesses?.length">work</mat-icon>
  </div>


  <div fxFlex="210px" fxHide.lt-md *ngIf="mode == 'full'">
    <div mat-line [matTooltip]="item?.configuration?.name">{{item?.configuration?.name}}</div>
  </div>
  <div fxFlex="36px">
  </div>
  <div fxFlex="200px" fxLayout="row" fxHide.lt-md *ngIf="mode == 'full'" fxLayoutGap="10px">
    <ng-container *ngFor="let check of item?.checks; index as i;">
      <div class="checks" fxLayout="row" fxLayoutAlign="start center" *ngIf="i<3">
        <small *ngIf="check?.matches" class="numberOfChecks">{{check?.matches}}</small>
        <app-check-type [checkType]="check.checkType" matTooltip="{{('checkType_'+check.checkType)|translate}}">
        </app-check-type>
      </div>
      <small fxLayout="row" fxLayoutAlign="start center" *ngIf="(i == item.checks.length-1) && item.checks.length>3">+
        {{item?.checks.length -3}} more</small>
    </ng-container>
  </div>

  <div fxFlex="140px" fxHide.lt-md *ngIf="mode == 'full'">
    <div mat-line>
      {{item?.user?.firstName}} {{item?.user?.middleName}} {{item?.user?.lastName}} {{item?.apiToken?.name}}
    </div>
    <small mat-line>{{ item.created| date:'short' }}</small>
  </div>

  <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <small *ngIf="item?.price">{{ item?.price | currency }}</small>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <small *ngIf="item?.cost">{{ item?.cost | currency }}</small>
      </div> -->
  <div fxFlex="54px" style="padding-left: 8px;" *ngIf="mode == 'full'">
    <mat-icon *ngIf="item?.isTesting" matTooltip="{{'session_testing' |translate}}">science</mat-icon>
  </div>
  <div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
</mat-list-item>