import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewChecked, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs';
import { Company } from 'src/app/_graphql/schema';
import { CompaniesService } from 'src/app/_services/companies.service';



@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  company: Company;
  form = this.fb.group({
    companyId: [null, Validators.required],
    note: [null, Validators.required],
  });
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotesDialogComponentData,
    private fb: FormBuilder,
    private _ngZone: NgZone,
    private companiesService: CompaniesService
  ) {

    this.company = data.company;
    this.form.patchValue({ companyId: this.company.id });
    this.companiesService.one(this.company.id).subscribe({
      next: x => {
        this.company = x;
        this.scrollToBottom();
      }
    })

  }
  ngOnDestroy(): void {

  }
  ngOnInit() {
    this.scrollToBottom();
    this.company = this.data.company;
    this.form.patchValue({ companyId: this.company.id });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  onSubmit() {
    if (this.form.valid) {
      console.log('sending')
      this.companiesService.addCompanyNote(this.form.value).subscribe({
        next: x => {
          this.form.patchValue({ note: null });
          // var s = this.companiesService.one(this.company.id).subscribe({ next: k => { this.company = k; s.unsubscribe() } });
        }, error: e => {
          // var s = this.companiesService.one(this.company.id).subscribe({ next: k => { this.company = k; s.unsubscribe() } });
        }
      })
    }
  }
}
export interface NotesDialogComponentData {
  company: Company;
}