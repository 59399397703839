import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base.service';
import { Translation, TranslationInput, TranslationLanguage } from '../_graphql/schema';
import { Subscribable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService extends BaseService<TranslationLanguage> {
  selectOneFields = gql`
  fragment SelectOneFields on Translation {
    id key value languageId
    language { id name }
    deleted created modified
  }`;

  selectAllFields = gql`
  fragment SelectAllFields on TranslationLanguage {
    id key
    translations {
      languageId value
      language { id name }
    }
  }`;

  selectByLanguageQuery = gql`
  query translationsByLanguage($isoCode2: String) {
    translationsByLanguage(isoCode2:$isoCode2) {
      id
      key
      value
    }
  }
  `;

  selectByKey = gql`
  query translationsByKey($key:String!) {
    translationsByKey(key:$key) {
      id language {id name} languageId key value
    }
  }`;

  mutationCreateUpdateTranslation = gql`
  mutation createUpdateTranslations($items: [TranslationInput]!) {
    createUpdateTranslations(items: $items) {
      id languageId key value
    }
  }`;

  loadedTranslations = false;
  currentLanguage: string;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar, public tt: TranslateService, public auth: AuthService) {
    super(apollo, sb);
    this.initGql('translation');
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "key";
    if (!this.loadedTranslations) {
      let lang = 'en';

      if (this.auth.user && this.auth.user.language?.isoCode2) {
        lang = this.auth.user.language.isoCode2;
      }

      this.tt.setDefaultLang(lang);
      this.tt.use(lang);
      this.getTranslations(lang);
      this.currentLanguage = lang;
    }
  }

  public getTranslationsByKey(key: string): Subscribable<Translation[]> {
    return this.query(
      this.selectByKey,
      { key }
    ) as unknown as Subscribable<Translation[]>;
  }

  public createUpdateTranslation(items: TranslationInput[]): Subscribable<Translation[]> {
    this.addRefetchQuery(this.selectByLanguageQuery, { isoCode2: this.currentLanguage });
    this.addRefetchQuery(this.selectByKey, { key: items[0].key });
    this.addRefetchQuery(this.selectQuery, { queryParams: this.queryParams });

    return this.mutation(
      this.mutationCreateUpdateTranslation,
      { items });
  }

  public getTranslations(langCode = 'en') {
    var _trans;
    try {
      _trans = JSON.parse(localStorage.getItem(`__translations_${langCode}`) as string);
    } catch (error) {
      localStorage.removeItem(`__translations_${langCode}`)
    }
    if (_trans) {
      this.fillUpTranslations(langCode, _trans);
    }

    this.apollo
      .watchQuery({
        query: this.selectByLanguageQuery,
        variables: { isoCode2: langCode }
      })
      .valueChanges.pipe(
        map((result: any) => {
          if (!result.data || !result.data.translationsByLanguage
          ) {
            return;
          }

          const translations: any = {};
          result.data.translationsByLanguage.map((tr: Translation) => {
            translations[tr.key] = tr.value;
          });

          localStorage.setItem(`__translations_${langCode}`, JSON.stringify(translations));
          this.fillUpTranslations(langCode, translations);
        }))
      .subscribe(trans => { });
  }

  public fillUpTranslations(langCode: string, translations: any) {
    this.tt.setTranslation(langCode, translations);

    this.tt.setDefaultLang(langCode);
    this.tt.use(langCode);
    this.loadedTranslations = true;
    this.currentLanguage = langCode;
  }


  gqlExport = gql`
  mutation exportTranslations {
    exportTranslations
  }`;

  gqlImport = gql`
  mutation importTranslations($fileId: ID) {
    importTranslations(fileId:$fileId)
  }`;

  public exportTranslations() {
    return this.mutation(this.gqlExport, {});
  }
  public importTranslations(fileId) {
    return this.mutation(this.gqlImport, { fileId });
  }

  public getExportTranslationsFileId() {
    return this.apollo
      .watchQuery({
        query: gql`query translationsFileId {translationsFileId} `,
      }).valueChanges.pipe(
        map((result: any) => {
          if (!result.data || !result.data.translationsFileId
          ) {
            return;
          }
          return result.data.translationsFileId;
        }))
  }

}
