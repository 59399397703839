<div class="main-sidebar" fxLayout="column">
  <mat-toolbar class="header">
    <mat-toolbar-row>
      <ng-content select="[header]"></ng-content>
      <div fxFlex></div>
      <button mat-icon-button (click)="closeSidebar()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div fxFlex style="overflow-y: auto;">

    <ng-content></ng-content>
  </div>
  <mat-toolbar class="footer">
    <mat-toolbar-row fxLayout="row" fxLayoutGap="8px">
      <div fxFlex></div>
      <ng-content select="[footer]"></ng-content>
    </mat-toolbar-row>
  </mat-toolbar>
</div>