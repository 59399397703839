import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { CompanyUsersComponent } from './users.component';
import { CompanyUserItemComponent } from './user-item/user-item.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { TranslationsModule } from 'src/app/admin/translations/translations.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserProfileComponent } from './user-profile/user-profile.component';



@NgModule({
  declarations: [
    CompanyUsersComponent,
    CompanyUserItemComponent,
    UserProfileComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    WrappersModule,
    PipesModule
  ]
})
export class CompanyUsersModule { }
