<ng-container *ngIf="conf">
  <form [formGroup]="form">
    <ng-container [ngTemplateOutlet]="formMainTemplate"
      [ngTemplateOutletContext]="{ conf:conf, fg:form }" style="display: flex; flex-direction: column;"></ng-container>
  </form>
</ng-container>

<ng-template #formMainTemplate let-conf="conf" let-fg="fg">
  <form [formGroup]="fg" [class]="conf?.cssClass" *ngIf="conf?.type !== 'HIDDEN'  
  && !(getFormElementlByName(conf?.name, this.form)?.disabled)">
    <div style="width: 100%;">
    <ng-container [ngTemplateOutlet]="formArrayTemplate" *ngIf="conf?.type == 'ARRAY'
     && (conf?.visible ? conf?.visible === true : true)"
     [ngTemplateOutletContext]="{ conf: conf, fg:fg }"></ng-container>
    </div>
    <div style="width: 100%;">

    <ng-container [ngTemplateOutlet]="formGroupTemplate" 
    *ngIf="conf?.type == 'FG' && (conf?.visible ? conf?.visible === true : true)"
      [ngTemplateOutletContext]="{ conf: conf, fg:fg }"></ng-container>
    </div>
    <ng-container *ngIf="conf?.type === 'TEXT'">
      <mat-form-field [ngStyle]="{width: conf?.cssClass}">
        <mat-label>{{ conf?.label }}</mat-label>
        <input matInput [formControlName]="conf?.name" [readonly]="conf?.disabled"/>
        <mat-hint><span> {{conf?.description}} </span></mat-hint>
          <mat-icon matSuffix *ngIf="conf?.name === 'phone'">phone</mat-icon>
          <mat-icon matSuffix *ngIf="conf?.name === 'websiteIp' || conf?.name === 'ipAddress'">travel_explore</mat-icon>
          <mat-icon matSuffix *ngIf="conf?.name === 'website'">public</mat-icon>
          <mat-icon matSuffix *ngIf="conf?.name === 'email'">email</mat-icon>

      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="conf?.type === 'FILE'">
      <app-file-upload [formControlName]="conf?.name" (fileUploaded)="patchFileId($event, conf, fg)"
      (fileRemoved)="delete()"></app-file-upload> 
    </ng-container>

    <ng-container *ngIf="conf?.type === 'DATE'">
      <mat-form-field>
        <mat-label>{{ conf?.label }}</mat-label>
        <input matInput [matDatepicker]="picker" [formControlName]="conf?.name"
        [min]="getMin(conf?.validation?.dateFutureLimit)" [max]="getMax(conf?.validation?.datePastLimit)"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint><span> {{conf?.description}} </span></mat-hint>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="conf?.type === 'NUMBER'">
      <mat-form-field>
        <mat-label>{{conf?.label}}</mat-label>
        <input matInput [formControlName]="conf?.name" type="number" />
        <mat-hint><span> {{conf?.description}} </span></mat-hint>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="conf?.type === 'SELECT'">
      <mat-form-field>
        <mat-label>{{ conf?.label }}</mat-label>
        <mat-select [formControlName]="conf?.name" *ngIf="conf.name !== 'province' &&  conf.name !== 'country'
        && conf.name !== 'countryCode' && conf.name !== 'state'">
          <mat-option *ngFor="let option of conf?.items" [value]="option?.name">
            {{ option?.label }}
          </mat-option>
          <mat-option *ngIf="!(conf?.items)?.length" disabled="true">
          {{ 'List Is Empty' }}
          </mat-option>
        </mat-select>
        <mat-select [formControlName]="conf?.name" *ngIf="conf.name === 'province'">
          <mat-option *ngFor="let option of ((states$ | async) | filterOn : 'isoCode2' : 
          [(getFormElementlByName('countryCode', form))?.value] : 'include' : 'country')" [value]="option?.isoCode">
            {{ option?.name }}
          </mat-option>
          <mat-option *ngIf="!((states$ | async) | filterOn : 'isoCode2' : 
          [(getFormElementlByName('countryCode', form))?.value] : 'include' : 'country')?.length" disabled="true">
          {{ 'List Is Empty' }}
          </mat-option>
        </mat-select>
        <mat-select [formControlName]="conf?.name" *ngIf="conf.name === 'state'">
          <mat-option *ngFor="let option of ((states$ | async) | filterOn : 'isoCode2' : 
          [(getFormElementlByName('country', form))?.value] : 'include' : 'country')" [value]="option?.isoCode">
            {{ option?.name }}
          </mat-option>
          <mat-option *ngIf="!((states$ | async) | filterOn : 'isoCode2' : 
          [(getFormElementlByName('country', form))?.value] : 'include' : 'country')?.length" disabled="true">
          {{ 'List Is Empty' }}
          </mat-option>
        </mat-select>
        <mat-select [formControlName]="conf?.name" *ngIf="conf.name === 'countryCode' || conf.name === 'country'">
          <mat-option *ngFor="let option of (countries$ | async)" [value]="conf?.displayType === 'isoCode3' ?  option?.isoCode3 : option?.isoCode2">
            {{ option?.name }}
          </mat-option>
        </mat-select>
        <mat-hint><span> {{conf?.description}} </span></mat-hint>
      </mat-form-field>      
    </ng-container>
    <ng-container *ngIf="conf?.type === 'EMAIL'">
      <mat-form-field>
        <mat-label>{{ conf?.label }}</mat-label>
        <input matInput [formControlName]="conf?.name" />
        <mat-hint><span> {{conf?.description}} </span></mat-hint>
      </mat-form-field>
    </ng-container>
  </form>

</ng-template>

<ng-template #formArrayTemplate let-conf="conf" let-fg="fg">
  <mat-card class="io-mb-2">
    <mat-card-title *ngIf="conf?.label" class="title">
      <h3>{{conf?.label}} </h3>
      <button mat-icon-button color="primary" (click)="addLine(conf?.name)" *ngIf="conf?.validation?.min !== conf?.validation?.max">
      <mat-icon>add_circle_outline</mat-icon>
  </button></mat-card-title>
    
    <mat-card-subtitle *ngIf="conf?.description">{{conf?.description}}</mat-card-subtitle>
    <ng-container *ngIf="getFA(fg, conf.name) as fa">
      <div *ngIf="conf.cssClass === 'showDropdown'">
        <mat-form-field class="documentsDropdown">
          <mat-label>{{ conf?.label }}</mat-label>
          <mat-select [formControl] = "documentType" (selectionChange)="filterConfig(getFA(fg, conf.name), conf.name)">
            <ng-continer *ngFor="let option of dropdownItems">
              <mat-option [value]="option?.name" *ngIf="(option?.visible === true)">
                  {{ option?.label }}
              </mat-option>
            </ng-continer>
          </mat-select>
          <mat-hint><span> {{conf?.description}} </span></mat-hint>
        </mat-form-field>
      </div>
      <div [formArray]="fa" class="io-grid">
        <ng-container *ngFor="let item of fa.controls; index as idx">
          <ng-container *ngIf="getFG(fa, idx) as _fg">
            <ng-container *ngIf="((conf?.items[idx]?.name === documentType?.value) || conf.cssClass !== 'showDropdown')">
              <ng-container *ngIf="conf?.items[idx]?.visible ? conf?.items[idx]?.visible === true : true">
                <ng-container *ngFor="let _item of conf?.items[idx]?.items">
                    <ng-container [ngTemplateOutlet]="formMainTemplate"
                    [ngTemplateOutletContext]="{ conf:_item, fg: _fg }"></ng-container>
                </ng-container>
              </ng-container>
              <button mat-icon-button color="warn" style="margin-top:6px" (click)="removeLine(conf, idx,  getFA(fg, conf?.name))" 
              *ngIf="!(getFA(fg, conf.name)?.value?.length === 1)">
                <mat-icon>delete_outline</mat-icon>
              </button>
          </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </mat-card>
</ng-template>
<ng-template #formGroupTemplate let-conf="conf" let-fg="fg">
  <mat-card class="io-mb-2">
    <mat-card-title *ngIf="conf?.label">{{conf?.label}}</mat-card-title>
    <mat-card-subtitle *ngIf="conf?.description">{{conf?.description}}</mat-card-subtitle>
    <ng-container *ngIf="getFGbyName(fg, conf.name) as _fg; else nema">
      <form >
        <ng-container [ngTemplateOutlet]="formMainTemplate" *ngFor="let item of conf?.items"
          [ngTemplateOutletContext]="{ conf:item, fg: _fg }"></ng-container>
      </form>
    </ng-container>

  </mat-card>
</ng-template>

