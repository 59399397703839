import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { ProductCheck } from "../_graphql/schema";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ProductChecksService extends BaseService<ProductCheck> {
  constructor(
    protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.selectOneFields = gql`
      fragment SelectOneFields on ProductCheck {
        id
        created
        createdBy { id firstName lastName email }
        deleted
        description
        includedChecks
        name
        nextPrice
        product { id name description }
        productId
        paymentInterval
        providerCheck { id name }
        providerCheckId
      }
    `;

    this.selectAllFields = gql`
    fragment SelectAllFields on ProductCheck {
        id
        created
        createdBy { id firstName lastName email }
        deleted
        description
        includedChecks
        name
        nextPrice
        product { id name description }
        productId
        paymentInterval
        providerCheck { id name }
        providerCheckId
    }
  `;

    this.initGql("productCheck");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }

}
