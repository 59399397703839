import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UiService } from 'src/app/_services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/_services/users.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  hide = true;
  onRoute$: Subscription;

  form: FormGroup;

  constructor(
    private us: UsersService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ui: UiService,
  ) {
    this.form = this.fb.group({
      username: [null, [Validators.email, Validators.required]],
      verificationCode: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
    });
    this.onRoute$ = this.route.queryParams.subscribe(params => {
      this.form.patchValue({
        'username': this.route.snapshot.paramMap.get('email'),
        'verificationCode': this.route.snapshot.paramMap.get('verificationCode'),
      });
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.form.disable();
      var k = this.us.resetPassword(this.form.value).subscribe({
        next: res => {
          this.form.enable();
          this.router.navigateByUrl('/info/password_reseted');
          k.unsubscribe();
        }, error: error => {
          this.form.enable();
          this.router.navigateByUrl('/info/password_not_reseted');
        }
      });
    }
  }

  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.onRoute$?.unsubscribe();
  }
}
