<item-wrapper #item [mainService]="tags" [form]="form" navigateList="tags" title="Tag">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column" fxLayoutGap="12px"
    style="padding: 0 12px;">
    <div fxLayout="row" class="form-row" fxLayoutGap="12px">
      <mat-form-field class="full-width">
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div fxLayout="row" class="form-row" fxLayoutGap="12px">
      <mat-form-field class="full-width">
        <mat-label>{{ 'Short code' }}</mat-label>
        <input matInput type="text" formControlName="shortCode" />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Description' }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div fxLayout="row" class="form-row" fxLayoutGap="12px">
      <mat-form-field fxFlex=10>
        <mat-label>{{ 'Color' }}</mat-label>
        <input matInput formControlName="color" type="color" [style.background]="form.controls.color.value"
          (colorPickerChange)="form.controls.color.setValue($event)" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Type' }}</mat-label>
        <input matInput formControlName="type" />
      </mat-form-field>
    </div>
  </form>
</item-wrapper>