import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscribable } from 'rxjs/internal/types';
import { Invoice, TransactionStatus } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { InvoicesService } from 'src/app/_services/invoices.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {
  $data: Subscribable<Invoice[]>;
  statusFilter = new FormControl();
  transactionStatuses = TransactionStatus;


  constructor(
    public service: InvoicesService,
    public auth: AuthService,
    public router: Router,
    public ui: UiService
  ) {
    this.service.queryParams.transactionStatuses = [];
    this.$data = this.service.all();
    this.service.fetchMoreData();
    this.statusFilter.valueChanges.subscribe(vals => {
      this.service.queryParams.transactionStatuses = vals;
      this.service.fetchMoreData();
    })

  }
}
