import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { CompanyCheck } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyChecksService } from 'src/app/_services/checks.service';


@Component({
  selector: 'app-company-checks',
  templateUrl: './company-checks.component.html',
  styleUrls: ['./company-checks.component.scss']
})
export class CompanyChecksComponent implements OnInit {

  $data: Subscribable<CompanyCheck[]>;

  constructor(
    public auth: AuthService,
    public service: CompanyChecksService,
  ) {

  }
  ngOnInit(): void {
    this.service.queryParams.isActive = true;
    this.$data = this.service.all();
  }

}
