<list-wrapper #wrapper [mainService]="tokens" navigateItem="tokens" title="Tokens"
  [readPerm]="auth.hasPermission(auth.perm.PurchaseRead)" [managePerm]="auth.hasPermission(auth.perm.PurchaseManage)">
  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{ "Name" | translate }}</div>
      </div>
      <div fxFlex="180px">
        <div mat-line>{{ "Created" | translate }}</div>
      </div>
      <div fxFlex="180px">
        <div mat-line>{{ "Last Used" | translate }}</div>
      </div>
      <div fxFlex="120px">
        <div mat-line>{{ "Expires" | translate }}</div>
      </div>
    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{ deleted: item?.deleted }">
      <mat-icon matListIcon *ngIf="'active' == calcStatus(item)" class="token active"
        matTooltip="{{ 'token_status_active' | translate }}">check_circle</mat-icon>
      <mat-icon matListIcon *ngIf="'expired' == calcStatus(item)" class="token expired"
        matTooltip="{{ 'token_status_expired' | translate }}">cancel
      </mat-icon>
      <mat-icon matListIcon *ngIf="'notallowed' == calcStatus(item)" class="token notallowed"
        matTooltip="{{ 'token_status_notallowed' | translate }}">error
      </mat-icon>
      <mat-icon matListIcon *ngIf="'expires' == calcStatus(item)" class="token expires"
        matTooltip="{{ 'token_status_expires' | translate }}">error</mat-icon>
      <h3 mat-line>{{ item?.name }}</h3>
      <small mat-line>
        <span *ngFor="let permission of item?.permissions; index as i"><span *ngIf="i > 0">, </span>{{ "permission_" +
          permission | translate }}</span>
      </small>

      <div fxFlex="180px">
        <small>{{ item?.created | date: "short" }}</small>
      </div>
      <div fxFlex="180px">
        <small>{{ item?.lastUsed | date: "short" }}</small>
      </div>
      <div fxFlex="120px">
        <small>{{ item?.expiration | date: "short" }}</small>
      </div>
      <mat-divider [inset]="true" *ngIf="i + 1 < ($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>