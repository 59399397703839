<list-wrapper #wrapper [mainService]="service" navigateItem="sanctions" title="Sanction Lists"
  [readPerm]="auth.hasPermission(auth.perm.XRayProviderRead)"
  [managePerm]="auth.hasPermission(auth.perm.XRayProviderManage)">

  <mat-list fxLayout="column" class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>
      <div fxFlex="80px" class="text-center">
        <mat-icon>person</mat-icon>
      </div>
      <div fxFlex="80px" class="text-center">
        <mat-icon>business</mat-icon>
      </div>
      <div fxFlex="120px" class="text-center">
        <mat-icon>supervised_user_circle</mat-icon>
      </div>
      <div fxFlex="120px" class="text-center">
        <div mat-line>{{'Initiated' | translate }}</div>
      </div>

    </h3>
    <!-- [routerLink]="[item.id]"
    routerLinkActive="router-link-active" -->
    <mat-list-item *ngFor="let item of $data | async; index as i" [ngClass]="{ deleted: item?.deleted}">
      <mat-icon matListIcon>fingerprint</mat-icon>

      <strong mat-line>{{ item?.source }}</strong>
      <small mat-line>{{ item?.moreInfo }}</small>

      <div fxFlex="80px" class="text-center">
        <small>{{ item?.individuals }}</small>
      </div>
      <div fxFlex="80px" class="text-center">
        <small>{{ item?.organizations }}</small>
      </div>
      <div fxFlex="120px" class="text-center">
        <small>{{ item?.user?.firstName }} {{ item?.user?.middleName }} {{ item?.user?.lastName }}</small>
      </div>
      <div fxFlex="120px" class="text-center">
        <small>{{ item?.created | date }}</small>
      </div>

      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-list>
</list-wrapper>