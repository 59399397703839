import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscribable, Unsubscribable } from 'rxjs/internal/types';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { Product, Purchase } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { ProductsService } from 'src/app/_services/products.service';
import { PurchasesService } from 'src/app/_services/purchases.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {

  $purchases: Subscribable<Purchase[]>;
  $creditCard: Subscribable<String>;
  $nextSubscription: Subscribable<Purchase>;
  purchases: Purchase[];
  canceling = false;
  refetchInterval;


  constructor(
    public service: ProductsService,
    public purchasesService: PurchasesService,
    public auth: AuthService,
    public ui: UiService
  ) {
    this.ui.setTitle("Subscriptions");
    // this.tokens.queryParams.sortOrder = "DESC";
    this.purchasesService.queryParams.take = 1;
    this.purchasesService.queryParams.isActive = true;
    this.$purchases = this.purchasesService.all(this.purchasesService.queryParams, false);
    this.$creditCard = this.purchasesService.creditCard()
    this.$nextSubscription = this.purchasesService.nextSubscription()
  }



  cancelProduct() {
    var mdl = <ConfirmationDialogData>{
      title: 'modal_title_cancel_subscription',
      content: 'modal_content_cancel_subscription',
      btnConfirmText: 'modal_btn_cancel_subscription',
      btnConfirmMode: 'warn'
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.canceling = true;
        var s = this.service.cancelProduct(this.purchasesService.refetchAdditionalQueries).subscribe({
          next: x => {
            this.doRefetch();
            s.unsubscribe()
          }, error: x => {
            this.doRefetch();
            s.unsubscribe()
          }
        })
      }
    });

  }
  doRefetch() {
    this.canceling = false;
    this.purchasesService.refetch()
  }
  test() {
    this.purchasesService.refetch()
  }
  ngOnInit(): void {
    this.doRefetch();

    // this.refetchInterval = setInterval(() => {     //uncomment this if we have card payment option again
    //   this.doRefetch();
    // }, 3000)
  }
  ngOnDestroy(): void {
    clearInterval(this.refetchInterval);
  }
}
