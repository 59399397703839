import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Feature } from 'src/app/_graphql/schema';

import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

import { UiService } from 'src/app/_services/ui.service';
import { FeaturesService } from 'src/app/_services/features.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  $data: Subscribable<Feature[]>;
  constructor(
    public service: FeaturesService,
    public auth: AuthService,
    private router: Router,
    public ui: UiService,
  ) {
  }

  ngOnInit(): void {
    this.$data = this.service.all();

  }


}
