import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Business, Person } from 'src/app/_graphql/schema';
import { Check } from 'src/app/_graphql/schema-session';

@Component({
  selector: 'app-view-business',
  templateUrl: './view-business.component.html',
  styleUrls: ['./view-business.component.scss']
})
export class ViewBusinessComponent implements OnInit, OnDestroy {
  @Input('business') business: Business = null;

  constructor(
  ) { }
  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    
  }
}
