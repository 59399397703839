<list-wrapper #wrapper [mainService]="service" navigateItem="companies" title="Companies"
  [readPerm]="auth.hasPermission(auth.perm.CompanyRead)" [managePerm]="false"
  [showDateFilter]="true">
    <form [formGroup]="form" toolbox>
      <mat-form-field appearance="outline" fxFlex="110px">
        <mat-label>Status</mat-label>
        <mat-select formControlName="companyStatuses" multiple>
          <mat-option *ngFor="let item of statuses | keyvalue" [value]="item.value">
            <app-company-status [status]="item.value"></app-company-status> {{('companyStatus_'+item.value)|translate}}
          </mat-option>
          <mat-select-trigger>{{form.value.companyStatuses?.length }} selected</mat-select-trigger>
        </mat-select>
      </mat-form-field>
    </form>
  <form [formGroup]="form" toolbox2>
    <app-range-picker formControlName="dateRange"></app-range-picker>
  </form>


  <mat-action-list fxLayout="column" class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>
      <div fxFlex="20" fxHide.lt-lg>
        <div mat-line>{{'Contact' }}</div>
      </div>
      <div fxFlex="140px" fxHide.lt-lg>
        <div mat-line>{{'Subscription' }}</div>
      </div>
      <div fxFlex="64px" fxHide.lt-md>
        <mat-icon style="font-size: 14pt;">chat</mat-icon>
      </div>
      <!-- <div fxFlex="75px" class="text-right">
        <div mat-line>{{'Count'|translate}}</div>
      </div> -->
      <div fxFlex="75px" class="text-right">
        <div mat-line>{{'Payment method' }}</div>
      </div>
      <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <div mat-line>{{'Price' | translate}}</div>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <div mat-line>{{'Cost'|translate}}</div>
      </div> -->

      <div fxFlex="44px">
        <div mat-line></div>
      </div>
    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="openCompany(item.id)">

      <app-avatar [avatarId]="item.avatarId" matListAvatar>
        <app-company-status [status]="item?.status"></app-company-status>
      </app-avatar>
      <strong mat-line>{{ item.name }}</strong>
      <small mat-line>
        {{ item?.idNumber }}
        {{ item.address }}{{ item.address2 ? ' '+item.address2: '' }}{{ (item.address ||
        item.address2) ?
        ', ': '' }}
        {{ item.zip }}
        {{ item.city }}{{ (item.zip || item.city) ?
        ', ': '' }}
        {{ item?.country?.name }}

      </small>
      <div fxFlex="20" fxHide.lt-lg>
        <div mat-line>{{ item?.contactPersonPosition }} {{ item?.contactPersonName }}</div>
        <small mat-line>
          {{ item?.email }}
        </small>
      </div>
      <div fxFlex="140px" style="padding: 10px 0;" fxHide.lt-lg>
        <div mat-line>{{item?.currentSubscription?.product?.name}}</div>
        <small mat-line>
          {{item?.currentSubscription?.startOfSubscription |
          date:'shortDate'}} <span *ngIf="item?.currentSubscription?.startOfSubscription">&mdash;</span>
          {{item?.currentSubscription?.endOfSubscription | date :'shortDate'}}
        </small>
      </div>
      <div fxFlex="64px" fxHide.lt-md matTooltip="{{ui.getLastNote(item?.adminNote)}}">
        <small>{{ item.adminNote?.split('||').length}}</small>
      </div>
      <div fxFlex="75px" class="text-right">
        <small>{{ item?.allowedPaymentMethod | translate }}</small>
      </div>
      <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <small *ngIf="item?.stats?.price">{{ item?.stats?.price | currency }}</small>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <small *ngIf="item?.stats?.cost">{{ item?.stats?.cost | currency }}</small>
      </div> -->

      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"
        *ngIf="auth.hasPermission(auth.perm.AllCompaniesManage)">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onChangeCompanyStatus(item)">
          <mat-icon>edit</mat-icon>
          {{'Change company status'|translate}}
        </button>
        <button mat-menu-item (click)="onChangePaymentMethod(item)">
          <mat-icon>payment</mat-icon>
          {{'Set payment method'|translate}}
        </button>
        <button mat-menu-item (click)="onOpenNote(item)">
          <mat-icon>chat</mat-icon>
          {{'Open notes'|translate}}
        </button>
      </mat-menu>
      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>