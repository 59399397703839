<mat-icon class="error" matTooltip="{{'transactionStatus_CANCELED'|translate}}" *ngIf="status == 'CANCELED'">
    dangerous
</mat-icon>
<mat-icon class="ok" matTooltip="{{'transactionStatus_COMPLETED'|translate}}" *ngIf="status == 'COMPLETED'">
    check_circle
</mat-icon>
<mat-icon class="warning" matTooltip="{{'transactionStatus_CREATED'|translate}}" *ngIf="status == 'CREATED'">
    outbound
</mat-icon>
<mat-icon class="warning" matTooltip="{{'transactionStatus_OVERPAID'|translate}}" *ngIf="status == 'OVERPAID'">
    check_circle
</mat-icon>
<mat-icon class="error" matTooltip="{{'transactionStatus_UNDERPAID'|translate}}" *ngIf="status == 'UNDERPAID'">
    error
</mat-icon>
<mat-icon class="error" matTooltip="{{'transactionStatus_UNKNOWN'|translate}}" *ngIf="status == 'UNKNOWN'">
    warning
</mat-icon>
<mat-icon class="warning" matTooltip="{{'transactionStatus_INVOICED'|translate}}" *ngIf="status == 'INVOICED'">
  outbound
</mat-icon>