import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { map, Subscribable } from "rxjs";
import { User } from "../_graphql/schema";

import { BaseService } from "./base.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class UsersService extends BaseService<any> {
  selectOneFields = gql`
    fragment SelectOneFields on User {
      id
      created
      countryId
      address
      address2
      avatarId
      city
      email
      firstName
      lastName
      middleName
      isLocked
      phoneNumber
      state
      title
      zip
    }
  `;

  selectAllFields = gql`
    fragment SelectAllFields on User {
      id
      emailConfirmed
      avatarId
      isLocked
      email
      firstName
      lastName
      middleName
      modified
      created
    }
  `;

  selectCurrentQuery = gql`
    query currentUser {
      currentUser {
        id
        firstName
        lastName
        emailConfirmed
        companies {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  `;

  gqlResendVerificationEndUser = gql`
    mutation resendVerificationEndUser {
      resendVerificationEndUser{ id emailConfirmed }
    }
  `;
  gqlLockEndUser = gql`
  mutation lockUser ($userId: ID!, $lock: Boolean!) {
    lockUser(userId: $userId, lock: $lock) { id isLocked }
  }
`;
  gqlVerifyEndUser = gql`
  mutation verifyEndUser($email: String!, $verificationCode: String!) {
    verifyEndUser(email: $email, verificationCode: $verificationCode){ id emailConfirmed }
  }
`;

  gqlAcceptInvitation = gql`
  mutation acceptInvitation($email: String!, $token: String!, $password: String!) {
    acceptInvitation(email: $email, token: $token, password: $password){ id emailConfirmed }
  }
`;

  gqlRequestResetPassword = gql`
  mutation requestResetPasswordUser($username: String!) {
    requestResetPasswordUser(username: $username)
  }
`;

  gqlResetPassword = gql`
  mutation resetPasswordUser($username: String!,$verificationCode: String!,$newPassword: String!) {
    resetPasswordUser(username: $username, verificationCode: $verificationCode, newPassword: $newPassword)
  }
`;


  gqlResendInvite = gql`
  mutation resendInvite($userId: ID!) {
    resendInvite(userId: $userId){ id emailConfirmed }
  }
`;
  currentUserWQ: any;
  public currentUser(): Subscribable<User> {
    if (!this.currentUserWQ) {
      this.currentUserWQ = this.apollo.watchQuery({
        query: this.selectCurrentQuery,
      });
    }
    return this.currentUserWQ.valueChanges.pipe(
      map<any, User>((result: any) => {
        if (!result || !result.data) return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          var r = result.data[keys[0]];
          return r;
        }
        return null;
      })
    );
  }
  refetchUser() {
    if (this.currentUserWQ)
      this.currentUserWQ.refetch();
  }

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql("user");
    this.selectOneQuery = gql`
    query user($id: ID) {
      user (id: $id){
        id
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
  `;
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "lastName";
    // this.createMutation = gql`
    //     mutation register($item: UserInput!) {
    //         register(user: $item, companyName:"") {
    //             id email authToken roles permissions
    //         }
    //     }`;
  }

  public resendVerificationEmail(): Subscribable<any> {
    return super.mutation(this.gqlResendVerificationEndUser, {});
  }
  public lockEndUser(userId, lock): Subscribable<User> {
    return super.mutation(this.gqlLockEndUser, { userId, lock });
  }
  public verifyEndUser(data): Subscribable<any> {
    return super.mutation(this.gqlVerifyEndUser, data);
  }

  public acceptInvitation(data): Subscribable<any> {
    return super.mutation(this.gqlAcceptInvitation, data);
  }

  public requestResetPassword(data): Subscribable<any> {
    return super.mutation(this.gqlRequestResetPassword, data);
  }
  public resetPassword(data): Subscribable<any> {
    return super.mutation(this.gqlResetPassword, data);
  }

  public resendInvite(data): Subscribable<User> {
    return super.mutation(this.gqlResendInvite, data);
  }
}
