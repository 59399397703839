import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { Session } from 'src/app/_graphql/schema-session';
import { AuthService } from 'src/app/_services/auth.service';
import { BusinessesService } from 'src/app/_services/businesses.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  $data: Subscribable<Session[]>;

  constructor(
    private router: Router, private auth: AuthService) {
  }

  ngOnInit(): void {
    this.auth.logout();
  }
}
