import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'communications-form-item',
  templateUrl: './communications-form-item.component.html',
  styleUrls: ['./communications-form-item.component.scss']
})
export class CommunicationsFormItem implements OnInit {
  @Input('fg') fg: FormArray;

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder
  ) {
  }
  ngOnInit(): void {


  }

  addLine(): void {
    this.fg.push(this.fb.group({
      email: [null, [Validators.email]],
      ipAddress: [null, [Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
      linkedIn: [, []],
      phone: [null, [Validators.pattern('[- +()0-9]{6,}')]],
      website: [null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      websiteIp: [null, [Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
    }));
  }
  removeLine(index: number) {

    if (this.fg.at(index).pristine) {
      this.fg.removeAt(index);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: <ConfirmationDialogData>{ title: "Remove communication item" },
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed)
        this.fg.removeAt(index);
    });
  }
}
