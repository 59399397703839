import { Component, Input, OnInit } from '@angular/core';
import { Address } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-view-address',
  templateUrl: './view-address.component.html',
  styleUrls: ['./view-address.component.scss']
})
export class ViewAddressComponent implements OnInit {
  @Input('addresses') addresses: Address[] = null;

  constructor(
  ) { }
  ngOnInit(): void {

  }
}
