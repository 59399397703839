import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscribable } from 'rxjs';
// import { Tag } from 'src/app/_graphql/schema';
import { UiService } from 'src/app/_services/ui.service';


@Component({
  selector: 'sidebar-wrapper',
  templateUrl: './sidebar-wrapper.component.html',
  styleUrls: ['./sidebar-wrapper.component.scss']
})
export class SidebarWrapperComponent {
  dataId: string;
  data$: Unsubscribable;
  @Input('normalizeProps') mode: string = 'normal';
  constructor(
    public ui: UiService,
    public router: Router,
    private route: ActivatedRoute) {

  }

  closeSidebar() {
    this.router.navigate([{ outlets: { sidebar: null } }],
      { relativeTo: this.route.parent });
  }

}
