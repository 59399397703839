<h3 class="separator-top">Documents
    <button mat-icon-button color="primary" (click)="addLine()">
        <mat-icon>add_circle_outline</mat-icon>
    </button>
</h3>
<div *ngFor="let item of fg.controls; index as i">
    <ng-container [formGroup]="item">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px" fxFlex>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex="50">
                        <mat-label>Document Type</mat-label>
                        <input matInput formControlName="type">
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <mat-label>Number</mat-label>
                        <input matInput formControlName="number" placeholder="">
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <mat-label>Expiry Date</mat-label>
                        <input matInput [matDatepicker]="expiry" formControlName="expiry">
                        <mat-datepicker-toggle matSuffix [for]="expiry"></mat-datepicker-toggle>
                        <mat-datepicker #expiry></mat-datepicker>
                    </mat-form-field>

                </div>


                <hr />
            </div>

            <div fxFlex="50px">
                <button mat-icon-button color="warn" style="margin-top:6px" (click)="removeLine(i)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>

        </div>

    </ng-container>
</div>