import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";

import { BaseService } from "./base.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class ProvidersConfigurationService extends BaseService<any> {
  selectOneFields = gql`
    fragment SelectOneFields on ProviderConfiguration {
      id
      name
      description
      companyId
      company {
        id
        name
        description
      }
      isTesting
      provider {
        id
        name
        description
      }
      keyVaultId
      keyVaultCert
      testingAccessConfig
      fieldSets {
        id
        name
        description
        companyId
      }
      fields {
        id
        name
        description
      }
    }
  `;

  selectAllFields = gql`
    fragment SelectAllFields on ProviderConfiguration {
      id
      name
      description
      companyId
      company {
        id
        name
        description
      }
      isTesting
      provider {
        id
        name
        description
      }
      keyVaultId
      keyVaultCert
      testingAccessConfig
      fieldSets {
        id
        name
        description
        companyId
      }
      fields {
        id
        name
        description
      }
    }
  `;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql("providerConfiguration", "providerConfigurations");
  }
}
