import { Component, Input } from "@angular/core";


@Component({
  selector: 'public-wrapper',
  templateUrl: './public-wrapper.component.html',
  styleUrls: ['./public-wrapper.component.scss'],
})
export class PublicWrapperComponent {
  @Input('mini') mini: boolean = false;
  constructor() {

  }
}