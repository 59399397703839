<button mat-button routerLink="/company/subscriptions">
  <mat-icon>keyboard_arrow_left</mat-icon> Return to subscriptions
</button>
<div *ngIf="loading" fxFill fxLayout="column" fxLayoutAlign="center center">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading" fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="24px" fxLayoutGap.gt-lg="36px" class="p-4">
  <app-subscription-card-item fxFlex *ngFor="let item of $data | async" [item]="item">

    <div *ngIf="purchases.length == 0">
      <button fxFlex mat-flat-button color="primary" (click)="payProduct(item.id)">
        <span *ngIf="item?.price; else payasyougo">
          Choose
        </span>
        <ng-template #payasyougo>
          Pay as you go
        </ng-template>
      </button>
    </div>
    <div *ngIf="purchases.length > 0 && (purchases[0]) as purchase">
      <button fxFlex mat-stroked-button color="primary" *ngIf="purchase?.product?.order == item.order" disabled>
        Currently active
      </button>

      <div *ngIf="purchase?.product?.order != item?.order">
        <button fxFlex mat-flat-button color="primary" (click)="payProduct(item)">
          <span *ngIf="item?.price; else payasyougo">
            Choose
          </span>
          <ng-template #payasyougo>
            Pay as you go
          </ng-template>
        </button>
      </div>
    </div>


  </app-subscription-card-item>
</div>