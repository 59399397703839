import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable, take } from 'rxjs';
import { Feature, Product, Provider, ProviderCheck } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { FeaturesService } from 'src/app/_services/features.service';
import { ProductsService } from 'src/app/_services/products.service';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';
import { ProvidersService } from 'src/app/_services/providers.service';

@Component({
  selector: 'app-feature-item',
  templateUrl: './feature-item.component.html',
  styleUrls: ['./feature-item.component.scss']
})
export class FeatureItemComponent implements OnInit {
  $data: Subscribable<Feature>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  form = this.fb.group({
    id: [null],
    order: [null, []],
    icon: [null, []],
    name: [null, Validators.required],
    description: [null, []],
  });
  constructor(
    private _ngZone: NgZone,
    public service: FeaturesService,
    private fb: FormBuilder,
    public auth: AuthService,
  ) {


  }


  ngOnInit(): void {
  }

}
