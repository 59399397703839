import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filterOn' })
export class filterOn implements PipeTransform {
    transform(list: any[] | null, k: string, acceptedIds: string[], include: string = "include", nestedObject: string = null) {
        if (!list || acceptedIds?.length == 0) {
            return [];
        }
        if (nestedObject) {
            return list.filter((item: any) => {
                if (item[nestedObject].hasOwnProperty(k)) {
                    if (include === "include") {
                        return acceptedIds.indexOf(item[nestedObject][k]) > -1;
                    } else {
                        return acceptedIds.indexOf(item[nestedObject][k]) === -1;
                    }
                } else {
                    return false;
                }
            });

        }
        else {
            return list.filter((item: any) => {
                if (item.hasOwnProperty(k)) {
                    if (include === "include") {
                        return acceptedIds.indexOf(item[k]) > -1;
                    } else {
                        return acceptedIds.indexOf(item[k]) === -1;
                    }
                } else {
                    return false;
                }
            });
        }
    }
}