import { Component, Input, OnInit } from '@angular/core';
import { Document } from 'src/app/_graphql/schema';


@Component({
  selector: 'view-business-documents',
  templateUrl: './view-business-documents.component.html',
  styleUrls: ['./view-business-documents.component.scss']
})
export class ViewBusinessDocumentsComponent implements OnInit {
  @Input('documents') documents: Document[] = null;

  constructor(
  ) { }
  ngOnInit(): void {

  }
}
