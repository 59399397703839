<list-wrapper #wrapper [mainService]="service" navigateItem="products-features" title="Product Features"
  [readPerm]="auth.hasPermission(auth.perm.ProductRead)" [managePerm]="auth.hasPermission(auth.perm.ProductManage)"
  [showDateFilter]="true">
  <mat-action-list fxLayout="column" class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>Product</div>
      </div>
      <div fxFlex="220px">
        <div mat-line>Feature</div>
      </div>

    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)">
      <mat-icon matListIcon>edit_note</mat-icon>
      <strong mat-line>{{ item.product.name }}</strong>
      <small mat-line>{{ item.product.description }}</small>

      <span fxFlex="220px">
        <span mat-line>{{ item.feature.name }}</span>
        <small mat-line>{{ item.feature.description }}</small>
      </span>


      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>