import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Apollo } from 'apollo-angular';
import { fromEvent, Observable, Subscribable, Subscriber, Subscription } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { TranslationsService } from 'src/app/_services/translations.service';
import { UiService } from 'src/app/_services/ui.service';


@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, OnDestroy {
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  menuMode: any = 'over';
  menuOpen = false;
  menuBackdrop = true;
  minimize = false;
  loading = false;
  loading$: Subscription;
  appWidth: number;
  submenu = '';
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    public ui: UiService,
    public auth: AuthService,
    public apollo: Apollo,
    public trans: TranslationsService
  ) {
    this.loading$ = this.ui.loaders.subscribe(visible => {
      this.loading = visible;
      window['_xrayLoader'] = visible ? true : false;
    });
  }

  ngOnInit(): void {
    this.ui.menuSideNav = this.sidenav;
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.onResize();
    })
    this.onResize();
  }

  setMenu(sidenav: MatSidenav) {
    this.menuBackdrop = !this.menuOpen;
    if (this.menuOpen) {
      this.menuMode = 'side';
      this.sidenav.open();
    } else {
      this.menuMode = 'over';
      this.sidenav.close();
    }
  }

  menuItemSelected() {
    if (this.menuMode == 'over')
      this.sidenav.close();
  }

  // switchLang(lang: string) {
  //   this.trans.getTranslations(lang);
  // }

  onResize() {
    if (this.appWidth == window.innerWidth)
      return;
    if (window.innerWidth < 960) {
      this.menuMode = 'over';
      this.menuBackdrop = true;
      this.menuOpen = false;
    } else {
      this.menuMode = 'side';
      this.menuBackdrop = false;
      this.menuOpen = true;
    }
    this.appWidth = window.innerWidth;
  }
  setSubMenu(submenu) {
    this.submenu = submenu;
  }
  ngOnDestroy() {
    this.loading$?.unsubscribe();
    this.resizeSubscription$?.unsubscribe()
  }
}
