export const environment = {
  production: true,
  // apiUrl: 'https://localhost:5031/graphql',
  apiUrl: 'https://portalapi.app-dev.xrayaml.com/graphql/',
  fileUpload: 'https://storage.app-dev.xrayaml.com/api/Files/upload',
  fileDownload: 'https://storage.app-dev.xrayaml.com/api/Files/download/',
  imageOriginal: 'https://storage.app-dev.xrayaml.com/api/images',
  imageLarge: 'https://storage.app-dev.xrayaml.com/api/bigimages',
  imageThumbnails: 'https://storage.app-dev.xrayaml.com/api/thumbnails'
  // (u body saljes file i polje categoryCode = AVATAR), a za download slika: 
};
