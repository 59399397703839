import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { ProviderChecksComponent } from './checks.component';
import { ProviderCheckItemComponent } from './check-item/check-item.component';
import { CheckTypeComponent } from 'src/app/components/check-type/check-type.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormBuilderModule } from 'src/app/components/form-builder/form-builder.module';


@NgModule({
  declarations: [
    ProviderChecksComponent,
    ProviderCheckItemComponent
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FormBuilderModule,
    WrappersModule,
    FormBuilderModule
  ]
})
export class ProviderChecksModule { }
