<div *ngIf="!service.loading else loading">
  <table width="100%">
    <thead class="report-header">
      <tr>
        <th class="report-header-cell">
          <div class="header-info">
            <img src="/assets/images/logo-white.svg" class="logo" />
            <span>
              {{ 'session_code' | translate}}:
              <strong>{{item?.code}}</strong>
            </span>
            <img [src]="qrCode" *ngIf="qrCode" class="qr">
          </div>
        </th>
      </tr>
    </thead>
    <tfoot class="report-footer">
      <tr>
        <td class="report-footer-cell">
          <div class="footer-info">
            <span>Generated by XRAY</span>
          </div>
        </td>
      </tr>
    </tfoot>
    <tbody class="report-content">
      <tr>
        <td class="report-content-cell">
          <div class="main">
            <table class="inv-holder">
              <tr>
                <td>
                  <h1>Session Input</h1>
                  <!-- <div *ngFor="let business of item?.businesses">
                      <app-view-business [business]="business"></app-view-business>
                    </div> -->
                  <!-- PERSON BLOCK START -->
                  <div *ngIf="item?.persons?.length >0">
                    <!-- <h3>Communication</h3> -->

                    <div *ngFor="let person of item.persons; index as i">
                      <br *ngIf="i>0">

                      <div class="inv-card">
                        <table class="inv-header">
                          <tr>
                            <td style="width:40px">
                              <div class="avatar">
                                <mat-icon>perm_identity</mat-icon>
                              </div>
                            </td>
                            <td>
                              <h2> {{person?.title}} {{person?.firstName}} {{person?.middleName}}
                                {{person?.lastName}} <small>{{person?.dob | date}}</small></h2>

                            </td>
                          </tr>
                        </table>
                        <div class="inv-card">
                          <table class="inv-table">
                            <tr *ngIf="person?.title">
                              <th>{{'person_title'|translate}}</th>
                              <td>{{person?.title}}</td>
                            </tr>
                            <tr *ngIf="person?.firstName">
                              <th>{{'person_firstName'|translate}}</th>
                              <td>{{person?.firstName}}</td>
                            </tr>
                            <tr *ngIf="person?.middleName">
                              <th>{{'person_middleName'|translate}}</th>
                              <td>{{person?.middleName}}</td>
                            </tr>
                            <tr *ngIf="person?.lastName">
                              <th>{{'person_lastName'|translate}}</th>
                              <td>{{person?.lastName}}</td>
                            </tr>
                            <tr *ngIf="person?.referenceId">
                              <th>{{'person_referenceId' | translate}}</th>
                              <td>{{person?.referenceId}}</td>
                            </tr>
                            <tr *ngIf="person?.socialNumber">
                              <th>{{'person_socialNumber' | translate}}</th>
                              <td>{{person?.socialNumber}}</td>
                            </tr>
                            <tr *ngIf="person?.isoLatin1Name">
                              <th>{{'person_isoLatin1Name'|translate}}</th>
                              <td>{{person?.isoLatin1Name}}</td>
                            </tr>
                            <tr *ngIf="person?.dob">
                              <th>{{'person_dob'|translate}}</th>
                              <td>{{person?.dob | date}}</td>
                            </tr>
                            <tr *ngIf="person?.gender">
                              <th>{{'person_gender'|translate}}</th>
                              <td>{{person?.gender}}</td>
                            </tr>
                          </table>
                        </div>
                        <!-- ADDRESS START -->
                        <div *ngIf="person?.addresses?.length >0">
                          <h3>Address</h3>
                          <div class="inv-card">
                            <div *ngFor="let address of person.addresses; index as i">
                              <br *ngIf="i>0">
                              <div class="inv-card">
                                <table class="inv-table">
                                  <tr *ngIf="address?.street">
                                    <th>{{'address_street' | translate}}</th>
                                    <td>{{address?.street}}</td>
                                  </tr>
                                  <tr *ngIf="address?.streetLine2">
                                    <th>{{ 'address_streetLine2' | translate }}</th>
                                    <td>{{address?.streetLine2}}</td>
                                  </tr>
                                  <tr *ngIf="address?.streetNumber">
                                    <th>{{'address_streetNumber'| translate}}</th>
                                    <td>{{address?.streetNumber}}</td>
                                  </tr>
                                  <tr *ngIf="address?.province">
                                    <th>{{'address_province'|translate}}</th>
                                    <td>{{address?.province}}</td>
                                  </tr>
                                  <tr *ngIf="address?.postalCode">
                                    <th>{{'address_postalCode'|translate}}</th>
                                    <td>{{address?.postalCode}}</td>
                                  </tr>
                                  <tr *ngIf="address?.city">
                                    <th>{{'address_city'|translate}}</th>
                                    <td>{{address?.city}}</td>
                                  </tr>
                                  <tr *ngIf="address?.countryCode">
                                    <th>{{'address_countryCode' | translate}}</th>
                                    <td>{{address?.countryCode}}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Communication START -->
                        <div *ngIf="person?.communications?.length >0">
                          <h3>Communication</h3>
                          <div class="inv-card">
                            <div *ngFor="let item of person.communications; index as i">
                              <br *ngIf="i>0">
                              <div class="inv-card">
                                <table class="inv-table">
                                  <tr *ngIf="item?.email">
                                    <th>{{'communication_email' | translate}}</th>
                                    <td>{{item?.email}}</td>
                                  </tr>
                                  <tr *ngIf="item?.ipAddress">
                                    <th>{{'communication_ipAddress'|translate}}</th>
                                    <td>{{item?.ipAddress}}</td>
                                  </tr>
                                  <tr *ngIf="item?.linkedIn">
                                    <th>{{'communication_linkedIn'|translate}}</th>
                                    <td>{{item?.linkedIn}}</td>
                                  </tr>
                                  <tr *ngIf="item?.phone">
                                    <th>{{'communication_phone'|translate}}</th>
                                    <td>{{item?.phone}}</td>
                                  </tr>
                                  <tr *ngIf="item?.website">
                                    <th>{{'communication_website'|translate}}</th>
                                    <td>{{item?.website}}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Communication END -->
                        <!-- DOCUMENT START -->
                        <div *ngIf="person?.documents?.length >0">
                          <h3>Documents</h3>
                          <div class="inv-card">
                            <div *ngFor="let document of person.documents; index as i">
                              <br *ngIf="i>0">
                              <div class="inv-card">
                                <table class="inv-table">
                                  <tr *ngIf="document?.type">
                                    <th>{{'document_type' | translate}}</th>
                                    <td>{{document?.type }}</td>
                                  </tr>
                                  <tr *ngIf="document?.number">
                                    <th>{{'document_number' | translate}}</th>
                                    <td>{{document?.number}}</td>
                                  </tr>
                                  <tr *ngIf="document?.expiry">
                                    <th>{{'document_expiry' | translate}}</th>
                                    <td>{{document?.expiry | date}}</td>
                                  </tr>
                                  <tr *ngFor="let item of document?.images; index as i;">
                                    <th>{{'document_images' | translate}}</th>
                                    <td>
                                      <span>{{item?.description}}</span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- DOCUMENT END -->
                      </div>
                    </div>

                  </div>
                  <!-- PERSON BLOCK END -->

                  <div class="inv-card" *ngFor="let business of item?.businesses">
                    <table class="inv-header">
                      <tr>
                        <td style="width:40px">
                          <div class="avatar">
                            <mat-icon>work_outline</mat-icon>
                          </div>
                        </td>
                        <td>
                          <h2>{{business?.companyName}}
                            <small>{{business?.foundingDate | date}} {{business?.companyIdType}}
                              {{business?.companyId}}</small>
                          </h2>

                        </td>
                      </tr>
                    </table>
                    <div class="inv-card">
                      <table class="inv-table">
                        <tr *ngIf="business?.foundingDate">
                          <th>{{ 'business_foundingDate' | translate }}</th>
                          <td>{{business?.foundingDate | date}}</td>
                        </tr>
                        <tr *ngIf="business?.companyIdType">
                          <th>{{'business_companyIdType' | translate}}</th>
                          <td>{{business?.companyIdType}}</td>
                        </tr>
                        <tr *ngIf="business?.companyId">
                          <th>{{'business_companyId' | translate}}</th>
                          <td>{{business?.companyId}}</td>
                        </tr>
                        <tr *ngIf="business?.referenceId">
                          <th>{{'business_referenceId' | translate}}</th>
                          <td>{{business?.referenceId}}</td>
                        </tr>
                      </table>
                    </div>
                    <!-- ADDRESS START -->
                    <div *ngIf="business?.addresses?.length >0">
                      <h3>Address</h3>
                      <div class="inv-card">
                        <div *ngFor="let address of business.addresses; index as i">
                          <br *ngIf="i>0">
                          <div class="inv-card">
                            <table class="inv-table">
                              <tr *ngIf="address?.street">
                                <th>{{'address_street' | translate}}</th>
                                <td>{{address?.street}}</td>
                              </tr>
                              <tr *ngIf="address?.streetLine2">
                                <th>{{ 'address_streetLine2' | translate }}</th>
                                <td>{{address?.streetLine2}}</td>
                              </tr>
                              <tr *ngIf="address?.streetNumber">
                                <th>{{'address_streetNumber'| translate}}</th>
                                <td>{{address?.streetNumber}}</td>
                              </tr>
                              <tr *ngIf="address?.province">
                                <th>{{'address_province'|translate}}</th>
                                <td>{{address?.province}}</td>
                              </tr>
                              <tr *ngIf="address?.postalCode">
                                <th>{{'address_postalCode'|translate}}</th>
                                <td>{{address?.postalCode}}</td>
                              </tr>
                              <tr *ngIf="address?.city">
                                <th>{{'address_city'|translate}}</th>
                                <td>{{address?.city}}</td>
                              </tr>
                              <tr *ngIf="address?.countryCode">
                                <th>{{'address_countryCode' | translate}}</th>
                                <td>{{address?.countryCode}}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ADDRESS END -->

                    <!-- Communication START -->
                    <div *ngIf="business?.communications?.length >0">
                      <h3>Communication</h3>
                      <div class="inv-card">
                        <div *ngFor="let item of business.communications; index as i">
                          <br *ngIf="i>0">
                          <div class="inv-card">
                            <table class="inv-table">
                              <tr *ngIf="item?.email">
                                <th>{{'communication_email' | translate}}</th>
                                <td>{{item?.email}}</td>
                              </tr>
                              <tr *ngIf="item?.ipAddress">
                                <th>{{'communication_ipAddress'|translate}}</th>
                                <td>{{item?.ipAddress}}</td>
                              </tr>
                              <tr *ngIf="item?.linkedIn">
                                <th>{{'communication_linkedIn'|translate}}</th>
                                <td>{{item?.linkedIn}}</td>
                              </tr>
                              <tr *ngIf="item?.phone">
                                <th>{{'communication_phone'|translate}}</th>
                                <td>{{item?.phone}}</td>
                              </tr>
                              <tr *ngIf="item?.website">
                                <th>{{'communication_website'|translate}}</th>
                                <td>{{item?.website}}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- DOCUMENTS START -->
                    <div *ngIf="business?.documents?.length >0">
                      <h3>Documents</h3>
                      <div class="inv-card">
                        <div *ngFor="let document of business.documents; index as i">
                          <br *ngIf="i>0">
                          <div class="inv-card">
                            <table class="inv-table">
                              <tr *ngIf="document?.type">
                                <th>{{'document_type' | translate}}</th>
                                <td>{{ document?.type }}</td>
                              </tr>
                              <tr *ngIf="document?.number">
                                <th>{{'document_number' | translate}}</th>
                                <td>{{document?.number}}</td>
                              </tr>
                              <tr *ngIf="document?.expiry">
                                <th>{{'document_expiry' | translate}}</th>
                                <td>{{document?.expiry | date}}</td>
                              </tr>

                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- DOCUMENTS END -->
                    <!-- Communication END -->
                    <br>
                    <!-- PERSON BLOCK START -->
                    <div *ngIf="business?.persons?.length >0">
                      <h3>Persons</h3>
                      <div class="inv-card">
                        <div *ngFor="let person of business.persons; index as i">
                          <br *ngIf="i>0">

                          <div class="inv-card">
                            <table class="inv-header">
                              <tr>
                                <td style="width:40px">
                                  <div class="avatar">
                                    <mat-icon>perm_identity</mat-icon>
                                  </div>
                                </td>
                                <td>
                                  <h2> {{person?.title}} {{person?.firstName}} {{person?.middleName}}
                                    {{person?.lastName}} <small>{{person?.dob | date}}</small></h2>

                                </td>
                              </tr>
                            </table>
                            <div class="inv-card">
                              <table class="inv-table">
                                <tr *ngIf="person?.title">
                                  <th>{{'person_title'|translate}}</th>
                                  <td>{{person?.title}}</td>
                                </tr>
                                <tr *ngIf="person?.firstName">
                                  <th>{{'person_firstName'|translate}}</th>
                                  <td>{{person?.firstName}}</td>
                                </tr>
                                <tr *ngIf="person?.middleName">
                                  <th>{{'person_middleName'|translate}}</th>
                                  <td>{{person?.middleName}}</td>
                                </tr>
                                <tr *ngIf="person?.lastName">
                                  <th>{{'person_lastName'|translate}}</th>
                                  <td>{{person?.lastName}}</td>
                                </tr>
                                <tr *ngIf="person?.isoLatin1Name">
                                  <th>{{'person_isoLatin1Name'|translate}}</th>
                                  <td>{{person?.isoLatin1Name}}</td>
                                </tr>
                                <tr *ngIf="person?.dob">
                                  <th>{{'person_dob'|translate}}</th>
                                  <td>{{person?.dob | date}}</td>
                                </tr>
                                <tr *ngIf="person?.gender">
                                  <th>{{'person_gender'|translate}}</th>
                                  <td>{{person?.gender}}</td>
                                </tr>
                              </table>
                            </div>
                            <!-- ADDRESS START -->
                            <div *ngIf="person?.addresses?.length >0">
                              <h3>Address</h3>
                              <div class="inv-card">
                                <div *ngFor="let address of person.addresses; index as i">
                                  <br *ngIf="i>0">
                                  <div class="inv-card">
                                    <table class="inv-table">
                                      <tr *ngIf="address?.street">
                                        <th>{{'address_street' | translate}}</th>
                                        <td>{{address?.street}}</td>
                                      </tr>
                                      <tr *ngIf="address?.streetLine2">
                                        <th>{{ 'address_streetLine2' | translate }}</th>
                                        <td>{{address?.streetLine2}}</td>
                                      </tr>
                                      <tr *ngIf="address?.streetNumber">
                                        <th>{{'address_streetNumber'| translate}}</th>
                                        <td>{{address?.streetNumber}}</td>
                                      </tr>
                                      <tr *ngIf="address?.province">
                                        <th>{{'address_province'|translate}}</th>
                                        <td>{{address?.province}}</td>
                                      </tr>
                                      <tr *ngIf="address?.postalCode">
                                        <th>{{'address_postalCode'|translate}}</th>
                                        <td>{{address?.postalCode}}</td>
                                      </tr>
                                      <tr *ngIf="address?.city">
                                        <th>{{'address_city'|translate}}</th>
                                        <td>{{address?.city}}</td>
                                      </tr>
                                      <tr *ngIf="address?.countryCode">
                                        <th>{{'address_countryCode' | translate}}</th>
                                        <td>{{address?.countryCode}}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Communication START -->
                            <div *ngIf="person?.communications?.length >0">
                              <h3>Communication</h3>
                              <div class="inv-card">
                                <div *ngFor="let item of person.communications; index as i">
                                  <br *ngIf="i>0">
                                  <div class="inv-card">
                                    <table class="inv-table">
                                      <tr *ngIf="item?.email">
                                        <th>{{'communication_email' | translate}}</th>
                                        <td>{{item?.email}}</td>
                                      </tr>
                                      <tr *ngIf="item?.ipAddress">
                                        <th>{{'communication_ipAddress'|translate}}</th>
                                        <td>{{item?.ipAddress}}</td>
                                      </tr>
                                      <tr *ngIf="item?.linkedIn">
                                        <th>{{'communication_linkedIn'|translate}}</th>
                                        <td>{{item?.linkedIn}}</td>
                                      </tr>
                                      <tr *ngIf="item?.phone">
                                        <th>{{'communication_phone'|translate}}</th>
                                        <td>{{item?.phone}}</td>
                                      </tr>
                                      <tr *ngIf="item?.website">
                                        <th>{{'communication_website'|translate}}</th>
                                        <td>{{item?.website}}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Communication END -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- PERSON BLOCK END -->
                  </div>
                </td>
                <td>
                  <h1>Session Details</h1>
                  <div class="inv-card">
                    <table class="inv-table">
                      <tr>
                        <th>{{'session_overallCheckStatus' | translate}}</th>
                        <td>
                          {{ ('checkStatus_'+item?.overallCheckStatus)|translate}}
                        </td>
                        <td style="width: 24px;">
                          <app-check-status [status]="item?.overallCheckStatus" style="display: inline;">
                          </app-check-status>
                        </td>
                      </tr>
                      <tr>
                        <th>{{'session_status'|translate}}</th>
                        <td>
                          {{ ('sessionStatus_'+item?.status)|translate}}
                        </td>
                        <td style="width: 24px;">
                          <app-session-status [status]="item?.status" style="display: inline;"></app-session-status>
                        </td>
                      </tr>
                      <tr *ngIf="item?.errorCode" class="label error">
                        <th>{{'session_error' | translate}}</th>
                        <td colspan="2"><em>Error: {{item?.errorCode}}</em>: {{item?.errorDescription}}</td>
                      </tr>
                      <tr>
                        <th>{{'session_testing' | translate}}</th>
                        <td colspan="2">{{(item?.isTesting ? 'Yes' : 'No') | translate}}</td>
                      </tr>
                      <tr *ngIf="item?.code">
                        <th>{{'session_code' | translate}}</th>
                        <td colspan="2">{{item?.code}}</td>
                      </tr>
                      <tr *ngIf="item?.description">
                        <th>{{'session_description' | translate}}</th>
                        <td colspan="2">{{item?.description}}</td>
                      </tr>
                      <tr>
                        <th>{{'session_created'|translate}}</th>
                        <td colspan="2">{{item?.created|date}}</td>
                      </tr>
                      <tr *ngIf="item?.company">
                        <th>{{'session_created_by_company'|translate}}</th>
                        <td colspan="2">{{item?.company?.name }}</td>
                      </tr>
                      <tr *ngIf="item?.user">
                        <th>{{'session_created_by'|translate}}</th>
                        <td colspan="2">{{item?.user.firstName}} {{item?.user.middleName}} {{item?.user.lastName}}</td>
                      </tr>
                      <tr>
                        <th>{{'session_configuration'|translate}}</th>
                        <td colspan="2">{{item?.configuration?.name}} </td>
                      </tr>
                      <!-- <tr>
                        <th>{{'session_checks' | translate}}</th>
                        <td colspan="2">
                          <span *ngFor="let check of item?.checks; index as i"><br *ngIf="i>0">{{(
                            'checkType_'+check?.checkType)|translate}}</span>
                        </td>
                      </tr> -->
                      <!-- <tr>
                        <th>{{'session_providers' | translate}}</th>
                        <td colspan="2">
                          <span *ngFor="let check of item?.configuration?.checks; index as i"><span *ngIf="i>0">,
                            </span>{{
                            check?.providerCheck?.provider?.name}}</span>
                        </td>
                      </tr> -->
                      <tr>
                        <th>{{'session_responseTransport' | translate}}</th>
                        <td colspan="2">{{('responseTransport_'+item?.responseTransport)|translate}}</td>
                      </tr>
                    </table>
                  </div>

                  <h3>{{'session_checks'|translate}}</h3>
                  <div class=" inv-card">
                    <div *ngFor="let check of item.checks; index as i">
                      <div class=" inv-card" [ngStyle]="{'margin-top': i > 0 ?'8px' : 0}">
                        <table class="inv-table">
                          <tr>
                            <th><strong>{{('checkType_'+check?.checkType)|translate}}</strong></th>
                            <td>
                              {{ ('checkStatus_'+check?.checkStatus)|translate}}
                            </td>
                            <td style="width: 24px;">
                              <app-check-status [status]="check?.checkStatus" style="display: inline;">
                              </app-check-status>
                            </td>
                          </tr>
                        </table>
                        <table class="inv-table">
                          <tr *ngIf="check?.errorCode">
                            <th>{{'check_error' | translate}}</th>
                            <td><em>Error: {{check?.errorCode}}</em>: {{check?.errorDescription}}</td>
                          </tr>
                          <tr *ngIf="check?.created">
                            <th>{{'check_created' | translate}}</th>
                            <td>{{check?.created | date:'short'}}</td>
                          </tr>
                          <tr *ngIf="check?.started">
                            <th>{{'check_started' | translate}}</th>
                            <td>{{check?.started | date:'short'}}</td>
                          </tr>
                          <tr *ngIf="check?.finished">
                            <th>{{'check_finished' | translate}}</th>
                            <td>{{check?.finished | date:'short'}}</td>
                          </tr>
                          <tr *ngIf="check?.modified">
                            <th>{{'check_modified' | translate}}</th>
                            <td>{{check?.modified | date:'short'}}</td>
                          </tr>
                          <tr *ngIf="check?.deleted">
                            <th>{{'check_deleted' | translate}}</th>
                            <td>{{check?.deleted | date:'short'}}</td>
                          </tr>

                          <tr *ngIf="check?.matches" class="label ok">
                            <th>{{'checkResults_statusFalsePositive'|translate}}</th>
                            <td>{{(check.results|filterOn:'status':['false-positive']).length}}</td>
                          </tr>
                          <tr *ngIf="check?.matches" class="label error">
                            <th>{{'checkResults_statusTruePositive'|translate}}</th>
                            <td>{{(check.results|filterOn:'status':['true-positive']).length}}</td>
                          </tr>
                          <tr *ngIf="check?.matches" class="label warn">
                            <th>{{'checkResults_statusPending'|translate}}</th>
                            <td>{{(check.results|filterOn:'status':['pending']).length}}</td>
                          </tr>
                          <tr *ngIf="check?.matches">
                            <th>{{'checkResults_total'|translate}}</th>
                            <td>{{check?.matches}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <!-- CHECK RESULTS START -->
            <div *ngFor="let check of item?.checks">
              <div *ngIf="check?.results.length > 0">
                <h4>{{('checkType_'+check?.checkType)|translate}} ({{check.matches}})
                </h4>
                <table class="results-table">
                  <tr>
                    <th width="36px"></th>
                    <th>{{'Name' |translate }}</th>
                    <th width="80px" class="text-center">{{'Category' |translate }}</th>
                    <th width="80px" class="text-center">
                      <span *ngIf="item?.persons.length">{{'Gender' |translate }}</span>
                      <span *ngIf="item?.businesses.length">{{'Type' |translate }}</span>
                    </th>

                    <th width="140px">{{'Reviewed'|translate }}</th>
                    <th width="140px">{{'Date'|translate }}</th>
                  </tr>
                  <tr *ngFor="let result of check.results"
                    [ngClass]="{ warning: ['TER/SIP'].indexOf(result?.category)>-1, 'false-positive': result?.status == 'false-positive'}">
                    <td>
                      <app-check-result-status mat-list-icon status="{{result?.status}}"></app-check-result-status>
                    </td>

                    <td>
                      {{ result?.person?.title }} {{ result?.person?.firstName }} {{ result?.person?.middleName }} {{
                      result?.person?.lastName }}
                      {{ result?.business?.companyName }}
                      <small mat-line *ngIf="result?.note" [innerText]="ui.getLastNote(result?.note)">

                      </small>
                    </td>


                    <td class="text-center">
                      <span class="category">{{ result?.category }}</span>
                    </td>

                    <td class="text-center"><span class="limit-overflow">{{ result?.person?.gender }}{{
                        result?.business?.companyIdType }}</span></td>

                    <td>
                      {{ result?.falsePositiveUser?.firstName}} {{
                      result?.falsePositiveUser?.middleName}} {{ result?.falsePositiveUser?.lastName}}

                      {{ result?.truePositiveUser?.firstName}} {{
                      result?.truePositiveUser?.middleName}} {{ result?.truePositiveUser?.lastName}}

                    </td>
                    <td>
                      <div *ngIf="result?.falsePositive">{{ result?.falsePositive | date:'short'}}</div>
                      <div *ngIf="result?.truePositive">{{ result?.truePositive | date:'short'}}</div>
                    </td>

                  </tr>
                </table>
              </div>
            </div>
            <!-- CHECK RESULTS END -->
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <div fxFill fxLayout="column" fxLayoutAlign="center center">
    <div>
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </div>
</ng-template>