import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Company, Import, Permission, Sanction } from 'src/app/_graphql/schema';
import { CompaniesService } from 'src/app/_services/companies.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { Apollo } from 'apollo-angular';
import { SanctionsService } from 'src/app/_services/sanctions.service';

@Component({
  selector: 'app-sanctions',
  templateUrl: './sanctions.component.html',
  styleUrls: ['./sanctions.component.scss']
})
export class SanctionsComponent implements OnInit {
  $data: Subscribable<Import[]>;
  constructor(
    public service: SanctionsService,
    public auth: AuthService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.$data = this.service.all();
  }


}
