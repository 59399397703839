<list-wrapper #wrapper [mainService]="service" navigateItem="features" title="Features"
  [readPerm]="auth.hasPermission(auth.perm.ProductRead)" [managePerm]="false" [showDateFilter]="true">
  <mat-action-list fxLayout="column" class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' |translate}}</div>
      </div>
    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)">
      <mat-icon matListIcon>verified</mat-icon>
      <strong mat-line>{{ item.name }}</strong>
      <small mat-line>{{ item.description }}</small>
      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>