<h3 class="separator-top">Communications
    <button mat-icon-button color="primary" (click)="addLine()">
        <mat-icon>add_circle_outline</mat-icon>
    </button>
</h3>
<div *ngFor="let item of fg.controls; index as i">
    <ng-container [formGroup]="item">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px" fxFlex>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex="50">
                        <mat-label>E-mail</mat-label>
                        <input matInput formControlName="email" placeholder="john.doe@example.com">
                        <mat-icon matSuffix>email</mat-icon>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <mat-label>Phone</mat-label>
                        <input matInput formControlName="phone" placeholder="+xxx xx xxx xxx">
                        <mat-icon matSuffix>phone</mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex="50">
                        <mat-label>Website</mat-label>
                        <input matInput formControlName="website" placeholder="">
                        <mat-icon matSuffix>public</mat-icon>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <mat-label>Website IP Address</mat-label>
                        <input matInput formControlName="websiteIp" placeholder="x.x.x.x">
                        <mat-icon matSuffix>travel_explore</mat-icon>

                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0">
                    <mat-form-field fxFlex>
                        <mat-label>LinkedIn Username</mat-label>
                        <input matInput formControlName="linkedIn" placeholder="username">
                        <span matPrefix class="opct"
                            *ngIf="item.get('linkedIn').value">https://www.linkedin.com/in/</span>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>IP Address</mat-label>
                        <input matInput formControlName="ipAddress" placeholder="x.x.x.x">
                        <mat-icon matSuffix>travel_explore</mat-icon>
                    </mat-form-field>

                </div>

                <hr />
            </div>
            <div fxFlex="50px">
                <button mat-icon-button color="warn" style="margin-top:6px" (click)="removeLine(i)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
        </div>

    </ng-container>
</div>