<ng-container *ngIf="obs$ | async"></ng-container>
<div class="item-container" *ngIf="!mainService.loading || !dataId" title="">
  <div fxLayout="row" class="toolbox">
    <button mat-icon-button (click)="getBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="item-title">
      <div class="title">
        {{ form?.controls.name?.value || form?.controls.title?.value || '' }} {{
        form?.controls.firstName?.value || form?.controls.firstName?.value || '' }} {{form?.controls.lastName?.value ||
        form?.controls.lastName?.value || '' }}</div>
      <div class="subtitle"> {{ form?.controls?.id?.value ? 'Edit' : 'New' }} {{ title | lowercase }}</div>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="deleteEnabled && managePerm">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item color="warn" (click)="del()" *ngIf="!isDeleted" [disabled]="!dataId">Delete</button>
      <button mat-menu-item color="warn" (click)="restore()" *ngIf="isDeleted" [disabled]="!dataId">Restore</button>
    </mat-menu>
    <div *ngIf="managePerm">
      <button (click)="submit()" mat-flat-button color="primary" [disabled]="!form?.valid"
        *ngIf="!form?.dirty; else submitfilled">Save</button>

      <ng-template #submitfilled><button mat-flat-button (click)="submit()" [disabled]="!form?.valid"
          color="primary">Save</button></ng-template>
    </div>

  </div>
  <div class="spacer">
    <div *ngIf="readPerm==null" class="infoblock warning"><strong>readPerm</strong> is not defined on Item Wrapper</div>
    <div *ngIf="managePerm==null" class="infoblock warning"><strong>managePerm</strong> is not defined on Item Wrapper
    </div>
    <ng-content select="[toolbox]"></ng-content>
  </div>
  <div class="holder">
    <ng-content></ng-content>
  </div>
</div>
<!-- 
{{readPerm}}
{{managePerm}} -->