import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { Session } from 'src/app/_graphql/schema-session';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  $data: Subscribable<Session[]>;

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
  }

  ngOnInit(): void {
    console.log("aaaa red")
    setTimeout(() => {
      if (this.auth.hasPermission(this.auth.perm.SessionRead))
        this.router.navigate(['/', 'company', 'dashboard']);
      else
        this.router.navigate(['/', 'company', 'configurations']);
    }, 200);

  }
}
