import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { ProductsComponent } from './products.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { ProductChecksComponent } from './products-checks/product-checks.component';
import { ProductCheckItemComponent } from './products-checks/product-check-item/product-check-item.component';
import { FeaturesComponent } from './features/features.component';
import { FeatureItemComponent } from './features/feature-item/feature-item.component';
import { ProductsFeaturesComponent } from './features/products-features/products-features.component';
import { ProductsFeaturesItemComponent } from './features/products-features/products-feature-item/products-feature-item.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';

@NgModule({
  declarations: [
    ProductsComponent,
    ProductItemComponent,
    ProductChecksComponent,
    ProductCheckItemComponent,
    FeaturesComponent,
    FeatureItemComponent,
    ProductsFeaturesComponent,
    ProductsFeaturesItemComponent
  ],
  imports: [
    PipesModule,
    ComponentsModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ]
})
export class ProductsModule { }
