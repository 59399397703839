<button mat-icon-button class="dialog-close-btn" tabindex="-1" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<h2 md-dialog-title>{{title | translate}}</h2>
<div md-dialog-content>{{content | translate}}</div>
<div mat-dialog-actions>
  <div fxFlex></div>
  <button mat-button (click)="dialogRef.close();">{{ btnCancelText | translate }}</button>
  <button mat-button color="{{btnConfirmMode}}" (click)="dialogRef.close(true);" cdkFocusInitial>{{ btnConfirmText |
    translate
    }}</button>
</div>