import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { map } from "rxjs";
import { Subscribable } from "rxjs/internal/types";
import { Country, SupportedCountry } from "../_graphql/schema";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class CountriesService extends BaseService<Country> {
  selectOneFields = gql`
    fragment SelectOneFields on Country {
      id
      name
      isoCode2
      isoCode3
      order
      created
      modified
      deleted
    }
  `;

  selectAllFields = gql`
    fragment SelectAllFields on Country {
      id
      name
      isoCode2
      isoCode3
      order
      deleted
    }
  `;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql("country", "countries");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }

  gqlSupportedIdentityCheckTypesWQ = gql`
    query supportedIdentityCheckTypes($configurationId: ID){
      supportedIdentityCheckTypes(configurationId : $configurationId){
        supportedDocumentTypes
        country {
          id
          name
          isoCode2
          isoCode3
          states {
            id
            name
            isoCode
          }
          
        }
      }
    }
  `;
  supportedIdentityCheckTypesWQ: any;
  public getSupportedIdentityCheckTypes(configurationId: string): Subscribable<SupportedCountry[]> {
    if (!this.supportedIdentityCheckTypesWQ) {
      this.supportedIdentityCheckTypesWQ = this.apollo.watchQuery({
        query: this.gqlSupportedIdentityCheckTypesWQ,
        variables: { configurationId }
      });
    }
    return this.supportedIdentityCheckTypesWQ.valueChanges.pipe(
      map<any, SupportedCountry>((result: any) => {
        if (!result || !result.data) return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          var r = result.data[keys[0]];
          return r;
        }
        return null;
      })
    );
  }
}
