import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, mergeMap, Subscribable, Unsubscribable } from 'rxjs';
import { Company, CompanyStatus } from 'src/app/_graphql/schema';
import { CompaniesService } from 'src/app/_services/companies.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyChangeStatusDialogComponentData } from 'src/app/components/dialog/company-change-status-dialog/company-change-status-dialog.component';
import { UiService } from 'src/app/_services/ui.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SetPaymentMethodDialogComponent, SetPaymentMethodDialogComponentData } from 'src/app/components/dialog/set-payment-method-dialog/set-payment-method-dialog.component';


@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit, OnDestroy {
  $data: Subscribable<Company[]>;
  filters$: Unsubscribable;
  statuses = CompanyStatus;
  unsub$: Unsubscribable;
  switchCompany$: Unsubscribable;

  form: FormGroup = this.fb.group({
    dateRange: new FormControl(
      {
        range: null,
        startDate: null,
        endDate: null
      }),
    companyStatuses: new FormControl()
  })

  constructor(
    public service: CompaniesService,
    public auth: AuthService,
    private router: Router,
    public ui: UiService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.$data = this.service.all();
    this.form.patchValue(
      this.service.queryParams,
      { emitEvent: false }
    )
    this.$data = this.service.all();

    this.filters$ = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(vals => {
      Object.assign(this.service.queryParams, vals)
      this.service.fetchMoreData();
    });
  }

  openCompany(companyId: string) {
    this.auth.companyId = companyId;
    this.switchCompany$ = this.service.switchCompany(companyId).subscribe({
      next: user => {
        this.auth.storeUser(user);
        this.router.navigate(['/redirect']);

      }
    });
  }
  onChangeCompanyStatus(item: Company) {
    var mdl = <CompanyChangeStatusDialogComponentData>{
      item: item
    };
    this.ui.openCompanyChangeStatusDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        var s = this.service.changeCompanyStatus(confirmed).subscribe({
          next: x => {
            this.service.fetchMoreData();
            s.unsubscribe();
          },
          error: x => {
            s.unsubscribe();
          }
        });
      }
    });
  }

  onOpenNote(item: Company) {
    this.form.reset();
    this.ui.openAdminNotesDialog(item).afterClosed().subscribe(confirmed => {
      this.service.fetchMoreData();
    });
  }
  onChangePaymentMethod(item: any) {
    var mdl = <SetPaymentMethodDialogComponentData>{
      item: item,
    };
    this.unsub$ = this.ui.openCompanySetPaymentMethod(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        console.log(confirmed)
        var s = this.service.setPaymentMehod(confirmed).subscribe({
          next: x => {
            this.service.fetchMoreData();
            this.ui.snack("Successfully set payment method and product");
            s.unsubscribe();
            this.unsub$.unsubscribe();
          },
          error: x => {
            s.unsubscribe();
            this.unsub$.unsubscribe();

          }
        });
      }
    });

  }

  ngOnDestroy(): void {
    this.filters$?.unsubscribe();
    this.switchCompany$?.unsubscribe();
  }
}
