<button mat-icon-button class="dialog-close-btn" tabindex="-1" (click)="dialogRef.close();">
  <mat-icon>close</mat-icon>
</button>
<h2 md-dialog-title>
  {{item.name}}: {{'modal_title_company_status' | translate}}
</h2>
<form md-dialog-content [formGroup]="form">
  <div fxFlex fxLayout="column">
    <p>{{'modal_content_company_status' | translate}}</p>

    <mat-form-field fxFlex>
      <mat-label>{{ 'New Status' | translate }}</mat-label>
      <mat-select formControlName="newStatus">
        <mat-option value="ACTIVE" *ngIf="['SUSPENDED','DECLINED','APPLIED','PAUSED'].indexOf(item.status) >-1">
          {{'companyStatus_ACTIVE' | translate}}
        </mat-option>
        <mat-option value="DECLINED" *ngIf="['APPLIED','PAUSED'].indexOf(item.status) >-1">
          {{'companyStatus_DECLINED' | translate}}
        </mat-option>
        <!-- <mat-option value="PAUSED" *ngIf="item.status == 'ACTIVE'">
          {{'companyStatus_PAUSED' | translate}}
        </mat-option> -->
        <mat-option value="SUSPENDED" *ngIf="item.status == 'ACTIVE'">
          {{'companyStatus_SUSPENDED' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field fxFlex>
      <mat-label>{{ 'New Status' | translate }}</mat-label>
      <mat-select formControlName="allowedPaymentMethod">
        <mat-option value="CARD">
          {{'allowedPaymentMethod_CARD' | translate}} 
        </mat-option>
        <mat-option value="INVOICE">
          {{'allowedPaymentMethod_INVOICE' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field fxFlex>
      <mat-label>{{ 'Message for company' }}</mat-label>
      <textarea matInput formControlName="statusUserMessage" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Internal note' }}</mat-label>
      <textarea matInput formControlName="adminMessage" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </div>
</form>

<div mat-dialog-actions>
  <div fxFlex></div>
  <button mat-button (click)="dialogRef.close();">{{ 'Cancel' | translate }}</button>
  <button mat-button color="primary" (click)="dialogRef.close(form.value);" [disabled]="!form.valid">{{
    'modal_btn_company_status' |
    translate
    }}</button>
</div>