<item-wrapper #item [mainService]="service" [form]="form" navigateList="checks" title="Company Checks"
  [readPerm]="auth.hasPermission(auth.perm.CompanyCheckRead)"
  [managePerm]="auth.hasPermission(auth.perm.CompanyCheckManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description' }}</mat-label>
      <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <div fxFlex fxLayout="row" fxLayoutGap="16px">

      <mat-form-field fxFlex>
        <mat-label>{{ 'Provider Check' | translate }}</mat-label>
        <app-check-type matPrefix
          [checkType]="((providerChecks$ | async) | filterOn:'id':[form.get('providerCheckId').value])[0]?.checkType">
        </app-check-type>
        <mat-select formControlName="providerCheckId">
          <mat-option *ngFor="let item of providerChecks$ | async" [value]="item.id">
            <span *ngIf="item?.provider?.name && auth.hasPermission(auth.perm.CompanyCheckManage)">{{
              item?.provider?.name}}:</span> {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0px" fxLayoutGap.gt-md="12px"
      *ngIf="auth.hasPermission(auth.perm.CompanyCheckPricingRead)">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Max Rate Monthly' }}</mat-label>
        <input matInput type="number" formControlName="maxRateMonthly" />
        <span matSuffix>req</span>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Price Per Check' }}</mat-label>
        <input matInput type="number" formControlName="pricePerCheck" />
        <span matPrefix>&euro;</span>
      </mat-form-field>
    </div>

    <mat-checkbox color="primary" matInput type="checkbox" formControlName="isActive">Is active</mat-checkbox>
  </form>
</item-wrapper>