import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-expander',
  templateUrl: './label-expander.component.html',
  styleUrls: ['./label-expander.component.scss']
})
export class LabelExpanderComponent implements OnInit {
  @Input('items') items: any[];
  @Input('linkPart') linkPart: any[];
  show = false;

  constructor() {

  }
  ngOnInit(): void {

  }
}
