import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilderComponent } from './form-builder.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { WrappersModule } from '../wrappers/wrappers.module';



@NgModule({
  declarations: [FormBuilderComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    WrappersModule
    
  ],
  exports: [
    FormBuilderComponent,
  ]
})
export class FormBuilderModule { }
