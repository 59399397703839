import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-info-page',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  translateKey?: String;
  onRoute$: Subscription;
  constructor(
    private route: ActivatedRoute,
  ) {
    this.onRoute$ = this.route.queryParams.subscribe(params => {
      this.translateKey = this.route.snapshot.paramMap.get('id');
    });
  }

  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.onRoute$?.unsubscribe();
  }
}
