import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { CompaniesComponent } from './companies.component';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { InvoicesComponent } from 'src/app/company/payments/invoices/invoices.component';
import { TransactionStatusComponent } from 'src/app/components/statuses/transaction-status/transaction-status.component';
import { PurchaseStatusComponent } from 'src/app/components/statuses/purchase-status/purchase-status.component';
import { InvoiceItemComponent } from 'src/app/company/payments/invoices/invoice-item/invoice-item.component';

@NgModule({
  declarations: [
    CompaniesComponent,
    InvoicesComponent,
    InvoiceItemComponent
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ],
  exports: [
    TransactionStatusComponent,
    PurchaseStatusComponent
  ]
})
export class CompaniesModule { }
