import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable, Unsubscribable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ListWrapperComponent } from 'src/app/components/wrappers/list-wrapper/list-wrapper.component';
import { SessionStatus } from 'src/app/_graphql/schema';
import { Session } from 'src/app/_graphql/schema-session';
import { Subsink } from 'src/app/_helpers/subsink';
import { AuthService } from 'src/app/_services/auth.service';
import { ConfigurationsService } from 'src/app/_services/configurations.service';
import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';
import { FiltersManagementService } from 'src/app/_services/filters-management.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit, OnDestroy {

  @ViewChild(ListWrapperComponent) wrapper: ListWrapperComponent;

  data$: Subscribable<Session[]>;
  statuses = SessionStatus;
  filters!: any;

  form!: FormGroup;

  checkStatuses = [
    'INITIATED',
    'PROCESSING',
    'PASSED',
    'NOT_PASSED',
    'ERROR',
  ]

  $configurations: Unsubscribable;
  $route: Unsubscribable;
  showImportBtn = false;
  subsink = new Subsink();
  checkType: FormControl = new FormControl(null);

  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    public service: SessionsCompanyService,
    private configurationsService: ConfigurationsService,
    private router: Router,
    private fb: FormBuilder,
    private filtersManagement: FiltersManagementService,

  ) {

    this.form = fb.group({
      checkStatuses: new FormControl(null),
      sessionStatuses: new FormControl(null),
      isMonitoring: new FormControl(null),
      isPerson: new FormControl(null),
      isBusiness: new FormControl(null),
      isTesting: new FormControl(null),
      isPendingCheck: new FormControl(null),
      isNonTesting: new FormControl(null),
      dateRange: new FormControl({ range: null, startDate: null, endDate: null }, []),

    });

    const search = this.route.snapshot.paramMap.get('search');
    this.filters = this.router.getCurrentNavigation()?.extras?.state?.['filters'];

    if (this.filters) {
      this.form.patchValue(this.filters);
      Object.keys(this.filters).forEach(key => {
        if ((this.service.queryParams).hasOwnProperty(key))
          this.service.queryParams[key] = this.filters[key];
      });
    }


    this.$configurations = this.configurationsService.all().subscribe({
      next: configurations => {
        this.showImportBtn = false;
        configurations.forEach(configuration => {
          configuration.checks.forEach(check => {
            if (check?.providerCheck?.sourceType == 'BUSINESS')
              this.showImportBtn = true;
          });
        });
      }
    });
    this.service.queryParams.query = search;
  }

  ngOnDestroy(): void {
    this.filtersManagement.setFilter('sessions', this.form.value);
    this.$configurations?.unsubscribe();
    this.$route?.unsubscribe();
    this.subsink.terminateAll();
  }

  ngOnInit(): void {
    if (this.filtersManagement.getFilters()?.sessions) {
      this.form.patchValue(this.filtersManagement.getFilters()?.sessions);
    }
    else {
      this.service.queryParams.skip = 0;
      this.service.queryParams.take = 25;
      this.service.pageIndex = 0;
      this.service.queryParams.isNonTesting = true;
      this.form.get('isNonTesting').setValue(true);
    }
    Object.assign(this.service.queryParams, this.form.value);
    this.data$ = this.service.all()
    this.subsink.subscription = this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((vals) => {
      Object.assign(this.service.queryParams, vals);
      this.service.fetchMoreData();
    });
  }


  resetFilters() {
    let oldFormValue = JSON.stringify(this.form.value);
    this.form.reset({ dateRange: { range: null, startDate: null, endDate: null } });
    this.service.queryParams.isPerson = null;
    this.service.queryParams.isBusiness = null;
    this.service.queryParams.isTesting = null;
    this.service.queryParams.checkStatuses = [];
    this.service.queryParams.isPendingCheck = null;
    this.service.queryParams.isNonTesting = null;
    this.service.queryParams.dateRange = null;
    this.service.queryParams.dateRange = null;
    this.service.queryParams.sessionStatuses = [];
    if (oldFormValue != JSON.stringify(this.form.value))
      this.service.fetchMoreData();
  }

  filterCheckType() {
    this.form.get('isPerson').setValue(this.checkType.value === 'isPerson');
    this.form.get('isBusiness').setValue(this.checkType.value !== 'isPerson');
    this.form.get('isBusiness').value === true ? this.form.get('isMonitoring').setValue(false) : null;
  }
}
