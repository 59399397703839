import { Component, Input, forwardRef, HostListener, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';
import { DocumentsService } from 'src/app/_services/documents.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    }]
})

export class FileUploadComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Input() type: string = "square";
  _isDisabled = false;
  private avatarSubscription: Subscription;
  profileProgressMode: ProgressSpinnerMode = 'indeterminate';
  loading = false;
  categoryCode = null;
  private jsonString: string;
  private parseError: boolean;
  data: any;

  setDisabledState(isDisabled: boolean) {
    this._isDisabled = isDisabled;
  }

  @HostListener('change', ['$event.target.files'])
  emitFiles(event: FileList) {
    this.loading = true;
    const file = event && event.item(0);
    this.avatarSubscription = this.docsService.postFiles(event, this.categoryCode)
      .subscribe({
        next: (data: any) => {
          if (data && data[0]) {
            this.data = data[0].id;
            console.log(this.data)
            this.fileUploaded.emit(data[0])
            this.propagateChange(this.data);
            this.loading = false;
          }
        }, error: (error: any) => {
          this.loading = false;
          console.log(error)
        }
      });
  }
  constructor(
    private docsService: DocumentsService,
    private ui: UiService
  ) {

  }
  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.avatarSubscription?.unsubscribe();
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    if (obj) {
      this.data = obj;
    }
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    return (!this.parseError) ? null : {
      jsonParseError: {
        valid: false,
      },
    };
  }

  // not used, used for touch input
  public registerOnTouched() { }

  // change events from the textarea
  onChange(event: any) {
    // get value from text area
    let newValue = event.target.value;

    try {
      // parse it to json
      this.data = newValue;
      this.parseError = false;
    } catch (ex) {
      // set parse error if it fails
      this.parseError = true;
    }

    // update the form
    this.propagateChange(this.data);
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange = (_: any) => { };




  remove() {
    this.data = null;
    this.fileRemoved.emit(this.data)
    this.propagateChange(this.data)
  }
}
