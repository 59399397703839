<item-wrapper #item [mainService]="applications" [form]="form" navigateList="applications" title="Configuration Group"
  [readPerm]="auth.perm.ConfigurationRead" [managePerm]="auth.perm.ConfigurationManage">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Configuration Group Name' | translate }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description' }}</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5" formControlName="description"></textarea>
    </mat-form-field>

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="0px" fxLayoutGap.gt-sm="12px"
      *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Matching Formula' |translate }}</mat-label>
        <input matInput formControlName="matchingFormula" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Price Per Check' |translate }}</mat-label>
        <input step="0.01" matInput type="number" formControlName="pricePerCheck" />
      </mat-form-field>
    </div>
  </form>
</item-wrapper>