<list-wrapper #wrapper [mainService]="providerChecks" navigateItem="provider-checks" title="Provider Checks"
  [readPerm]="auth.hasPermission(auth.perm.ProviderRead)" [managePerm]="auth.hasPermission(auth.perm.ProviderManage)">
  <mat-form-field appearance="outline" fxFlex="150px" toolbox>
    <mat-label>Provider</mat-label>
    <mat-select [formControl]="providerIds" multiple (selectionChange)="filterChecks()">
      <mat-option *ngFor="let item of $providers | async" [value]="item?.id">
        {{item?.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="46px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>

      <div fxFlex="120px">
        <div mat-line>{{'Provider' | translate }}</div>
      </div>
    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{ deleted: item?.deleted}">
      <mat-icon matListIcon>verified</mat-icon>
      <app-check-type matListIcon [checkType]="item.checkType"></app-check-type>
      <strong mat-line>{{ item?.name }}</strong>
      <small mat-line>
        {{ item?.description }}
      </small>
      <div fxFlex="120px">
        <small>{{ item?.provider?.name}}</small>
      </div>

      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>