import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { TokensComponent } from './tokens.component';
import { TokenItemComponent } from './token-item/token-item.component';
import {
  ClipboardModule
} from '@angular/cdk/clipboard';

import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    TokensComponent,
    TokenItemComponent,
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ],

})
export class TokensModule { }
