import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperComponent } from './wrapper/wrapper.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { CompanyRoutingModule } from './company-routing.module';
import { CompaniesModule } from './company-item/companies.module';
import { TokensModule } from './tokens/tokens.module';
import { ConfigurationsModule } from './configurations/configurations.module';
import { ApplicationsModule } from './applications/applications.module';
import { ComponentsModule } from '../components/components.module';
import { SessionsModule } from './sessions/sessions.module';
import { CompanyChecksModule } from './company-checks/company-checks.module';
import { TranslationsModule } from '../admin/translations/translations.module';
import { LoginModule } from '../admin/login/login.module';
import { CompanyUsersModule } from './users/users.module';
import { TranslateModule } from '@ngx-translate/core';
import { InfoPageModule } from '../admin/info-page/info.module';
import { TermsAndConditionsModule } from '../admin/terms-and-conditions/terms-and-conditions.module';
import { PaymentOptionsComponent } from './payments/payment-options/payment-options.component';
import { PaymentsComponent } from './payments/payments.component';
import { SubscriptionCardItemComponent } from './payments/subscription-card-item/subscription-card-item.component';
import { RedirectModule } from './redirect/redirect.module';
import { DashboardModule } from './dashboard/dashboard.module';




@NgModule({
  declarations: [
    WrapperComponent,
    PaymentOptionsComponent,
    PaymentsComponent,
    SubscriptionCardItemComponent
  ],
  imports: [
    LoginModule,
    TermsAndConditionsModule,
    InfoPageModule,
    CompanyUsersModule,
    CommonModule,
    CompanyRoutingModule,
    TranslationsModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CompaniesModule,
    TokensModule,
    ConfigurationsModule,
    RedirectModule,
    DashboardModule,
    ApplicationsModule,
    CompanyChecksModule,
    SessionsModule,
    ComponentsModule,
    TranslateModule.forChild()
  ],
  exports: [
    WrapperComponent,
    SubscriptionCardItemComponent
  ]
})
export class CompanyModule { }
