import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, finalize, delay } from 'rxjs/operators';

import { UiService } from '../_services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public ui: UiService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'POST') {
      // this.ui.loaderShow();
    }

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.body.errors && event.body.errors.length) {
          console.log(event.body.errors)
          event.body.errors.forEach((element: any) => {

            if (element.message?.indexOf("You are not authorized to run this") > -1) {
              this.ui.openSnackBar("Session has expired, please login again.", "OK");
              this.router.navigate(['/logout']);
              this.hideAll()
            } else {
              if (element.message) {
                this.ui.openSnackBar(element.message, "OK");
              }
            }
          });
        }

        return event.clone();
      }
    }));
  }
  hideAll(): void {
    //try to hide all active modals
    var openModals: any = document.querySelectorAll("[mat-dialog-close]");
    if (openModals) {
      for (let i = 0; i < openModals.length; i++) {
        openModals[0].click();
      }
    }
  }
}
