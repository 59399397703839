import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { combineLatest, distinctUntilChanged, map, Observable, Subscribable, Unsubscribable } from 'rxjs';
import { ListWrapperComponent } from 'src/app/components/wrappers/list-wrapper/list-wrapper.component';
import { Dashboard, Product, Purchase } from 'src/app/_graphql/schema';
import { Session } from 'src/app/_graphql/schema-session';
import { AuthService } from 'src/app/_services/auth.service';
import { DashboardCompanyService } from 'src/app/_services/dashboard-company.service';
import { PurchasesService } from 'src/app/_services/purchases.service';
import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(ListWrapperComponent) wrapper: ListWrapperComponent;
  sessions$: any;
  dashboard: Dashboard;
  $dashboard: Unsubscribable;
  sessionsExist$: Subscribable<Session[]>;
  colorScheme = {
    domain: ["#53ABBF", "#F0B738", "#E7E6EB", "#A5A6A8", "#32363F", "#9FDAEC", '#FAD37B', '#1D8198', '#C7C9CC', '#5B5E64']
  };
  checksData = [];
  pieData = [];
  sessionsPie = [];

  sessionsCount = 0;
  checksCount = 0;
  monitoredCount = 0;
  pendingCount = 0;

  purchase: Purchase = null;
  showBtnCreateSession = true;

  $purchases: Subscribable<Purchase[]>;
  $nextSubscription: Subscribable<Purchase>;
  checksIncluded = 0;
  checksSpent = 0;

  $filters: Unsubscribable;
  purchaseUnsub$: Unsubscribable;

  form: FormGroup = this.fb.group({
    dateRange: new FormControl(
      {
        range: null,
        startDate: null,
        endDate: null
      }
    ),
  });


  constructor(
    private router: Router,
    public auth: AuthService,
    private translate: TranslateService,
    private purchasesService: PurchasesService,
    public sessionsService: SessionsCompanyService,
    public service: DashboardCompanyService,
    private fb: FormBuilder
  ) {
    this.showBtnCreateSession = !this.auth.hasRoles(['Admin']);
    this.form.patchValue({
      dateRange: {
        range: 'today',
        startDate: moment().utcOffset(0).startOf('day').toDate().toISOString(),
        endDate: moment().utcOffset(0).endOf('day').toDate().toISOString()
      },
      isNonTesting: true,
    });

    this.setQueryParams(this.form.value);
    this.sessionsExist$ = this.sessionsService.getOneSession();

    this.purchasesService.queryParams.take = 1;
    this.purchasesService.queryParams.isActive = true;
    this.sessionsService.queryParams.isNonTesting = true;
    this.purchaseUnsub$ = this.purchasesService.all(this.purchasesService.queryParams, false).subscribe({
      next: x => {
        this.purchase = x[0]
        this.checksIncluded = 0;
        this.checksSpent = 0;
        x[0]?.purchaseItems?.forEach(itm => {
          this.checksIncluded += itm.includedChecks
          this.checksSpent += itm.spentChecks;
        });

      }
    });
    this.$nextSubscription = this.purchasesService.nextSubscription()

    this.sessionsService.queryParams.sessionStatuses = [];
    this.sessionsService.queryParams.take = 5;
    this.sessionsService.queryParams.isPendingCheck = true;

    this.sessions$ = this.sessionsService.all();
    this.getStats();
  }

  ngAfterViewInit() {
  }
  ngOnInit(): void {
    this.$filters = this.form.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(vals => {
      this.setQueryParams(vals);
      this.sessionsService.fetchMoreData();
      this.getStats();
    });
  }
  private setQueryParams(value) {
    Object.assign(this.service.queryParams, value);
    this.sessionsService.queryParams.isPendingCheck = true;
    this.sessionsService.queryParams.isNonTesting = true;
    this.service.queryParams.statsRange = this.form.get('dateRange')?.value;
  }
  onDateChanged() {
    this.sessionsService.queryParams.isPendingCheck = true;
    this.sessionsService.queryParams.isNonTesting = true;
    this.sessionsService.queryParams.dateRange = this.service.queryParams.dateRange;
    this.sessionsService.fetchMoreData();
    this.getStats();
  }
  getStats() {
      const sessionsCount$ = (this.service.getSessionStats({
        statsRange: this.service.queryParams.statsRange,
        grouping: 'SESSION',
        isNonTesting: true
      }) as Observable<any>).pipe();

      const checksCount$ = (this.service.getSessionStats({
        statsRange: this.service.queryParams.statsRange,
        isNonTesting: true
      }) as Observable<any>).pipe();

      const monitoredCount$ = (this.service.getSessionStats({
        grouping: 'SESSION',
        isMonitoring: true,
        sessionStatuses: ['ONGOING'],
        isNonTesting: true
      }) as Observable<any>).pipe();

      const pendingCount$ = (this.service.getSessionStats({
        statsRange: this.service.queryParams.statsRange,
        grouping: 'SESSION',
        isPendingCheck: true,
        isNonTesting: true
      }) as Observable<any>).pipe();

      const companyChecksStats$ = (this.service.getCompanyChecksStats({
        statsRange: this.service.queryParams.statsRange,
        isNonTesting: true,
      }) as Observable<any>).pipe();

      combineLatest([sessionsCount$, checksCount$, monitoredCount$, pendingCount$, companyChecksStats$]).subscribe(
        ([sessionsCount, checksCount, monitoredCount, pendingCount, companyChecksCount]) => {
          this.sessionsCount = sessionsCount.stats ? sessionsCount.stats.count : 0;
          this.checksCount = checksCount.stats ? checksCount.stats.count : 0;
          this.monitoredCount = monitoredCount.stats ? monitoredCount.stats.count : 0;
          this.pendingCount = pendingCount.stats ? pendingCount.stats.count : 0;
          if (companyChecksCount) {
            const checksData = companyChecksCount.data.map(el => ({
              name: this.translate.instant('checkTypeShort_' + el.checkType),
              value: el?.stats ? el.stats.count : 0
            }));
            this.checksData = checksData;
          }
        }
      );

    const passedStats$ = (this.service.getSessionStats({
      statsRange: this.service.queryParams.statsRange,
      grouping: 'SESSION',
      checkStatuses: ['PASSED'],
      isNonTesting: true
    }) as Observable<any>).pipe();
    
    const notPassedStats$ = (this.service.getSessionStats({
      statsRange: this.service.queryParams.statsRange,
      grouping: 'SESSION',
      checkStatuses: ['NOT_PASSED'],
      isNonTesting: true
    }) as Observable<any>).pipe();

    const businessStats$ = (this.service.getSessionStats({
      statsRange: this.service.queryParams.statsRange,
      grouping: 'SESSION',
      isBusiness: true,
      isNonTesting: true
    }) as Observable<any>).pipe();
    
    const personStats$ = (this.service.getSessionStats({
      statsRange: this.service.queryParams.statsRange,
      grouping: 'SESSION',
      isPerson: true,
      isNonTesting: true
    }) as Observable<any>).pipe();
    
    combineLatest([businessStats$, personStats$, passedStats$, notPassedStats$]).subscribe
    (([businessSessionCount, personsSessionCount, passedSessionsCount, notPassedSessionsCount]) => {    
      this.sessionsPie = [
        {
          "name": "Passed",
          "value": passedSessionsCount?.stats ? passedSessionsCount?.stats?.count : 0 
        },
        {
          "name": "Not passed",
          "value": notPassedSessionsCount?.stats ? notPassedSessionsCount?.stats?.count : 0 
        },
      ];
      this.pieData = [
        {
          "name": "Business",
          "value": businessSessionCount?.stats ? businessSessionCount?.stats?.count : 0 
        },
        {
          "name": "Person",
          "value": personsSessionCount?.stats ? personsSessionCount?.stats?.count : 0 
        },
      ];
    });
  }
  provideFilters(filter: object = {}) {
    if (this.form.value.dateRange) {
      filter['dateRange'] = this.form.value.dateRange;
    }
    this.router.navigate(['company', 'sessions'], { state: { filters: filter } });
  }

  ngOnDestroy(): void {
    this.sessionsService.queryParams.isPendingCheck = null;
    this.sessionsService.queryParams.dateRange = null;
    this.sessionsService.queryParams.isNonTesting = null;
    this.sessionsService.queryParams.take = 25;
    this.$filters?.unsubscribe();
    this.$dashboard?.unsubscribe();
    this.purchaseUnsub$?.unsubscribe();
  }
}
