import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  title: string;
  content: string;
  btnCancelText: string;
  btnConfirmText: string;
  btnConfirmMode: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,

  ) {
    this.title = data.title ?? 'Confirm';
    this.content = data.content ?? 'Do you want to continue?';
    this.btnCancelText = data.btnCancelText ?? 'Cancel';
    this.btnConfirmText = data.btnConfirmText ?? 'Confirm';
    this.btnConfirmMode = data.btnConfirmMode ?? 'primary';
  }



}
export interface ConfirmationDialogData {
  title: string;
  content: string;
  btnCancelText: string;
  btnConfirmText: string;
  btnConfirmMode: string;
}