import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base.service';
import { Tag, TagInput } from '../_graphql/schema';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class TagsService extends BaseService<Tag, TagInput> {
  selectOneFields = gql`
  fragment SelectOneFields on Tag {
    id shortCode name description color type
  }`;

  selectAllFields = gql`
  fragment SelectAllFields on Tag {
    id shortCode name description color type
  }`;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql('tag');
  }

  allExlude() {
    return this.all();
  }
}