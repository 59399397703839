import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { ProviderItemComponent } from './provider-item/provider-item.component';
import { ProvidersComponent } from './providers.component';
import { ProviderChecksModule } from './checks/checks.module';

@NgModule({
  declarations: [
    ProvidersComponent,
    ProviderItemComponent
  ],
  imports: [
    ProviderChecksModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ]
})
export class ProvidersModule { }
