import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscribable } from 'rxjs';
import { Country } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CountriesService } from 'src/app/_services/countries.service';
import { UiService } from 'src/app/_services/ui.service';
import { UsersService } from 'src/app/_services/users.service';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-company-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})
export class CompanyUserItemComponent implements OnInit, OnDestroy {
  itemId: string;
  route$: any;

  form = this.fb.group({
    id: [null],
    countryId: [null, []],
    address: [null, []],
    address2: [null, []],
    avatarId: [null, []],
    city: [null, []],
    email: [null, [Validators.email, Validators.required]],
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    middleName: [null, []],
    // iAgree: [null, [Validators.requiredTrue]],
    // password: [null, [Validators.required]],
    phoneNumber: [null, []],
    state: [null, []],
    title: [null, []],
    zip: [null, []],
  });
  $countries: Subscribable<Country[]>;
  hide = true;

  constructor(
    private route: ActivatedRoute,
    public service: UsersService,
    public countriesService: CountriesService,
    private fb: FormBuilder,
    public auth: AuthService,
    private ui: UiService,
  ) {
    this.$countries = this.countriesService.all();

  }


  ngOnInit(): void {
    this.route$ = this.route.paramMap.subscribe((params: ParamMap) => {
      this.itemId = params.get('id');
      if (this.itemId != 'new') {
        this.form.removeControl('password');
        this.form.removeControl('iAgree');
        // this.form.removeControl('email');
        if (this.itemId != this.auth.user?.id) {
          this.form.disable()
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.route$?.unsubscribe();
  }
  resendEmailVerification() {
    var mdl = <ConfirmationDialogData>{
      title: 'Resend email verification',
      content: 'In order to receive email notifications, please verify your email. Do you want to resend verification email?',
      btnConfirmText: 'Resend',
      btnCancelText: 'Cancel'
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        var k = this.service.resendVerificationEmail().subscribe({
          next: res => {
            k?.unsubscribe();
          }
        });
      }
    });
  }
}
