import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'addresses-form-item',
  templateUrl: './addresses-form-item.component.html',
  styleUrls: ['./addresses-form-item.component.scss']
})
export class AdressesFormItem implements OnInit {
  @Input('fg') fg: FormArray;

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder
  ) {
  }
  ngOnInit(): void {


  }

  addLine(): void {
    this.fg.push(this.fb.group({
      city: [null, [Validators.required]],
      countryCode: [null, [Validators.maxLength(2), Validators.minLength(2), Validators.required]],
      province: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      street: [null, [Validators.required]],
      streetLine2: [null, []],
      streetNumber: [null, []],
    }));
  }
  removeLine(index: number) {

    if (this.fg.at(index).pristine) {
      this.fg.removeAt(index);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: <ConfirmationDialogData>{ title: "Remove address item" },
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed)
        this.fg.removeAt(index);
    });
  }
}
