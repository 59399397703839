import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscribable, Unsubscribable } from 'rxjs';
import { Company, PaymentMethod, Product } from 'src/app/_graphql/schema';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-set-payment-method-dialog',
  templateUrl: './set-payment-method-dialog.component.html',
  styleUrls: ['./set-payment-method-dialog.component.scss']
})
export class SetPaymentMethodDialogComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  item: any;
  $products: Subscribable<Product[]>;


  public get paymentMethods() {
    return PaymentMethod;
  }

  form = this.fb.group({
    companyId: [null, Validators.required],
    allowedPaymentMethod: [null, []],
    productId: [null, []],
  });

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<SetPaymentMethodDialogComponent>,
    public productsService: ProductsService,
    @Inject(MAT_DIALOG_DATA) public data: SetPaymentMethodDialogComponentData,


  ) {
    // this.productsService.queryParams.isVisible = true;
    this.productsService.queryParams.sortBy = "order";
    this.$products = this.productsService.dropdown();
    this.item = this.data.item;
    this.form.patchValue({
      companyId: this.item.id, allowedPaymentMethod: this.item?.allowedPaymentMethod,
      productId: this.item?.currentSubscription?.product?.id
    });

  }


  ngOnInit(): void {
  }
}
export interface SetPaymentMethodDialogComponentData {
  item: any;
}