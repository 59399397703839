import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base.service';
import { State } from '../_graphql/schema';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class StatesService extends BaseService<State> {
  selectOneFields = gql`
  fragment SelectOneFields on State {
    id name countryId isoCode country { name isoCode2 }
  }`;

  selectAllFields = gql`
  fragment SelectAllFields on State {
    id name countryId isoCode country { name isoCode2 }
  }`;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql('state');
  }
}
