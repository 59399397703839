import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable } from 'rxjs/internal/types';
import { Country, State } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CountriesService } from 'src/app/_services/countries.service';
import { StatesService } from 'src/app/_services/states.service';

@Component({
  selector: 'app-states-item',
  templateUrl: './states-item.component.html',
  styleUrls: ['./states-item.component.scss']
})
export class StatesItemComponent implements OnInit {
  data$: Subscribable<State>;
  countries$: Subscribable<Country[]>;
  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    countryId: [null, Validators.required],
    isoCode: [null, Validators.required]
  });
  constructor(public states: StatesService, public countriesService: CountriesService, public auth: AuthService, private fb: FormBuilder) {
    this.countries$ = this.countriesService.all();
  }

  ngOnInit(): void {
  }

}
