import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { Feature } from "../_graphql/schema";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
})
export class FeaturesService extends BaseService<Feature> {
    constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
        super(apollo, sb);
        this.selectOneFields = gql`
      fragment SelectOneFields on Feature {
        id
        name
        icon
        order
        description
      }
    `;

        this.selectAllFields = gql`
    fragment SelectAllFields on Feature {
        id
        name
        icon
        order
        description
    }
  `;

        this.initGql("feature");
        this.queryParams.sortOrder = "ASC";
        this.queryParams.sortBy = "name";
    }

}
