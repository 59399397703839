<mat-icon *ngIf="checkType == 'PERSONAL'">person</mat-icon>
<mat-icon *ngIf="checkType == 'ADDRESS'">home</mat-icon>
<mat-icon *ngIf="checkType == 'LOCATION'">place</mat-icon>
<mat-icon *ngIf="checkType == 'DOCUMENT_ID'">badge</mat-icon>
<mat-icon *ngIf="checkType == 'DOCUMENT_PHOTO'">portrait</mat-icon>
<mat-icon *ngIf="checkType == 'BUSINESS_VALIDATION'">work</mat-icon>
<mat-icon *ngIf="checkType == 'PERSONAL_SANCTION_LIST'">person_off</mat-icon>
<mat-icon *ngIf="checkType == 'BUSINESS_SANCTION_LIST'">work_off</mat-icon>
<mat-icon *ngIf="checkType == 'PEP'">recent_actors</mat-icon>
<mat-icon *ngIf="checkType == 'DOCUMENT_FORGERY'">grid_off</mat-icon>
<mat-icon *ngIf="checkType == 'BANK_ID'">payment</mat-icon>
<mat-icon *ngIf="checkType == 'BANKING_API'">account_balance</mat-icon>
<mat-icon *ngIf="checkType == 'NONE'">api</mat-icon>
<mat-icon *ngIf="checkType == 'BUSINESS_ORIGIN'">location_city</mat-icon>
<mat-icon *ngIf="checkType == 'GOVERNMENT_IDENTITY_CHECK'">assured_workload</mat-icon>
<mat-icon *ngIf="checkType == 'RESIDENTIAL'">maps_home_work</mat-icon>
<mat-icon *ngIf="checkType == 'CREDIT_BUREAU'">maps_home_work</mat-icon>
<mat-icon *ngIf="checkType == 'PHONE'">phone</mat-icon>



<mat-icon *ngIf="['BUSINESS_ADVERSE_MEDIA', 'PERSONAL_ADVERSE_MEDIA' ].indexOf(checkType) != -1">
    track_changes</mat-icon>

<mat-icon *ngIf="['BUSINESS_PEP', 'PERSONAL_PEP', 'PEP'].indexOf(checkType) != -1">
    recent_actors</mat-icon>
<mat-icon
    *ngIf="['MONITORING_BUSINESS_ADVERSE_MEDIA', 'MONITORING_BUSINESS_PEP', 'MONITORING_BUSINESS_SANCTIONS', 
    'MONITORING_PERSONAL_ADVERSE_MEDIA', 'MONITORING_PERSONAL_PEP', 'MONITORING_PERSONAL_SANCTIONS'].indexOf(checkType) != -1">
    track_changes</mat-icon>