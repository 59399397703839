import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountriesComponent } from './countries/countries.component';
import { CountryItemComponent } from './countries/country-item/country-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { StatesComponent } from './states/states.component';
import { StatesItemComponent } from './states/states-item/states-item.component';

@NgModule({
  declarations: [
    CountriesComponent,
    CountryItemComponent,
    StatesComponent,
    StatesItemComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ]
})
export class BaseModule { }
