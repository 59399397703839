<public-wrapper mini="true">
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch" (submit)="onSubmit()">
    <h3>{{'forgot_password_title' | translate}}</h3>
    <p>{{'forgot_password_description' | translate}}</p>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Email' | translate }}</mat-label>
      <input matInput type="email" formControlName="username" />
    </mat-form-field>

    <button mat-raised-button fxFlex color="primary" [disabled]="!this.form.valid">{{'Request Password Reset'|
      translate}}</button>
  </form>
</public-wrapper>