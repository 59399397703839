<list-wrapper #wrapper [mainService]="configurations" navigateItem="configurations" title="Configurations"
  [readPerm]="auth.hasPermission(auth.perm.ConfigurationRead)"
  [managePerm]="auth.hasPermission(auth.perm.ConfigurationManage)">
  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="46px" fxHide.lt-md></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>
      <div fxFlex="80px" class="text-center">
        <div mat-line>{{'Checks' }}</div>
      </div>
      <div fxFlex="120px" fxHide.lt-md>
        <div mat-line>{{'Code' }}</div>
      </div>
      <div fxFlex="85px" class="text-right">
        <div mat-line>{{'Count'|translate}}</div>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <div mat-line>{{'Price' | translate}}</div>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <div mat-line>{{'Cost'|translate}}</div>
      </div>
      <div fxFlex="54px">

      </div>

    </h3>
    <mat-list-item *ngFor="let item of data$ | async; index as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{ deleted: item?.deleted}">
      <mat-icon matListIcon fxHide.lt-md>dynamic_feed</mat-icon>

      <h3 mat-line>{{ item?.name }}</h3>
      <small mat-line>
        {{ item?.description }}
      </small>


      <div fxFlex="80px" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>verified</mat-icon>
        <small>{{ item?.checks.length}}</small>
      </div>
      <div fxFlex="120px" fxHide.lt-md>
        <small>{{ item?.configCode}}</small>
      </div>
      <div fxFlex="85px" class="text-right">
        <small>{{ item?.stats?.count }}</small>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <small *ngIf="item?.stats?.price">{{ item?.stats?.price | currency }}</small>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <small *ngIf="item?.stats?.cost">{{ item?.stats?.cost | currency }}</small>
      </div>
      <div fxFlex="54px" class="text-center">
        <mat-icon *ngIf="item.isTesting" matTooltip="{{'configuration_testing' |translate}}">science</mat-icon>
      </div>

      <mat-divider [inset]="true" *ngIf="i+1<(data$ | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>