import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { ProviderCheck, Permission } from "../_graphql/schema";
import { AuthService } from "./auth.service";
import { BaseService } from "./base.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class ProviderChecksService extends BaseService<ProviderCheck> {
  FieldBase = gql`
  fragment FieldBase on Field {
    id
    name
    type
    displayType
    validation { 
      required 
      ...on ArrayValidation 
        { min max }
      ...on NumberValidation 
        { min max step } 
      ...on StringValidation 
        { minLength maxLength pattern } 
      ...on DateValidation 
        { minDate maxDate dateLimitStep dateFutureLimit datePastLimit } 
    }
  }
  `
  FieldFull = gql`
  fragment FieldFull on Field {
    ...FieldBase
    items { 
      ...FieldBase
      items {
        ...FieldBase
        items {
          ...FieldBase
          items {
            ...FieldBase
            items {
              ...FieldBase
              items {
                ...FieldBase
              }
            }
          }
        }
      }
    }
  }
  ${this.FieldBase}

  `
  selectOneFields = gql`
    fragment SelectOneFields on ProviderCheck {
      id
      name
      description
      country
      checkTypes {
        id
        checkType
      }
      providerId
      sourceType
      deleted
      ${this.auth.allowedField(Permission.ConfigurationPricingRead, 'maxRateMonthly')}
      ${this.auth.allowedField(Permission.ConfigurationPricingRead, 'pricePerCheck')}
      ${this.auth.allowedField(Permission.ConfigurationPricingRead, 'costPerCheck')}
      fieldSet {
        id name description title isSystemLocked
        rootField {
          ...FieldFull
        }
      }
      fieldSetId
    }
    ${this.FieldFull}
  `;

  selectAllFields = gql`
    fragment SelectAllFields on ProviderCheck {
      id
      name
      description
      country
      checkTypes {
        id
        providerCheckId
        checkType
      }
      sourceType
      deleted
      provider { id name }
      fieldSet {
        id name description title isSystemLocked
        rootField {
          ...FieldFull
        }
      }
      fieldSetId
    }
    ${this.FieldFull}
  `;



  constructor(protected apollo: Apollo, protected sb: MatSnackBar, private auth: AuthService) {
    super(apollo, sb);
    this.initGql("providerCheck");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }
}
