<item-wrapper #item [mainService]="providers" [form]="form" navigateList="providers" title="Provider"
  [readPerm]="auth.hasPermission(auth.perm.ProviderRead)" [managePerm]="auth.hasPermission(auth.perm.ProviderManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">

    <div fxFlex fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field fxFlex="30">
        <mat-label>{{ 'Code' }}</mat-label>
        <input matInput formControlName="code" />
      </mat-form-field>
    </div>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description' }}</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
      <mat-label>{{ 'Key Vault ID' }}</mat-label>
      <input matInput formControlName="keyVaultId" />
    </mat-form-field>
    <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
      <mat-label>{{ 'Key Vault Cert' }}</mat-label>
      <textarea matInput formControlName="keyVaultCert" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
      <mat-label>{{ 'Testing Access Config' }}</mat-label>
      <textarea matInput formControlName="testingAccessConfig" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <mat-checkbox matInput type="checkbox" color="primary" formControlName="isTesting">Is in testing mode</mat-checkbox>


  </form>
</item-wrapper>