import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { SanctionsItemComponent } from './sanctions-item/sanctions-item.component';
import { SanctionsComponent } from './sanctions.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    SanctionsComponent,
    SanctionsItemComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ]
})
export class SanctionsModule { }
