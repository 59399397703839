import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiltersManagementService {
  private filters: any = {};

  constructor(
  ) { }

  setFilter(property: string, value: any): void {
    this.filters[property] = value;
  }

  getFilters(): any {
    return this.filters;
  }

  clearFilter(property: string): void {
    if (this.filters.hasOwnProperty(property)) {
      delete this.filters[property];
    }
  }

  clearAllFilters(): void {
    this.filters = {};
  }

  hasFilter(property: string): boolean {
    return this.filters.hasOwnProperty(property);
  }
}
