import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { BaseService } from "./base.service";
import { UiService } from "./ui.service";

@Injectable({
  providedIn: "root",
})
export class ProductFeaturesService extends BaseService<any> {
  selectOneFields = gql`
      fragment SelectOneFields on ProductFeature {
        id
        featureId
        feature { id name description }
        productId
        product { id name description }
        created
        deleted
      }
    `;
  selectAllFields = gql`
    fragment SelectAllFields on ProductFeature {
        id
        featureId
        feature { id name description }
        productId
        product { id name  description }
        created
        deleted
    }
  `;

  constructor(
    protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);



    this.initGql("productFeature");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "product.name";
  }

}
