import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable, take } from 'rxjs';
import { CheckType, CompanyCheck, Company, ProviderCheck } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyChecksService } from 'src/app/_services/checks.service';
import { CompaniesService } from 'src/app/_services/companies.service';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';



@Component({
  selector: 'app-cmpany-check-item',
  templateUrl: './company-check-item.component.html',
  styleUrls: ['./company-check-item.component.scss']
})
export class CompanyCheckItemComponent implements OnInit {
  data$: Subscribable<ProviderCheck>;
  service$: Subscribable<CompanyCheck[]>;
  companies$: Subscribable<Company[]>;
  providerChecks$: Subscribable<ProviderCheck[]>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  checkTypes = CheckType;

  form = this.fb.group({
    id: [null],
    description: [null, []],
    name: [null, Validators.required],
    isActive: [true, Validators.required],
    maxRateMonthly: [null, []],
    pricePerCheck: [null, []],
    providerCheckId: [null, [Validators.required]],
  });
  constructor(
    private _ngZone: NgZone,
    public service: CompanyChecksService,
    public companiesService: CompaniesService,
    public providerChecksService: ProviderChecksService,
    private fb: FormBuilder,
    public auth: AuthService
  ) {
    this.form.valueChanges.subscribe(val => {
      if (!auth.hasPermission(auth.perm.CompanyCheckPricingManage)) {
        ['pricePerCheck', 'maxRateMonthly'].forEach(el => this.form.get(el).disable({ emitEvent: false }))
      }
    });

    this.service$ = service.all();
    this.companies$ = companiesService.all();
    if (auth.hasPermission(auth.perm.ProviderRead)) {
      providerChecksService.queryParams.take = 25000;
      this.providerChecks$ = providerChecksService.all();
    }


  }

  ngOnInit(): void {
  }

}
