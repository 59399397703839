import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WrapperComponent } from './wrapper/wrapper.component';
import { TranslationsComponent } from './translations/translations.component';
import { CountriesComponent } from './base/countries/countries.component';
import { CountryItemComponent } from './base/countries/country-item/country-item.component';
import { AuthGuard } from '../_guards/auth.guard';
import { StatesComponent } from './base/states/states.component';
import { StatesItemComponent } from './base/states/states-item/states-item.component';
import { TagsComponent } from './tags/tags.component';
import { TagItemComponent } from './tags/tag-item/tag-item.component';
import { CompaniesComponent } from './companies/companies.component';

import { ProvidersComponent } from './providers/providers.component';
import { ProviderItemComponent } from './providers/provider-item/provider-item.component';
import { ProviderChecksComponent } from './providers/checks/checks.component';
import { ProviderCheckItemComponent } from './providers/checks/check-item/check-item.component';
import { Permission } from '../_graphql/schema';
import { SanctionsComponent } from './sanctions/sanctions.component';
import { SanctionsItemComponent } from './sanctions/sanctions-item/sanctions-item.component';
import { CompanyItemComponent } from '../company/company-item/company-item.component';
import { ProductsComponent } from './products/products.component';
import { ProductItemComponent } from './products/product-item/product-item.component';
import { CompanyUserItemComponent } from '../company/users/user-item/user-item.component';
import { ProductChecksComponent } from './products/products-checks/product-checks.component';
import { ProductCheckItemComponent } from './products/products-checks/product-check-item/product-check-item.component';
import { FeaturesComponent } from './products/features/features.component';
import { FeatureItemComponent } from './products/features/feature-item/feature-item.component';
import { ProductsFeaturesComponent } from './products/features/products-features/products-features.component';
import { ProductsFeaturesItemComponent } from './products/features/products-features/products-feature-item/products-feature-item.component';
import { FieldsetsComponent } from './fieldsets/fieldsets.component';
import { FieldsetItemComponent } from './fieldsets/fieldset-item/fieldset-item.component';



const routes: Routes = [
  {
    path: 'admin',
    component: WrapperComponent,
    children: [
      {
        path: 'full',
        pathMatch: 'full',
        redirectTo: '/admin/companies'
      },
      {
        path: 'users/:id',
        component: CompanyUserItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      }, {
        path: 'translations',
        component: TranslationsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'countries',
        component: CountriesComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'countries/:id',
        component: CountryItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'states',
        component: StatesComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'states/:id',
        component: StatesItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'tags',
        component: TagsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'tags/:id',
        component: TagItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'sanctions',
        component: SanctionsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemManage,
          redirectPath: '/admin'
        }
      }, {
        path: 'sanctions/:id',
        component: SanctionsItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemManage,
          redirectPath: '/admin'
        }
      },
      {
        path: 'providers',
        component: ProvidersComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.ProviderRead,
          redirectPath: '/admin'
        }
      },
      {
        path: 'providers/:id',
        component: ProviderItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.ProviderRead,
          redirectPath: '/admin/providers'
        }
      },
      {
        path: 'provider-checks',
        component: ProviderChecksComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.ProviderRead,
          redirectPath: '/admin'
        }
      },
      {
        path: 'fieldsets',
        component: FieldsetsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemManage,
          redirectPath: '/admin'
        }
      },
      {
        path: 'fieldsets/:id',
        component: FieldsetItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SystemManage,
          redirectPath: '/admin'
        }
      },
      {
        path: 'provider-checks/:id',
        component: ProviderCheckItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.ProviderRead,
          redirectPath: '/admin/provider-checks'
        }
      },
      {
        path: 'sanctions-list',
        component: ProviderCheckItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.ProviderRead,
          redirectPath: '/admin/provider-checks'
        }
      },

      {
        path: 'companies',
        component: CompaniesComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'companies/:id',
        component: CompanyItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/admin/companies'
        }
      },
      {
        path: 'products',
        component: ProductsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'products/:id',
        component: ProductItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/admin/products'
        }
      },
      {
        path: 'product-checks',
        component: ProductChecksComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'product-checks/:id',
        component: ProductCheckItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      // {
      //   path: 'products-features',
      //   component: ProductsFeaturesComponent,
      //   canActivate: [AuthGuard],
      //   data: {
      //     permission: Permission.CompanyRead,
      //     redirectPath: '/login'
      //   }
      // },
      // {
      //   path: 'products-features/:id',
      //   component: ProductsFeaturesItemComponent,
      //   canActivate: [AuthGuard],
      //   data: {
      //     permission: Permission.CompanyRead,
      //     redirectPath: '/login'
      //   }
      // },
      {
        path: 'features',
        component: FeaturesComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'features/:id',
        component: FeatureItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },


      {
        path: '**',
        redirectTo: '/login'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
