<list-wrapper #wrapper [mainService]="tags" navigateItem="tags" title="Tags">
  <mat-action-list>
    <mat-list-item *ngFor="let item of data$ | async" (click)="wrapper.edit(item.id)">
      <div class="info" [style.backgroundColor]="item.color">
        <div mat-line class="head">{{item.name}}</div>
        <div mat-line >{{ item.shortCode }}</div>
      </div>
      <div class="info">
        <div mat-line class="head">Desc</div>
        <div mat-line>{{ item.description }}</div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>
