<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" [ngClass]="{'wrapper':true }">
  <div class="container">
    <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 36px;">
      <button mat-icon-button color="primary" routerLink="/">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="text-center" fxFlex>
        <a [routerLink]="['/']"><img src="/assets/images/logo.svg" class="logo"></a>
      </div>
      <button mat-icon-button color="primary" style="opacity: 0; visibility: hidden;">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="8px" (submit)="submit()">
      <h3>Company</h3>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Company Name' | translate }}</mat-label>
        <input matInput formControlName="companyName" />
      </mat-form-field>
      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="8px" fxLayoutGap.gt-md="16px">
        <mat-form-field fxFlex>
          <mat-label>{{ 'Company ID' | translate }}</mat-label>
          <input matInput formControlName="companyId" />
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{ 'VAT Number' | translate }}</mat-label>
          <input matInput formControlName="vatNumber" />
        </mat-form-field>
      </div>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Website' | translate }}</mat-label>
        <input matInput formControlName="website" placeholder="https://yourwebsite.com" />
        <mat-error *ngIf="form.get('website').hasError('pattern')">Invalid URL format</mat-error>

      </mat-form-field>
      <h3>Address</h3>

      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 1' | translate }}</mat-label>
        <input matInput formControlName="address" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 2' | translate }}</mat-label>
        <input matInput formControlName="address2" />
      </mat-form-field>

      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
        <mat-form-field fxFlex="30">
          <mat-label>{{ 'Postal Code' | translate }}</mat-label>
          <input matInput formControlName="zip" />
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{ 'City' | translate }}</mat-label>
          <input matInput formControlName="city" />
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
        <mat-form-field fxFlex>
          <mat-label>{{ 'Country' | translate }}</mat-label>
          <mat-select formControlName="countryId">
            <mat-option *ngFor="let item of $countries | async" [value]="item.id">
              {{(item.name) | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>


      </div>


      <h3>User Account</h3>
      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="8px" fxLayoutGap.gt-md="16px">
        <mat-form-field fxFlex>
          <mat-label>{{ 'First Name' | translate }}</mat-label>
          <input matInput formControlName="userFirstName" />
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{ 'Last Name' | translate }}</mat-label>
          <input matInput formControlName="userLastName" />
        </mat-form-field>
      </div>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Phone' | translate }}</mat-label>
        <input matInput formControlName="userPhone" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'E-mail' | translate }}</mat-label>
        <input matInput type="email" formControlName="userEmail" />
        <mat-hint>It will be your username</mat-hint>
      </mat-form-field>
      <mat-form-field fxFlex hintLabel="{{'Needs to contain at least one lower, upper and one digit character.'}}">
        <mat-label>{{'Password' | translate}}</mat-label>
        <input matInput type="password" formControlName="userPassword" minlength="6"
          [type]="hide ? 'password' : 'text'"/>
        <button mat-icon-button type="button" matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
      [userPassword]="this.form.get('userPassword')">
      </app-password-strength>

      <mat-checkbox matInput color="primary" type="checkbox" formControlName="iAgree"
        [ngClass]="{'unselected': !form.get('iAgree').value}"><span
          [innerHTML]="( 'register.terms' | translate)"></span>
      </mat-checkbox>
      <button mat-raised-button fxFlex color="primary" [disabled]="!form.valid">{{'Register'| translate}}</button>
    </form>
  </div>
</div>