import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { of, Subscribable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { PageRequestInput } from '../_graphql/schema';


@Injectable({
  providedIn: 'root'
})
export class BaseService<ReturnT = any, ImportT = any> {
  pageIndex: number = 0;
  loading: boolean = false;
  selectOneFields!: DocumentNode;
  selectAllFields!: DocumentNode;
  selectOneQuery!: DocumentNode;
  selectQuery!: DocumentNode;
  createMutation!: DocumentNode;
  modifyMutation!: DocumentNode;
  deleteMutation!: DocumentNode;
  restoreMutation!: DocumentNode;
  publishMutation!: DocumentNode;

  queryParams: PageRequestInput = {
    showDeleted: false,
    query: '',
    sortBy: '',
    sortOrder: '',
    dateRange: null,
    statsRange: null,
    // isNonTesting: true,
    // startDate: null,
    // endDate: null,
    // companyStatuses: null,
    // isActive: null,
    // isVisible: null,
    skip: 0,
    take: 25
  };

  filters: any;

  totalCount = 0;
  public refetchAdditionalQueries: any = [];

  private nodeName!: string;
  private nodeNamePlural!: string;
  private listQuery!: QueryRef<any, any>;
  customQueryRef: QueryRef<any, any>;

  // private queryParams: any;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {

  }

  public initGql(nodeName: string, nodeNamePlural: string | null = null) {
    this.nodeName = nodeName;
    this.nodeNamePlural = nodeNamePlural ? nodeNamePlural : `${nodeName}s`;
    this.createQueries();
  }

  public createQueries() {
    this.selectOneQuery = gql`
    query ${this.nodeName}($id: ID!) {
      ${this.nodeName}(id: $id) {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;

    this.selectQuery = gql`
      query ${this.nodeNamePlural}($queryParams: PageRequestInput) {
        ${this.nodeNamePlural}(pageRequestInput: $queryParams) {
          data {
          ...SelectAllFields
          }
          totalCount
        }
      }
      ${this.selectAllFields}
      `;

    this.createMutation = gql`
    mutation add${this.camelNodeName()}($item: ${this.camelNodeName()}Input!) {
      create${this.camelNodeName()}(item: $item)
      {
        ...SelectOneFields 
      }
    }
    ${this.selectOneFields}
    `;

    this.modifyMutation = gql`
    mutation edit${this.camelNodeName()}($item: ${this.camelNodeName()}Input! ) {
      update${this.camelNodeName()}(item: $item)
      {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;

    this.deleteMutation = gql`
    mutation delete${this.camelNodeName()}($id: ID!) {
      delete${this.camelNodeName()}(id: $id) 
      {
        id
        deleted
      }
    }
    `;

    this.restoreMutation = gql`
    mutation restore${this.camelNodeName()}($id: ID!) {
      restore${this.camelNodeName()}(id: $id)
      {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;

    this.publishMutation = gql`
    mutation publish${this.camelNodeName()}($id: ID!) {
      publish${this.camelNodeName()}(id: $id)
      {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;
  }
  oneWQ: Subscribable<ReturnT>[] = [];
  public one(id: string, useCache = true): Subscribable<ReturnT> {
    this.loading = true;
    if (!this.oneWQ[id]) {
      console.log('Main subscriber  created for ONE ' + this.nodeName + ' ' + id)
      this.oneWQ[id] = this.apollo.watchQuery({
        query: this.selectOneQuery,
        variables: { id },
        fetchPolicy: useCache ? 'cache-first' : 'network-only'
      })
      // this.addRefetchQuery(this.selectOneQuery, { id: id})

    }
    return this.oneWQ[id].valueChanges.pipe(
      map((result: any) => {
        this.loading = false;
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }


        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        })),
    );

  }

  public setFilters(filters: any) {
    this.filters = filters;
  }
  allWQ = [];

  public all(filters: any = null, useCache = true): Subscribable<ReturnT[]> {
    this.loading = true;
    if (!this.allWQ[this.nodeName]) {
      this.allWQ[this.nodeName] = this.apollo.watchQuery({
        query: this.selectQuery,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        variables: { queryParams: this.queryParams, ...filters }
      });
      this.addRefetchQuery(this.selectQuery, { queryParams: this.queryParams })
    }
    return this.allWQ[this.nodeName].valueChanges.pipe(
      map((result: any) => {
        this.loading = false;
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          this.totalCount = result.data[keys[0]].totalCount || 0;
          return result.data[keys[0]].data;
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        })),
    );
  }

  dropdownWQ = [];
  public dropdown(filters: any = null, useCache = true): Subscribable<ReturnT[]> {
    this.loading = true;
    var qp = {
      showDeleted: false,
      skip: 0,
      take: 25
    }
    if (!this.dropdownWQ[this.nodeName]) {
      this.dropdownWQ[this.nodeName] = this.apollo.watchQuery({
        query: this.selectQuery,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        variables: { queryParams: qp, ...filters }
      });
      this.addRefetchQuery(this.selectQuery, { queryParams: qp })
    }
    return this.dropdownWQ[this.nodeName].valueChanges.pipe(
      map((result: any) => {
        this.loading = false;
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          this.totalCount = result.data[keys[0]].totalCount || 0;
          return result.data[keys[0]].data;
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        })),
    );
  }

  public query(query: DocumentNode, data: any = null, useCache = true): Subscribable<ReturnT[]> {
    this.customQueryRef = this.apollo.watchQuery({
      query: query,
      fetchPolicy: useCache ? 'cache-first' : 'network-only',
      variables: data
    })
    return this.customQueryRef.valueChanges.pipe(
      map((result: any) => {
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        })),
    );
  }

  public fetchMoreData() {
    this.allWQ[this.nodeName].fetchMore({
      variables: { offset: this.queryParams.skip, limit: this.queryParams.take }
    });
  }
  public fetchOne(id: string) {
    this.oneWQ[id]?.refetch();
  }

  public create(data: ImportT): Subscribable<ReturnT> {
    return this.apollo.mutate<ImportT>({
      mutation: this.createMutation,
      refetchQueries: [
        { query: this.selectQuery, variables: { queryParams: this.queryParams } },
        ...this.refetchAdditionalQueries
      ],
      variables: data
    }).pipe(
      map((result: any) => {
        if (!result || !result.data)
          return null;
        this.sb.open(this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1) + ' created!', 'OK', {
          duration: 2000
        });
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        }))
    );
  }

  public modify(data: ImportT): Subscribable<ReturnT> {
    return this.apollo.mutate({
      mutation: this.modifyMutation,
      refetchQueries: [

        ...this.refetchAdditionalQueries
      ],
      variables: data
    }).pipe(
      first(),
      map((result: any) => {
        if (!result || !result.data)
          return null;

        this.sb.open(this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1) + ' updated', 'OK', {
          duration: 2000
        });
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        }))
    );
  }

  public publish(data: any): Subscribable<ReturnT> {
    return this.apollo.mutate({
      mutation: this.publishMutation,
      refetchQueries: [
        { query: this.selectQuery, variables: { queryParams: this.queryParams } }
      ],
      variables: { id: data.id }
    }).pipe(
      first(),
      map((result: any) => {
        if (!result || !result.data)
          return null;
        this.sb.open(this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1) + ' is created!', 'OK', {
          duration: 2000
        });
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        }))
    );
  }
  public refetch(): Promise<any> {
    return this.apollo.client.refetchQueries({ include: 'active' })
  }
  public delete(data: any): Subscribable<ReturnT> {
    return this.apollo.mutate({
      mutation: this.deleteMutation,
      refetchQueries: [
        { query: this.selectQuery, variables: { queryParams: this.queryParams } },
        { query: this.selectOneQuery, variables: { id: data.id } },
        ...this.refetchAdditionalQueries
      ],
      variables: { id: data.id }
    }).pipe(
      first(),
      map((result: any) => {
        if (!result || !result.data)
          return null;
        this.sb.open(this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1) + ' deleted', 'OK', {
          duration: 2000
        });
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        }))
    );
  }

  public restore(data: any) {
    return this.apollo.mutate({
      mutation: this.restoreMutation,
      refetchQueries: [
        { query: this.selectQuery, variables: { queryParams: this.queryParams } },
        { query: this.selectOneQuery, variables: { id: data.id } },
        ...this.refetchAdditionalQueries
      ],
      variables: { id: data.id }
    });
  }

  public mutation(mutation: DocumentNode, data: any, update: any = null) {
    return this.apollo.mutate({
      mutation: mutation,
      refetchQueries: this.refetchAdditionalQueries,
      variables: data,
      update: update
    }).pipe(
      map((result: any) => {
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        catchError(error => {
          console.log(error);
          return of(null);
        }))
    );
  }

  public addRefetchQuery(query: DocumentNode, variables: any = null) {
    this.refetchAdditionalQueries.push(
      { query, variables }
    );
  }

  public setRefetchQueries(query: DocumentNode, variables: any = null) {
    this.refetchAdditionalQueries = [{ query, variables }];
  }

  public resetRefetchQueries() {
    this.refetchAdditionalQueries = [];
  }

  public showDeleted(show = true) {
    this.queryParams.showDeleted = show;
    this.queryParams.skip = 0;
    this.fetchMoreData();
  }

  public applyPager(pageIndex: number, pageSize: number) {
    this.pageIndex = pageIndex;
    this.queryParams.take = pageSize;
    this.queryParams.skip = pageIndex * pageSize;
    this.fetchMoreData();
  }

  private camelNodeName() {
    if (!this.nodeName || this.nodeName.length == 0)
      return '';

    return this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1);
  }

  public compareById(a: any, b: any) {
    return a.id == b.id;
  }
}
