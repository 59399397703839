<list-wrapper #wrapper [mainService]="service" navigateItem="sessions" title="Sessions"
  [readPerm]="auth.hasPermission(auth.perm.SessionRead)" [managePerm]="auth.hasPermission(auth.perm.SessionCreate)"
  [showDateFilter]="true">

  <form toolbox [formGroup]="form" class="form-layout io-gap">
    <mat-form-field appearance="outline">
      <mat-label>{{'Check status'|translate}}</mat-label>
      <mat-select formControlName="checkStatuses" multiple>
        <mat-option *ngFor="let item of checkStatuses" [value]="item">
          <app-check-status [status]="item"></app-check-status> {{('checkStatus_'+item)|translate}}
        </mat-option>
        <mat-select-trigger>{{form.value.checkStatusFilter?.length }} selected</mat-select-trigger>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'Session status'|translate}}</mat-label>
      <mat-select formControlName="sessionStatuses" multiple>
        <mat-option *ngFor="let item of statuses | keyvalue" [value]="item.value">
          <app-session-status [status]="item.value"></app-session-status> {{('sesionStatus_'+item.value)|translate}}
        </mat-option>
        <mat-select-trigger>{{form.value.sessionStatuses?.length }} selected</mat-select-trigger>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Check type' | translate}}</mat-label>
      <mat-select [formControl]="checkType" (selectionChange)="filterCheckType()">
        <mat-option value="isPerson">
          {{'Person' | translate}}
        </mat-option>
        <mat-option value="isBusiness">
          {{'Business' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="checkBoxContainer">
      <!-- <mat-checkbox class="hidden" formControlName="isPerson" matTooltip="{{'Person Checks' | translate}}">
        <mat-icon [ngClass]="form.value.isPerson ? 'checkboxIconChecked' : 'checkboxIcon' "
          style="border-radius: 5px 0px 0px 5px;">perm_identity</mat-icon>
      </mat-checkbox>
      <mat-checkbox class="hidden" formControlName="isBusiness" matTooltip="{{'Business Checks' | translate}}">
        <mat-icon [ngClass]="form.value.isBusiness ? 'checkboxIconChecked' : 'checkboxIcon' ">work_outline</mat-icon>
      </mat-checkbox> -->
      <mat-checkbox class="hidden" formControlName="isTesting" matTooltip="{{'Testing Checks' | translate}}">
        <mat-icon [ngClass]="form.value.isTesting ? 'checkboxIconChecked' : 'checkboxIcon' "
        style="border-radius: 5px 0px 0px 5px;">science_off</mat-icon>
      </mat-checkbox>
      <mat-checkbox class="hidden" formControlName="isMonitoring" matTooltip="{{'Monitoring Checks' | translate}}" 
      *ngIf="checkType.value !== 'isBusiness'">
        <mat-icon [ngClass]="form.value.isMonitoring ? 'checkboxIconChecked' : 'checkboxIcon' ">
          track_changes_filled</mat-icon>
      </mat-checkbox>
      <mat-checkbox checked="true" class="hidden" formControlName="isNonTesting" matTooltip="{{'Non-Testing Checks' | translate}}">
        <mat-icon [ngClass]="form.value.isNonTesting ? 'checkboxIconChecked' : 'checkboxIcon' ">
          ballot</mat-icon>
      </mat-checkbox>
      <mat-checkbox class="hidden" formControlName="isPendingCheck" matTooltip="{{'Pending Checks' | translate}}">
        <mat-icon [ngClass]="form.value.isPendingCheck ? 'checkboxIconChecked' : 'checkboxIcon' "
          style="border-radius: 0px 5px 5px 0px;">access_time_filled</mat-icon>
      </mat-checkbox>
    </div>
    <button mat-button style="min-width:auto;" color="primary" (click)="resetFilters()">
      {{'Clear' | translate}}
    </button>
  </form>
  <form [formGroup]="form" toolbox2>
    <app-range-picker formControlName="dateRange"></app-range-picker>
  </form>
  <div toolbox2 style="gap: 8px">
    <div style="padding-top: 3px" *ngIf="auth.hasPermission(auth.perm.SessionCreate) && showImportBtn">
      <a mat-stroked-button routerLink="/company/sessions/bulk-import">
        {{"Bulk Import" | translate}}
      </a>
    </div>
  </div>
  <!--(_configuration.checks | getKeysOf:['providerCheck', 'sourceType']) == 'BUSINESS' -->
  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>

      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' | translate}}</div>
      </div>
      <div fxFlex="110px" fxHide.lt-md>
        <div mat-line>{{'Code'| translate }}</div>
      </div>
      <div fxFlex="210px" fxHide.lt-md>
        <div mat-line>{{'Config'| translate }}</div>
      </div>
      <div fxFlex="36px">
      </div>
      <div fxFlex="200px" fxHide.lt-md>
        <div mat-line>{{'Checks'| translate }}</div>
      </div>
      <div fxFlex="140px" fxHide.lt-md>
        <div mat-line>{{'Created'| translate }}</div>
      </div>
      <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <div mat-line>{{'Price' | translate}}</div>
      </div>
      <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <div mat-line>{{'Cost'|translate}}</div>
      </div> -->
      <div fxFlex="54px"></div>
    </h3>
    <app-session-list-item *ngFor="let item of data$|async; index as i"
      (click)="wrapper.edit('/company/sessions/'+item.id)" [item]="item"></app-session-list-item>
    <!-- <mat-list-item *ngFor="let item of ($data | async) as items; index as i"
      (click)="wrapper.edit('/company/sessions/'+item.id)">
      <app-check-status matListIcon [status]="item?.overallCheckStatus">
      </app-check-status>


      <h3 mat-line>
        {{ item?.persons?.length > 0 ? (item.persons[0].firstName + ' ' + item.persons[0].lastName) : '' }}
        {{ item?.businesses?.length > 0 ? item.businesses[0].companyName : '' }}
      </h3>
      <small mat-line>
        {{item?.description}}</small>
      <div fxFlex="110px" fxHide.lt-md class="text-center">
        <div mat-line><code *ngIf="item.code">{{item?.code }}</code></div>
      </div>
      <div fxFlex="36px">
        <mat-icon *ngIf="item.persons?.length">perm_identity</mat-icon>
        <mat-icon *ngIf="item.businesses?.length">work_outline</mat-icon>
      </div>


      <div fxFlex="210px" fxHide.lt-md>
        <div mat-line>{{item.configuration?.name}}</div>
      </div>
      <div fxFlex="200px" fxLayout="row" fxHide.lt-md>
        <ng-container *ngFor="let check of item?.checks; index as i;">
          <div class="checks" fxLayout="row" fxLayoutAlign="start center" *ngIf="i<3">
            <app-check-type [checkType]="check.checkType" matTooltip="{{('checkType_'+check.checkType)|translate}}">
            </app-check-type>
            <small *ngIf="check?.matches">{{check?.matches}}</small>
          </div>
          <small fxLayout="row" fxLayoutAlign="start center"
            *ngIf="(i == item.checks.length-1) && item.checks.length>2">+ {{item?.checks.length+1 - 3}} more</small>
        </ng-container>
      </div>

      <div fxFlex="140px" fxHide.lt-md>
        <div mat-line>
          {{item?.user?.firstName}} {{item?.user?.middleName}} {{item?.user?.lastName}}
        </div>
        <small mat-line>{{ item.created| date:'short' }}</small>
      </div>

      <div fxFlex="54px" style="padding-left: 8px;">
        <mat-icon *ngIf="item.isTesting" matTooltip="{{'session_testing' |translate}}">science</mat-icon>
      </div>

      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item> -->

  </mat-action-list>
</list-wrapper>