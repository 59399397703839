<item-wrapper #item [mainService]="service" [form]="form" navigateList="sanctions" title="Sanction List"
  [readPerm]="auth.hasPermission(auth.perm.XRayProviderRead)"
  [managePerm]="auth.hasPermission(auth.perm.XRayProviderManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>{{ 'Source'|translate }}</mat-label>
      <mat-select formControlName="source">
        <mat-option *ngFor="let databaseSource of databaseSources" [value]="databaseSource">
          {{('databaseSource_'+databaseSource) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'File URL' | translate }}</mat-label>
      <input matInput formControlName="fileUrl" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'More Info' | translate }}</mat-label>
      <input matInput formControlName="moreInfo" />
    </mat-form-field>
  </form>
</item-wrapper>