<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" [ngClass]="{'wrapper':true }">
  <div class="container">
    <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 36px;">
      <button mat-icon-button color="primary" (click)="backClicked()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="text-center" fxFlex>
        <a [routerLink]="['/']"><img src="/assets/images/logo.svg" class="logo"></a>
      </div>
      <button mat-icon-button color="primary" style="opacity: 0; visibility: hidden;">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>Terms &amp; Conditions<small>Last updated on 2022-03-21</small></h1>
    <p>These XRAY Terms & Conditions (the <strong>&quot;Agreement&quot;</strong>) is a legal agreement between Tango
      AML AB
      (<strong>&quot;Tango&quot;</strong>) and the
      entity or person (the <strong>&quot;Customer&quot;</strong>) who applies to use Tango’s service XRAY. This
      Agreement
      describes the terms and conditions that apply to your use of the Service.</p>
    <p>If you do not understand any of the terms of this Agreement, please contact us before applying to use our
      Services.</p>
    <p>When applying to use our service you confirm that you accept these terms and conditions and that you agree to
      comply with them.</p>
    <h2>1. Background</h2>
    <p>1.1 Tango offers an identification and verification service called XRAY. </p>
    <p>1.2 XRAY aggregates a collection of data providers with over 400 data sources such as Governments, banks,
      business registers and biometrics. In 195 different countries of the world, customers are verified for identity
      status, real-time biometrics and facial recognition, PEP, sanctions and watchlists name detection, reputation, and
      other relevant variables for specific laws compliance.</p>
    <p>1.3 XRAY offers internal audit and assessment of your AML licensing and procedures to ensure compliance. </p>

    <h2>2. Features</h2>
    <p>2.1 XRAY features consists of:</p>
    <ol>
      <li><strong>Online ID Verification:</strong>
        <p>More than 8,000 document types worldwide, including passports and invoices can be scanned in real time for
          customer
          identification to make sure that &quot;you are you&quot;.</p>
      </li>
      <li>
        <strong>Electronic ID Verification:</strong>
        <p>E-ID’s (e.g., Bank-ID in Sweden or MitID in Denmark) are used for online identifications to sign agreements,
          transfer money or other conducts requiring KYC-compliance.</p>
      </li>
      <li>
        <strong>Business Verification:</strong>
        <p>Businesses are verified through data from Government registers, criminal watchlists and other verification
          services.
          This is also used to determine the ultimate beneficiaries.</p>
      </li>
      <li>
        <strong>PEP, Watchlist & Sanctions Check:</strong>
        <p>XRAY investigates through the network lists of the UN, OFAC, EU, HMT, DFAT, Interpol, Police forces,
          Government and State agencies to screen against AML.</p>
      </li>
      <li>
        <strong>Real-time AML-Checks:</strong>
        <p>XRAY accesses real-time updated networks with watchlists and sanction lists to keep the dirty money out of
          the economy. The system flags risks on a global scale.</p>
      </li>
      <li>
        <strong>Income Analysis:</strong>
        <p>The system covers hundreds of banks in seven countries for getting primary and secondary income reports in
          real time.</p>
      </li>
      <li>
        <strong>Phone number validation:</strong>
        <p>Phone number validation is a trusted form of two-factor authentication. It provides an additional security
          layer for fraud checks.</p>
      </li>
      <li>
        <strong>Behaviour and Reputation Check:</strong>
        <p>XRAY investigates deeper on linkages and connections of a customer with suspicious behaviours of terrorism,
          money laundering and other high risks.</p>
      </li>
      <li>
        <strong>Open Banking for Identity Verification:</strong>
        <p>The system verifies bank accounts for buyer proof of identity and bank account owner. With access to trusted
          banks, e-commerce is more secure against money laundering.</p>
      </li>
    </ol>
    <h2>3. The Customer’s commitments</h2>
    <p>3.1 When using the Service, the Customer confirms that he or she has a legitimate interest to do so.
    <p>3.2 When using or applying to use the Service, the Customer agrees to leave information about him or herself and
      about a third party to which the use of the Service relates to, such as, but not limited to, name, telephone
      number, personal ID number, corporate identification number, gender, age, e-mail address, home address and
      domicile.</p>
    <p>3.3 The Customer also confirms when using or applying to use the Service to comply with XRAY’s Privacy Policy
      which explains how the data collected in clause 3.2 is processed, as well as understanding his or her rights under
      the Privacy Policy.</p>
    <p>3.4 The Customer also confirms that the information provided from the Service will not be used in a deceptive
      manner, or with the intent to cause harm for others by spreading this information to, for example, outside the
      work sphere in social media platforms, such as Facebook, Instagram, YouTube, Telegram, TikTok, Twitter, or other
      public websites.</p>
    <p>3.5 Tango is under no circumstances responsible or liable for any harm, may it be economic or other, that may be
      caused from spreading the information received from the Service to third parties, social media platforms, or other
      public websites.</p>
    <h2>4. Tango’s commitments</h2>
    <p>4.1 Tango undertakes through the Service to verify, check, and analyse the information provided by the Customer
      when using the Service. The time for receiving a verification, check or analysis may take no longer than a couple
      of minutes.</p>
    <p>4.2 Tango is responsible for ensuring that all the information received from the Service is accurate and only
      comes from trustworthy sources such as Government registers, criminal watchlists, network lists such as the UN,
      OFAC, EU, HMT, DFAT, Interpol and Police forces and banks.</p>

    <h2>5. Prices and Payment terms</h2>
    <p>5.1 XRAY Services are bought through subscription packages which include a limited number of searches in XRAY
      per month where the prices vary depending on what is agreed between Tango and the Customer. </p>
    <p>5.2 The Customer is responsible for ensuring that the payment can be made for using the Service depending on
      which payment option the Customer chooses. </p>

    <h2>6. Availability of the Service</h2>
    <p>6.1 Tango always strives to have a high availability of the Service. To ensure high availability, Tango
      continuously maintains and updates the software. Maintenance that can lead to downtime for shorter periods.</p>
    <p>6.2 Tango is under no circumstances responsible for damage, be it economic or other, due to the Service being
      unavailable due to testing, updating, maintaining, or configuration. </p>

    <h2>7. Laws and regulations</h2>
    <p>7.1 Tango AML AB is a company registered in Sweden subject to Swedish law and complies with all legislation,
      regulation, directives, and decisions applicable to it.</p>
    <p>7.2 Tango assumes no responsibility for the legality of the Customer’s use of the Service or the content of the
      data received by the Customer in connection with using the service. It is entirely up to the Customer to comply
      with legislation towards its own customers or towards third parties.</p>

    <h2>8. Rights</h2>
    <p>8.1 Tango has all rights, including intellectual property rights, to trademarks and other immaterial assets made
      available to the Customer in connection with the use of the Service.</p>
    <p>8.2 The Customer’s breach of these rights is considered a material breach of contract and the Customer will
      immediately be suspended from further use of the Service.</p>
    <p>8.3 If Tango considers the material breach as severe to that extent that it may have cost an economic loss, Tango
      reserves the right to take further legal actions in accordance with clause 12.</p>
    <p>8.4 The rights of using the service cannot be transferred to a third party, unless that party is a part of the
      same company, or the same corporate group and the Service is used by the company or the corporate group together.
    </p>


    <h2>9. The period of validity of the Agreement and ground for termination</h2>
    <p>9.1 This agreement is valid from the time when the Customer is given access to use the Service until further
      notice is given. The Customer has the right to end the subscription package at any time without giving a reason to
      do so. </p>
    <p>9.2 Tango has the right to terminate this agreement and suspend the Customer from any further use of the service
      if:</p>
    <ol>
      <li>the Customer refuses to comply with the conditions set out in clause 3,</li>
      <li>the Customer is unable or unwilling to pay for further use of the Service,</li>
      <li>the Customer’s use of the service is in violation with clause 8.1</li>
    </ol>
    <p>9.3 Tango reserves the right to change these Terms & Conditions at any given time and when doing so the Customer
      will be notified via e-mail. If the Customer does not agree with the new Terms & Conditions, the Customer has the
      right to end the subscription in accordance with clause 9.1.</p>

    <h2>10. Limitation of Liability</h2>
    <p>10.1Tango will not be liable for any loss or damage, whether in contract, breach of regulatory duty, or
      otherwise,
      arising under or in connection with the use of, or inability to use, the Service. This includes:</p>
    <ul>
      <li>loss of profits, sales, business, or revenue,</li>
      <li>business interruption,</li>
      <li>loss of anticipated savings,</li>
      <li>loss of business opportunity, goodwill, or reputation, or</li>
      <li>any indirect or consequential loss or damage.</li>
    </ul>

    <h2>11. Force Majeure</h2>
    <p>11.1 Both Tango and the Customer are exempted from fulfilling any obligation set out in these Terms & Conditions
      if the failure is based on circumstances beyond reasonable control and which prevent or significantly impede
      timely performance thereof (&quot;Force Majeure&quot;).</p>
    <p>11.2 Such circumstances may be war, Government intervention, burglary, seizures, general restrictions, labour
      conflict, major accidents, extreme weather conditions, restrictions on energy supply, non-authorisations, or
      delivery from sub-contractors that could not reasonably have been foreseen.</p>

    <h2>12. Applicable Law and Disputes</h2>
    <p>12.1 These Terms & Conditions and any dispute arising out of or in connection with it will be governed by and
      interpreted in accordance with the laws of Sweden.</p>
    <p>12.2 Any dispute, controversy, or claim concerning these Terms & Conditions, its execution, interpretation and/or
      application or other legal relationship related to these Terms & Conditions shall in first instance be resolved in
      good faith through negotiations between Tango and the Customer. </p>
    <p>12.3 Disputes that arise in connection with these Terms & Conditions, and which could not be resolved within 30
      calendar days through discussions between Tango and the Customer, shall be finally settled through arbitration
      proceedings administered by the Stockholm Chamber of Commerce’s Arbitration Institute (&quot;SCC&quot;). </p>

  </div>
</div>