import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable, take } from 'rxjs';
import { Feature, Product } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { FeaturesService } from 'src/app/_services/features.service';
import { ProductFeaturesService } from 'src/app/_services/product-features.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-products-feature-item',
  templateUrl: './products-feature-item.component.html',
  styleUrls: ['./products-feature-item.component.scss']
})
export class ProductsFeaturesItemComponent implements OnInit {
  data$: Subscribable<Product>;
  $features: Subscribable<Feature[]>;
  $products: Subscribable<Product[]>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  form = this.fb.group({
    id: [null],
    productId: [null, Validators.required],
    featureId: [null, Validators.required],
  });
  constructor(
    private _ngZone: NgZone,
    private fb: FormBuilder,
    public auth: AuthService,
    public service: ProductFeaturesService,
    public productsService: ProductsService,
    public featuresService: FeaturesService,
  ) {
    productsService.queryParams.take = 25000;
    featuresService.queryParams.take = 25000;

    this.$products = this.productsService.all();
    this.$features = this.featuresService.all();


  }


  ngOnInit(): void {
  }

}
