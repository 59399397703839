import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CompanyChangeStatusDialogComponent, CompanyChangeStatusDialogComponentData } from '../components/dialog/company-change-status-dialog/company-change-status-dialog.component';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../components/dialog/confirmation-dialog/confirmation-dialog.component';
import { NotesDialogComponent, NotesDialogComponentData } from '../components/dialog/notes-dialog/notes-dialog.component';
import { SetPaymentMethodDialogComponent, SetPaymentMethodDialogComponentData } from '../components/dialog/set-payment-method-dialog/set-payment-method-dialog.component';
import { Company } from '../_graphql/schema';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public snacks: Subject<string>;
  public loaders: Subject<boolean>;
  public loading = false;
  public menuSideNav: MatSidenav;
  public menuHasBack = false;
  private loadingCount = 0;
  public _appTitle = "";

  constructor(
    private appTitle: Title,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private tt: TranslateService,
  ) {
    this.snacks = new Subject<string>();
    this.loaders = new Subject<boolean>();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  snack(message: string) {
    this.tt.get(message).subscribe(msg => {
      this.openSnackBar(msg, "OK")
      this.snacks.next(msg);
    })
  }

  loaderShow() {
    this.loaders.next(true);
    this.loading = true;
  }

  loaderHide(successMsg?: string) {
    this.loaders.next(false);
    this.loading = false;
    if (successMsg) {
      this.snacks.next(successMsg);
    }
  }
  loaderStop() {
    this.loading = false;
    this.loadingCount = 0;
    this.loaders.next(false);
  }

  menuToggle() {
    this.menuSideNav.toggle();
  }

  getBack() {

  }

  setTitle(title: string) {
    this._appTitle = title;
    this.appTitle.setTitle(title);
  }

  title() {
    return this._appTitle;
  }

  getLastNote(notes: string) {
    if (!notes || notes == "")
      return "";
    try {
      var _notes = notes.split("||");
      if (_notes.length > 0) {
        var lastNote = _notes[_notes.length - 2];
        return lastNote.split('</small> ')[1].replace("</p>", "");
      } else {
        return "";
      }
    } catch {
      return "wrong string format for notes..."
    }
  }

  openConfirmationDialog(data: ConfirmationDialogData): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: data,
    });
    return dialogRef;
  }

  openCompanyChangeStatusDialog(data: CompanyChangeStatusDialogComponentData): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(CompanyChangeStatusDialogComponent, {
      width: '450px',
      data: data,
    });
    return dialogRef;
  }
  openAdminNotesDialog(company: Company): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      width: '450px',
      data: <NotesDialogComponentData>{ company },
    });
    return dialogRef;
  }
  openCompanySetPaymentMethod(data: SetPaymentMethodDialogComponentData): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(SetPaymentMethodDialogComponent, {
      width: '450px',
      data: data,
    });
    return dialogRef;
  }
}
