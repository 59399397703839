import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { CompanyItemComponent } from './company-item.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    CompanyItemComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    WrappersModule,
  ],
  exports: [

  ]
})
export class CompaniesModule { }
