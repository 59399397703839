import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'company-row-item',
  templateUrl: './company-row-item.component.html',
  styleUrls: ['./company-row-item.component.scss']
})
export class CompanyRowItem implements OnInit {
  @Input('item') item: any;

  constructor() { }

  ngOnInit(): void {

  }


}
