<item-wrapper #item [mainService]="service" [form]="form" navigateList="products" title="Product"
  [readPerm]="auth.hasPermission(auth.perm.ProviderRead)" [managePerm]="auth.hasPermission(auth.perm.ProviderManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Product Details
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxFlex fxLayout="column">
        <div fxLayoutGap="16px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'Name' }}</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
          <mat-form-field fxFlex="15%">
            <mat-label>{{ 'Icon' }}</mat-label>
            <input matInput formControlName="icon" />
          </mat-form-field>
          <mat-form-field fxFlex="15%">
            <mat-label>{{ 'Order' }}</mat-label>
            <input matInput type="number" formControlName="order" />
          </mat-form-field>
        </div>
        <mat-form-field fxFlex>
          <mat-label>{{ 'Description' }}</mat-label>
          <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{ 'Alternate description' |translate }}</mat-label>
          <textarea matInput formControlName="alternateDescription" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
        <div fxLayoutGap="16px">
          <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
            <mat-label>{{ 'Payment Interval' }}</mat-label>
            <mat-select formControlName="paymentOption">
              <mat-option *ngFor="let item of ['MONTHLY', 'PAY_AS_YOU_GO']" [value]="item">
                {{('paymentOption_'+item) | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
            <mat-label>{{ 'Old Price' }}</mat-label>
            <input matInput formControlName="oldPrice" type="number" />
            <button mat-icon-button matSuffix disabled>
              <mat-icon>euro</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
            <mat-label>{{ 'Price' }}</mat-label>
            <input matInput formControlName="price" type="number" />
            <button mat-icon-button matSuffix disabled>
              <mat-icon>euro</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <mat-checkbox color="primary" matInput type="checkbox" formControlName="isDefault">Is default</mat-checkbox>
        <mat-checkbox color="primary" matInput type="checkbox" formControlName="isDisabled">Is disabled</mat-checkbox>
        <mat-checkbox color="primary" matInput type="checkbox" formControlName="isVisible">Is visible</mat-checkbox>

      </div>
    </mat-expansion-panel><br />
    <h3 fxLayout="row" fxLayoutAlign="start center">
      <span>{{'Product Checks' | translate}} ({{ productChecks?.controls?.length}})</span>
      <button mat-icon-button color="primary" [matMenuTriggerFor]="productsMenu"
        *ngIf="($providerChecks| async)?.length != productChecks?.controls?.length">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <mat-menu #productsMenu="matMenu">
        <button mat-menu-item *ngFor="let item of ($providerChecks| async | filterOn: 'id': ((
          this.form.get('productChecks').value?.length ?
          (this.form.get('productChecks').value | getKeysOf:['providerCheckId'])
          :
          ['bla']
          )):'exclude')" (click)="addProductCheck(item.id)">{{item?.name}}</button>
      </mat-menu>
    </h3>
    <mat-card *ngIf="productChecks?.controls?.length == 0">
      No product checks added. Use button above to add them.
    </mat-card>

    <mat-accordion formArrayName="productChecks">
      <div *ngFor="let _check of productChecks?.controls; let idx = index">
      <mat-expansion-panel [formGroupName]="idx" 
      *ngIf="($providerChecks|async |filterOn:'id':[this.productChecks.at(idx).get('providerCheckId').value])?.length">
        <mat-expansion-panel-header>
          <mat-panel-title *ngIf="this.productChecks.at(idx)?.get('name')?.value">
            <span
              *ngIf="(this.productChecks.at(idx)?.get('name')?.value) === 
              ($providerChecks|async |filterOn:'id':[this.productChecks.at(idx).get('providerCheckId').value])[0]?.name">
              {{this.productChecks.at(idx)?.get('name')?.value}}
            </span>
            <span
              *ngIf="(this.productChecks.at(idx)?.get('name')?.value) !== 
              ($providerChecks|async |filterOn:'id':[this.productChecks.at(idx).get('providerCheckId').value])[0]?.name">
              {{this.productChecks.at(idx)?.get('name')?.value + ' (' + 
              ($providerChecks|async |filterOn:'id':[this.productChecks.at(idx).get('providerCheckId').value])[0]?.name
              +')'}}
            </span>
          </mat-panel-title>
          <mat-panel-title *ngIf="!this.productChecks.at(idx)?.get('name')?.value">
            <span
              *ngIf="($providerChecks|async |filterOn:'id':[this.productChecks.at(idx).get('providerCheckId').value])[0]?.name">
              {{($providerChecks|async |filterOn:'id':[this.productChecks.at(idx).get('providerCheckId').value])[0]?.name}}
            </span>
          </mat-panel-title>
          <mat-panel-description>
            <span *ngIf="this.productChecks.at(idx)?.valid">
              <span *ngIf="this.productChecks.at(idx).get('includedChecks').value as pc;">
                {{ pc }} {{pc > 1 ? 'checks' : 'check'}} included</span><span
                *ngIf="this.productChecks.at(idx).get('includedChecks').value > 0 && this.productChecks.at(idx).get('nextPrice')?.valid">,
                then
              </span><span *ngIf="this.productChecks.at(idx).get('nextPrice')?.valid"> {{
                this.productChecks.at(idx).get('nextPrice').value | currency }}
                {{('paymentInterval_'+this.productChecks.at(idx).get('paymentInterval').value) | translate | lowercase
                }}
              </span>
            </span>
            <span class="warning" *ngIf="!this.productChecks.at(idx)?.valid">
              Enter product check details to continue
            </span>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div fxLayoutGap="16px" fxFlex>
            <mat-form-field fxFlex>
              <mat-label>{{ 'Name' }}</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
            <mat-form-field fxFlex="20%">
              <mat-label>{{ 'Payment Interval' }}</mat-label>
              <mat-select formControlName="paymentInterval">
                <mat-option *ngFor="let item of paymentIntervals |keyvalue" [value]="item.value">
                  {{('paymentInterval_'+item.value) | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="20%">
              <mat-label>{{ 'Included Checks' }}</mat-label>
              <input matInput formControlName="includedChecks" type="number" step="1" />
            </mat-form-field>
            <mat-form-field fxFlex="20%">
              <mat-label>{{ 'Next Price' | translate}}</mat-label>
              <input matInput formControlName="nextPrice" type="number" step="0.01" />
              <button mat-icon-button matSuffix disabled>
                <mat-icon>euro</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <mat-form-field fxFlex>
            <mat-label>{{ 'Description' }}</mat-label>
            <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>{{ 'Provider Check' |translate }}</mat-label>
            <mat-select formControlName="providerCheckId" disabled>
              <mat-option *ngFor="let item of $providerChecks|async" [value]="item.id">
                {{(item.name) | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-action-row>
          <button mat-button color="warn" (click)="deleteProductCheck(idx)">{{'Delete'|translate}}</button>
          <div fxFlex></div>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
    </mat-accordion>


    <br />
    <h3 fxLayout="row" fxLayoutAlign="start center">
      <span>{{'Features' | translate}} ({{features?.controls?.length}})</span>
      <button mat-icon-button color="primary" [matMenuTriggerFor]="featuresMenu"
        *ngIf="($features| async)?.length != features?.controls?.length">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <mat-menu #featuresMenu="matMenu">
        <button mat-menu-item (click)="addFeature(item)" *ngFor="let item of ($features| async | filterOn: 'id': ((
          this.form.get('features').value?.length ?
          (this.form.get('features').value | getKeysOf:['id'])
          :
          ['bla']
          )):'exclude')">{{item.name}}
        </button>
      </mat-menu>
    </h3>
    <mat-card *ngIf="features?.controls?.length == 0">
      No product features added. Use button above to add them.
    </mat-card>

    <mat-accordion formArrayName="features">
      <mat-expansion-panel *ngFor="let item of features?.controls; let idx = index" [formGroupName]="idx">
        <mat-expansion-panel-header>
          <mat-panel-title>

            <span *ngIf="$features|async |filterOn:'id':[this.features.at(idx).get('id').value] as pc">
              {{pc[0]?.name}}
            </span>
          </mat-panel-title>
          <mat-panel-description>


          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-action-row>
          <button mat-button color="warn" (click)="deleteFeature(idx)">{{'Remove'|translate}}</button>
          <div fxFlex></div>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
  <!-- <h3>Form Form</h3>
  <pre>{{ form.value |json }}</pre>
  <h3>Form GQL</h3>
  <pre>{{ product |json }}</pre>
  <pre>{{($providerChecks|async)|json}}</pre> -->
</item-wrapper>