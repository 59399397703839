import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base.service';
import { Language } from '../_graphql/schema';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService extends BaseService<Language> {
  selectOneFields = gql`
  fragment SelectOneFields on Language {
    id name isoCode2 isoCode3
  }`;

  selectAllFields = gql`
  fragment SelectAllFields on Language {
    id name isoCode2 isoCode3
  }`;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql('language');
  }
}