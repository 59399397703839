import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscribable } from 'rxjs/internal/types';
import { Company, CompanyToken } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companies.service';
import { TokensService } from 'src/app/_services/tokens.service';

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {
  $data: Subscribable<CompanyToken[]>;
  company$: Subscribable<Company>;

  constructor(
    public tokens: TokensService,
    public companies: CompaniesService,
    public auth: AuthService,
  ) {
    this.company$ = this.companies.currentCompany();
    // this.tokens.queryParams.sortOrder = "DESC";
    // this.tokens.queryParams.sortBy = "created";
  }

  calcStatus(item) {
    var _expires = moment(item.expiration);
    var _now = moment();

    if (!item.isAllowed
    ) {
      return 'notallowed';
    }
    if (_expires.isBefore(_now)) {
      return 'expired';
    }
    if (_expires.isAfter(_now.add(15, 'day'))) {
      return 'active';
    } else {
      return 'expires';
    }
  }

  ngOnInit(): void {
    this.$data = this.tokens.all();
  }
}
