import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable, take } from 'rxjs';
import { Company, ConfigApplication, ResponseTransport } from 'src/app/_graphql/schema';
import { ApplicationsService } from 'src/app/_services/applications.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ProvidersConfigurationService } from 'src/app/_services/providers-configurations.service';

@Component({
  selector: 'app-application-item',
  templateUrl: './application-item.component.html',
  styleUrls: ['./application-item.component.scss']
})
export class ApplicationItemComponent implements OnInit {
  $data: Subscribable<ConfigApplication>;
  companies$: Subscribable<Company[]>;
  providers$: Subscribable<any[]>;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    // companyId: [null, Validators.required],
    description: [null, []],
    matchingFormula: [null, []],
    pricePerCheck: [null, [Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]],
  });


  constructor(
    private _ngZone: NgZone,
    public applications: ApplicationsService,
    public auth: AuthService,
    public providers: ProvidersConfigurationService,
    private fb: FormBuilder,
  ) {

  }

  public get ResponseTransport() {
    return ResponseTransport;
  }

  ngOnInit(): void {
    this.providers$ = this.providers.all();
    this.form.patchValue({ companyId: this.auth.companyId })
  }
}
