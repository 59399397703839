<public-wrapper mini="true">
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch" (submit)="login()">
    <mat-form-field fxFlex>
      <mat-label>{{ 'Username' | translate }}</mat-label>
      <input matInput type="email" formControlName="username" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'Password' | translate}}</mat-label>
      <input matInput type="password" formControlName="password" minlength="4" [type]="hide ? 'password' : 'text'"
        (keyup.enter)="login()" />
      <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <button mat-raised-button fxFlex color="primary" [disabled]="loading || !form.valid">{{'Login'| translate}}</button>
    <button mat-button fxFlex type="button" style="margin: 8px 0" routerLink="/forgot-password">{{'Forgot password?'|
      translate}}</button>
    <p innerHTML="{{'login_register_text'|
    translate}}"></p>

  </form>
</public-wrapper>