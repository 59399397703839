import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { TranslationsComponent } from './translations.component';
import { AppTranslateModule } from 'src/app/components/translations/app-translate.module';

@NgModule({
  declarations: [TranslationsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
    AppTranslateModule
  ]
})
export class TranslationsModule { }
