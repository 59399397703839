import { Component, Input, OnInit } from '@angular/core';
import { Document } from 'src/app/_graphql/schema';
import { DocumentsService } from 'src/app/_services/documents.service';


@Component({
  selector: 'app-view-documents',
  templateUrl: './view-documents.component.html',
  styleUrls: ['./view-documents.component.scss']
})
export class ViewDocumentsComponent implements OnInit {
  @Input('documents') documents: Document[] = null;

  constructor(
    private documentService: DocumentsService,

  ) { }
  ngOnInit(): void {
  }

  downloadDocument(fileId:string, name:string) {
    this.documentService.downloadFile(fileId, name);
  }
}
