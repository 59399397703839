import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UiService } from 'src/app/_services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  form: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private as: AuthService,
    private router: Router,
    private ui: UiService,
    private snackBar: MatSnackBar,
  ) {
    if (window['_doreload']) {
      window.location.reload()
    }
    this.form = fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.ui.setTitle("XRAY")
  }

  login(): void {
    this.loading = true;
    var user = Object.assign({}, this.form.value)
    this.as.login(user.username, user.password).subscribe({
      next: (res: any) => {
        this.loading = false;
        if (res && res.data?.loginUser) {
          console.log(res)
          console.log(JSON.stringify(res))
          this.as.storeUser(res.data?.loginUser);
          if (res.data.loginUser.roles.indexOf('User') != -1 || res.data.loginUser.roles.indexOf('CompanyAdmin') != -1) {
            this.router.navigateByUrl('/redirect');
          } else {
            this.router.navigateByUrl('/admin/companies');
          }

        }
      }, error: error => {
        this.snackBar.open(error, 'Got it', {
          duration: 3000,
        });
        this.loading = false;
        this.ui.snack(error.message);
      }
    });
  }
}
