import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { FieldSet, Provider } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { FieldsetsService } from 'src/app/_services/fieldsets.service';
import { ProvidersService } from 'src/app/_services/providers.service';

@Component({
  selector: 'app-fieldsets',
  templateUrl: './fieldsets.component.html',
  styleUrls: ['./fieldsets.component.scss']
})
export class FieldsetsComponent implements OnInit {

  data$: Subscribable<FieldSet[]>;

  constructor(
    public auth: AuthService,
    public service: FieldsetsService,) {
  }

  ngOnInit(): void {
    this.data$ = this.service.all();
  }

}
