import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { DocumentType } from 'src/app/_graphql/schema';
@Component({
  selector: 'business-documents-form-item',
  templateUrl: './business-documents-form-item.component.html',
  styleUrls: ['./business-documents-form-item.component.scss']
})
export class BusinessDocumentsFormItem implements OnInit {
  @Input('fg') fg: FormArray;
  documentTypes = DocumentType;

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder
  ) {
  }
  ngOnInit(): void {


  }

  addLine(): void {
    var line = this.fb.group({
      number: [null, []],
      type: [null, [Validators.required]],
      expiry: [null, []],
    });
    this.fg.push(line);
  }
  removeLine(index: number) {

    if (this.fg.at(index).pristine) {
      this.fg.removeAt(index);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: <ConfirmationDialogData>{ title: "Remove communication item" },
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed)
        this.fg.removeAt(index);
    });
  }

}
