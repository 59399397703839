import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { Configuration } from 'src/app/_graphql/schema';
import { ConfigurationsService } from 'src/app/_services/configurations.service';
import { DocumentsService } from 'src/app/_services/documents.service';


import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-session-bulk-import',
  templateUrl: './session-bulk-import.component.html',
  styleUrls: ['./session-bulk-import.component.scss']
})
export class SessionBulkImportComponent implements OnInit, OnDestroy {
  form: FormGroup = this.fb.group({
    fileId: [null, [Validators.required]],
    configurationId: [null, [Validators.required]],
    description: [null, []],
  });
  selectedIndex = 0;
  $configurations: Subscribable<Configuration[]>;
  constructor(
    public service: SessionsCompanyService,
    public ui: UiService,
    private router: Router,
    private fb: FormBuilder,
    private ds: DocumentsService,
    private configurationsService: ConfigurationsService,
  ) {
    this.ui.setTitle("Bulk Import");
    this.$configurations = this.configurationsService.all();
  }

  setConfigurationId(configurationId: string) {
    this.form.patchValue({ configurationId: configurationId });
    this.selectedIndex = 1;
  }
  downloadTpl() {
    this.ds.downloadFile('templateType=2', 'template.csv');
  }
  import() {
    this.selectedIndex = 0;
    let s = this.service.importSessions(this.form.value).subscribe({
      next: x => {
        s.unsubscribe();
        this.router.navigate(['/', 'company', 'sessions']);
      }, error: x => {
        console.log(x);
        s.unsubscribe();
      }
    })
  }
  ngOnInit(): void { }


  ngOnDestroy(): void {

  }
}