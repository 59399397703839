import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { Check, Session } from 'src/app/_graphql/schema-session';
import { EventBusService } from 'src/app/_services/event-bus.service';


import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';
import { UiService } from 'src/app/_services/ui.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ConfigurationsService } from 'src/app/_services/configurations.service';
import { Configuration } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-session-item',
  templateUrl: './session-item.component.html',
  styleUrls: ['./session-item.component.scss'],
  providers: [
    // { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } }
  ]
})
export class SessionItemComponent implements OnInit, OnDestroy {
  title = "";
  itemId: string;
  item$: Unsubscribable;
  item: Session;
  checks$: Unsubscribable;
  checks: Check[];
  onSessionNavigateToNextResult$: Subscription;
  onRoute$: Subscription;
  animationDuration = "300ms"
  @ViewChild("tabs", { static: false }) tabs: MatTabGroup;
  configurations$: Subscribable<Configuration[]>;
  providerCheckChanged: boolean =  false;




  constructor(
    public service: SessionsCompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private eventBusService: EventBusService,
    public ui: UiService,
    public auth: AuthService,
    public configurations: ConfigurationsService,
  ) {
    this.ui.setTitle("Session");
    this.onSessionNavigateToNextResult$ = this.eventBusService.data$.subscribe($event => {
      if ($event.name == 'onSessionNavigateToNextResult') {
        this.onSessionNavigateToNextResult($event.data);
      }

    });
    this.onRoute$ = this.route.queryParams.subscribe(() => {
      this.loadData(this.route.snapshot.paramMap.get('id'));
    });
    this.configurations$ = configurations.all();

  }

  loadData(itemId: string) {
    if (itemId == null || this.itemId == itemId) {
      return;
    }
    this.itemId = itemId;
    this.checks$ = this.service.one(this.itemId).subscribe({
      next: session => {
        this.item = session;
        this.checks = session.checks;
        if (new Date(this.item['configuration']?.['checks'][0]?.providerCheck?.modified) > new Date(this.item?.created) ||
        (new Date(this.item['configuration']?.['checks'][0]?.providerCheck?.fieldSet?.modified) > new Date(this.item?.created))) 
          this.providerCheckChanged = true;
      }
    });
  }

  ngOnInit(): void { }

  onCancelMonitoring() {
    var mdl = <ConfirmationDialogData>{
      title: 'modal_title_monitoring_cancel',
      content: 'modal_content_monitoring_cancel',
      btnConfirmText: 'modal_btn_monitoring_cancel',
      btnConfirmMode: 'warn'
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        var s = this.service.toggleMonitoring(this.itemId).subscribe({ next: x => { s.unsubscribe() }, error: () => { s.unsubscribe() } })
      }
    });

  }

  onSessionNavigateToNextResult(currentResultId: string) {
    this.animationDuration = "0ms";
    this.checks.forEach((check, checkIndex) => {
      var index = check.results.findIndex(el => el.id == currentResultId);
      if (index > -1) {
        if (index == check.results.length - 1) {
          if (checkIndex < this.item?.checks.length - 1) {
            this.tabs.selectedIndex = checkIndex + 1;
            // get first item from nrxt tab
            if (this.checks[checkIndex + 1].results?.length) {
              this.router.navigate(['.', 'result', this.checks[checkIndex + 1].results[0].id], { relativeTo: this.route })
              return;
            }
          }
        }
        if (index < check.results.length - 1) {
          this.router.navigate(['.', 'result', check.results[index + 1].id], { relativeTo: this.route })
        } else {
          this.router.navigate(['./'], { relativeTo: this.route })
        }


        var element = document.querySelector('mat-list-item.router-link-active')
        // if (element)
        // this.scrollIntoView(element);
      }
    });
  }
  scrollIntoView(element: any) {
    element.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      this.animationDuration = "300ms";
    }, 500);
  }
  showTab(index: number) {
    this.tabs.selectedIndex = index;
    var element = document.querySelector('mat-tab-group')
    if (element)
      this.scrollIntoView(element);

  }

  hasResults() {
    var hasResults = false;
    this.checks.forEach(check => {
      if (check?.results.length) {
        hasResults = true;
      }
    });
    return hasResults;
  }
  rerunSession() {
    this.router.navigate(['company', 'sessions', 'edit', this.itemId])
  }


  ngOnDestroy(): void {
    this.item$?.unsubscribe();
    this.checks$?.unsubscribe();
    this.onSessionNavigateToNextResult$?.unsubscribe();
    this.onRoute$?.unsubscribe();
  }

}

