import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { ConfigApplication } from "../_graphql/schema";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ApplicationsService extends BaseService<ConfigApplication> {
  selectOneFields = gql`
    fragment SelectOneFields on ConfigApplication {
      id
      name
      created
      description
      pricePerCheck
      matchingFormula
      created
    }
  `;

  selectAllFields = gql`
    fragment SelectAllFields on ConfigApplication {
      id
      name
      created
      description
      configurations {
        id
        name
      }
    }
  `;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql("configApplication", "configApplications");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }
}
