import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable } from 'rxjs';
import { Company } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companies.service';
import { SanctionsService } from 'src/app/_services/sanctions.service';

@Component({
  selector: 'app-sanctions-item',
  templateUrl: './sanctions-item.component.html',
  styleUrls: ['./sanctions-item.component.scss']
})
export class SanctionsItemComponent implements OnInit {

  data$: Subscribable<any>;
  databaseSources = ['EU_FSF', 'USA_OFAC'];
  form = this.fb.group({
    fileUrl: [null, []],
    moreInfo: [null, []],
    source: [null, [Validators.required]]
  });
  constructor(
    public auth: AuthService,
    public service: SanctionsService,
    private fb: FormBuilder
  ) { }


  ngOnInit(): void {
  }
}
