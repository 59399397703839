<form [formGroup]="form" style="height: calc(100% - 64px)">
  <mat-stepper [linear]="true" #stepper [selectedIndex]="selectedIndex">
    <mat-step [editable]="form.get('configurationId').value != null">
      <ng-template matStepLabel>Select Configuration</ng-template>
      <div class="holder">
        <mat-action-list class="inv-list">
          <div *ngFor="
          let _configuration of $configurations | async ">

            <mat-list-item (click)="setConfigurationId(_configuration.id)"
              *ngIf="(_configuration.checks | getKeysOf:['providerCheck', 'sourceType']) == 'BUSINESS'">
              <mat-icon matListIcon *ngIf="_configuration.id == form.get('configurationId').value">
                radio_button_checked
              </mat-icon>
              <mat-icon matListIcon *ngIf="_configuration.id != form.get('configurationId').value">
                radio_button_unchecked
              </mat-icon>
              <h3 matLine>{{ _configuration?.name }}</h3>
              <p matLine>
                <span>{{ _configuration?.description }}</span>
              </p>
            </mat-list-item>
          </div>
        </mat-action-list>
      </div>
      <div class="actions">
        <!-- <button mat-button matStepperPrevious>
          <mat-icon>chevron_left</mat-icon>
          Previous
        </button> -->
        <div fxFlex></div>
        <button mat-button matStepperNext [disabled]="!form.get('configurationId').valid">
          Next
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [editable]="form.get('configurationId').value != null">
      <ng-template matStepLabel>Upload CSV file</ng-template>
      <div class="holder">
        <mat-action-list class="inv-list">
          <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="24px" fxLayoutAlign.lg="space-between center">
            <div fxFlex>
              <div style="padding: 64px">
                <p>{{'Please, enter description and upload CSV file containing import data to proceed.'}}</p>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="24px">
                  <div fxFlex.gt-sm="140px">
                    <app-file-upload formControlName="fileId"></app-file-upload>
                  </div>
                  <mat-form-field fxFlex>
                    <mat-label>{{ "Description" | translate }}</mat-label>
                    <input matInput formControlName="description" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-divider vertical style="height:200px" fxHide.lt-lg></mat-divider>

            <div fxFlex>
              <div style="padding: 64px">
                <h3>Note</h3>
                <p>If you are here for the first time, you'll need to download CSV template. Once template is
                  downloaded, please fill it with data to create new sessions.</p>
                <button mat-stroked-button (click)="downloadTpl()">{{'Download template'|translate}}</button>
              </div>
            </div>
          </div>
        </mat-action-list>
      </div>
      <div class="actions">
        <button mat-button matStepperPrevious>
          <mat-icon>chevron_left</mat-icon>
          Previous
        </button>
        <div fxFlex></div>
        <button mat-button (click)="import()" [disabled]="!form.valid">
          Next
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [editable]="form.get('configurationId').value != null">
      <ng-template matStepLabel>Done</ng-template>
      <div class="holder" fxLayoutAlign="center center" style="min-height: 80vh">
        <mat-spinner></mat-spinner>
      </div>

    </mat-step>
  </mat-stepper>
</form>