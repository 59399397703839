<mat-accordion *ngIf="documents?.length">
  <h3>Document<span *ngIf="documents?.length>1">es</span></h3>
  <mat-expansion-panel *ngFor="let document of documents">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{document?.type}}
      </mat-panel-title>
      <mat-panel-description>
        <span></span>
        <!-- <mat-icon>location_on</mat-icon> -->
      </mat-panel-description>
    </mat-expansion-panel-header>
    <!-- {{ document | json}} -->
    <table *ngIf="document">
      <tr *ngIf="document?.type">
        <th>{{'document_type' | translate}}</th>
        <td>{{document?.type}}</td>
      </tr>
      <tr *ngIf="document?.number">
        <th>{{'document_number' | translate}}</th>
        <td>{{document?.number}}</td>
      </tr>
      <tr *ngIf="document?.expiry">
        <th>{{'document_expiry' | translate}}</th>
        <td>{{document?.expiry | date : 'mediumDate' : 'UTC'}}</td>
      </tr>
    </table>
  </mat-expansion-panel>

</mat-accordion>