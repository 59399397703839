import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscribable } from 'rxjs';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { User } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { UiService } from 'src/app/_services/ui.service';
import { UsersService } from 'src/app/_services/users.service';

@Component({
  selector: 'app-company-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class CompanyUsersComponent {

  $data: Subscribable<User[]>;
  showOptions = false;

  constructor(
    public dialog: MatDialog,
    public service: UsersService,
    public auth: AuthService,
    public ui: UiService
  ) {
    this.$data = this.service.all();
    this.showOptions = this.auth.hasRoles(["Admin"]) || this.auth.hasRoles(["CompanyAdmin"]);
  }
  onResendInvite(userId: string) {
    var k = this.service.resendInvite({ userId }).subscribe({
      next: res => {
        this.ui.snack("Invitation mail sent!")
        k.unsubscribe();
      }, error: error => {
        this.ui.snack("Sending email invitation failed!")
        k.unsubscribe();
      }
    });
  }
  onLockUser(user: User, block: boolean) {
    var mdl = <ConfirmationDialogData>{
      title: !block ? 'modal_title_user_unblock' : 'modal_title_user_block',
      content: !block ? 'modal_content_user_unblock' : 'modal_content_user_block',
      btnConfirmText: !block ? 'modal_btn_user_unblock' : 'modal_btn_user_block',
      btnConfirmMode: !block ? 'primary' : 'warn'
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        var s = this.service.lockEndUser(user.id, block).subscribe({
          next: x => {
            this.service.fetchMoreData()
            s.unsubscribe();
          },
          error: x => {
            s.unsubscribe();
          }
        });
      }
    });
  }
}

