<list-wrapper #wrapper [mainService]="service" navigateItem="product-checks" title="Product Checks"
  [readPerm]="auth.hasPermission(auth.perm.ProductRead)" [managePerm]="auth.hasPermission(auth.perm.ProductManage)"
  [showDateFilter]="true">
  <mat-action-list fxLayout="column" class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="48px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' |translate}}</div>
      </div>
      <div fxFlex="128px">
        <div mat-line>Product</div>
      </div>
      <div fxFlex="128px">
        <div mat-line>Provider Check</div>
      </div>
      <div fxFlex="128px" class="text-right">
        <div mat-line>Included Checks</div>
      </div>
      <div fxFlex="98px" class="text-right">
        <div mat-line>Next Price</div>
      </div>

    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)">
      <mat-icon matListIcon>verified</mat-icon>
      <strong mat-line>{{ item.name }}</strong>
      <small mat-line>{{ item.description }}</small>

      <span fxFlex="128px">
        <small mat-line>{{item?.product?.name}}</small>
      </span>
      <span fxFlex="128px">
        <small mat-line>{{item?.providerCheck?.name}}</small>
      </span>
      <small fxFlex="128px" class="text-right">
        {{item?.includedChecks}}
      </small>

      <small fxFlex="98px" class="text-right">
        {{item?.nextPrice | currency}}
      </small>

      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>