<list-wrapper #wrapper [mainService]="service" navigateItem="inventory" title="Inventory"
    [readPerm]="auth.hasPermission(auth.perm.SessionRead)" [managePerm]="false" [showDateFilter]="false">
    <div toolbox style="gap: 8px">
    </div>
    <mat-action-list class="inv-list">
        <h3 matSubheader fxLayout="row" fxFill>
            <div fxFlex="48px"></div>
            <div fxFlex>
                <div mat-line>{{'Name' }}</div>
            </div>
            <div fxFlex="76px" class="text-center"></div>
            <div fxFlex="36px"></div>
            <div fxFlex="155px" fxHide.lt-sm>
                <div mat-line>{{'Session Count'|translate}}</div>
            </div>
            <div fxFlex="140px" fxHide.lt-sm>
                <div mat-line>{{'Last Session'|translate}}</div>
            </div>
        </h3>
        <mat-list-item *ngFor="let item of (data$ | async) as items; index as i"
            (click)="navigateTo(item?.referenceId)">
            <app-check-status matListIcon [status]="item?.lastSession?.overallCheckStatus">
            </app-check-status>
            <h3 mat-line>
                {{ item?.companyName }}
                {{ item?.firstName }} {{ item?.lastName }}
            </h3>
            <small mat-line>
                {{item.referenceId}}
            </small>
            <div fxFlex="76px" class="text-center">
                <small>{{item?.numberOfSessions}}</small>
            </div>
            <div fxFlex="36px">
                <mat-icon *ngIf="item.__typename=='Person'">person</mat-icon>
                <mat-icon *ngIf="item.__typename=='Business'">work</mat-icon>
            </div>
            <div fxFlex="140px" fxHide.lt-sm>
                <div mat-line>
                    {{item?.lastSession?.user?.firstName}} {{item?.lastSession?.user?.middleName}}
                    {{item?.lastSession?.user?.lastName}} {{item?.lastSession?.apiToken?.name}}
                </div>
                <small mat-line>
                    {{item?.lastSession?.created | date :'short'}}
                </small>
            </div>
            <mat-divider [inset]="true" *ngIf="i+1<(data$ | async)?.length"></mat-divider>
        </mat-list-item>

    </mat-action-list>
</list-wrapper>
