<mat-card>
  <mat-card-header>
    <div mat-card-avatar class="avatar">
      <mat-icon>perm_identity</mat-icon>
    </div>

    <mat-card-title class="mat-card-title">{{ person?.title }} {{ person?.firstName }} {{ person?.middleName }} {{
      person?.lastName }}
    </mat-card-title>
    <mat-card-subtitle class="mat-card-subtitle">{{ person?.dob | date : 'mediumDate' : 'UTC' }}&nbsp;</mat-card-subtitle>
  </mat-card-header>
  <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
  <mat-card-content>
    <mat-card>
      <table>
        <tr *ngIf="person?.title">
          <th>{{'person_title' | translate}}</th>
          <td>{{person?.title}}</td>
        </tr>
        <tr *ngIf="person?.firstName">
          <th>{{'person_firstName' | translate}}</th>
          <td>{{person?.firstName}}</td>
        </tr>
        <tr *ngIf="person?.middleName">
          <th>{{'person_middleName' | translate}}</th>
          <td>{{person?.middleName}}</td>
        </tr>
        <tr *ngIf="person?.lastName">
          <th>{{'person_lastName' | translate}}</th>
          <td>{{person?.lastName}}</td>
        </tr>
        <tr *ngIf="person?.referenceId">
          <th>{{'person_referenceId' | translate}}</th>
          <td>{{person?.referenceId}}</td>
        </tr>
        <tr *ngIf="person?.socialNumber">
          <th>{{'person_socialNumber' | translate}}</th>
          <td>{{person?.socialNumber}}</td>
        </tr>
        <tr *ngIf="person?.isoLatin1Name">
          <th>{{'person_isoLatin1Name' | translate}}</th>
          <td>{{person?.isoLatin1Name}}</td>
        </tr>
        <tr *ngIf="person?.dob">
          <th>{{'person_dob' | translate}}</th>
          <td>{{person?.dob | date : 'mediumDate' : 'UTC'}}</td>
        </tr>
        <tr *ngIf="person?.gender">
          <th>{{'person_gender' | translate}}</th>
          <td>{{person?.gender}}</td>
        </tr>
        <tr *ngIf="person?.nationality">
          <th>{{'person_nationality' | translate}}</th>
          <td>{{person?.nationality}}</td>
        </tr>
      </table>
    </mat-card>
    <app-view-address [addresses]="person?.addresses"></app-view-address>
    <app-view-communication [communications]="person?.communications"></app-view-communication>
    <app-view-documents [documents]="person?.documents"></app-view-documents>

  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions> -->
</mat-card>

<!-- <pre>{{ person | json}}</pre> -->