<item-wrapper #item [mainService]="service" [form]="form" navigateList="/company/users" title="User Profile"
  [readPerm]="true" [managePerm]="true" [backOnSave]="false">

  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column" fxLayoutGap="12px"
    style="padding: 0 12px;">
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="8px" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'First Name' | translate }}</mat-label>
        <input matInput formControlName="firstName" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Middle Name' | translate }}</mat-label>
        <input matInput formControlName="middleName" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="8px" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Last Name' | translate }}</mat-label>
        <input matInput formControlName="lastName" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Phone' | translate }}</mat-label>
        <input matInput formControlName="phoneNumber" />
      </mat-form-field>
    </div>
    <mat-form-field fxFlex>
      <mat-label>{{ 'E-mail' | translate }}</mat-label>
      <input matInput [value]="form.get('email').value" readonly disabled />
    </mat-form-field>




    <h3>Address</h3>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 1' | translate }}</mat-label>
        <input matInput formControlName="address" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 2' | translate }}</mat-label>
        <input matInput formControlName="address2" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex="25">
        <mat-label>{{ 'Postal Code' | translate }}</mat-label>
        <input matInput formControlName="zip" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'City' | translate }}</mat-label>
        <input matInput formControlName="city" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Country' | translate }}</mat-label>
        <mat-select formControlName="countryId">
          <mat-option *ngFor="let item of $countries | async" [value]="item.id">
            {{(item.name) | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'State' | translate }}</mat-label>
        <input matInput formControlName="state" />
      </mat-form-field>
    </div>
  </form>

</item-wrapper>