import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { DocumentType, SupportedCountry } from 'src/app/_graphql/schema';
import { CountriesService } from 'src/app/_services/countries.service';
@Component({
  selector: 'documents-form-item',
  templateUrl: './documents-form-item.component.html',
  styleUrls: ['./documents-form-item.component.scss']
})
export class DocumentsFormItem implements OnInit {
  @Input('fg') fg: FormArray;
  @Input('configurationId') configurationId: string;


  documentTypes = DocumentType;
  supportedIdentityCheckTypes = [];
  supportedDocuments = [];
  states = [];


  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public countriesService: CountriesService
  ) {
  }
  ngOnInit(): void {
    this.countriesService.getSupportedIdentityCheckTypes(this.configurationId).subscribe({ next: x => this.supportedIdentityCheckTypes = x });
    setTimeout(() => {
      for (let i=0; i<this.fg.length; i++) {
        if (this.fg.at(i)?.get('country').value) {
          this.onCountryChange(i);
          for (let j=0; j<(this.fg.at(i)?.get('images') as FormArray)?.length; i++)
            (this.fg.at(i)?.get('images') as FormArray)?.at(j).patchValue({documentId: null, id:  null});
        }
      }
    }, 100);

  }
  addImage(index): void {
    var images = this.fg.controls[index].get('images') as FormArray;
    var image = this.fb.group({
      description: [null, []],
      documentId: [null, []],
      id: [null, []],
      imageBase64: [null, []],
      imagePath: [null, []],
      fileId: [null, []],
      name: [null, []]
    });
    images.push(image)


  }
  addLine(): void {
    var line = this.fb.group({
      cityOfIssue: [null, []],
      countyOfIssue: [null, []],
      provinceOfIssue: [null, []],
      districtOfIssue: [null, []],
      cardType: [null, []],
      individualReferenceNumber: [null, []],
      country: [null, [Validators.required]],
      state: [null, []],
      type: [null, [Validators.required]],
      expiry: [null, [Validators.required]],
      version: [null, []],
      number: [null, [Validators.required]],
      serialNumber: [null],
      images: new FormArray([]),
    });
    this.fg.push(line);
    this.addImage(this.fg.length - 1);
  }
  removeLine(index: number) {

    if (this.fg.at(index).pristine) {
      this.fg.removeAt(index);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: <ConfirmationDialogData>{ title: "Remove communication item" },
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed)
        this.fg.removeAt(index);
    });
  }
  removeImage(groupIndex, imageIndex) {
    console.log(groupIndex, imageIndex);
    var image = this.fg.controls[groupIndex].get('images') as FormArray;
    image.removeAt(imageIndex);
  }

  onCountryChange(idx) {
    this.supportedIdentityCheckTypes.forEach((supportedIdentityCheck: SupportedCountry) => {
      if (supportedIdentityCheck.country.isoCode2 == this.fg.at(idx).get('country').value) {
        this.supportedDocuments = supportedIdentityCheck.supportedDocumentTypes;
        this.states = supportedIdentityCheck.country.states;
      }
    });
    if (!this.states?.length && !this.states){
      this.fg.at(idx).patchValue({
        state: null,
      })
    }
  if (!this.supportedDocuments?.length && !this.supportedDocuments){
      this.fg.at(idx).patchValue({
        type: null,
      })
    }
  }
}
