import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscribable } from 'rxjs';
import { Configuration, Permission } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { ConfigurationsService } from 'src/app/_services/configurations.service';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {

  data$: Subscribable<Configuration[]>;

  constructor(
    public auth: AuthService,
    private router: Router,
    public configurations: ConfigurationsService,
  ) { }

  ngOnInit(): void {
    this.data$ = this.configurations.all();
  }

  onNavigateToApplication(event, item) {
    this.router.navigate(['/', 'company', 'applications', item?.configApplication?.id]);
    event.stopPropagation();
  }

}
