import { NgModule } from '@angular/core';

import { filterOn } from './filter-on.pipe';
import { getKeysOf } from './get-keys-of.pipe';


@NgModule({
    declarations: [
        filterOn,
        getKeysOf
    ],
    exports: [
        filterOn,
        getKeysOf
    ]
})
export class PipesModule { }
