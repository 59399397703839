import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { map, Subscribable } from "rxjs";
import { Company, Permission } from "../_graphql/schema";
import { AuthService } from "./auth.service";
import { BaseService } from "./base.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UsersService } from "./users.service";

@Injectable({
  providedIn: "root",
})
export class CompaniesService extends BaseService<Company> {
  constructor(
    protected apollo: Apollo,
    protected sb: MatSnackBar,
    private usersService: UsersService,
    private auth: AuthService
  ) {
    super(apollo, sb);
    this.selectOneFields = gql`
      fragment SelectOneFields on Company {
        id
        name
        avatarId
        status
        description
        contactPersonPosition
        contactPersonName
        idNumber
        vatNumber
        address
        address2
        countryId
        zip
        city
        state
        email
        facebook
        linkedin
        phoneNumber
        website
        ipAddresses
        allowedPaymentMethod
        ${this.auth.allowedField(Permission.AllCompaniesManage, 'adminNote')},
        ${this.auth.allowedField(Permission.CompanyCheckPricingManage, 'pricePerCheck')}
      }
    `;
    this.selectAllFields = gql`
    fragment SelectAllFields on Company {
      id
      name
      avatarId
      status
      ${this.auth.allowedField(Permission.AllCompaniesManage, 'adminNote')},
      description
      contactPersonPosition
      contactPersonName
      idNumber
      address
      address2
      zip
      city
      country { id name }
      state
      email
      phoneNumber
      allowedPaymentMethod
      currentSubscription {
        id
        startOfSubscription
        endOfSubscription
        product {
          id
          name
          price
        }
      }
    #   stats(
    #     pageRequestInput: {
    #       statsRange: ${this.queryParams.statsRange}
    #     }
    #   ){
    #    count
    #    ${this.auth.allowedField(Permission.ConfigurationPricingRead, 'price')}
    #    ${this.auth.allowedField(Permission.ProviderPricingRead, 'cost')}
    #  }
    }

  `;

    this.initGql("company", "companies");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }

  gqlSetCompany: DocumentNode = gql`
    mutation setCompany($companyId: ID!) {
      setCompany(companyId: $companyId, join: true, isDefault: true) {
        id
      }
    }
  `;
  public setCompany(companyId: string): Subscribable<any> {
    return super.mutation(this.gqlSetCompany, { companyId: companyId });
  }

  gqlSwitchCompany: DocumentNode = gql`
    mutation switchCompany($companyId: ID!) {
      switchCompany(companyId: $companyId) {
        id
        email
        authToken
        roles
        city
        permissions
        language {
          id
          isoCode2
          name
        }
        company {
          id
          name
        }
      }
    }
  `;
  public switchCompany(companyId: string): Subscribable<any> {
    localStorage.setItem('companyId', companyId);
    this.addRefetchQuery(this.usersService.selectCurrentQuery);
    this.apollo.client.clearStore();
    var t = super.mutation(this.gqlSwitchCompany, { companyId: companyId });
    t.subscribe({
      next: (x) => {
        this.auth.storeUser(x);
      },
    });
    return t;
  }

  selectCurrentQuery: DocumentNode = gql`
    query currentCompany {
      company {
        id
        name
        avatarId
        status
        allowedPaymentMethod
      }
    }
  `;

  currentCompanyWQ: any;
  public currentCompany(): Subscribable<Company> {
    if (!this.currentCompanyWQ) {
      this.currentCompanyWQ = this.apollo.watchQuery({
        query: this.selectCurrentQuery,
      });
    }
    return this.currentCompanyWQ.valueChanges.pipe(
      map<any, Company>((result: any) => {
        if (!result || !result.data) return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          var r = result.data[keys[0]];
          return r;
        }
        return null;
      })
    );
  }

  gqlChangeCompanyStatus: DocumentNode = gql`
    mutation setCompanyStatus($companyId: ID!, $newStatus: CompanyStatus!, $statusUserMessage: String, $adminMessage: String, $allowedPaymentMethod: PaymentMethod) {
      setCompanyStatus(
        companyId: $companyId, 
        newStatus: $newStatus, 
        statusUserMessage: $statusUserMessage,
        adminMessage: $adminMessage,
        allowedPaymentMethod: $allowedPaymentMethod
      ) {
        id
        status
        modified
        allowedPaymentMethod
      }
    }
  `;
  public changeCompanyStatus(data: any): Subscribable<any> {

    return super.mutation(this.gqlChangeCompanyStatus, data, (store, { data: { setCompanyStatus } }) => {
      // Read the data from our cache for this query.
      // console.log(setCompanyStatus);
      // console.log(store);
      // const data = store.readQuery({ query: this.allWQ });
      // console.log(data)
      // Add our comment from the mutation to the end.
      // const comments = [...data.comments, submitComment];
      // // Write our data back to the cache.
      // store.writeQuery({ query: CommentAppQuery, { comments }  });
    });
  }
  gqlAddCompanyNote: DocumentNode = gql`
  mutation addCompanyNote($companyId: ID!, $note: String!) {
    addCompanyNote(
      companyId: $companyId, 
      note: $note
    ) {
      id
      adminNote
      modified
    }
  }
`;
  public addCompanyNote(data: any): Subscribable<any> {
    this.addRefetchQuery(this.selectOneQuery, { id: data.companyId });
    return super.mutation(this.gqlAddCompanyNote, data);
  }
  gqlSetPaymentMethod: DocumentNode = gql`
  mutation setCompanyPaymentOptions($companyId: ID!, $productId: ID, $allowedPaymentMethod: PaymentMethod) {
    setCompanyPaymentOptions(
      companyId: $companyId, 
      productId: $productId, 
      allowedPaymentMethod: $allowedPaymentMethod
    ) {
      id
      modified
      allowedPaymentMethod
    }
  }
`;
public setPaymentMehod(data: any): Subscribable<any> {
  return super.mutation(this.gqlSetPaymentMethod, data);
}
}
