import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from './_services/translations.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  constructor(public trans: TranslationsService, private tt: TranslateService) {
    
  }
}
