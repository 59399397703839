<list-wrapper #wrapper [mainService]="service" navigateItem="checks" title="Available Checks"
  [readPerm]="auth.hasPermission(auth.perm.CompanyCheckRead)"
  [managePerm]="auth.hasPermission(auth.perm.CompanyCheckManage)">
  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="46px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>
      <div fxFlex="36px"></div>
      <div fxFlex="180px" fxHide.lt-sm>
        <div mat-line>{{'Check' | translate }}</div>
      </div>
      <div fxFlex="36px"></div>
      <div fxFlex="85px" class="text-right">
        <div mat-line>{{'Count'|translate}}</div>
      </div>
      <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <div mat-line>{{'Price' | translate}}</div>
      </div> -->
      <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <div mat-line>{{'Cost'|translate}}</div>
      </div> -->
      <div fxFlex="8px">
        <div mat-line></div>
      </div>
    </h3>
    <mat-list-item *ngFor="let item of $data | async; index as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{ deleted: item?.deleted}">
      <!-- <app-check-type matListIcon [checkType]="item.checkType"></app-check-type> -->
      <mat-icon matListIcon>verified</mat-icon>

      <h3 mat-line>{{ item?.name }}</h3>
      <small mat-line>
        {{ item?.description }}
      </small>
      <div fxFlex="36px"></div>
      <div fxFlex="180px" fxHide.lt-sm>
        <small mat-line>{{ item?.providerCheck?.name}}</small>
      </div>
      <div fxFlex="36px"></div>
      <div fxFlex="85px" class="text-right">
        <small>{{ item?.stats?.count }}</small>
      </div>
      <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ConfigurationPricingRead)" class="text-right">
        <small *ngIf="item?.stats?.price">{{ item?.stats?.price | currency }}</small>
      </div> -->
      <!-- <div fxFlex="85px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)" class="text-right">
        <small *ngIf="item?.stats?.cost">{{ item?.stats?.cost | currency }}</small>
      </div> -->
      <div fxFlex="8px">
        <div mat-line></div>
      </div>

      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>