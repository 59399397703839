import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscribable, map, take } from 'rxjs';
import { CheckType, Country, FieldSet, Provider, ProviderCheck, SourceType } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CountriesService } from 'src/app/_services/countries.service';
import { FieldsetsService } from 'src/app/_services/fieldsets.service';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';
import { ProvidersService } from 'src/app/_services/providers.service';


@Component({
  selector: 'app-provider-check-item',
  templateUrl: './check-item.component.html',
  styleUrls: ['./check-item.component.scss']
})
export class ProviderCheckItemComponent implements OnInit {
  data$: Subscribable<ProviderCheck>;
  providersService$: Subscribable<Provider[]>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  sourceType = SourceType;
  fieldSets$!: Subscribable<FieldSet[]>;
  countries$!: Subscribable<Country[]>;

  conf: any = null;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  checkTypes = CheckType;

  form = this.fb.group({
    id: [null],
    checkTypes: [[], [Validators.required]],
    name: [null, Validators.required],
    description: [null, Validators.required],
    providerId: [null, Validators.required],
    maxRateMonthly: [0, []],
    pricePerCheck: [0, []],
    costPerCheck: [0, []],
    sourceType: [null, [Validators.required]],
    fieldSetId: [null, []],
    country: [null, []],
  });
  compareWith(option1: any, option2: any): boolean {
    return option1 && option2 && option1.checkType === option2.checkType;
  }
  constructor(
    private _ngZone: NgZone,
    public providerChecksService: ProviderChecksService,
    public providersService: ProvidersService,
    private fb: FormBuilder,
    public auth: AuthService,
    private fieldSetsService: FieldsetsService,
    private route: ActivatedRoute,
    private countries: CountriesService
  ) {
    this.fieldSets$ = fieldSetsService.getFieldSetWithoutFields();
    this.providersService$ = providersService.dropdown();
    this.countries$ = countries.dropdown();
    this.form.valueChanges.subscribe(val => {
      if (!auth.hasPermission(auth.perm.ProviderPricingManage)) {
        ['pricePerCheck', 'maxRateMonthly', 'costPerCheck'].forEach(el => this.form.get(el).disable({ emitEvent: false }))
      }
    });
  }

  ngOnInit(): void {
    (this.fieldSets$ as Observable<FieldSet[]>).pipe(map((items) => items['data'].find((itm) => itm?.isDefault)))
    .subscribe((res) => {
      if (res && this.route.snapshot.paramMap.get('id') === 'new')
        this.form.get('fieldSetId').setValue(res.id);
    });
  }

}
