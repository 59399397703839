import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';

import { ComponentsModule } from 'src/app/components/components.module';
import { CompanyChecksComponent } from './company-checks.component';
import { CompanyCheckItemComponent } from './company-check-item/company-check-item.component';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { PipesModule } from 'src/app/_pipes/pipes.module';


@NgModule({
  declarations: [
    CompanyChecksComponent,
    CompanyCheckItemComponent,
  ],
  imports: [
    PipesModule,
    ComponentsModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
  ]
})
export class CompanyChecksModule { }
