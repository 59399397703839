<button mat-icon-button class="dialog-close-btn" tabindex="-1" (click)="dialogRef.close();">
    <mat-icon>close</mat-icon>
  </button>
  <h2 md-dialog-title>
    {{item.name}}: {{'modal_title_payment_method' | translate}}
  </h2>
  <form md-dialog-content [formGroup]="form">
    <div fxFlex fxLayout="column">
      <p>{{'modal_content_payment_method' | translate}}</p>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Payment method' | translate }}</mat-label>
        <mat-select formControlName="allowedPaymentMethod">
          <mat-option value="CARD">
            {{'allowedPaymentMethod_CARD' | translate}} 
          </mat-option>
          <mat-option value="INVOICE">
            {{'allowedPaymentMethod_INVOICE' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Product' |translate }}</mat-label>
        <mat-select formControlName="productId">
          <mat-option *ngFor="let item of $products | async" [value]="item.id">
            {{(item.name) | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  
  <div mat-dialog-actions>
    <div fxFlex></div>
    <button mat-button (click)="dialogRef.close();">{{ 'Cancel' | translate }}</button>
    <button mat-button color="primary" (click)="dialogRef.close(form.value);" [disabled]="!form.valid">{{
      'modal_btn_payment_method' |
      translate
      }}</button>
  </div>