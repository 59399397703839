import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { DocumentType } from 'src/app/_graphql/schema';
@Component({
  selector: 'document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss']
})
export class DocumentsItem implements OnInit {
    form: FormGroup = this.fb.group({
    fileId: [null, [Validators.required]],
    name: [null, []]
  });
  @Input('fg') fg: FormGroup;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();



  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder
  ) {
  }
  ngOnInit(): void {


  }
  delete() {
    this.onDelete.emit();
  }
  patchFileId(file:any) {
    this.fg.patchValue({ fileId: file.id, name: file.name })

  }
  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fg.patchValue({ imageBase64: reader.result })
    };
  }


}
