import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscribable } from 'rxjs';
import { Permission, Provider, ProviderCheck } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';
import { ProvidersService } from 'src/app/_services/providers.service';

@Component({
  selector: 'app-provider-checks',
  templateUrl: './checks.component.html',
  styleUrls: ['./checks.component.scss']
})
export class ProviderChecksComponent implements OnInit, OnDestroy {

  $data: Subscribable<ProviderCheck[]>;
  providerIds: FormControl = new FormControl([]);
  $providers: Subscribable<Provider[]>;


  constructor(
    public auth: AuthService,
    public providerChecks: ProviderChecksService,
    public providers: ProvidersService
  ) {
  }
  ngOnInit(): void {
    this.$data = this.providerChecks.all();
    this.$providers = this.providers.dropdown();
    if (this.providerChecks.queryParams.providerIds) {
      // this.providerChecks.queryParams.providerIds = null;
      this.providerIds.patchValue(this.providerChecks.queryParams.providerIds, { emitEvent: false });
    }
  }

  filterChecks() {
    this.providerChecks.queryParams.providerIds = this.providerIds?.value;
    this.providerChecks.fetchMoreData();
  }
  ngOnDestroy(): void {
    // this.providerChecks.queryParams.providerIds = null;
  }

}
