<div>
<button mat-button [matMenuTriggerFor]="menuDates" style="min-width: auto;">
  <span class="date-ranger">
   <div *ngIf="data?.range">{{('dateRange_'+ data?.range)|translate}}</div> 
    <small *ngIf="data?.startDate || data?.endDate">{{data?.startDate | date:'shortDate'}} - {{ data?.endDate |
      date:'shortDate'}}</small>
  </span>
    <mat-icon>event</mat-icon>
  </button>
<mat-menu #menuDates="matMenu">
  <button mat-menu-item *ngFor="let possibleRange of possibleRanges" (click)="onChange(possibleRange)" 
  [ngStyle]="{'background-color': possibleRange === data?.range ? '#f3f2f5' : 'white'}" class="matItem">
    <!-- <mat-checkbox [checked]="possibleRange === data?.range" style="margin-right: 10px;" color="primary"></mat-checkbox> -->
      {{('dateRange_'+possibleRange)|translate}}
  </button>
  <mat-date-range-input [rangePicker]="picker" style="opacity: 0;">
    <input matStartDate placeholder="Start date" (dateChange)="changeStartEndDate($event, 'startDate')" [value]="data?.startDate">
    <input matEndDate placeholder="End date" (dateChange)="changeStartEndDate($event, 'endDate')" [value]="data?.endDate">
  </mat-date-range-input>
  <mat-date-range-picker #picker></mat-date-range-picker>
  <button mat-menu-item (click)="clearRange()" style="margin-top: -15px;">
    <mat-icon>clear</mat-icon>
  {{'Clear dates'|translate}}
  </button>
  </mat-menu>
</div>

