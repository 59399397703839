<list-wrapper #wrapper [mainService]="service" navigateItem="users" title="Users"
  [readPerm]="auth.hasPermission(auth.perm.UserRead)" [managePerm]="auth.hasRoles(['Admin', 'CompanyAdmin']) ">

  <mat-action-list class="inv-list">
    <mat-list-item *ngFor="let item of ($data | async); index as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{isLocked: item.isLocked}">
      <app-avatar [avatarId]="item.avatarId" matListAvatar>
        <mat-icon matListIcon matTooltip="{{'userStatus_unverified'|translate}}" *ngIf="!item.emailConfirmed"
          class="unverified">pending</mat-icon>
        <mat-icon matListIcon matTooltip="{{'userStatus_verified'|translate}}" *ngIf="item.emailConfirmed"
          class="verified">verified</mat-icon>
      </app-avatar>



      <strong mat-line>{{ item?.firstName }} {{ item?.middleName }} {{ item?.lastName }}</strong>
      <small mat-line>
        {{ item.email }}
      </small>
      <mat-icon *ngIf="item.isLocked">lock</mat-icon>

      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"
        *ngIf="showOptions && auth.hasPermission(auth.perm.UserManage) && (item.id != auth?.user?.id)">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onResendInvite(item?.id)" *ngIf="!item.emailConfirmed">
          <mat-icon>email</mat-icon>
          {{'Resend email invitation'|translate}}
        </button>
        <button mat-menu-item (click)="onLockUser(item, true)" *ngIf="!item.isLocked && item.id != auth?.user?.id">
          <mat-icon>lock</mat-icon>
          {{'Block user'|translate}}
        </button>
        <button mat-menu-item (click)="onLockUser(item, false)" *ngIf="item.isLocked && item.id != auth?.user?.id">
          <mat-icon>lock_open</mat-icon>
          {{'Unblock user'|translate}}
        </button>
      </mat-menu>
      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>

</list-wrapper>