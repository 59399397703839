import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from 'src/app/_graphql/schema';
import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';
import { UiService } from 'src/app/_services/ui.service';


@Component({
  selector: 'app-session-list-item',
  templateUrl: './session-list-item.component.html',
  styleUrls: ['./session-list-item.component.scss']
})
export class SessionListItemComponent implements OnInit {
  @Input('mode') mode: string = 'full';
  @Input('item') item: Session;

  constructor(
    private sessionsCompanyService: SessionsCompanyService,
    private ui: UiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit(): void {

  }
}
