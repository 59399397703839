<sidebar-wrapper #wrapper>
    <div header>Insert test data to your session</div>
    <div class="io-p-3">
        <p>Test data are presented below. If you want to insert some data to your session input, just click
            on them.</p>
        <div *ngIf="((items$ | async)|filterOn:['checkType']:checkTypes).length == 0">
            <div class="infoblock info">
                We were <strong>unable to find any test data</strong> for selected configuration.</div>
        </div>
    </div>
    <!-- <mat-accordion>
        <mat-expansion-panel *ngFor="let item of items$ | async; index as i">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{item?.firstName}} {{item?.lastName}} {{item?.companyName}}
                </mat-panel-title>
                <mat-panel-description>
                    {{item.description}}
                    <mat-icon>account_circle</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <pre>{{item|json}}</pre>
            <mat-action-row>
                <button mat-button color="primary">Next</button>
            </mat-action-row>
        </mat-expansion-panel>

    </mat-accordion> -->
    <mat-action-list>
        <mat-list-item *ngFor="let item of (items$ | async)|filterOn:['checkType']:checkTypes; index as i"
            (click)="setData(item, wrapper);">
            <app-check-type matListIcon [checkType]="item?.checkType">
            </app-check-type>
            <h3 matLine> {{item?.firstName}} {{item?.lastName}} {{item?.companyName}} </h3>
            <p matLine>
                {{item.description}}
            </p>
        </mat-list-item>
    </mat-action-list>



    <!-- <pre>{{form?.value|json}}</pre>
    <pre>{{items$|async|json}}</pre> -->
    <!-- <button mat-flat-button footer>aaA</button>
    <button mat-flat-button footer color="primary">aaA</button> -->
</sidebar-wrapper>>