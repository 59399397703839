<mat-sidenav-container [hasBackdrop]="menuBackdrop" fxFill autosize>
  <mat-sidenav #sidenav [mode]="menuMode" [ngClass]="{minimized: minimize}" [opened]="menuOpen"
    (click)="menuItemSelected()">
    <div fxFill fxLayout="column">

      <div class="text-center">
        <img src="/assets/images/logo-white.svg" class="logo" *ngIf="!minimize">
        <img src="/assets/images/logo-mini-white.svg" class="logo-mini" *ngIf="minimize">
      </div>
      <mat-divider></mat-divider>
      <div style="position: relative;">
        <div [ngClass]="{'first-level': true, 'open': submenu == ''}">
          <button mat-menu-item routerLink="/admin/translations" *ngIf="auth.hasPermission(auth.perm.SystemRead)"
            routerLinkActive="active">
            <mat-icon>translate</mat-icon>
            <span *ngIf="!minimize">Translations</span>
          </button>
          <button mat-menu-item routerLink="/admin/countries" *ngIf="auth.hasPermission(auth.perm.SystemRead)"
            routerLinkActive="active">
            <mat-icon>flag</mat-icon>
            <span *ngIf="!minimize">Countries</span>
          </button>
          <button mat-menu-item routerLink="/admin/states" *ngIf="auth.hasPermission(auth.perm.SystemRead)"
            routerLinkActive="active">
            <mat-icon>flag</mat-icon>
            <span *ngIf="!minimize">States</span>
          </button>
          <button mat-menu-item routerLink="/admin/sanctions" *ngIf="auth.hasPermission(auth.perm.ProviderRead)"
            routerLinkActive="active">
            <mat-icon>fingerprint</mat-icon>
            <span *ngIf="!minimize">Sanction Lists</span>
          </button>

          <button mat-menu-item routerLink="/admin/providers" *ngIf="auth.hasPermission(auth.perm.ProviderRead)"
            routerLinkActive="active">
            <mat-icon>verified_user</mat-icon>
            <span *ngIf="!minimize">Providers</span>
          </button>
          <button mat-menu-item routerLink="/admin/provider-checks" *ngIf="auth.hasPermission(auth.perm.ProviderRead)"
            routerLinkActive="active">
            <mat-icon>verified</mat-icon>
            <span *ngIf="!minimize">Provider Checks</span>
          </button>
          <!-- <button mat-menu-item routerLink="/admin/fieldsets" *ngIf="auth.hasPermission(auth.perm.SystemRead)"
            routerLinkActive="active">
            <mat-icon>draw</mat-icon>
            <span *ngIf="!minimize">Fieldsets</span>
          </button> -->
          <button mat-menu-item routerLink="/admin/products" (click)="setSubMenu('products')"
            *ngIf="auth.hasPermission(auth.perm.CompanyRead)" routerLinkActive="active">
            <mat-icon>shop</mat-icon>
            <span *ngIf="!minimize">Products</span>
          </button>


          <button mat-menu-item routerLink="/admin/companies" *ngIf="auth.hasPermission(auth.perm.CompanyRead)"
            routerLinkActive="active">
            <mat-icon>business</mat-icon>
            <span *ngIf="!minimize">Companies</span>
          </button>
        </div>
        
        <div [ngClass]="{'second-level': true, 'open':submenu != ''}">
          <div *ngIf="submenu == 'products'"></div>
          <button mat-menu-item routerLinkActive="active" (click)="setSubMenu('')">
            <mat-icon>arrow_back_ios</mat-icon>
            <span *ngIf="!minimize">{{ 'Back' | translate }}</span>
          </button>
          <button mat-menu-item routerLink="/admin/products" *ngIf="auth.hasPermission(auth.perm.ProductRead)"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
              true}">
            <mat-icon>shop</mat-icon>
            <span *ngIf="!minimize">Products</span>
          </button>
          <!-- <button mat-menu-item routerLink="/admin/product-checks" *ngIf="auth.hasPermission(auth.perm.ProductRead)"
            routerLinkActive="active">
            <mat-icon>verified</mat-icon>
            <span *ngIf="!minimize">Product Checks</span>
          </button> -->
          <button mat-menu-item routerLink="/admin/features" *ngIf="auth.hasPermission(auth.perm.ProductRead)"
            routerLinkActive="active">
            <mat-icon>history_edu</mat-icon>
            <span *ngIf="!minimize">Features</span>
          </button>
          <!-- <button mat-menu-item routerLink="/admin/products-features" *ngIf="auth.hasPermission(auth.perm.ProductRead)"
            routerLinkActive="active">
            <mat-icon>edit_note</mat-icon>
            <span *ngIf="!minimize">Product Features</span>
          </button> -->
        </div>
      </div>
        <div style="margin-top: auto;"></div>
  
        <!-- <button mat-menu-item class="documentation" *ngIf="!minimize">
            <mat-icon matTooltip="More Info">info</mat-icon>
            <a class="underline" href="https://docs.xrayaml.com/">Help</a>
          <button mat-icon-button (click)="minimize = !minimize">
            <mat-icon *ngIf="!minimize">chevron_left</mat-icon>
          </button>
        </button>
        <a *ngIf="minimize" href="https://docs.xrayaml.com/" class="infoIcon">
          <mat-icon matTooltip="Help">info</mat-icon>
        </a>
        <button mat-icon-button (click)="minimize = !minimize">
          <mat-icon *ngIf="minimize">chevron_right</mat-icon>
        </button> -->
        <!-- <button mat-menu-item (click)="minimize = !minimize" *ngIf="minimize">
          <mat-icon *ngIf="!minimize">chevron_left</mat-icon>
          <mat-icon *ngIf="minimize">chevron_right</mat-icon>
        </button> -->
        <div class="documentation" *ngIf="!minimize">
          <div>
            <mat-icon matTooltip="More Info">info</mat-icon>
            <a class="underline" href="https://docs.xrayaml.com/" *ngIf="!minimize" target="_blank">Help</a>
          </div>
          <button mat-icon-button (click)="minimize = !minimize">
            <mat-icon *ngIf="!minimize">chevron_left</mat-icon>
          </button>
        </div>
        <button mat-menu-item *ngIf="minimize">
          <a class="underline" href="https://docs.xrayaml.com/" *ngIf="minimize" target="_blank">
            <mat-icon matTooltip="More Info">info</mat-icon>
          </a>
        </button>
        <button mat-menu-item (click)="minimize = !minimize" *ngIf="minimize">
          <mat-icon >chevron_right</mat-icon>
        </button>
      </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
    <mat-toolbar>
      <mat-toolbar-row>
        <button *ngIf="!ui.menuHasBack" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <button *ngIf="ui.menuHasBack" mat-icon-button (click)="ui.getBack(); sidenav.toggle()">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span class="page-title">{{ ui.title() }}</span>

        <span fxFlex></span>
        <app-user-menu></app-user-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <!-- <div class="toolbox">{{ ui.title() }}</div> -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>