import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { catchError, EMPTY, first, iif, switchMap, tap, Unsubscribable } from 'rxjs';
// import { Tag } from 'src/app/_graphql/schema';
import { UiService } from 'src/app/_services/ui.service';
import { Location } from '@angular/common'
import { AuthService } from 'src/app/_services/auth.service';


@Component({
  selector: 'item-wrapper',
  templateUrl: './item-wrapper.component.html',
  styleUrls: ['./item-wrapper.component.scss']
})
export class ItemWrapperComponent implements OnInit, OnDestroy {

  dataId: string;
  @Input('other') other: any;
  @Input('form') form: FormGroup;
  @Input('readPerm') readPerm: boolean;
  @Input('managePerm') managePerm: boolean;
  @Input('normalizeProps') normalizeProps: string[] = ['tags'];
  @Input('isDeleted') isDeleted = false;
  @Input('deleteEnabled') deleteEnabled = false;
  @Input('title') title: string;
  @Input('mainService') mainService: any;
  @Input('navigateList') navigateList: string;
  @Input('backOnSave') backOnSave = true;
  @Input('backOnCreate') backOnCreate = true;
  constructor(
    public auth: AuthService,
    public ui: UiService,
    public router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location) {

    // this.route.paramMap.subscribe((x: any) => {
    //   if (x.params && x.params.id && x.params.id != 'new') {
    //     this.dataId = x.params.id;
    //   } else {
    //     console.log(x.params.id)
    //     if (this.mainService) {
    //       this.mainService.loading = false;
    //     }
    //   }
    // });
  }

  obs$: any;
  ngOnInit() {

    if (!this.mainService)
      throw new Error("No service provided in item wrapper");

    if (this.managePerm) {
      this.form?.enable();
    } else {
      this.form?.disable();
    }

    this.ui.setTitle(this.title);

    this.obs$ = this.route.params.pipe(
      switchMap(params => {
        //iif is using defer to only call the condition function and pick an (inner) Observable when the (outer) Observable is subscribed to.
        //This doesn't really make a difference when used inside switchMap because the code inside switchMap is executed on every emit
        return iif(
          () => params['id'] === "new",
          //if condition is true 
          EMPTY, //emits complete signal
          this.mainService.one(params['id']) // else 
        );
      }),
      // A function that returns an Observable identical to the source, but runs the specified Observer or callback(s) for each item.
      // Used when you want to affect outside state with a notification without altering the notification
      // it can be used to imlement side effects
      tap((res: any) => {
        this.dataId = res?.id;
        if (res && res.hasOwnProperty('deleted') && this.managePerm) {
          this.deleteEnabled = true;
          this.isDeleted = (res?.deleted != null);
        }
        if (res.deleted != null)
          this.form.disable();
        if (this.form)
          this.form.patchValue(res);
      }),
      catchError((err) => {
        this.ui.snack(err.message);
        console.log(err.mesage);
        return EMPTY;
      })
    );

  }

  // cleanUp(_data) {
  //   var data;
  //   if (Array.isArray(_data)) {
  //     data = Object.assign([], _data);
  //   } else {
  //     data = Object.assign({}, _data);
  //   }

  //   var keys = Object.keys(data);
  //   keys.forEach(key => {
  //     console.log(key, typeof data[key])
  //     if (key == '__typename') {
  //       delete data[key];
  //     }
  //     console.log(typeof data[key]);

  //     if (typeof data[key] == "object" && data[key] != null) {
  //       data[key] = this.cleanUp(data[key]);
  //     }
  //   });
  //   return data;
  // }

  submit() {
    this.ui.loaderShow();

    var data = Object.assign({}, this.form.value);

    this.normalizeProps?.forEach(elem => {
      if (elem == 'checkTypes') {
        if (data[elem])
          data[elem] = this.normalizeCheckType(data[elem]);
      } else {
        if (data[elem])
          data[elem] = this.normalizeTags(data[elem]);
      }
    });

    if (data['id']) {
      this.mainService.modify({ item: data }).subscribe(
        (retval: any) => {
          this.ui.loaderHide("Item saved");
          this.form.patchValue(retval);
          if (this.backOnSave)
            this.getBack();
        },
        (error: any) => {
          this.ui.loaderHide(error.message);
          this.ui.snack(error.message);
        }
      );
    } else {
      var create$ = this.mainService.create({ item: data }).subscribe(
        (retval: any) => {
          this.ui.loaderHide("Item created");
          if (this.form) {
            this.form.patchValue(retval);
          }
          if (this.backOnCreate)
            this.getBack();
          create$.unsubscribe();
        },
        (error: any) => {
          this.ui.loaderHide(error.message);
          this.ui.snack(error.message);
          create$.unsubscribe();
        }
      );
    }
  }
  publish() { }

  del() {
    if (!this.dataId)
      return;

    this.mainService.delete({ id: this.dataId }).pipe(
      first(),
      catchError((err) => {
        this.ui.snack(err.message);
        return EMPTY
      })
    ).subscribe(() => {
      this.getBack();
    });
  }

  restore() {
    if (!this.dataId)
      return;

    this.mainService.restore({ id: this.dataId }).pipe(
      first(),
      catchError(err => {
        this.ui.loaderHide(err.message);
        return EMPTY;
      })
    ).subscribe(() => {
      this.ui.snack("Item restored");
      this.getBack();
    });
  }

  getBack() {
    this.location.back()
    // this.router.navigate(['/admin/', this.navigateList]);
  }
  normalizeCheckType(items: any) { // tags: Tag[]
    if (!items || items.length == 0)
      return items;

    return items.map(item => {

      var r = { checkType: item.checkType };

      return r;
    });
  }
  normalizeTags(tags: any) { // tags: Tag[]
    if (!tags || tags.length == 0)
      return tags;

    return tags.map(tag => {

      var r = { id: tag.id };

      return r;
    });
  }

  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  ngOnDestroy() {
    if (!this.other)
      return;
    Object.keys(this.other)?.forEach(e => {
      if (typeof this.other[e]?.unsubscribe === 'function')
        this.other[e]?.unsubscribe();
    });
  }
}
