import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscribable } from 'rxjs';
import { Company, User } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companies.service';
import { UsersService } from 'src/app/_services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  user$: Subscribable<User>;
  company$: Subscribable<Company>;
  segment: String;
  @Input() showCompanyName: boolean = false;
  constructor(
    private companies: CompaniesService,
    private router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public apollo: Apollo,
    private currentUserService: UsersService,
  ) {
    this.user$ = this.currentUserService.currentUser();
  }
  ngOnInit(): void {
    this.segment = window.location.href.split('/')[3];
    if (this.showCompanyName)
      this.company$ = this.companies.currentCompany();
  }
  resendVerificationEmail() {
    var k = this.currentUserService.resendVerificationEmail().subscribe({
      next: res => {
        k.unsubscribe();
      }
    });
  }
  getAvatar(company: Company): string {
    return company?.avatarId ? `${environment.imageThumbnails}/${company.avatarId}` : ''
  }

  openCompany(companyId: string) {

    this.auth.companyId = companyId;
    this.companies.switchCompany(companyId).subscribe({
      next: user => {
        this.auth.storeUser(user);
        this.router.navigate(['/redirect']);
        // setTimeout(() => {

        //   this.router.navigate(['/', 'redirect']);
        // }, 200);

      }
    });
  }
}
