<item-wrapper #item [mainService]="service" [form]="form" navigateList="products-features" title="Product Feature"
  [readPerm]="auth.hasPermission(auth.perm.ProductRead)" [managePerm]="auth.hasPermission(auth.perm.ProductManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">

    <!-- <mat-form-field fxFlex>
      <mat-label>{{ 'Name' }}</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description' }}</mat-label>
      <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field> -->
    <mat-form-field fxFlex>
      <mat-label>{{ 'Product' |translate }}</mat-label>
      <mat-select formControlName="productId">
        <mat-option *ngFor="let item of $products | async" [value]="item.id">
          {{(item.name) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'Feature' |translate }}</mat-label>
      <mat-select formControlName="featureId">
        <mat-option *ngFor="let item of $features | async" [value]="item.id">
          {{(item.name) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>



  </form>
</item-wrapper>