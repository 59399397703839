import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EventBusService {

    data$ = new Subject<EventBusServiceData>();
    constructor() { }
}

export type EventBusServiceData = {
    name: string;
    data: any;
};