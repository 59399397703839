import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { PersonOrBusiness } from "../_graphql/schema";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
})
export class InventoriesService extends BaseService<PersonOrBusiness> {
    constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
        super(apollo, sb);
        this.selectOneFields = gql`
            fragment SelectOneFields on PersonOrBusiness {
                __typename
            }`;

        this.selectAllFields = gql`
            fragment SelectAllFields on PersonOrBusiness {
                ...on Person {
                    id
                    firstName
                    lastName
                    referenceId
                    numberOfSessions
                    firstSession { id user {id firstName middleName lastName} created overallCheckStatus }
                    lastSession { id user {id firstName middleName lastName} created overallCheckStatus apiToken {name} }
                }
                ... on Business {
                    id
                    companyName
                    referenceId
                    numberOfSessions
                    firstSession { id user {id firstName middleName lastName} created overallCheckStatus }
                    lastSession { id user {id firstName middleName lastName} created overallCheckStatus apiToken {name} }
                }
            }`;

        this.initGql("inventories", "inventories");
        this.queryParams.sortOrder = "ASC";
        this.queryParams.sortBy = "name";
    }
}
