<mat-sidenav-container [hasBackdrop]="menuBackdrop" fxFill autosize>
  <mat-sidenav #sidenav [mode]="menuMode" [ngClass]="{minimized: minimize}" [opened]="menuOpen"
    (click)="menuItemSelected()">
    <div fxFill fxLayout="column">

      <div class="text-center">
        <img src="/assets/images/logo-white.svg" class="logo" *ngIf="!minimize">
        <img src="/assets/images/logo-mini-white.svg" class="logo-mini" *ngIf="minimize">
      </div>
      <mat-divider></mat-divider>
      <div style="position: relative;">
        <div [ngClass]="{'first-level': true, 'open':!expanded}">
          <button mat-menu-item routerLink="/admin/companies/" routerLinkActive="active"
            *ngIf="auth.hasPermission(auth.perm.AllCompaniesRead)">
            <mat-icon>arrow_back_ios</mat-icon>
            <span *ngIf="!minimize">{{ 'All Companies' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item routerLink="/company/dashboard" *ngIf="auth.hasPermission(auth.perm.SessionRead)"
            routerLinkActive="active">
            <mat-icon>dashboard</mat-icon>
            <span *ngIf="!minimize">Dashboard</span>
          </button>
          <button mat-menu-item routerLink="/company/sessions" *ngIf="auth.hasPermission(auth.perm.SessionRead)"
            routerLinkActive="active">
            <mat-icon>fact_check</mat-icon>
            <span *ngIf="!minimize">Sessions</span>
          </button>
          <button mat-menu-item routerLink="/company/inventory" *ngIf="auth.hasPermission(auth.perm.SessionRead)"
            routerLinkActive="active">
            <mat-icon>fact_check</mat-icon>
            <span *ngIf="!minimize">Inventory</span>
          </button>
          <button mat-menu-item routerLink="/company/users" *ngIf="auth.hasPermission(auth.perm.CompanyRead)"
            routerLinkActive="active">
            <mat-icon>supervised_user_circle</mat-icon>
            <span *ngIf="!minimize">Users</span>
          </button>

          <button mat-menu-item [routerLink]="['/','company','profile', (company$|async)?.id]"
            *ngIf="auth.hasPermission(auth.perm.CompanyRead)" routerLinkActive="active">
            <mat-icon>business</mat-icon>
            <span *ngIf="!minimize">Company Profile</span>
          </button>
        </div>
        <div [ngClass]="{'second-level': true, 'open':expanded}">
          <button mat-menu-item routerLink="/company/sessions" routerLinkActive="active"
            (click)="setSubMenuOpen(!true)">
            <mat-icon>arrow_back_ios</mat-icon>
            <span *ngIf="!minimize">{{ 'Sessions' | translate }}</span>
          </button>
          <!-- <button mat-menu-item routerLink="/company/configurations"
            *ngIf="auth.hasPermission(auth.perm.ConfigurationRead)" routerLinkActive="active">
            <mat-icon>dynamic_feed</mat-icon>
            <span *ngIf="!minimize">Configurations</span>
          </button> -->
          <button mat-menu-item routerLink="/company/checks" *ngIf="auth.hasPermission(auth.perm.CompanyCheckRead)"
            routerLinkActive="active">
            <mat-icon>verified</mat-icon>
            <span *ngIf="!minimize">Available Checks</span>
          </button>
          <button mat-menu-item routerLink="/company/tokens" *ngIf="auth.hasPermission(auth.perm.CompanyRead)"
            routerLinkActive="active">
            <mat-icon>generating_tokens</mat-icon>
            <span *ngIf="!minimize">Tokens</span>
          </button>
          <button mat-menu-item routerLink="/company/subscriptions" *ngIf="auth.hasPermission(auth.perm.CompanyRead)"
            routerLinkActive="active">
            <mat-icon>store</mat-icon>
            <span *ngIf="!minimize">Subscriptions</span>
          </button>
          <button mat-menu-item routerLink="/company/invoices" *ngIf="auth.hasPermission(auth.perm.CompanyRead)"
            routerLinkActive="active">
            <mat-icon>receipt</mat-icon>
            <span *ngIf="!minimize">Invoices</span>
          </button>

        </div>
      </div>



      <div style="margin-top: auto;"></div>

      <button mat-menu-item (click)="minimize = !minimize" *ngIf="minimize">
        <mat-icon *ngIf="!minimize">chevron_left</mat-icon>
        <mat-icon *ngIf="minimize">chevron_right</mat-icon>
        <span *ngIf="!minimize">Show as Rail</span>
      </button>
      <div class="documentation" *ngIf="!minimize">
        <mat-icon matTooltip="More Info">info</mat-icon>
        <a class="underline" href="https://docs.xrayaml.com/" target="_blank">Help</a>
      </div>
      <a *ngIf="minimize" href="https://docs.xrayaml.com/" class="infoIcon" target="_blank">
        <mat-icon matTooltip="Help">info</mat-icon>
      </a>
      <button mat-menu-item (click)="setSubMenuOpen(true)">
        <mat-icon>settings</mat-icon>
        <span *ngIf="!minimize">Settings</span>
        <button *ngIf="!minimize" mat-icon-button class="minimise-btn" (click)="onMinimise($event)">
          <mat-icon *ngIf="!minimize">chevron_left</mat-icon>
        </button>
      </button>


    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
    <mat-toolbar>
      <mat-toolbar-row>
        <button *ngIf="!ui.menuHasBack" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <button *ngIf="ui.menuHasBack" mat-icon-button (click)="ui.getBack();sidenav.toggle()">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span class="page-title">{{ ui.title() }}</span>
        <span fxFlex></span>
        <app-user-menu [showCompanyName]="true"></app-user-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <!-- <div class="toolbox">{{ ui.title() }}</div> -->
    <div class="sidebar-outlet">
      <router-outlet name='sidebar'></router-outlet>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>