import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { map, Subscribable } from "rxjs";
import { Purchase } from "../_graphql/schema";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
})
export class PurchasesService extends BaseService<Purchase> {
    constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
        super(apollo, sb);
        this.selectOneFields = gql`
            fragment SelectOneFields on Purchase {
                id
                status
            }
    `;

        this.selectAllFields = gql`
            fragment SelectAllFields on Purchase {
                id
                created
                status
                paymentMethod
                createdBy {lastName firstName id }
                isActive
                isInSubscription
                price
                startOfSubscription
                endOfSubscription
                productName
                purchaseItems {
                    id
                    includedChecks
                    spentChecks
                    price
                    days
                    productCheck { id providerCheck { id name } paymentInterval }
                }
                product {
                    id
                    name   
                    icon
                    order
                    description
                    alternateDescription
                    isVisible
                    isDisabled
                    checks(showDeleted: null, skip: 0, take: 1000) { id created deleted   name description includedChecks paymentInterval nextPrice productId providerCheckId providerCheck { id name }}
                    features(showDeleted: null, skip: 0, take: 1000) { id icon order name description }
                    oldPrice
                    price
                    paymentOption
                }
            }
  `;

        this.initGql("purchase");
        this.queryParams.sortOrder = "DESC";
        this.queryParams.sortBy = "created";
    }


    gqlCreditCard: DocumentNode = gql` query creditCard { creditCard } `;

    cardDetailsWQ: any;
    public creditCard(): Subscribable<String> {
        if (!this.cardDetailsWQ) {
            this.cardDetailsWQ = this.apollo.watchQuery({
                query: this.gqlCreditCard,
                fetchPolicy: 'network-only'
            });
            this.addRefetchQuery(this.gqlCreditCard)
        }
        return this.cardDetailsWQ.valueChanges.pipe(
            map<any, String>((result: any) => {
                if (!result || !result.data) return null;
                const keys = Object.keys(result.data);
                if (result.data && keys.length) {
                    var r = result.data[keys[0]];
                    return r;
                }
                return null;
            })
        );
    }
    gqlNextSubscription: DocumentNode = gql` query nextSubscription { company {
        id
        nextSubscription {
          id
          price
          product {
            id
            name
            price
            order
          },
          startOfSubscription
          startOfNextSubscription
          endOfSubscription
        }
    }} `;
    nextSubscriptionWQ: any;
    public nextSubscription(): Subscribable<Purchase> {
        if (!this.nextSubscriptionWQ) {
            this.nextSubscriptionWQ = this.apollo.watchQuery({
                query: this.gqlNextSubscription,
                fetchPolicy: 'network-only'
            });
            this.addRefetchQuery(this.gqlNextSubscription)
        }
        return this.nextSubscriptionWQ.valueChanges.pipe(
            map<any, String>((result: any) => {
                if (!result || !result.data) return null;
                const keys = Object.keys(result.data);
                if (result.data && keys.length) {
                    var r = result.data[keys[0]];
                    return r;
                }
                return null;
            })
        );
    }
}
