import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { LogoutModule } from "../company/logout/logout.module";
import { MaterialModule } from "../material.module";
import { DirectivesModule } from "../_directives/directives.module";
import { PipesModule } from "../_pipes/pipes.module";
import { CheckTypeComponent } from "./check-type/check-type.component";
import { CompanyChangeStatusDialogComponent } from "./dialog/company-change-status-dialog/company-change-status-dialog.component";
import { ConfirmationDialogComponent } from "./dialog/confirmation-dialog/confirmation-dialog.component";
import { NoteItemComponent } from "./dialog/notes-dialog/note-item/note-item.component";
import { NotesDialogComponent } from "./dialog/notes-dialog/notes-dialog.component";
import { LabelExpanderComponent } from "./label-expander/label-expander.component";
import { SidebarWrapperComponent } from "./sidebar-wrapper/sidebar-wrapper.component";
import { CompanyStatusComponent } from "./statuses/company-status/company-status.component";
import { PurchaseStatusComponent } from "./statuses/purchase-status/purchase-status.component";
import { TransactionStatusComponent } from "./statuses/transaction-status/transaction-status.component";
import { UserMenuComponent } from "./user-menu/user-menu.component";
import { RangePickerComponent } from './range-picker/range-picker.component';
import { SetPaymentMethodDialogComponent } from './dialog/set-payment-method-dialog/set-payment-method-dialog.component';
import { FormBuilderModule } from "./form-builder/form-builder.module";



@NgModule({
    declarations: [
        ConfirmationDialogComponent,
        UserMenuComponent,
        CheckTypeComponent,
        CompanyChangeStatusDialogComponent,
        NotesDialogComponent,
        NoteItemComponent,
        CompanyStatusComponent,
        TransactionStatusComponent,
        PurchaseStatusComponent,
        LabelExpanderComponent,
        SidebarWrapperComponent,
        RangePickerComponent,
        SetPaymentMethodDialogComponent,
    ],
    imports: [
        DirectivesModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        RouterModule,
        PipesModule,
        CommonModule,
        FormBuilderModule
    ],
    exports: [
        DirectivesModule,
        CommonModule,
        TranslateModule,
        CheckTypeComponent,
        UserMenuComponent,
        CompanyStatusComponent,
        TransactionStatusComponent,
        PurchaseStatusComponent,
        LogoutModule,
        LabelExpanderComponent,
        SidebarWrapperComponent,
        RangePickerComponent

    ],
    providers: [],
    bootstrap: [],
})
export class ComponentsModule { }
