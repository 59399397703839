import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subscribable } from 'rxjs';
import { CompanyToken } from '../_graphql/schema';
import { BaseService } from './base.service';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class TokensService extends BaseService<CompanyToken> {
  selectOneFields = gql`
  fragment SelectOneFields on CompanyToken {
    id name isAllowed 
    permissions ipWhitelist webhookAuthKey webhookUrl token
    created expiration lastUsed modified 
    userId user { firstName middleName lastName}
  }`;

  selectAllFields = gql`
  fragment SelectAllFields on CompanyToken {
    id name isAllowed 
    permissions token
    created expiration lastUsed modified deleted
    userId user { firstName middleName lastName}
  }`;



  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql('companyToken');
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";

    // override create mutation for token field
    this.createMutation = gql`
      mutation ccreateCompanyToken($item: CompanyTokenInput!) {
        createCompanyToken(
          item: $item
        ) {
        token
          ...SelectOneFields
        }
      }${this.selectOneFields}`;
  }

}




















