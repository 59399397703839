<item-wrapper #item [mainService]="tokens" [form]="form" navigateList="tokens" title="Token"
  [readPerm]="auth.hasPermission(auth.perm.PurchaseManage)" [managePerm]="auth.hasPermission(auth.perm.PurchaseManage)"
  [backOnCreate]="false" [deleteEnabled]="true">

  <div class="infoblock notice" fxFlex>
    <p *ngIf="!form.get('id').value" class="hint">
      <mat-icon color="primary">info</mat-icon>
      <strong>{{ "token_hint_before_create" | translate }}</strong>
    </p>
    <p *ngIf="form.get('token').value" class="hint">
      <mat-icon color="primary">info</mat-icon>
      <strong>{{ "token_hint_after_create" | translate }}</strong>
    </p>
    <p *ngIf="form.get('id').value && !form.get('token').value" class="hint">
      <mat-icon color="primary">info</mat-icon>
      <strong>{{ "About the token" | translate }}</strong>
    </p>
    <p>
      A token allows you to authenticate with our API. It is a secret, like a
      password, that should be protected. It is used for building third-party
      implementations based on our API.
    </p>
    <p>
      You can create more than one token and you should consider creating a
      different token for different access uses.
    </p>
    <p>
      If the feature you're trying to access is not included in your current subscription,
      you will not be able to use it. Upgrade your subscription to access this feature,
      or consider selecting a different plan that includes the feature you need.
      Read more in the <a class="underline" href="https://docs.xrayaml.com/docs/XRAYAPI/authentication"> Documentation.</a>
    </p>
  
  </div>
  <div *ngIf="form.get('token').value" class="infoblock notice" style="cursor: pointer;">
    <div class="token">
      <strong>Token</strong>
      <mat-icon color="primary" [cdkCopyToClipboard]="form.get('token').value"
        (cdkCopyToClipboardCopied)="onClipboardCopy()">content_copy</mat-icon>
    </div>
    <p class="tokenText">{{form.get('token').value}}</p>
  </div>
  <form [formGroup]="form" (onsubmit)="item.submit()" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="8px">
      <mat-form-field fxFlex>
        <mat-label>{{ "Name" }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field fxFlex="20" *ngIf="!form.get('id').value">
        <mat-label>Expires Date</mat-label>
        <input matInput min [matDatepicker]="picker" formControlName="expiration" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="20" *ngIf="form.get('id').value">
        <mat-label>Expires Date</mat-label>
        <input matInput disabled="true" value="{{ form.get('expiration').value | date }}" />
        <button mat-icon-button matSuffix disabled>
          <mat-icon>event</mat-icon>
        </button>
      </mat-form-field>
      <!-- <div fxFlex="54px"></div> -->
    </div>

    <!-- <mat-form-field fxFlex *ngIf="form.get('token').value">
      <mat-label>{{ "Token" }}</mat-label>
      <input
        matInput
        [type]="isHidden ? 'password' : 'text'"
        formControlName="token"
        [readonly]="true"
      />
      <button mat-icon-button matSuffix (click)="isHidden = !isHidden">
        <mat-icon *ngIf="isHidden">visibility</mat-icon>
        <mat-icon *ngIf="!isHidden">visibility_off</mat-icon></button
      ><button
        mat-icon-button
        matSuffix
        [cdkCopyToClipboard]="form.get('token').value"
        (cdkCopyToClipboardCopied)="onClipboardCopy()"

      >
        <mat-icon>content_copy</mat-icon>
      </button>
      <mat-hint>{{ "token_hint_after_create" | translate }} </mat-hint>
    </mat-form-field> -->
    <mat-form-field fxFlex>
      <mat-label>{{ "Webhook URL" | translate }}</mat-label>
      <input matInput formControlName="webhookUrl" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ "Webhook AuthKey" | translate }}</mat-label>
      <input matInput formControlName="webhookAuthKey" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ "IP Whitelist" | translate }}</mat-label>
      <input matInput formControlName="ipWhitelist" />
    </mat-form-field>

    <div *ngIf="!form.get('id')?.value; else viewOnly">
      <div *ngFor="let item of permissionItems; let i = index">
        <mat-checkbox color="primary" matInput type="checkbox" (click)="$event.stopPropagation()"
          (change)="$event ? toggleRow(item) : null" [checked]="selectionModel.isSelected(item)" [disabled]="selectionModel.selected.indexOf('SESSION_CREATE')>-1 &&(item === 'SESSION_TEST_CREATE') 
          || selectionModel.selected.indexOf('SESSION_CREATE')>-1 &&(item === 'SESSION_READ') 
          || selectionModel.selected.indexOf('SESSION_TEST_CREATE')>-1 &&(item === 'SESSION_READ')">{{ "permission_" +
          item | translate }}
        </mat-checkbox>
      </div>
    </div>
    <ng-template #viewOnly>
      <div *ngFor="let item of permissionItems; let i = index">
        <mat-checkbox color="primary" matInput type="checkbox"
          [checked]="form.get('permissions').value.indexOf(item) > -1" disabled>{{ "permission_" + item | translate }}
        </mat-checkbox>
      </div>
    </ng-template>
    <br />

    <mat-checkbox color="primary" matInput type="checkbox" formControlName="isAllowed">Is Allowed</mat-checkbox>
  </form>
</item-wrapper>