<item-wrapper #item [mainService]="service" [form]="form" navigateList="fieldsets" title="Fieldset"
  [readPerm]="auth.hasPermission(auth.perm.SystemRead)" [managePerm]="auth.hasPermission(auth.perm.SystemManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column" fxLayoutGap="12px"
    style="padding: 0 12px;">
    <div fxLayout="column" class="form-row" fxLayoutGap="12px">
      <mat-form-field class="full-width">
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Description' }}</mat-label>
        <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="isSystemLocked">is system locked?</mat-checkbox>
    </div>
    <app-form-builder-definition [conf]="form.value?.rootField"></app-form-builder-definition>

    <pre>{{ form.value?.rootField | json }}</pre>
  </form>
</item-wrapper>