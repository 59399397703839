import { Component, } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {

  constructor(
    private router: Router,
    private ui: UiService,
    private _location: Location

  ) {

  }
  backClicked() {
    this._location.back();
  }
}
