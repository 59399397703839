import { Component, Input } from '@angular/core';
import { Communication } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-view-communication',
  templateUrl: './view-communication.component.html',
  styleUrls: ['./view-communication.component.scss']
})
export class ViewCommunicationComponent {
  @Input('communications') communications: Communication[] = null;

  constructor(
  ) { }
  itemCount(item: Communication): number {
    var num = 0;
    if (item?.ipAddress) num++;
    if (item?.linkedIn) num++;
    if (item?.phone) num++;
    if (item?.website) num++;
    if (item?.email) num++;

    return num;
  }
}
