import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { Subscribable } from "rxjs";
import { Product } from "../_graphql/schema";
import { BaseService } from "./base.service";
import { UiService } from "./ui.service";

@Injectable({
    providedIn: "root",
})
export class ProductsService extends BaseService<Product> {
    constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
        super(apollo, sb);
        this.selectOneFields = gql`
            fragment SelectOneFields on Product {
                id
                coverId
                name
                icon
                order
                description
                alternateDescription
                price
                oldPrice
                montlyFee
                isDisabled
                isVisible
                isDefault
                created
                deleted
                paymentOption
                checks(showDeleted: null, skip: 0, take: 1000) { id created deleted icon order name description includedChecks paymentInterval nextPrice productId providerCheckId providerCheck { id name }}
                features(showDeleted: null, skip: 0, take: 1000) { id name description}
            }`;

        this.selectAllFields = gql`
            fragment SelectAllFields on Product {
                id
                name
                icon
                order
                description
                alternateDescription
                isVisible
                isDisabled
                isDefault
                paymentOption
                checks(showDeleted: null, skip: 0, take: 1000) { id created deleted   name description includedChecks paymentInterval nextPrice productId providerCheckId providerCheck { id name }}
                features(showDeleted: null, skip: 0, take: 1000) { id icon order name description }
                oldPrice
                price
                montlyFee
                created
                deleted
            }`;

        this.initGql("product");
        this.queryParams.sortOrder = "ASC";
        this.queryParams.sortBy = "order";
    }

    gqlPayProduct: DocumentNode = gql`
    mutation payProduct($productId: ID!) {
        payProduct(productId: $productId) 
    }`;

    public payProduct(productId: string): Subscribable<any> {
        return super.mutation(this.gqlPayProduct, { productId });
    }

    gqlCancelProduct: DocumentNode = gql`
    mutation cancelProduct{
        cancelProduct{
            id
            isActive
        }
    }`;

    public cancelProduct(refetch): Subscribable<any> {
        this.refetchAdditionalQueries = refetch;
        return super.mutation(this.gqlCancelProduct, {},);
    }
}
