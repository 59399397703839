<div *ngIf="item">
    <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="space-between center" style="margin: 24px">
        <div style="width: 1px;"></div>
        <app-transaction-status [status]="item?.status"></app-transaction-status>
        <div fxFlex>
            <h2>{{item.name}}
                <small>{{item.created | date}}</small>
            </h2>
        </div>

        
        <button mat-stroked-button *ngIf="auth.hasPermission(auth.perm.PurchaseRead)" (click)="downloadInvoice()" [disabled]="creatingPdf">
           {{'Download'|translate}}
        </button> 
        <button mat-raised-button *ngIf="auth.hasPermission(auth.perm.PurchaseManage)"
        [disabled]="item?.status === 'COMPLETED' || item?.status === 'CREATED'"
        color="primary" (click)="pay(item?.id)">{{'Pay'|translate}}</button>
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" *ngIf="auth.hasPermission(auth.perm.InvoiceManage) || 
        auth.hasPermission(auth.perm.AllCompaniesManage)">
            <mat-icon>more_vert</mat-icon>
        </button>
           
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="item?.status != 'COMPLETED'" (click)="setInvoiceStatus('COMPLETED')"> 
                <mat-icon class="ok">check_circle</mat-icon>{{'Set Completed'|translate}}
            </button>
            <button mat-menu-item *ngIf="item?.status != 'INVOICED'" (click)="setInvoiceStatus('INVOICED')">  
                <mat-icon class="warn">outbound</mat-icon>{{'Set Invoiced'|translate}}</button>
            <button mat-menu-item *ngIf="item?.status != 'CANCELED'" (click)="setInvoiceStatus('CANCELED')"> 
                <mat-icon class="error">dangerous</mat-icon>{{'Set Canceled'|translate}}
            </button>     
        </mat-menu>
        <div style="width: 1px;"></div>
    </div>
    <div fxLayout="column" class="inv-list">
        <h3>{{'Summary'|translate}}</h3>
        <table mat-table [dataSource]="summary" fxFlex>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Item </th>
                <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
                <td mat-footer-cell *matFooterCellDef> <strong>Total</strong> </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef class="text-center"> {{'Quantity' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-center" width="140"> {{element?.quantity}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef class="text-right"> {{'Unit Price' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-right" width="140"> {{element?.price | currency }}
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>
            <ng-container matColumnDef="subtotal">
                <th mat-header-cell *matHeaderCellDef class="text-right"> {{'Price' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-right" width="140"> {{element?.subtotal | currency }}
                </td>
                <td mat-footer-cell *matFooterCellDef class="text-right"><strong>{{getTotalCost() | currency }}</strong>
                </td>

            </ng-container>


            <tr mat-header-row *matHeaderRowDef="summaryColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: summaryColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="summaryColumns"></tr>

        </table>
    </div>

    <div fxLayout="column" class="inv-list">
        <h3>{{'Transactions'|translate}}</h3>
        <table mat-table [dataSource]="transactions" fxFlex>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef width="80"> {{'Status'|translate}} </th>
                <td mat-cell *matCellDef="let element" width="80">
                    <app-transaction-status [status]="element?.status"></app-transaction-status>
                </td>
            </ng-container>
            <ng-container matColumnDef="payedAmount">
                <th mat-header-cell *matHeaderCellDef class="text-right"> {{'Payed Amount' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-right" width="140"> {{element?.payedAmount | currency
                    }}
                </td>
            </ng-container>
            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef class="text-right"> {{'Subtotal Price' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-right" width="140"> {{element?.totalPrice| currency
                    }}
                </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> {{'Created' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element?.created | date }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="transactionColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: transactionColumns;"></tr>
        </table>
    </div>
</div>