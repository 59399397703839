<div [ngClass]="type" [style.backgroundImage]="(image ? 'url('+ image +')' : '')">

  <mat-progress-spinner *ngIf="loading" [diameter]="44" [strokeWidth]="2" [mode]="profileProgressMode" diameter="36">
  </mat-progress-spinner>
  <label *ngIf="!image && !_isDisabled">
    <mat-icon color="primary">cloud_upload</mat-icon>
    <input class="file" accept="image/*" type="file">
  </label>
  <button *ngIf="image" type="button" (click)="remove()" [disabled]="_isDisabled">
    <mat-icon color="warn">clear</mat-icon>
  </button>
  <input type="hidden" [value]="data" (change)="onChange($event)" (keyup)="onChange($event)" />
</div>