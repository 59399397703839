import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, EMPTY, first, Observable, Subscribable, Subscription, Unsubscribable } from 'rxjs';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { Company, CompanyCheck, CompanyStatus, Configuration, Field, FieldSet, ResponseTransport, SessionInput } from 'src/app/_graphql/schema';
import { Session } from 'src/app/_graphql/schema-session';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companies.service';
import { ConfigurationsService } from 'src/app/_services/configurations.service';
import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';
import { UiService } from 'src/app/_services/ui.service';
import { AdressesFormItem } from './addresses-form-item/addresses-form-item.component';
import { BusinessDocumentsFormItem } from './business-documents-form-item/business-documents-form-item.component';
import { CommunicationsFormItem } from './communications-form-item/communications-form-item.component';
import { DocumentsFormItem } from './documents-form-item/documents-form-item.component';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';
@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'accent' },
  }]
})
export class SessionComponent implements OnInit, OnDestroy {
  data$: Subscribable<Session>;
  responseTransports = ResponseTransport;
  selectedMode: string;
  // form: FormGroup;
  $configurations: Subscribable<Configuration[]>;
  checksSupportedByConfiguration = [];

  chkWaitForResult = new FormControl();
  $onFormSent: Subscription;

  formType: string;
  isLinear = false;
  isEditable = true;
  hidePersonStep = true;
  hideBusinessStep = true;

  selectedIndex = 0;

  sessionCreated = false;
  cloneData: any;
  testData:any;
  formBuilderForm: any;
  formIsInvalid: boolean = true;
  $configuration: Unsubscribable;
  $configurationOne: Unsubscribable;


  
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('businessCommunicationsForm') businessCommunicationsForm: CommunicationsFormItem;
  @ViewChild('businessAddressesForm') businessAddressesForm: AdressesFormItem;
  @ViewChild('businessDocumentsForm') businessDocumentsForm: BusinessDocumentsFormItem;

  @ViewChild('personCommunicationsForm') personCommunicationsForm: CommunicationsFormItem;
  @ViewChild('personAddressesForm') personAddressesForm: AdressesFormItem;
  @ViewChild('personDocumentsForm') personDocumentsForm: DocumentsFormItem;
  sessionId = this.activatedRoute.snapshot.paramMap.get('id');
  conf: any = null;
  form = this.fb.group({
    applicationId: [null, []],
    configurationId: [null, Validators.required],
    // businesses: new FormArray([]),
    // persons: new FormArray([]),
    checks: new FormArray([]),
    description: [null, []],
    code: [null, [Validators.maxLength(10)]],
    isTesting: [false, []],
    webHook: [null, []],
    responseTransport: ['DEV_NULL', []],
  });


  constructor(
    private sessionsCompanyService: SessionsCompanyService,
    public dialog: MatDialog,
    private ui: UiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    public configurationsService: ConfigurationsService,
    public service: SessionsCompanyService,
    private fb: FormBuilder,
    private copmaniesService: CompaniesService,
  ) {
    this.sessionCreated = false;
  }
  ngOnDestroy(): void {
    this.$onFormSent?.unsubscribe();
    this.$configuration?.unsubscribe();
  }


  ngOnInit() {
   

    (<Observable<Company>>this.copmaniesService.currentCompany()).pipe(
      first(),
      catchError(err => {
        this.ui.snack(err.message);
        return EMPTY
      })
    ).subscribe(res => {
      if (res.status == CompanyStatus.Applied) {
        this.form.get('isTesting').setValue(true);
        this.form.get('isTesting').setValidators([Validators.requiredTrue]);
        this.chkWaitForResult.disable();
      }
    });
      if (this.sessionId) {
      let unsub = this.sessionsCompanyService.one(this.sessionId).subscribe({
        next: res => {
          this.cloneData = res;
          this.form.patchValue({configurationId:this.cloneData?.configurationId, isTesting:this.cloneData?.isTesting});
          unsub?.unsubscribe();
        }
      })
    }

    this.chkWaitForResult.valueChanges.subscribe((val) => { this.form.patchValue({ responseTransport: val ? 'LONG_POLLING' : 'DEV_NULL' }) })
    this.$configuration = this.form.get('configurationId').valueChanges
    .pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(configurationId => {
      if (configurationId)
      this.$configurationOne = this.configurationsService.configurationOnCreateSession(configurationId).subscribe({
          next: cnf => {
            this.conf = cnf['fieldSet']?.rootField;
            console.log(this.conf)
            this.$configurationOne.unsubscribe();
            //find all supported checktypes by configuration to show test data if needed
            // this.checksSupportedByConfiguration = []
            // cnf.checks?.forEach(check => {
            //   check?.providerCheck?.checkTypes?.forEach(providerCheckType => {
            //     if (this.checksSupportedByConfiguration.indexOf(providerCheckType?.checkType) == -1)
            //       this.checksSupportedByConfiguration.push(providerCheckType?.checkType);
            //   });

            // });

            // this.onTypeChanged(cnf.checks[0]?.providerCheck?.sourceType ? cnf.checks[0]?.providerCheck?.sourceType : 'PERSON');
          }
        })
    });

    this.$onFormSent = this.sessionsCompanyService.sessionTestDataForm$.subscribe({
      next: testData => {
        if (testData)
          this.testData = testData;
          // this.setupTestFormData(testData)
      }
    })

    this.$configurations = this.configurationsService.all();

  }
  isValid(ev) {
    this.formIsInvalid = ev;
  }
  setApplicationId(id: String): void {
    this.form.patchValue({ 'applicationId': id, 'configurationId': null });
    this.selectedIndex = 1;
  }
  setConfigurationId(id: String): void {
    this.form.patchValue({ 'configurationId': id });
    this.selectedIndex = 1;
  }
  formValue(ev) {
    this.formBuilderForm = ev;
  }

  onSubmit() {
    if (!this.form.valid)
      return;
    console.log(this.formBuilderForm) 
    this.form.addControl(this.conf.name, this.formBuilderForm.controls[this.conf.name]);
    var item = Object.assign({}, this.form.getRawValue());
    delete item.applicationId;
    if (item.businesses && item.businesses.length && item.persons && item.persons.length) {
      var _persons = item.persons;
      item.businesses[0].persons = _persons;
      delete item.persons;
    }
    if (item.isTesting) {
      this.onCreateSession(item);
      return;
    } else {
      this.sessionsCompanyService.preSessionCreateCheck(item.configurationId).subscribe({
        next: res => {
          if (res == "") {
            this.onCreateSession(item)
          } else {
            var w = window.open(res, '_blank');
            var pollTimer = window.setInterval(() => {
              if (w.closed !== false) { // !== is required for compatibility with Opera
                window.clearInterval(pollTimer);
                this.onCreateSession(item)
              }
            }, 200);
            var mdl = <ConfirmationDialogData>{
              title: 'modal_title_payment_enrollment_complete',
              content: 'modal_content_payment_enrollment_complete',
              btnConfirmText: 'modal_btn_payment_enrollment_complete',
            };
            this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
              if (confirmed) {
                this.onCreateSession(item)
              }
            });
          }
        }
      });
    }
  }
onCreateSession(item) {
    if (this.sessionCreated)
      return;
    this.sessionCreated = true;
    this.sessionsCompanyService.create({ item }).subscribe(
      {
        next: val => {
          if (item.responseTransport == "LONG_POLLING") {
            this.ui.snack("Session created!")
            this.router.navigate(['..', val.id], { relativeTo: this.activatedRoute })
          } else {
            this.ui.snack("Session created! Remember to reload sessions in several minutes.");
            this.router.navigate(['/', 'company', 'sessions']);
          }

        }, error: error => {
          this.ui.loaderHide(error.message);
          this.ui.snack(error.message);
        }
      }
    );
  }

  showTestDataPanel() {
    this.router.navigate([
      { outlets: { sidebar: ['session-test-data', this.formType, this.checksSupportedByConfiguration.join('|')] } }
    ],
      { relativeTo: this.activatedRoute.parent });
  }
  getCheckSum(checks: CompanyCheck[]) {
    let sum = 0;
    checks?.forEach((check, index) => {
      if (check?.pricePerCheck && check?.isActive)
        sum += check?.pricePerCheck;
    })
    return sum;
  }
}
