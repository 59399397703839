import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Tag } from 'src/app/_graphql/schema';
import { TagsService } from 'src/app/_services/tags.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  data$: Subscribable<Tag[]>;
  constructor(public tags: TagsService) { }

  ngOnInit(): void {
    this.data$ = this.tags.all();
    console.log(this.data$)
  }
}
