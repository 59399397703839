import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable, take } from 'rxjs';
import { Provider } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { ProvidersService } from 'src/app/_services/providers.service';

@Component({
  selector: 'app-provider-item',
  templateUrl: './provider-item.component.html',
  styleUrls: ['./provider-item.component.scss']
})
export class ProviderItemComponent implements OnInit {
  data$: Subscribable<Provider>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    code: [null, Validators.required],
    description: [null, []],
    isTesting: [true, []],
    keyVaultCert: [null, []],
    keyVaultId: [null, []],
    testingAccessConfig: [null, []],
  });
  constructor(
    private _ngZone: NgZone,
    public providers: ProvidersService,
    private fb: FormBuilder,
    public auth: AuthService,
  ) { }


  ngOnInit(): void {
  }

}
