import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModuleComponent } from '../translate-module/translate-module.component';

@Component({
  selector: 'translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {
  @Input() term: string;
  @Input() keyDisabled: boolean = true;
  @Input() color: string;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openModal() {

    if (!this.term)
      return ;

    const dialogRef = this.dialog.open(TranslateModuleComponent, {
      height: 'auto',
      width: '800px',
      data: { term: this.term, keyDisabled: this.keyDisabled }
    });
  }
}
