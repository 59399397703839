import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { Provider } from "../_graphql/schema";
import { BaseService } from "./base.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class ProvidersService extends BaseService<Provider> {
  selectOneFields = gql`
    fragment SelectOneFields on Provider {
      id
      name
      description
      code
      deleted
      keyVaultId
      keyVaultCert
      testingAccessConfig
      isTesting
      #fieldSets { id name description }
      #fields { id name description }
    }
  `;

  selectAllFields = gql`
    fragment SelectAllFields on Provider {
      id
      name
      description
      code
      created
      deleted
      isTesting
    }
  `;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql("provider", "providers");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }
}
