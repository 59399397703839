import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Check } from 'src/app/_graphql/schema-session';

@Component({
  selector: 'app-check-result-status',
  templateUrl: './check-result-status.component.html',
  styleUrls: ['./check-result-status.component.scss']
})
export class CheckResultStatusComponent implements OnInit, OnDestroy {
  @Input('status') status: string;

  constructor(
  ) { }
  ngOnInit(): void {

  }
  ngOnDestroy(): void {

  }
}
