import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],

})

export class AvatarComponent implements OnInit {
  @Input() avatarId: string;
  image: string;
  @Input() type: string = "avatar";

  constructor(

  ) {

  }
  ngOnInit(): void {
    if (!this.image && this.avatarId)
      this.image = `${environment.imageThumbnails}/${this.avatarId}`;
  }

}
