import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscribable } from 'rxjs';
import { Country } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CountriesService } from 'src/app/_services/countries.service';
import { CurrentUserService } from 'src/app/_services/current-user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  form = this.fb.group({
    id: [null],
    countryId: [null, [Validators.required]],
    address: [null, [Validators.required]],
    address2: [null, []],
    avatarId: [null, []],
    city: [null, []],
    email: [null, [Validators.email, Validators.required]],
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    middleName: [null, []],
    phoneNumber: [null, []],
    state: [null, []],
    title: [null, []],
    zip: [null, []],
  });
  $countries: Subscribable<Country[]>;
  hide = true;



  constructor(
    public service: CurrentUserService,
    public countriesService: CountriesService,
    private fb: FormBuilder,
    public auth: AuthService,
  ) {
    this.$countries = this.countriesService.dropdown();

  }


  ngOnInit(): void {
    // this.form.patchValue({
    //   firstName: "Dusko",
    //   lastName: "Angirevic",
    //   middleName: "Milka",
    //   phoneNumber: "+38765919289",
    //   address: "Banovic Strahinje",
    //   address2: "3",
    //   city: "Banja Luka",
    //   zip: "78000",
    //   iAgree: true,
    //   email: "dusko+1@invenit.io",
    //   password: "Hopa123Cupa",
    //   countryId: "1250d1d7-420a-458e-8712-c91c4bcf8407"
    // })
  }


}
