<div fxFill *ngIf="!($purchases|async)" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
</div>

<div fxLayout="column" *ngIf="($purchases|async) as purchases" fxLayout.gt-md="row" fxLayoutGap="24px"
    fxLayoutGap.gt-md="36px" class="p-4">
    <!-- <button (click)="test()">test</button> -->

    <div fxFlex="34%">
        <div *ngIf="(purchases ) as _purchases">
            <app-subscription-card-item *ngIf="_purchases?.length" [item]="_purchases[0]?.product">

                <button fxFlex mat-stroked-button color="primary" routerLink="/company/subscriptions/change"
                    *ngIf="auth.hasPermission(auth.perm.PurchaseManage)">
                    Change
                </button>

                <button fxFlex mat-stroked-button color="warn" (click)="cancelProduct()" [disabled]="canceling"
                    *ngIf="(_purchases[0]?.product.paymentOption != 'PAY_AS_YOU_GO') && auth.hasPermission(auth.perm.PurchaseManage)">
                    Cancel
                </button>
            </app-subscription-card-item>
        </div>


    </div>

    <div fxFlex fxLayout="column" fxLayoutGap="24px" fxLayoutGap.gt-lg="36px">
        <div *ngIf="($purchases|async) as purchases">
            <mat-card *ngIf="purchases.length">
                <mat-card-title>
                    Checks Usage Breakdown
                </mat-card-title>

                <mat-card-content>

                    <div fxLayout="column" class="rows">
                        <div fxFlex *ngFor="let check of purchases[0]?.purchaseItems; index as i" fxLayout="row"
                            class="row-item" fxLayoutAlign="space-around center">
                            <div fxLayout="column">
                                <strong> {{check?.productCheck?.providerCheck?.name}} </strong>
                                <small>
                                    {{check?.includedChecks }} checks included
                                </small>
                            </div>
                            <div fxFlex></div>
                            <div fxLayout="column" fxFlex class="text-right" fxLayoutAlign="center end">
                                <strong *ngIf="check?.includedChecks - check?.spentChecks > 0">
                                    {{ check?.includedChecks - check?.spentChecks}} check{{check?.includedChecks -
                                    check?.spentChecks==1 ? '' : 's'}} left</strong>
                                <strong *ngIf="(check?.includedChecks - check?.spentChecks)  < 0" class="extra">{{
                                    check?.spentChecks - check?.includedChecks}} extra check{{check?.includedChecks -
                                    check?.spentChecks!=1 ? '' : 's'}} spent</strong>

                                <small *ngIf="(check?.includedChecks - check?.spentChecks)  < 0">
                                    {{
                                    (check?.productCheck?.paymentInterval == 'PER_CHECK' ?
                                    (check?.price *
                                    (check?.spentChecks -
                                    check?.includedChecks )) : (check?.price *
                                    (check?.days )))| currency
                                    }}</small>
                            </div>

                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>

        <div *ngIf="purchases?.length">
            <mat-card>
                <mat-card-title>
                    Payment Details
                </mat-card-title>
                <mat-card-content fxLayout="column" class="rows">
                    <div fxFlex fxLayout="row" fxLayoutGap="8px" class="row-item" fxLayoutAlign="space-between center"
                        *ngFor="let itm of purchases;">
                        <div fxLayout="column" fxFlex>
                            <strong>Last Payment</strong>
                            <small>{{itm?.startOfSubscription | date}} &dash; {{itm?.endOfSubscription | date}}</small>
                        </div>
    
                        <div fxFlex *ngIf="($creditCard | async) as creditCard">
                            <mat-form-field  style="margin-bottom: 0; max-width: 60%">
                                <mat-label>{{ 'Credit Card' }}</mat-label>
                                <input matInput type="text" [value]="creditCard" [disabled]="true" />
                                <img matSuffix *ngIf="creditCard[0]==4" src="/assets/images/cc-visa.svg">
                                <img matSuffix *ngIf="creditCard[0]==5" src="/assets/images/cc-mastercard.svg">
                                <img matSuffix *ngIf="creditCard[0]==3" src="/assets/images/cc-americanexpress.svg">
                                <img matSuffix *ngIf="creditCard[0]==6" src="/assets/images/cc-discover.svg">
                            </mat-form-field>
                        </div>
                        <div>
                        <div *ngFor="let itm of purchases;">
                            <app-purchase-status [status]="itm?.status"></app-purchase-status>
                        </div>
                        <div fxFlex *ngIf="itm.product.price" class="price text-right" style="margin-top: 3px;margin-left: 3px;">
                            {{itm.product.price | currency }}
                        </div>
                    </div>
                    </div>
                    <div *ngIf="($nextSubscription|async) as ns">
                        <div *ngIf="ns?.nextSubscription" fxFlex fxLayout="row" fxLayoutGap="8px" class="row-item"
                            fxLayoutAlign="space-around center">
                            <div fxLayout="column" fxFlex>
                                <strong>Next Payment ({{ns.nextSubscription?.product.name}})</strong>
                                <small>{{ns.nextSubscription?.startOfNextSubscription | date}} &dash;
                                    {{ns.nextSubscription?.endOfSubscription | date}}</small>
                            </div>
                            <div *ngIf="ns.nextSubscription?.product.price" class="price text-right">
                                {{ns.nextSubscription?.product.price | currency }}
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-button routerLink="/company/invoices">View invoices</button>
                </mat-card-actions>
            </mat-card>
        </div>

    </div>


</div>