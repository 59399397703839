import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { CompanyCheck, Permission } from "../_graphql/schema";
import { AuthService } from "./auth.service";
import { BaseService } from "./base.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class CompanyChecksService extends BaseService<CompanyCheck> {

  selectOneFields = gql`
    fragment SelectOneFields on CompanyCheck {
      id
      name
      created
      deleted
      isActive
      description
      companyId
      providerCheckId
      ${this.auth.allowedField(Permission.CompanyCheckPricingRead, 'maxRateMonthly')}
      ${this.auth.allowedField(Permission.CompanyCheckPricingRead, 'pricePerCheck')}
    }
  `;


  selectAllFields = gql`
    fragment SelectAllFields on CompanyCheck {
      id
      name
      created
      deleted
      description
      stats {
        count
        ${this.auth.allowedField(Permission.ConfigurationPricingRead, 'price')}
        ${this.auth.allowedField(Permission.ProviderPricingRead, 'cost')}
      }
      providerCheck {
        id
        name
      }
    }
  `;


  constructor(protected apollo: Apollo, protected sb: MatSnackBar, private auth: AuthService) {
    super(apollo, sb);
    this.initGql("companyCheck");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }
}
