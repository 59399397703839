import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable } from 'rxjs';
import { Country } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CountriesService } from 'src/app/_services/countries.service';

@Component({
  selector: 'app-country-item',
  templateUrl: './country-item.component.html',
  styleUrls: ['./country-item.component.scss']
})
export class CountryItemComponent implements OnInit {
  data$: Subscribable<Country>;

  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    isoCode2: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(2), Validators.pattern('^[a-zA-Z \-\']+')]],
    isoCode3: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern('^[a-zA-Z \-\']+')]],
    isDefault: [false, [Validators.required]],
    order: [100],
  });

  constructor(
    public auth: AuthService,
    public countries: CountriesService,
    private fb: FormBuilder
  ) {
    const isoCode2 = this.form.get('isoCode2');
    isoCode2.valueChanges.subscribe(() => {
      isoCode2.patchValue(isoCode2.value?.toUpperCase(), { emitEvent: false });
    });
    const isoCode3 = this.form.get('isoCode3');
    isoCode3.valueChanges.subscribe(() => {
      isoCode3.patchValue(isoCode3.value?.toUpperCase(), { emitEvent: false });
    });
  }

  ngOnInit(): void {
  }

}
