<mat-accordion *ngIf="documents?.length && 
(!((documents | filterOn : 'number' : [null])?.length) || !((documents | filterOn : 'individualReferenceNumber': [null])?.length))" >
  <h3>Document<span *ngIf="documents?.length>1">s</span></h3>
  <mat-expansion-panel *ngFor="let document of documents">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{('documentType_'+document?.type)|translate}}
      </mat-panel-title>
      <mat-panel-description>
        <span></span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table *ngIf="document">
      <tr *ngIf="document?.idType">
        <th>{{'document_idType' | translate}}</th>
        <td>{{('documentIdType_'+document?.idType)|translate}}</td>
      </tr>
      <tr *ngIf="document?.type">
        <th>{{'document_type' | translate}}</th>
        <td>{{('documentType_'+document?.type)|translate}}</td>
      </tr>
      <tr *ngIf="document?.number">
        <th>{{'document_number' | translate}}</th>
        <td>{{document?.number}}</td>
      </tr>
      <tr *ngIf="document?.state">
        <th>{{'document_stateOfIssue' | translate}}</th>
        <td>{{document?.state}}</td>
      </tr>
      <tr *ngIf="document?.provinceOfIssue">
        <th>{{'document_provinceOfIssue' | translate}}</th>
        <td>{{document?.provinceOfIssue}}</td>
      </tr>
      <tr *ngIf="document?.cityOfIssue">
        <th>{{'document_cityOfIssue' | translate}}</th>
        <td>{{document?.cityOfIssue}}</td>
      </tr>
      <tr *ngIf="document?.country">
        <th>{{'document_countryOfIssue' | translate}}</th>
        <td>{{document?.country}}</td>
      </tr>
      <tr *ngIf="document?.districtOfIssue">
        <th>{{'document_districtOfIssue' | translate}}</th>
        <td>{{document?.districtOfIssue}}</td>
      </tr>
      <tr *ngIf="document?.expiry">
        <th>{{'document_expiry' | translate}}</th>
        <td>{{document?.expiry | date : 'mediumDate' : 'UTC'}}</td>
      </tr>
      <tr *ngIf="document?.serialNumber">
        <th>{{'document_card_number' | translate}}</th>
        <td>{{document?.serialNumber }}</td>
      </tr>
      <tr *ngIf="document?.individualReferenceNumber">
        <th>{{'document_individualReferenceNumber' | translate}}</th>
        <td>{{document?.individualReferenceNumber}}</td>
      </tr>
      <tr *ngIf="document?.cardType">
        <th>{{'document_cardType' | translate}}</th>
        <td>{{document?.cardType}}</td>
      </tr>
      <tr *ngFor="let item of document?.images; index as i;">
        <th>{{'document_file' | translate}}</th>
        <td class="file">
            <span (click)="downloadDocument(item.fileId, item.name)" class="download" matTooltip="{{'download_file' |translate}}"> {{item?.name}}</span>
            <span style="font-weight: 400;">{{item?.description}}</span>
        </td>
      </tr>
    </table>
  </mat-expansion-panel>

</mat-accordion>