import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscribable, Unsubscribable } from 'rxjs/internal/types';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { CompanyToken, Product, Purchase } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { ProductsService } from 'src/app/_services/products.service';
import { PurchasesService } from 'src/app/_services/purchases.service';
import { TokensService } from 'src/app/_services/tokens.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit, OnDestroy {
  $data: Subscribable<Product[]>;
  $purchases: Unsubscribable;
  purchases: Purchase[];
  loading = false;
  constructor(
    public service: ProductsService,
    public auth: AuthService,
    public router: Router,
    public purchasesService: PurchasesService,
    public ui: UiService
  ) {
    this.ui.setTitle("Select new subscription");

  }
  ngOnDestroy(): void {
    this.$purchases?.unsubscribe();
  }



  payProduct(item: Product) {
    let upgrade = item.order > this.purchases[0].product.order
    console.log(upgrade)
    var mdl = <ConfirmationDialogData>{
      title: (upgrade ? 'Upgrade to' : 'Downgrade to') + ' ' + item.name + ' subscription?',
      content: 'You are about to change current subscription. Do you want to continue?',
      btnConfirmText: upgrade ? 'Upgrade' : 'Downgrade',
      btnConfirmMode: upgrade ? 'primary' : 'warn'
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.loading = true;
        this.service.payProduct(item.id).subscribe({
          next: res => {
            if (res == "") {
              this.onContinue()
            } else {
              var w = window.open(res, '_blank');
              var pollTimer = window.setInterval(() => {
                if (w.closed !== false) { // !== is required for compatibility with Opera
                  window.clearInterval(pollTimer);
                  this.onContinue()
                }
              }, 200);
            }
          },
          error: e => {
            this.loading = false;
          }
        });
      }
    });
  }
  onContinue() {
    console.log('onContinue')
    var i = setTimeout(() => {
      this.purchasesService.queryParams.take = 1;
      var s = this.purchasesService.all({}, false).subscribe({
        next: x => {
          this.loading = false;
          this.ui.snack("Subscription change request noted.")
          this.purchasesService.refetch();
          s.unsubscribe();
          this.router.navigateByUrl('/company/subscriptions');
        }
      });
    }, 1000);
  }
  ngOnInit(): void {
    this.service.queryParams.isVisible = true;
    this.service.queryParams.sortBy = "order";
    this.$data = this.service.all();

    this.purchasesService.queryParams.take = 1;
    this.$purchases = this.purchasesService.all({ isActive: true }, false).subscribe({
      next: x => {
        this.purchases = x;
      }
    });
  }
}
