import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { I } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  FilesFragment = gql`
    fragment FilesField on EndUserType {
      files {
        id blobName contentType
        name size description
        category { id name }
        deleted created modified
      }
    }
  `;


  selectCategoriesQuery = gql`
    query fileCategories {
      fileCategories {
        id name code
      }
    }
  `;

  updateFilePropertiesMutation = gql`
    mutation setFileProperties(
      $fileIds: [ID!]!
      $fileCategoryId: ID
      $description: String
      $userId: ID
    ) {
      setFileProperties(
        fileIds: $fileIds
        fileCategoryId: $fileCategoryId
        description: $description
      ) {
        id
        name
        category {
          id
          name
        }
        description
      }
    }
  `;

  deleteFileMutation = gql`
    mutation deleteFile($fileId: ID!) {
      deleteFile(fileId: $fileId)
    }
  `;



  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private auth: AuthService
  ) { }

  getCategories() {
    return this.apollo
      .watchQuery({
        query: this.selectCategoriesQuery
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.fileCategories)
      );
  }

  public setFileProperties(fileProps: any) {
    return this.apollo.mutate({
      mutation: this.updateFilePropertiesMutation,
      variables: fileProps
    });
  }

  public deleteFile(fileId: any, projectId: any, lotId: any) {
    return this.apollo.mutate({
      mutation: this.deleteFileMutation,
      variables: { fileId }
    });
  }

  public downloadFile(fileId: string, name: string) {
    let url = environment.fileDownload + fileId;
    if (fileId == 'templateType=2') {
      url = environment.fileDownload + '?' + fileId;
    }
    this.http
      .get(url, {
        responseType: 'blob',
        headers: { 'Authorization': 'Bearer ' + this.auth.authToken },
      })
      .subscribe(fileBlob => {
        saveAs(fileBlob, name);
      });
  }

  postImage(
    filesToUpload: FileList,
    categoryCode: string = 'AVATAR',
    description: string | null = null,
    applicationId: string | null = null
  ) {
    // console.log(filesToUpload, fileCategory, description)
    const endpoint = environment.fileUpload;
    const formData: FormData = new FormData();

    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload: any = filesToUpload.item(i);
      formData.append('file_' + 1, fileToUpload, fileToUpload.name);
    }

    if (categoryCode) {
      formData.append('categoryCode', categoryCode);
    }

    if (description) {
      formData.append('description', description);
    }

    if (applicationId) {
      formData.append('applicationId', applicationId);
    }

    // console.log('file upload', formData);
    return this.http.post(endpoint, formData, { headers: { 'Authorization': `Bearer ${this.auth.authToken}` } }).pipe(
      map((files: any) => files));
  }

  postFiles(
    filesToUpload: FileList,
    categoryCode: any = null
  ) {
    const endpoint = environment.fileUpload;
    const formData: FormData = new FormData();

    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload: any = filesToUpload.item(i);
      formData.append('file_' + 1, fileToUpload, fileToUpload.name);
    }

    if (categoryCode) {
      formData.append('categoryCode', categoryCode);
    }

    return this.http.post(endpoint, formData, { headers: { 'Authorization': `Bearer ${this.auth.authToken}` } });
  }


  updateFileList(projectId: any, lotId: any) {
    // console.log('update file list', projectId, lotId);

    // this.apollo
    //   .query({
    //     query: null,
    //     variables: {
    //       id: projectId ? projectId : lotId
    //       },
    //     fetchPolicy: 'network-only'
    //   })
    //   .subscribe(({ data }) => {
    //     console.log('query execute project or lot files', data);
    //   });
  }
}
