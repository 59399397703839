import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable, take } from 'rxjs';
import { Product, ProductCheck, Provider, ProviderCheck } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { ProductChecksService } from 'src/app/_services/product-checks.service';
import { ProductsService } from 'src/app/_services/products.service';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';
import { ProvidersService } from 'src/app/_services/providers.service';

@Component({
  selector: 'app-product-check-item',
  templateUrl: './product-check-item.component.html',
  styleUrls: ['./product-check-item.component.scss']
})
export class ProductCheckItemComponent implements OnInit {
  data$: Subscribable<Product>;
  $providdataerChecks: Subscribable<ProductCheck[]>;
  $providerChecks: Subscribable<ProviderCheck[]>;
  $products: Subscribable<Product[]>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    description: [null, []],
    includedChecks: [null, []],
    paymentInterval: ['PER_CHECK', Validators.required],
    nextPrice: [null, []],
    productId: [null, Validators.required],
    providerCheckId: [null, Validators.required],
  });
  constructor(
    private _ngZone: NgZone,
    private fb: FormBuilder,
    public auth: AuthService,
    public service: ProductChecksService,
    public productsService: ProductsService,
    public providerChecksService: ProviderChecksService,
  ) {
    providerChecksService.queryParams.take = 25000;
    productsService.queryParams.take = 25000;
    this.$providerChecks = this.providerChecksService.all();
    this.$products = this.productsService.all();


  }


  ngOnInit(): void {
  }

}
