<item-wrapper #item [mainService]="configurations" [form]="form" navigateList="configurations" title="Configuration"
  [readPerm]="auth.perm.ConfigurationRead" [managePerm]="auth.perm.ConfigurationManage" [normalizeProps]="['checks']">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0px" fxLayoutGap.gt-md="12px">
      <mat-form-field fxFlex fxFlex.gt-md="140px">
        <mat-label>{{ 'Config Code' }}</mat-label>
        <input matInput formControlName="configCode" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <p class="small" innerHTML="{{ 'configuration_info_config_code' | translate}}"></p>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description'|translate }}</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{'Checks'|translate}}</mat-label>
      <mat-select formControlName="checks" multiple [compareWith]="compareObjects">
        <mat-option *ngFor="let check of companyChecks$ |async" [value]="{id: check.id}">
          {{ check?.providerCheck?.provider?.name ? check?.providerCheck?.provider?.name+': ':"" }} {{check.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-checkbox color="primary" matInput type="checkbox" formControlName="isTesting">
      {{'Is Testing'|translate}}
    </mat-checkbox>
    <mat-checkbox color="primary" matInput type="checkbox" formControlName="allowOverrides">
      {{'Allow Overrides'|translate}}
    </mat-checkbox>
    <p class="small" innerHTML="{{ 'configuration_info_allow_overrides' | translate}}"></p>
  </form>

</item-wrapper>