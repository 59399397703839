import { Component, Input } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Company, Product } from 'src/app/_graphql/schema';
import { CompaniesService } from 'src/app/_services/companies.service';

@Component({
  selector: 'app-subscription-card-item',
  templateUrl: './subscription-card-item.component.html',
  styleUrls: ['./subscription-card-item.component.scss']
})
export class SubscriptionCardItemComponent {
  company$: Subscribable<Company>;
  _item: Product;

  get item(): Product {
    return this._item;
  }
  @Input() set item(item: Product) {
    let _itm = Object.assign({}, item);
    if (_itm.features) {
      _itm.features = Object.assign([], _itm.features);
      _itm.features = _itm.features.sort((a, b) => a?.order - b?.order);
    }
    this._item = _itm;
  }

  constructor(
    private companies: CompaniesService,
  ) {
    this.company$ = this.companies.currentCompany();


  }

}
