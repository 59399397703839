import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable, take, Unsubscribable } from 'rxjs';
import { ConfirmationDialogData } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { Feature, PaymentInterval, Product, ProviderCheck } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { FeaturesService } from 'src/app/_services/features.service';
import { ProductChecksService } from 'src/app/_services/product-checks.service';
import { ProductsService } from 'src/app/_services/products.service';
import { ProviderChecksService } from 'src/app/_services/provider-checks.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit, OnDestroy {
  $route: Unsubscribable;
  itemId: string;
  $product: Unsubscribable;
  product: Product;
  $data: Subscribable<Product>;
  $providerChecks: Subscribable<ProviderCheck[]>;
  $features: Subscribable<Feature[]>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  paymentIntervals = PaymentInterval;
  expansionPanelCount: number = 0;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  newProductCheckForm = this.fb.group({
    id: [null, []],
    name: [null, [Validators.required]],
    description: [null, []],
    includedChecks: [null, [Validators.required]],
    paymentInterval: ['PER_CHECK', Validators.required],
    nextPrice: [null, [Validators.required]],
    productId: [null, Validators.required],
    providerCheckId: [null, Validators.required],
  });
  form = this.fb.group({
    id: [null],
    coverId: [null],
    name: [null, Validators.required],
    icon: ['custom.svg'],
    order: [null],
    description: [null, []],
    alternateDescription: [null, []],
    paymentOption: ['MONTHLY', []],
    price: [null, []],
    oldPrice: [null, []],
    productChecks: this.fb.array([]),
    features: this.fb.array([]),
    isDefault: [false, []],
    isDisabled: [false, []],
    isVisible: [false, []],
  });
  formChecks = this.fb.group({
    checks: this.fb.array([])
  });

  constructor(
    private _ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    public service: ProductsService,
    private featuresService: FeaturesService,
    private fb: FormBuilder,
    public auth: AuthService,
    private ui: UiService,
    public providerChecks: ProviderChecksService,
    public productChecksService: ProductChecksService,
  ) {
    this.$providerChecks = this.providerChecks.dropdown({queryParams: {skip: null, take: null}});
    this.$features = this.featuresService.dropdown({queryParams: {skip: null, take: null}});
    this.$route = this.route.queryParams.subscribe(params => {
      this.loadData(this.route.snapshot.paramMap.get('id'));
      if (this.route.snapshot.paramMap.get('id') === 'new')
        this.form.patchValue({ 'isVisible': true });
    });
  }
  ngOnDestroy(): void {
    this.$product?.unsubscribe();
    this.$route?.unsubscribe();
  }

  loadData(itemId: string) {
    if (itemId == null || itemId == 'new' || this.itemId == itemId) {
      return;
    }
    this.newProductCheckForm.patchValue({ productId: itemId });
    this.itemId = itemId;
    this.$data = this.service.one(itemId);
    this.$product = this.service.one(itemId).subscribe({
      next: _product => {
        this.product = Object.assign({}, _product);

        this.productChecks.clear();
        this.product?.checks.forEach(check => {
          if (check?.providerCheck) {
            this.productChecks.push(this.fb.group({
              id: [check?.id, [Validators.required]],
              name: [check?.name, [Validators.required]],
              description: [check?.description, []],
              paymentInterval: [check?.paymentInterval, [Validators.required]],
              includedChecks: [check?.includedChecks, [Validators.required]],
              nextPrice: [check?.nextPrice, [Validators.required]],
              providerCheckId: [check?.providerCheckId, Validators.required],
            }));
          }
        });

        this.features.clear();

        this.product.features.forEach(productFeature => {
          this.features.push(this.fb.group({
            id: [productFeature?.id, [Validators.required]]
          }));
        });

      }
    })
  }

  ngOnInit(): void {}

  deleteProductCheck(idx) {
    var mdl = <ConfirmationDialogData>{
      title: 'modal_title_delete_product_check',
      content: 'modal_content_delete_product_check',
      btnConfirmText: 'modal_btn_delete_product_check',
      btnConfirmMode: 'warn'
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.productChecks.removeAt(idx)
      }
    });
  }

  get productChecks(): FormArray {
    return this.form.get('productChecks') as FormArray
  }

  addProductCheck(providerCheckId) {
    this.productChecks.push(this.fb.group({
      id: [null, []],
      name: [null, [Validators.required]],
      description: [null, []],
      paymentInterval: ['PER_CHECK', [Validators.required]],
      includedChecks: [null, [Validators.required]],
      nextPrice: [null, [Validators.required]],
      providerCheckId: [providerCheckId, [Validators.required]],
    }));
  }

  get features(): FormArray {
    return this.form.get('features') as FormArray
  }

  addFeature(feature) {
    var fCc = this.features;
    fCc.push(this.fb.group({
      id: [feature?.id],
      name: [feature?.name],
      description: [feature?.description],
      icon: [feature?.icon],
      order: [feature?.order],
    }));
  }
  deleteFeature(idx) {
    var mdl = <ConfirmationDialogData>{
      title: 'modal_title_delete_product_feature',
      content: 'modal_content_delete_product_feature',
      btnConfirmText: 'modal_btn_delete_product_feature',
      btnConfirmMode: 'warn'
    };
    this.ui.openConfirmationDialog(mdl).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.features.removeAt(idx)
      }
    });
  }


  navigateTo(path: string) {
    this.router.navigateByUrl(path);
  }

}
