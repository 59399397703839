import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs';
import { Country } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { CountriesService } from 'src/app/_services/countries.service';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit {
  $data: Subscribable<Country[]>;

  constructor(
    public auth: AuthService,
    public countries: CountriesService
  ) { }

  ngOnInit(): void {
    this.$data = this.countries.all();
  }

}
