<div fxFlex.gt-sm="140px" class="holder">
    <form [formGroup]="form">
        <app-file-upload formControlName="fileId" (fileUploaded)="patchFileId($event)"
            (fileRemoved)="delete()"></app-file-upload>
    </form>
    <form [formGroup]="fg">
        <mat-form-field appearance="fill" fxFill>
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" placeholder="">
        </mat-form-field>
    </form>
</div>