<mat-accordion *ngIf="addresses?.length && !((addresses | filterOn : 'street' : [null, ''])?.length)">
  <h3>Address<span *ngIf="addresses?.length>1">es</span></h3>
  <mat-expansion-panel *ngFor="let address of addresses">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{address?.street}} {{address?.streetLine2}} {{address?.streetNumber}}{{address?.city ? ', ' : null}}
        {{address?.city}}
        {{address?.countryCode ? '('+address?.countryCode+')' : ''}}
      </mat-panel-title>
      <mat-panel-description>
        <span></span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table *ngIf="address">
      <tr *ngIf="address?.street">
        <th>{{'address_street' | translate}}</th>
        <td>{{address?.street}}</td>
      </tr>
      <tr *ngIf="address?.streetLine2">
        <th>{{'address_streetLine2' | translate}}</th>
        <td>{{address?.streetLine2}}</td>
      </tr>
      <tr *ngIf="address?.streetNumber">
        <th>{{'address_streetNumber' | translate}}</th>
        <td>{{address?.streetNumber}}</td>
      </tr>
      <tr *ngIf="address?.province">
        <th>{{'address_province' | translate}}</th>
        <td>{{address?.province}}</td>
      </tr>
      <tr *ngIf="address?.postalCode">
        <th>{{'address_postalCode' | translate}}</th>
        <td>{{address?.postalCode}}</td>
      </tr>
      <tr *ngIf="address?.city">
        <th>{{'address_city' | translate}}</th>
        <td>{{address?.city}}</td>
      </tr>
      <tr *ngIf="address?.countryCode">
        <th>{{'address_countryCode' | translate}}</th>
        <td>{{address?.countryCode}}</td>
      </tr>
    </table>
  </mat-expansion-panel>

</mat-accordion>