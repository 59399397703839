import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscribable, Subscription } from 'rxjs';
import { SessionsCompanyService } from 'src/app/_services/sessions-company.service';


@Component({
  selector: 'session-test-data',
  templateUrl: './session-test-data.component.html',
  styleUrls: ['./session-test-data.component.scss']
})
export class SessionTestData implements OnInit, OnDestroy {
  @Input('form') form: FormGroup;
  @Input('formType') formType: String;

  checkTypes = [];
  $onRoute: Subscription;
  items$: Subscribable<any>;
  constructor(
    public ss: SessionsCompanyService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
  ) {

    this.$onRoute = this.route.queryParams.subscribe(params => {
      this.checkTypes = this.route.snapshot.paramMap.get('checkTypes').split("|");
      this.formType = this.route.snapshot.paramMap.get('formType');
    });

    this.items$ = ss.getSessionTestData();
  }
  ngOnInit(): void {


  }
  setData(item, wrapper) {
    console.log(['Send', item])
    this.ss.sessionTestDataForm.next(item);
    wrapper.closeSidebar();
  }
  ngOnDestroy(): void {
    this.$onRoute?.unsubscribe();
  }
}
