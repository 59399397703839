<mat-accordion class="example-headers-align" *ngIf="communications?.length
&& (!((communications | filterOn : 'phone' : [null, ''])?.length) 
|| !((communications | filterOn : 'email' : [null, ''])?.length))">
  <h3>Communication<span *ngIf="communications?.length>1">s</span></h3>
  <mat-expansion-panel *ngFor="let item of communications">
    <mat-expansion-panel-header *ngIf="itemCount(item)>0">
      <mat-panel-title>
        {{ itemCount(item) }}
        {{ (itemCount(item) == 1 ? 'entry' : 'entries') |translate }}
      </mat-panel-title>
      <mat-panel-description>
        <span></span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table>
      <tr *ngIf="item?.email">
        <th>{{'communication_email' | translate}}</th>
        <td>{{item?.email}}</td>
      </tr>
      <tr *ngIf="item?.phone">
        <th>{{'communication_phone' | translate}}</th>
        <td>{{item?.phone}}</td>
      </tr>
    </table>
  </mat-expansion-panel>

</mat-accordion>