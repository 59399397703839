import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { CountUpModule } from 'ngx-countup';
import { SessionsModule } from '../sessions/sessions.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComponentsModule } from 'src/app/components/components.module';





@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    SessionsModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    WrappersModule,
    RouterModule,
    CountUpModule,
    NgxChartsModule,
    ComponentsModule
  ]
})
export class DashboardModule { }
