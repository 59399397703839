import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { ItemWrapperComponent } from './item-wrapper/item-wrapper.component';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CompanyRowItem } from '../company/company-row-item/company-row-item.component';
import { PlaceholderComponent } from '../placeholder/placeholder.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PublicWrapperComponent } from './public-wrapper/public-wrapper.component';
import { RouterModule } from '@angular/router';
import { AvatarUploadComponent } from '../avatar-upload/avatar-upload.component';
import { AvatarComponent } from '../avatar/avatar.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';





@NgModule({
  declarations: [
    ListWrapperComponent,
    ItemWrapperComponent,
    CompanyRowItem,
    PublicWrapperComponent,
    PlaceholderComponent,
    AvatarComponent,
    AvatarUploadComponent,
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ],
  exports: [
    ItemWrapperComponent,
    ListWrapperComponent,
    PublicWrapperComponent,
    CompanyRowItem,
    PlaceholderComponent,
    AvatarComponent,
    AvatarUploadComponent,
    FileUploadComponent
  ]
})
export class WrappersModule { }
