<public-wrapper mini="true">
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch" (submit)="onSubmit()">
    <h3>{{'account_activate_title' | translate}}</h3>
    <p>{{'account_activate_description' | translate}}</p>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Email' | translate }}</mat-label>
      <input matInput type="email" [value]="this.form.get('email').value" disabled />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Verification Code' | translate }}</mat-label>
      <input matInput type="text" [value]="this.form.get('token').value" disabled />
    </mat-form-field>
    <mat-form-field hintLabel="{{'Needs to contain at least one lower, upper and one digit character.'}}">
      <mat-label>{{'Password' | translate}}</mat-label>
      <input matInput type="password" #password formControlName="password" minlength="6" [type]="hide ? 'password' : 'text'"
        (keyup.enter)="onSubmit()" />
      <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
    [userPassword]="this.form.get('password')">
  </app-password-strength>
    <button mat-raised-button fxFlex color="primary" [disabled]="!this.form.valid">{{'Activate'| translate}}</button>
  </form>
</public-wrapper>