import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: forwardRef(() => RangePickerComponent),
    }
  ]
})
export class RangePickerComponent implements ControlValueAccessor, OnInit, Validator {

  possibleRanges = ['today', 'yesterday', 'lastWeek', 'lastMonth', 'last7Days', 'last30Days', 'custom'];
  data:any;
  notPassed:boolean = false;
  @ViewChild('picker') picker: MatDateRangePicker<Date>;

  constructor() { }

  onChange(event:any) {
    this.data.range = event != this.data.range ? event : null;
    try {
      if (this.data.range) {
        this.data.startDate = null;
        this.data.endDate = null;
        this.setRange(this.data?.range);
        this.notPassed = false;
      } else {
        this.data.startDate = null;
        this.data.endDate = null;
      }
    }
    catch {
      this.notPassed = true;
    }
    this.propagateChange(this.data);
  }

  changeStartEndDate(event:any, dateType:string) {
    if (dateType === 'startDate') {
      this.data.startDate = new Date(event.value?._d)?.toISOString();
      // this.data.endDate = null;
    }
    if (dateType === 'endDate') {
      this.data.endDate =  new Date(event.value?._d)?.toISOString();
    }

    this.propagateChange(this.data);
  }


  ngOnInit(): void {

  }
 
  public writeValue(data: any) {
    this.data = data;
    if (data?.range)
      this.setRange(data.range);
  }

  registerOnTouched(onTouched: any) {
  }

  markAsTouched() {

  }
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  setDisabledState(disabled: boolean) {
  }
  private propagateChange = (_: any) => {};


  setRange(range) {

    switch (range) {
      case 'today':
        this.data.startDate = moment().utcOffset(0).startOf('day').toDate().toISOString();
        this.data.endDate = moment().utcOffset(0).endOf('day').toDate().toISOString();
        break;
      case 'yesterday':
        this.data.startDate = moment().utcOffset(0).startOf('day').subtract(1, 'd').toDate().toISOString();
        this.data.endDate = moment().utcOffset(0).endOf('day').subtract(1, 'd').toDate().toISOString();
        break;
      case 'lastWeek':
        let curWeek = moment().utcOffset(0).startOf('day').day("Monday").week();
        this.data.startDate = moment().utcOffset(0).startOf('day').day("Monday").week(curWeek - 1).toDate().toISOString();
        this.data.endDate = moment().utcOffset(0).endOf('day').day("Sunday").week(curWeek).toDate().toISOString();
        break;
      case 'lastMonth':
        this.data.startDate = moment().utcOffset(0).startOf('day').subtract(1, 'month').date(1).toDate().toISOString();
        this.data.endDate = moment().utcOffset(0).endOf('day').date(1).subtract(1, 'day').toDate().toISOString();
        break;
      case 'last7Days':
          this.data.startDate = moment().utcOffset(0).startOf('day').subtract(8, 'd').toDate().toISOString();
          this.data.endDate = moment().utcOffset(0).endOf('day').subtract(1, 'd').toDate().toISOString();
        break;
      case 'last30Days':
          this.data.startDate = moment().utcOffset(0).startOf('day').subtract(31, 'd').toDate().toISOString();
          this.data.endDate = moment().utcOffset(0).endOf('day').subtract(1, 'd').toDate().toISOString();
        break;
      case 'custom':
        this.picker?.open();
        break;
      default:
        this.data.startDate = null;
        this.data.endDate = null;
    }
  }
  clearRange() {
    this.data.range = null;
    this.data.startDate = null;
    this.data.endDate = null;
    this.propagateChange(this.data);
  }
  
  public validate(c: FormControl) {
    return (!this.notPassed) ? null : {
      error: {
        valid: false,
      },
    };
  }
}
