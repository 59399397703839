import { Component, Input, OnInit } from '@angular/core';
import { CheckType } from 'src/app/_graphql/schema';
import { Check } from 'src/app/_graphql/schema-session';
import { TranslationsService } from 'src/app/_services/translations.service';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent implements OnInit {
  @Input('translationKey') translationKey: string;
  _title = "";
  _description = "";

  constructor(
    private trans: TranslationsService
  ) {

  }
  ngOnInit(): void {
    var _keyTitle = 'placeholder_' + this.translationKey + '_title';
    var _keyDescription = 'placeholder_' + this.translationKey + '_description';
    this.trans.getTranslationsByKey(_keyTitle).subscribe({
      next: x => {
        var _tr = x.find(item => item.key == _keyTitle);
        if (_tr) {
          this._title = _tr.value;
        }
      }
    });
    this.trans.getTranslationsByKey(_keyDescription).subscribe({
      next: x => {
        var _tr = x.find(item => item.key == _keyDescription);
        if (_tr) {
          this._description = _tr.value;
        }
      }
    });
    this._title = 'placeholder_' + this.translationKey + '_title (< translate)';
    this._description = 'placeholder_' + this.translationKey + '_description (< translate)';
  }
}
