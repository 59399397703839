import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscribable } from 'rxjs';
import { CompanyCheck, Company, ConfigApplication, Configuration } from 'src/app/_graphql/schema';
import { ApplicationsService } from 'src/app/_services/applications.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyChecksService } from 'src/app/_services/checks.service';
import { ConfigurationsService } from 'src/app/_services/configurations.service';
@Component({
  selector: 'app-configuration-item',
  templateUrl: './configuration-item.component.html',
  styleUrls: ['./configuration-item.component.scss']
})
export class ConfigurationItemComponent implements OnInit {

  data$: Subscribable<Configuration>;
  companyChecks$: Subscribable<CompanyCheck[]>;
  companies$: Subscribable<Company[]>;
  applications$: Subscribable<ConfigApplication[]>;

  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }
  form = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    checks: [null, Validators.required],
    description: [null, []],
    configCode: [null, Validators.required],
    configApplicationId: [null, []],
    allowOverrides: [false, Validators.required],
    isTesting: [false, Validators.required],
  });

  constructor(
    public configurations: ConfigurationsService,
    public application: ApplicationsService,
    public companyChecksService: CompanyChecksService,
    public auth: AuthService,
    private fb: FormBuilder
  ) {

  }
  ngOnInit(): void {
    this.companyChecks$ = this.companyChecksService.all();
  }

}
