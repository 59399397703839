import { Component, OnInit } from '@angular/core';
import { Subscribable } from 'rxjs/internal/types';
import { State } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { StatesService } from 'src/app/_services/states.service';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss']
})
export class StatesComponent implements OnInit {

  data$: Subscribable<State[]>;
  constructor(
    public auth: AuthService,
    public states: StatesService
  ) { }

  ngOnInit(): void {
    this.data$ = this.states.all();
  }
}
