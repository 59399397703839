import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, CompanyStatus } from 'src/app/_graphql/schema';



@Component({
  selector: 'company-change-status-dialog',
  templateUrl: './company-change-status-dialog.component.html',
  styleUrls: ['./company-change-status-dialog.component.scss']
})
export class CompanyChangeStatusDialogComponent {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  item: Company;
  public get companyStatuses() {
    return CompanyStatus;
  }

  form = this.fb.group({
    companyId: [null, Validators.required],
    newStatus: [null, Validators.required],
    allowedPaymentMethod: [null],
    statusUserMessage: [null, [Validators.maxLength(450)]],
    adminMessage: [null, [Validators.maxLength(450)]],
  });

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<CompanyChangeStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CompanyChangeStatusDialogComponentData,

  ) {
    this.item = data.item;
    this.form.patchValue({ companyId: data.item.id })
  }
}
export interface CompanyChangeStatusDialogComponentData {
  item: Company
}