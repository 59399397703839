<mat-card>
  <mat-card-header>
    <div mat-card-avatar class="avatar">
      <mat-icon>work_outline</mat-icon>
    </div>
    <mat-card-title class="mat-card-title">
      {{ business?.companyName}}
    </mat-card-title>
    <mat-card-subtitle class="mat-card-subtitle">
      {{ business?.foundingDate | date : 'mediumDate' : 'UTC'}} {{ business?.companyIdType}} {{ business?.companyId}} &nbsp;
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-card *ngIf="business?.companyName">
      <table>
        <tr *ngIf="business?.companyName">
          <th>{{'business_companyName' | translate}}</th>
          <td>{{ business?.companyName }}</td>
        </tr>
        <tr *ngIf="business?.foundingDate">
          <th>{{'business_foundingDate' | translate}}</th>
          <td>{{ business?.foundingDate | date : 'mediumDate' : 'UTC' }}</td>
        </tr>
        <tr *ngIf="business?.companyIdType">
          <th>{{'business_companyIdType' | translate}}</th>
          <td>{{ business?.companyIdType }}</td>
        </tr>
        <tr *ngIf="business?.companyId">
          <th>{{'business_companyId' | translate}}</th>
          <td>{{ business?.companyId }}</td>
        </tr>
        <tr *ngIf="business?.referenceId">
          <th>{{'business_referenceId' | translate}}</th>
          <td>{{ business?.referenceId }}</td>
        </tr>
      </table>
    </mat-card>
    <app-view-address [addresses]="business?.addresses"></app-view-address>
    <app-view-communication [communications]="business?.communications"></app-view-communication>
    <view-business-documents [documents]="business?.documents"></view-business-documents>
    <div *ngFor="let person of business?.persons" style="margin-top: 24px;">
      <app-view-person [person]="person"></app-view-person>
    </div>

  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions> -->
</mat-card>