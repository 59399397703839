<button mat-icon-button class="dialog-close-btn" tabindex="-1" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</button>

<h2 mat-dialog-title>{{'Notes' | translate}}: {{company.name}}</h2>
<div mat-dialog-content #scrollMe>
  <div class="notes" *ngIf="company?.adminNote">
    <div *ngFor="let note of company?.adminNote.split('||')">
      <app-note-item [note]="note" *ngIf="note"> </app-note-item>
    </div>
  </div>
</div>
<form mat-dialog-actions [formGroup]="form" (submit)="onSubmit()">
  <mat-form-field appearance="fill">
    <mat-label>{{'Enter note'|translate}}</mat-label>
    <textarea matInput cdkTextareaAutosize formControlName="note" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"></textarea>
    <button mat-icon-button matSuffix color="primary" [disabled]="!form.valid">
      <mat-icon>send</mat-icon>
    </button>
  </mat-form-field>
</form>