import { Component, Input, OnInit } from '@angular/core';
import { Check } from 'src/app/_graphql/schema-session';

@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styleUrls: ['./check-status.component.scss']
})
export class CheckStatusComponent implements OnInit {
  @Input('status') status: string;

  constructor(
  ) { }
  ngOnInit(): void {

  }
}
