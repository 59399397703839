import { NgModule } from '@angular/core';
import {ClickStopPropagation} from "./click-stop-propagation.directive"


@NgModule({
    declarations: [
        ClickStopPropagation
    ],
    exports: [
        ClickStopPropagation
    ]
})
export class DirectivesModule { }
