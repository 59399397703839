<item-wrapper #item [mainService]="service" [form]="form" navigateList="product-checks" title="Product Check"
  [readPerm]="auth.hasPermission(auth.perm.ProductRead)" [managePerm]="auth.hasPermission(auth.perm.ProductManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">

    <mat-form-field fxFlex>
      <mat-label>{{ 'Name' }}</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description' }}</mat-label>
      <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Product' |translate }}</mat-label>
      <mat-select formControlName="productId">
        <mat-option *ngFor="let item of $products | async" [value]="item.id">
          {{(item.name) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'Provider Check' |translate }}</mat-label>
      <mat-select formControlName="providerCheckId">
        <mat-option *ngFor="let item of $providerChecks | async" [value]="item.id">
          {{(item.name) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div fxLayoutGap="16px">
      <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
        <mat-label>{{ 'Included Checks' }}</mat-label>
        <input matInput formControlName="includedChecks" type="number" step="1" />
      </mat-form-field>
      <mat-form-field fxFlex *ngIf="auth.hasPermission(auth.perm.ProviderManage)">
        <mat-label>{{ 'Next Price' | translate}}</mat-label>
        <input matInput formControlName="nextPrice" type="number" step="0.01" />
        <button mat-icon-button matSuffix disabled>
          <mat-icon>euro</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
</item-wrapper>
<prE>{{ form.value | json }}</prE>