import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';
import { RegisterComponent } from './register.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { VerifyComponent } from './verify/verify.component';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';


@NgModule({
  declarations: [
    RegisterComponent,
    VerifyComponent,
    AcceptInviteComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PasswordStrengthComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    WrappersModule,
  ]
})
export class RegisterModule { }
