<item-wrapper #item [mainService]="service" [form]="form" navigateList="/company/users" title="User"
  [readPerm]="auth.hasPermission(auth.perm.UserRead)"
  [managePerm]="auth.hasRoles(['Admin', 'CompanyAdmin']) || (auth.hasRoles(['Admin', 'CompanyAdmin']) && itemId == 'new') || ((itemId == auth.user?.id) )">

  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column" fxLayoutGap="12px"
    style="padding: 0 12px;">
    <div fxLayout="row" fxLayoutGap="16px">
      <app-avatar-upload fxFlex="120px" formControlName="avatarId"></app-avatar-upload>
      <div fxFlex fxLayout="column">
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="8px" fxLayoutGap.gt-md="16px">
          <mat-form-field fxFlex.gt-md>
            <mat-label>{{ 'First Name' | translate }}</mat-label>
            <input matInput formControlName="firstName" />
          </mat-form-field>
          <mat-form-field fxFlex.gt-md="25%">
            <mat-label>{{ 'Middle Name' | translate }}</mat-label>
            <input matInput formControlName="middleName" />
          </mat-form-field>
          <mat-form-field fxFlex.gt-md>
            <mat-label>{{ 'Last Name' | translate }}</mat-label>
            <input matInput formControlName="lastName" />
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="8px" fxLayoutGap.gt-md="16px">
          <mat-form-field fxFlex *ngIf="form.get('id').value">
            <mat-label>{{ 'E-mail' | translate }}</mat-label>
            <input matInput [value]="form.get('email')?.value" disabled/>
            <mat-icon class="error" matSuffix matTooltip="{{'Resend email verification'|translate}}" 
            *ngIf="!auth.user?.emailConfirmed" (click)="resendEmailVerification()">error</mat-icon>          
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="!form.get('id').value">
            <mat-label>{{ 'E-mail' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-hint>This will be your username</mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>{{ 'Phone' | translate }}</mat-label>
            <input matInput formControlName="phoneNumber" />
          </mat-form-field>

        </div>
      </div>
    </div>








    <h3>Address</h3>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 1' | translate }}</mat-label>
        <input matInput formControlName="address" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Street Line 2' | translate }}</mat-label>
        <input matInput formControlName="address2" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex="25">
        <mat-label>{{ 'Postal Code' | translate }}</mat-label>
        <input matInput formControlName="zip" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'City' | translate }}</mat-label>
        <input matInput formControlName="city" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0" fxLayoutGap.gt-md="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Country' | translate }}</mat-label>
        <mat-select formControlName="countryId">
          <mat-option *ngFor="let item of $countries | async" [value]="item.id">
            {{(item.name) | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'State' | translate }}</mat-label>
        <input matInput formControlName="state" />
      </mat-form-field>
    </div>
    <!-- <mat-checkbox matInput color="primary" type="checkbox" formControlName="iAgree" *ngIf="!form.get('id').value"
      [ngClass]="{'unselected': !form.get('iAgree')?.value}"><span [innerHTML]="( 'register.terms' | translate)"></span>
    </mat-checkbox> -->



  </form>

</item-wrapper>