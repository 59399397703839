<list-wrapper #wrapper [mainService]="service" navigateItem="fieldsets" title="Fieldsets"
  [readPerm]="auth.hasPermission(auth.perm.SystemRead)" [managePerm]="auth.hasPermission(auth.perm.SystemManage)">
  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="46px"></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>
      <div fxFlex="120px">
        <div mat-line>{{'Created' }}</div>
      </div>
      <div fxFlex="54px">
      </div>
    </h3>
    <mat-list-item *ngFor="let item of data$ | async; index as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{ deleted: item?.deleted}">
      <mat-icon matListIcon>verified_user</mat-icon>
      <strong mat-line>{{ item?.name }}</strong>

      <small mat-line>
        {{ item?.description }}
      </small>

      <div fxFlex="120px">
        <div mat-line><small>{{ item?.created | date }}</small></div>
      </div>
      <div fxFlex="54px">
        <div mat-line *ngIf="item?.isSystemLocked">
          <mat-icon matTooltip="System locked">lock</mat-icon>
        </div>
      </div>
      <mat-divider [inset]="true" *ngIf="i+1<(data$ | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>