<list-wrapper #wrapper [mainService]="service" navigateItem="sessions" title="Dashboard" [showQueryField]="false"
    [showPagination]="false" [readPerm]="auth.hasPermission(auth.perm.SessionRead)" [managePerm]="false"
    [showDateFilter]="true" (onDateChanged)="onDateChanged()">
    <div class="io-px-3 .io-pb-2">
        <form [formGroup]="form" class="forma">
            <div class="io-ml-a">
                <app-range-picker #rangePicker formControlName="dateRange"></app-range-picker>
            </div>
        </form>
        <div class="io-row-l">
            <div class="io-flex">
                <div *ngIf="purchase">
                    <h3 class="io-mt-0">Subscription</h3>
                    <div class="io-row io-pb-2">
                        <div class="io-flex">
                            <div class="io-card io-p-3">
                                <div class="io-row-l" style="align-items: center;">
                                    <div class="io-flex" style="width: 100%;">
                                        <div class="io-row io-pb-2">
                                            <div class="io-flex">
                                                <div class="io-pb-1 stats2">
                                                    <small>Spent Checks</small>
                                                    <strong [countUp]="checksSpent">0</strong>
                                                </div>
                                            </div>
                                            <div class="io-flex">
                                                <div class="io-pb-1 stats2">
                                                    <small>Available Checks</small>
                                                    <strong
                                                        [countUp]="checksIncluded>checksSpent ? checksIncluded-checksSpent : 0">0</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="io-row" *ngIf="$nextSubscription|async as data">
                                            <div class="io-flex" *ngIf="!data?.nextSubscription">
                                                <div class="stats2">
                                                    <small>Started on</small>
                                                    <strong>{{purchase?.startOfSubscription|date:'shortDate'}}</strong>
                                                </div>
                                            </div>
                                            <div class="io-flex" *ngIf="purchase?.endOfSubscription">
                                                <div class="stats2">
                                                    <small>Renews on</small>
                                                    <strong>{{purchase?.endOfSubscription|date:'shortDate'}}</strong>
                                                </div>
                                            </div>
                                            <div class="io-flex" *ngIf="data?.nextSubscription">
                                                <div class="stats2">
                                                    <small>Next Subscription</small>
                                                    <strong>{{data?.nextSubscription?.product?.name}}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="io-flex" style="width: 100%;">
                                        <button mat-flat-button class="io-p-2 subs-box"
                                            routerLink="/company/subscriptions">
                                            <div class="io-row" style="align-items: center;">
                                                <img *ngIf="purchase?.product?.icon"
                                                    [src]="'/assets/images/'+purchase?.product?.icon" class="no-flex" />
                                                <div class="io-flex" style="line-height:1.4">
                                                    <div>
                                                        <strong>{{purchase?.product?.name}}</strong>
                                                        <small
                                                            *ngIf="purchase?.product?.paymentOption != 'PAY_AS_YOU_GO'">{{
                                                            purchase?.product?.price | currency }}/month</small>
                                                        <small
                                                            *ngIf="purchase?.product?.paymentOption == 'PAY_AS_YOU_GO'">{{
                                                            purchase?.product?.description}}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="desc">{{purchase?.product?.alternateDescription}}</span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <ng-container *ngIf="(sessionsExist$ | async)?.totalCount == 0 && showBtnCreateSession">
                    <h3>Sessions</h3>
                    <div class="io-d-flex io-card io-p-3" style="justify-content:space-between;">
                        <div>You have not created sessions yet.</div>
                        <button mat-button color="primary" routerLink="/company/sessions/new">Create session</button>
                    </div>
                </ng-container>
                <div *ngIf="(sessionsExist$ | async)?.totalCount > 0 && (sessions$ | async) as sessions">
                    <h3>Pending Checks</h3>
                    <div class="io-row io-pb-2">
                        <div class="io-flex">
                            <div class="io-card">
                                <mat-list *ngIf="!sessions.length">
                                    <mat-list-item>
                                        0 checks performed
                                    </mat-list-item>
                                </mat-list>
                                <mat-action-list *ngIf="sessions.length">
                                    <app-session-list-item mode="short" *ngFor="let item of sessions " [item]="item"
                                        (click)="wrapper.edit('/company/sessions/'+item.id)"></app-session-list-item>
                                    <div style="text-align: right;">
                                        <button mat-button color="primary"
                                            (click)="rangePicker.clearRange(); provideFilters({isPendingCheck:true})">Show
                                            more</button>
                                    </div>
                                </mat-action-list>
                            </div>

                        </div>
                    </div>
                </div>
                <h3 *ngIf="(sessionsExist$ | async)?.totalCount > 0">Sessions</h3>
                <div *ngIf="(sessionsExist$ | async)?.totalCount > 0" class="io-row-l">
                    <div class="io-flex">
                        <div class="io-card io-p-2">
                            <div class="placeholder ratio-16x9">
                                <div class="chart-holder">
                                    <div class="legend-custom">
                                        <span *ngFor="let itm of pieData" [matTooltip]="itm?.name">
                                            <div>{{itm?.name}}</div>
                                        </span>
                                    </div>
                                    <ngx-charts-bar-horizontal [results]='pieData' [scheme]="colorScheme"
                                        [xAxis]="false" [yAxis]="false" [legend]="false" [roundEdges]="false">
                                    </ngx-charts-bar-horizontal>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="io-flex">
                        <div class="io-card io-p-2">
                            <div class="placeholder ratio-16x9">
                                <div class="legend-custom">
                                    <span *ngFor="let itm of sessionsPie" [matTooltip]="itm?.name">
                                        <div>{{itm?.name}}</div>
                                    </span>
                                </div>
                                <div class="chart-holder">
                                    <ngx-charts-pie-chart [scheme]="colorScheme" [results]='sessionsPie'
                                        [doughnut]="true" [legend]="false" legendPosition="right" legendTitle="" [roundEdges]="false">
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="(sessionsExist$ | async)?.totalCount > 0" class="io-flex">
                <h3 class="io-mt-0">Other Info</h3>
                <div class="io-row io-pb-2">
                    <div class="io-flex">
                        <a (click)="provideFilters(form.value)">
                            <div class="io-card stats border-blue">
                                <strong [countUp]="sessionsCount">0</strong>
                                <small>Sessions</small>
                            </div>
                        </a>
                    </div>
                    <div class="io-flex">
                        <a (click)="provideFilters(form.value)">
                            <div class="io-card stats border-dark">
                                <strong [countUp]="checksCount">0</strong>
                                <small>Checks</small>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="io-row io-pb-2">
                    <div class="io-flex">
                        <a (click)="provideFilters({isMonitoring:true,sessionStatuses:['ONGOING']})">
                            <div class="io-card stats border-orange">
                                <strong [countUp]="monitoredCount">0</strong>
                                <small>Actively monitored</small>
                            </div>
                        </a>
                    </div>
                    <div class="io-flex">
                        <a (click)="provideFilters({isPendingCheck: true})">
                            <div class="io-card stats border-info">
                                <strong [countUp]="pendingCount">0</strong>
                                <small>Pending</small>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="io-row io-pb-2" *ngIf="(sessionsExist$ | async)?.totalCount > 0 && checksData.length">
                    <div class="io-flex">
                        <h3>Performed Checks</h3>
                        <div class="io-card io-p-2">
                            <div class="placeholder ratio-16x9">
                                <div class="chart-holder">
                                    <ngx-charts-bar-vertical [results]="checksData" [scheme]="colorScheme"
                                        [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                        [showYAxisLabel]="false" [xAxisLabel]="false" [yAxisLabel]="true"
                                        [rotateXAxisTicks]="false" [roundEdges]="false">
                                    </ngx-charts-bar-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="io-row">
                    <div class="io-flex">
                        <h3>Sessions</h3>
                        <div class="io-card io-p-3">
                            <div class="placeholder ratio-16x9">
                                <div class="chart-holder">
                                    <ngx-charts-bar-horizontal [results]='pieData' [scheme]="colorScheme" [xAxis]="true"
                                        [yAxis]="true" [legend]="false">
                                    </ngx-charts-bar-horizontal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
    <!-- <pre>{{pieData|json}}</pre>
    <pre>{{checksData|json}}</pre> -->
</list-wrapper>