import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InfoComponent } from '../admin/info-page/info.component';
import { LoginComponent } from '../admin/login/login.component';
import { AcceptInviteComponent } from '../admin/register/accept-invite/accept-invite.component';
import { ForgotPasswordComponent } from '../admin/register/forgot-password/forgot-password.component';
import { RegisterComponent } from '../admin/register/register.component';
import { ResetPasswordComponent } from '../admin/register/reset-password/reset-password.component';
import { VerifyComponent } from '../admin/register/verify/verify.component';
import { TermsAndConditionsComponent } from '../admin/terms-and-conditions/terms-and-conditions.component';
import { Permission } from '../_graphql/schema';
import { AuthGuard } from '../_guards/auth.guard';
import { CompanyCheckItemComponent } from './company-checks/company-check-item/company-check-item.component';
import { CompanyChecksComponent } from './company-checks/company-checks.component';
import { CompanyItemComponent } from './company-item/company-item.component';
import { ConfigurationItemComponent } from './configurations/configuration-item/configuration-item.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './logout/logout.component';
import { SessionCertificateComponent } from './sessions/certificate/certificate.component';
import { CertificatePublicComponent } from './sessions/certificate-public/certificate-public.component';
import { ResultItemComponent } from './sessions/result-item/result-item.component';
import { SessionItemComponent } from './sessions/session-item/session-item.component';
import { SessionComponent } from './sessions/session/session.component';
import { SessionsComponent } from './sessions/sessions.component';
import { TokenItemComponent } from './tokens/token-item/token-item.component';
import { TokensComponent } from './tokens/tokens.component';
import { CompanyUserItemComponent } from './users/user-item/user-item.component';
import { CompanyUsersComponent } from './users/users.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { PaymentOptionsComponent } from './payments/payment-options/payment-options.component';
import { PaymentsComponent } from './payments/payments.component';
import { InvoicesComponent } from './payments/invoices/invoices.component';
import { InvoiceItemComponent } from './payments/invoices/invoice-item/invoice-item.component';
import { SessionBulkImportComponent } from './sessions/session-bulk-import/session-bulk-import.component';
import { InventoryComponent } from './inventory/inventory.component';
import { SessionTestData } from './sessions/session/session-test-data/session-test-data.component';
import { RedirectComponent } from './redirect/redirect.component';



const routes: Routes = [
  {
    path: 'terms',
    component: TermsAndConditionsComponent
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'register',
    component: RegisterComponent
  }, {
    path: 'verify/:email/:verificationCode',
    component: VerifyComponent
  }, {
    path: 'accept-invite/:email/:token',
    component: AcceptInviteComponent
  }, {
    path: 'reset-password/:email/:verificationCode',
    component: ResetPasswordComponent
  }, {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  }, {
    path: 'info/:id',
    component: InfoComponent
  }, {
    path: 'logout',
    component: LogoutComponent,
  }, {
    path: 'certificate-public/:id',
    component: CertificatePublicComponent
  },
  {
    path: 'certificate/:id',
    component: SessionCertificateComponent,
    canActivate: [AuthGuard],
    data: {
      permission: Permission.SessionRead,
      redirectPath: '/login'
    },
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },

  {
    path: 'company',
    component: WrapperComponent,
    children: [
      {
        path: 'session-test-data/:formType/:checkTypes',
        component: SessionTestData,
        outlet: 'sidebar',
      },
      // {
      //   path: 'full',
      //   pathMatch: 'full',
      //   redirectTo: '/company/dashboard'
      // },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/login'
        }
      }, {
        path: '',
        component: CompanyItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'profile/:id',
        component: CompanyItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/company'
        }
      },
      {
        path: 'inventory',
        component: InventoryComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/'
        }
      },
      {
        path: 'sessions',
        component: SessionsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/configurations'
        }
      },
      {
        path: 'sessions/q/:search',
        component: SessionsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/configurations'
        }
      },
      {
        path: 'sessions/bulk-import',
        component: SessionBulkImportComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionCreate,
          redirectPath: '/configurations'
        }
      },
      {
        path: 'sessions/new',
        component: SessionComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'sessions/edit/:id',
        component: SessionComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'sessions/:id',
        component: SessionItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/login'
        },
        children: [
          {
            path: 'result/:resultId',
            component: ResultItemComponent,
            canActivate: [AuthGuard],
            data: {
              permission: Permission.SessionRead,
              redirectPath: '/login'
            },
          }
        ]
      },
      {
        path: 'sessions/filter/:filter',
        component: SessionsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.SessionRead,
          redirectPath: '/configurations'
        }
      },
      {
        path: 'configurations',
        component: ConfigurationsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.ConfigurationRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'configurations/:id',
        component: ConfigurationItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.ConfigurationRead,
          redirectPath: '/company/configurations'
        }
      },
      {
        path: 'checks',
        component: CompanyChecksComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyCheckRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'checks/:id',
        component: CompanyCheckItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyCheckRead,
          redirectPath: '/company/checks'
        }
      },
      {
        path: 'tokens',
        component: TokensComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'tokens/:id',
        component: TokenItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.CompanyRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'users',
        component: CompanyUsersComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.UserRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'users/:id',
        component: CompanyUserItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.UserRead,
          redirectPath: '/company/users'
        },
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.UserRead,
          redirectPath: '/login'
        }
      }, {
        path: 'invoices/:id',
        component: InvoiceItemComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.UserRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'subscriptions',
        component: PaymentsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.UserRead,
          redirectPath: '/login'
        }
      },
      {
        path: 'subscriptions/change',
        component: PaymentOptionsComponent,
        canActivate: [AuthGuard],
        data: {
          permission: Permission.UserRead,
          redirectPath: '/login'
        }
      },
      {
        path: '**',
        redirectTo: 'login'
      }
    ]
  }, {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
