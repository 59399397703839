import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class CurrentUserService extends BaseService<any> {


  selectOneFields = gql`
    fragment SelectAllFields on User {
      id
    }
  `;
  selectAllFields = gql`
    fragment SelectAllFields on User {
      id
    }
  `;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar) {
    super(apollo, sb);
    this.initGql("currentUser", "currentUser");
    this.selectQuery = gql`
          query currentUser {
            currentUser {
              id
              created
              countryId
              address
              address2
              avatarId
              city
              email
              firstName
              lastName
              middleName
              iAgree
              phoneNumber
              state
              title
              zip
            }
          }`;
    this.selectOneQuery = gql`
          query currentUser {
            currentUser {
              id
              created
              countryId
              address
              address2
              avatarId
              city
              email
              firstName
              lastName
              middleName
              iAgree
              phoneNumber
              state
              title
              zip
            }
          }`;
    this.modifyMutation = gql`
          mutation updateProfile($item: UserInput!) {
            updateUser(item: $item) {
              id
              created
              countryId
              address
              address2
              avatarId
              city
              email
              firstName
              lastName
              middleName
              phoneNumber
              state
              title
              zip
            }
          }`;

  }
}
