import { L } from '@angular/cdk/keycodes';
import { error } from '@angular/compiler/src/util';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscribable, Unsubscribable } from 'rxjs';
import { Language, Translation } from 'src/app/_graphql/schema';
import { LanguagesService } from 'src/app/_services/languages.service';
import { TranslationsService } from 'src/app/_services/translations.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-translate-module',
  templateUrl: './translate-module.component.html',
  styleUrls: ['./translate-module.component.scss']
})
export class TranslateModuleComponent implements OnInit, OnDestroy {
  translations$: Subscribable<Translation[]>;
  languages: Language[];
  translations: Translation[];
  transSubscription: Unsubscribable;
  langSubscription: Unsubscribable;
  loading = true;

  form = this.fb.group({
    key: [null, Validators.required]
  });

  constructor(
    public trans: TranslationsService,
    public langs: LanguagesService,
    public fb: FormBuilder,
    public ui: UiService,
    public dialogRef: MatDialogRef<TranslateModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    console.log('dialog data', this.data);

    if (this.data.keyDisabled) {
      this.form.controls.key.disable();
    }

    this.form.patchValue({ key: this.data.term });
    this.translations$ = this.trans.getTranslationsByKey(this.data.term);

    this.langSubscription = this.langs.all().subscribe({
      next: (langs) => {
        this.transSubscription = this.translations$.subscribe({
          next: (translations) => {
            this.loading = false;
            // console.log('lang trans data: ', langs, translations);
            if (langs.length < 1)
              return;

            this.languages = langs;
            this.translations = translations;
            const forms = this.fb.array([]);
            langs.forEach((x: Language) => {
              const translation = translations.find(tr => tr.languageId == x.id);
              const form = this.fb.group({
                key: [this.data.term, Validators.required],
                value: [translation?.value, Validators.required],
                languageId: [x.id, Validators.required]
              });

              forms.push(form);
            });

            this.form.addControl('langs', forms);
          }
        });
      }
    });
  }

  get langsFormArray(): FormArray {
    return this.form.get('langs') as FormArray;
  }

  convert(frm: any, langId: number) {
    const lang = this.languages.find(x => x.id == langId);

    let isLat = false;
    if (lang?.isoCode2 == 'sr-cr') {
      isLat = false;
    } else if (lang?.isoCode2 == 'sr-lt') {
      isLat = true;
    } else {
      return;
    }
  }

  languageName(langId: number) {
    return this.languages.find(x => x.id == langId)?.name;
  }

  convertAndSave() {
    for (const lang of this.langsFormArray.controls) {
      this.convert(lang, lang.value.languageId);
    }

    this.save();
  }

  save() {
    const langs: any = this.form.controls.langs;

    for (const ll of langs.controls) {
      ll.controls.key.setValue(this.form.controls.key.value);
    }

    if (!this.form.valid)
      return;
    this.loading = true;
    this.trans
      .createUpdateTranslation(this.form.controls.langs.value)
      .subscribe(
        {
          next: (result) => {
            // console.log('createUpdateTranslation result', result);
            this.ui.loaderHide('Item saved');
            this.loading = false;
            this.dialogRef.close();
          }, error: (msg: any) => {
            console.log('ERROR' + msg);
            this.loading = false;
            this.ui.loaderHide('Can\'t save the item');
          }
        });
  }

  ngOnDestroy() {
    this.transSubscription?.unsubscribe();
  }

}
