import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { BehaviorSubject, map, Subscribable } from 'rxjs';
import { Dashboard, Permission } from '../_graphql/schema';
import { Session } from '../_graphql/schema-session';
import { BaseService } from './base.service';
import { AuthService } from './auth.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DashboardCompanyService extends BaseService<Dashboard> {

  selectOneFields = gql`
  fragment SelectOneFields on Dashboard  {
    businessSessionsCount
    checksCount
    monitoringsCount
    pendingChecksCount
    personSessionsCount
    sessionsCount
  }`;

  //    Do not work:
  //    # price @include(if: ${this.auth.hasPermGraph(Permission.ConfigurationPricingRead)})
  //    # cost @include(if: ${this.auth.hasPermGraph(Permission.ProviderPricingRead)})

  selectAllFields = gql`
  fragment SelectAllFields on Dashboard  {
    businessSessionsCount
    checksCount
    monitoringsCount
    pendingChecksCount
    personSessionsCount
    sessionsCount
  }`;

  constructor(protected apollo: Apollo, protected sb: MatSnackBar, protected auth: AuthService) {
    super(apollo, sb);


    this.initGql('dashboard', 'dashboards');
    this.queryParams.take = 25;
    this.queryParams.sortOrder = "DESC";
    this.queryParams.sortBy = "created";
  }

  gqlSessionStats = gql`
  query company ($pageRequestInput: PageRequestInput){
    company {
    id
      stats( pageRequestInput:$pageRequestInput ){
        count
      } 
    }
  }
  `;
  public getSessionStats(vars): Subscribable<any> {
    return this.apollo.watchQuery({
      query: this.gqlSessionStats, variables: { pageRequestInput: vars }
    }).valueChanges.pipe(
      map<any, any>((result: any) => {
        if (!result || !result.data) return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          var r = result.data[keys[0]];
          return r;
        }
        return null;
      })
    );
  }

  gqlCompanyChecksStats = gql`
  query companyChecks ($pageRequestInput: PageRequestInput){
    companyChecks {
     data {
       id
       checkType
       stats(pageRequestInput:$pageRequestInput) {
         count
       }
     }
   }

  }
  `;
  public getCompanyChecksStats(vars): Subscribable<any> {
    return this.apollo.watchQuery({
      query: this.gqlCompanyChecksStats, variables: { pageRequestInput: vars }
    }).valueChanges.pipe(
      map<any, any>((result: any) => {
        if (!result || !result.data) return null;
        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          var r = result.data[keys[0]];
          return r;
        }
        return null;
      })
    );
  }
}
