<item-wrapper #item [mainService]="countries" [form]="form" navigateList="countries" title="Country"
  [readPerm]="auth.hasPermission(auth.perm.ProviderRead)" [managePerm]="auth.hasPermission(auth.perm.ProviderManage)">
  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column" fxLayoutGap="12px"
    style="padding: 0 12px;">
    <div fxLayout="row" class="form-row" fxLayoutGap="12px">
      <mat-form-field class="full-width">
        <mat-label>{{ 'Name' }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div fxLayout="row" class="form-row" fxLayoutGap="12px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'ISO Code 2' }}</mat-label>
        <input matInput formControlName="isoCode2" maxlength="2" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'ISO Code 3' }}</mat-label>
        <input matInput formControlName="isoCode3" maxlength="3" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Order' }}</mat-label>
        <input type="number" matInput formControlName="order" />
      </mat-form-field>
    </div>

  </form>
</item-wrapper>