<list-wrapper #wrapper [mainService]="applications" navigateItem="applications" title="Configuration Groups"
  [readPerm]="auth.perm.ConfigurationRead" [managePerm]="auth.perm.ConfigurationManage">
  <mat-action-list class="inv-list">
    <h3 matSubheader fxLayout="row" fxFill>
      <div fxFlex="46px" fxHide.lt-md></div>
      <div fxFlex>
        <div mat-line>{{'Name' }}</div>
      </div>

      <div fxFlex="40">
        <div mat-line>{{'Configurations' | translate}}</div>
      </div>

      <div fxFlex="8px">
        <div mat-line></div>
      </div>

    </h3>
    <mat-list-item *ngFor="let item of $data | async; index  as i" (click)="wrapper.edit(item.id)"
      [ngClass]="{ deleted: item?.deleted}">
      <mat-icon matListIcon>apps</mat-icon>
      <h3 mat-line>
        {{ item?.name }}
      </h3>

      <small mat-line>{{item.description}}</small>
      <!-- <div fxFlex="45px" fxLayout="row">
        <mat-icon>dynamic_feed</mat-icon>
        <small>{{ item?.configurations?.length}}</small>
      </div> -->
      <div fxFlex="40" (click)="$event.stopPropagation()">
        <app-label-expander [items]="item?.configurations" linkPart="/company/configurations/">
        </app-label-expander>
      </div>
      <mat-divider [inset]="true" *ngIf="i+1<($data | async)?.length"></mat-divider>
    </mat-list-item>
  </mat-action-list>
</list-wrapper>